import React, { useContext, useEffect, useState } from 'react';
// import classes from './CondensedShopNow.module.css';
import CondensedShopNowSection from '../../molecules/CondensedShopNowSection/CondensedShopNowSection';
import ShopNowFeaturedItems from '../../molecules/ShopNowFeaturedItems/ShopNowFeaturedItems';
import shopNowClasses from '../ShopNow/ShopNow.module.css';
import { useNavigate } from 'react-router-dom';
import { changePageNumber, clearPageNumber, resetItems } from '../../../../redux/itemsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { config } from '../../../../config/config';
import { mobileStyle } from '../../Home';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { auctionSearch } from '../../../../redux/searchReducer';
// import BrowseItemUpperHalf from '../../molecules/BrowseItemUpperHalf/BrowsItemUpperHalf';
// import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import HeaderV2 from '../../molecules/Header/HeaderV2';
import NewFooter from '../../molecules/NewFooter/NewFooter';
import { ChevronLeft } from "@mui/icons-material";
// import constants from '../../../../constants/en';
import { imageUrls } from '../../../../constants/url';
import { FilterContext } from '../../../../store/filter-context';
import BrowseItemsBanner from '../../atoms/BrowseItemsBanner/BrowseItemsBanner';
import ShopSection from '../../molecules/ShopSection/ShopSection';
import { getAllCategoriesHaveItem } from '../../../../redux/categoryReducer';
import LoadingScreen from '../../LoadingScreen/Loading';
import { Helmet } from 'react-helmet-async';
const ASSETS_BASE_URL = config.ASSETS_BASE_URL
const {REACT_APP_URL} = config

// const {SHOP_NOW_DESKTOP_IMAGE, SHOP_NOW_MOBILE_IMAGE} = constants;
const SHOP_NOW_DESKTOP_IMAGE = imageUrls.shop_now_desktop_banner;
const SHOP_NOW_DESKTOP_BANNER_LOW_QUALITY = imageUrls.shop_now_desktop_banner_low_quality;
const SHOP_NOW_MOBILE_IMAGE = imageUrls.shop_now_mobile_banner;
const SHOP_NOW_MOBILE_IMAGE_LOW_QUALITY = imageUrls.shop_now_mobile_banner_low_quality;

const CondensedShopNow = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const primaryBackgroundColor = mobileStyle.backgroundColor[2];
    const primaryColor = mobileStyle.color[1];
    const forMobile = useMediaQuery({
        maxWidth: 768
    })
    const [searchText, setSearchText] = useState('')
    const {reset}  = useContext(FilterContext)
    const browseBrands = imageUrls.shop_now_brands_you_love_desktop;
    const browseBrandsLowQuality = imageUrls.shop_now_brands_you_love_desktop_low_quality;
    const browseBrandsMobile = imageUrls.shop_now_brands_you_love_mobile;
    const browseBrandsMobileLowQuality = imageUrls.shop_now_brands_you_love_mobile_low_quality;
    const browseCause = imageUrls.shop_now_cause_you_care_desktop;
    const browseCauseLowQuality = imageUrls.shop_now_cause_you_care_desktop_low_quality;
    const browseCauseMobile = imageUrls.shop_now_cause_you_care_mobile;
    const browseCauseMobileLowQuality = imageUrls.shop_now_cause_you_care_mobile_low_quality;
    // const browseMerch = imageUrls.shop_now_our_merch_desktop;
    // const browseMerchLowQuality = imageUrls.shop_now_our_merch_desktop_low_quality;
    // const browseMerchMobile = imageUrls.shop_now_our_merch_mobile;
    // const browseMerchMobileLowQuality = imageUrls.shop_now_our_merch_mobile_low_quality;

    const {categories, loading} = useSelector(state => state.categories)

    const params = {
        from: 'shop-now',
        image: !forMobile ? SHOP_NOW_DESKTOP_IMAGE : SHOP_NOW_MOBILE_IMAGE,
        heading: 'Boundless Shop',
        about: '50-80% of all proceeds from purchases go directly to charity.',
        lowQualityImage: !forMobile ? SHOP_NOW_DESKTOP_BANNER_LOW_QUALITY : SHOP_NOW_MOBILE_IMAGE_LOW_QUALITY
    }

    useEffect(() => {
        reset()/// for reseting filters in filtercontext
        dispatch(changePageNumber(1)) /// for changing page number of browseItems page
        dispatch(resetItems())
        // dispatch(getAllCategoriesHaveItem())
    },[])

    const firstSectionClick = () => {
        //   navigate('/browse-items', {state:{from:'shopNow-marketplace'}})
        navigate('/browse-brands')
    }

    const thirdSectionClick = () => {
        // navigate("/browse-causes", { state: { from: 'browse-cause' } })
        navigate("/browse-causes")
    }

    const fourthSectionClick = () => {
        // dispatch(clearPageNumber());
        // navigate("/browse-items", {
        //   state: { buyNow: true, title: "Buy now", from: 'buyNow' },
        // })
        navigate('/browse-stores')
    }

    const initiateSearch = async (searchTerm) => {
        dispatch(auctionSearch({ searchTerm, variable: 'itemsList' }))
        dispatch(auctionSearch({ searchTerm, variable: 'auctionList' }))
        navigate("/search", { state: { search: searchText } });
    };

    const handleChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleClick = (e) => {
        if (e.key === "Enter" && e.target.value.length >= 2) initiateSearch(e.target.value);
        if (!e.key && searchText.length >= 2) initiateSearch(searchText);
    };

    const goBack = () => {
        navigate(-1, {
            preventScrollReset: true
        });
    }

    const handleCategoryClick = (data) => {
        navigate(`/${data?.category?.id}/browse-items?item-type=browse-category`)
    }

    return (
        <>
        <Helmet prioritizeSeoTags>
            <title>Charitable Shopping For Goods at Boundless Giving</title>
            <meta 
              name="description" 
              content="At Boundless Giving, explore the range of charity shop fundraising ideas where 50-80% of all proceeds from charitable shopping for goods go directly to charity." 
            />
            <meta 
              name="keywords"
              content="Shopping For Good"
            />
            <link rel="canonical" href={`${REACT_APP_URL}/shop-now`} />
        </Helmet>
         {
            loading ? <LoadingScreen bgColor="#F0E8E3" /> :
            <div style={{ backgroundColor: primaryBackgroundColor, minHeight: '100vh' }} >
                <HeaderV2
                    logoType={(!forMobile) ? 'black' : 'black2'}
                    primaryBackgroundColor={primaryBackgroundColor}
                    primaryColor={mobileStyle.color[1]}
                    transparentNav
                    containerStyle={{ position: 'fixed' }}
                />
                <div className={`${shopNowClasses.shop_now_container}`}>
                    {/* <BrowseItemUpperHalf params={params} /> */}
                    <BrowseItemsBanner 
                       image={params?.image}
                       heading={params?.heading}
                       about={params?.about}
                       primaryColor={mobileStyle.color[1]}
                       desktopImageStyle={{backgroundPosition:"top right"}}
                       mobileImageStyle={{height:'340px', backgroundPosition:'center right'}}
                    //    gradientClass="shop_now_white_gradient"
                       aboutStyle={{fontSize:"22px",width:"300px"}}
                       lowQualityImage={params?.lowQualityImage}
                    />
                    <div className={`${shopNowClasses.shop_now_bottom_container}`} >
                        <div className={`${shopNowClasses.mobile_padding_container} ${shopNowClasses.section1_container}  margin_class`} >
                        <div className='back-container' style={{marginBottom:'25px'}}>
                       <button style={{display:'flex',columnGap:'10px',justifyContent:'center',alignItems:'center',fontSize:forMobile ? '12px':'18px'}} onClick={goBack}><ChevronLeft style={{color:'rgba(252, 51, 0, 1)'}}/>Back</button> 
                    </div>
                            <FormControl variant="outlined" fullWidth>
                                <OutlinedInput
                                    placeholder="Search"
                                    sx={{
                                        padding: '2px 10px',
                                        minHeight: '40px',
                                        borderRadius: "5px",
                                        border: `1px solid ${primaryColor}`,
                                        color: primaryColor,
                                        fontSize: !forMobile ? '18px' : '14px',
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        fontFamily: 'Instrument Sans',
                                        flexDirection: 'row-reverse'
                                    }}
                                    id="search-text-search-page"
                                    fullWidth
                                    onKeyDown={handleClick}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end" >
                                            <SearchIcon sx={{ color: "#30313a7a", fontSize: !forMobile ? '18px' : '14px', fontWeight: 600 }} />
                                            {/* onClick={handleClick} */}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <section  className={shopNowClasses.mobile_padding_container} >
                            <CondensedShopNowSection onClick={firstSectionClick} mainText={'Brands You Love'} image={forMobile ? browseBrandsMobile : browseBrands} lowQualityImage={forMobile ? browseBrandsMobileLowQuality :browseBrandsLowQuality} />
                        </section>
                        <section className={shopNowClasses.mobile_padding_container} style={{ backgroundColor: "rgba(212, 180, 184, 0.50)" }}>
                            <ShopNowFeaturedItems />
                        </section>
                        <section  className={shopNowClasses.mobile_padding_container} style={{ paddingTop: "40px", paddingBottom: "40px",  }}>
                            <CondensedShopNowSection onClick={fourthSectionClick} mainText={'By Causes You Care About'} image={forMobile ? browseCauseMobile : browseCause} roundedContainerStyleProps={{paddingBlock:forMobile && '92px'}} lowQualityImage={forMobile ? browseCauseMobileLowQuality : browseCauseLowQuality} />
                        </section>
                            {/* <CondensedShopNowSection onClick={fourthSectionClick} mainText='Browse By Merch' image={forMobile ? browseMerchMobile : browseMerch} lowQualityImage={forMobile ? browseMerchMobileLowQuality : browseMerchLowQuality} /> */}
                        {/* <section  className={shopNowClasses.mobile_padding_container} style={{ paddingTop: "20px", paddingBottom: "40px",  }}>
                            <div className={` margin_class ${shopNowClasses.section4_main} ${shopNowClasses.mobile_padding_container}`} >
                                        <ShopSection
                                            list={categories}
                                            onClick={(data) => handleCategoryClick(data)}
                                            imageFolder={'categoryIcons'}
                                            title='Browse by Categories'
                                            checkHaveItem={true}
                                        />
                            </div>
                        </section> */}
                    </div>
                </div>
                {/* <MobileSubFooter style={{ padding: '6vw 5vw 2vw 5vw' }} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
                <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

            </div>
            }
        </>
    )
}

export default CondensedShopNow