import moment from "moment-timezone"

export const getAge = (_dob) => {
    let today = new Date();
    let birthDate = new Date(_dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

// Default Start Date with time in UTC (international time standard)
export const setDefaultStartDate = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setUTCHours(0, 0, 0, 0);
    return endOfDay.toISOString();
}

// Default End Date with time in UTC (international time standard)
export const setDefaultEndDate = (date) => {
    const endOfDay = new Date(date);
    endOfDay.setUTCHours(23, 59, 59, 999);
    return endOfDay.toISOString();
}

// Calculate timeleft (Coundown)
export const calculateTimeLeft = (date) => {
    // const year = new Date(date).getFullYear();
    const difference = +new Date(moment.tz(date, "America/Los_Angeles").format()) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  }

export const formatDate = (date) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const newDate = new Date(date)
  return `${months[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`
}

export const getImageVariant=(base_url)=>{     
  if(window.innerWidth>=1440){
      return base_url+'_large'
   }else if(window.innerWidth<1440 && window.innerWidth>=1024 ){
      return base_url+'_medium'
   }else if(window.innerWidth<1024 && window.innerWidth>=768 ){
      return base_url+'_small' 
   }else if(window.innerWidth<768 && window.innerWidth>=425 ){
      return base_url+'_large'
   }else if(window.innerWidth<425 && window.innerWidth>=375 ){
      return base_url+'_medium'
   }else if( window.innerWidth<375 ){
      return base_url+'_small'
   }
}

export const getImageWithoutVariant =(base_url)=>{
  if(base_url) return base_url
}

export const checkImageBeforeUpload = (url) => {
   const img = document.createElement("img");
   const promise = new Promise((resolve, reject) => {
     img.onload = () => {
       const width  = img.naturalWidth;
       const height = img.naturalHeight; 
       resolve({width, height});
     };
     img.onerror = reject;
   });
   img.src = url;
   return promise;
 }

 export const getGuestUserId = () => {
   return  localStorage.getItem('guest_user_id')
 }