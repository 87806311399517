import React, { useEffect, useState } from 'react'
import donorServices from '../../../../services/donor-services'
import { useNavigate, useParams } from 'react-router-dom'
import HeaderV2 from '../../molecules/Header/HeaderV2';
import style from './AboutDonor.module.css'
import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import { mobileStyle } from '../../Home';
import NewFooter from '../../molecules/NewFooter/NewFooter';
import { Helmet } from 'react-helmet-async';
import HeaderV3 from '../../molecules/Header/HeaderV3/HeaderV3';

function AboutDonor() {
  const [donor, setDonor] = useState({})
  const navigate = useNavigate();

  const { donorId } = useParams();

  useEffect(() => {
    fetchDonor(donorId);
  }, [donorId])


  const fetchDonor = async (donorId) => {
    if (!donorId) return navigate('/not-found')
    const donor = await donorServices.getdonorById(donorId);
    setDonor(donor[0]);
  }
  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#F0E8E3" }}>
      <Helmet>
          <title>{`About ${donor?.org_name}`}</title>
          <meta 
            name="description" 
            content={donor?.about}
          />
      </Helmet>
      {/* <HeaderV2 isSubHeader={true} subHeaderTitle="About The Donor" primaryBackgroundColor="#F0E8E3" primaryColor="#30313A" logoType="black2" containerStyle={{ position: "fixed" }} /> */}
      <HeaderV3 isSubHeader subHeaderTitle="About The Donor" primaryBackgroundColor="#F0E8E3" position='absolute' />
      <div className='margin_class'>

        <div className={` ${style.about_donor}`}>
          <img src={donor?.splashimage} loading='lazy' alt='donor' />
          <div className={style.ad_content}>
            <h1>{donor?.org_name}</h1>
            <p>{donor?.about}</p>
          </div>

        </div>
      </div>
      {/* <MobileSubFooter style={{padding:'6vw 5vw 7vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </div>
  )
}

export default AboutDonor