import React,{memo} from 'react'
import classes from './NewFooter.module.css'
// import bgBlackLogo2 from '../../../../images/newHomeImages/blackLogo2.svg'
// import bgWhiteLogo2 from "../../../../images/bgWhiteLogo2.svg"
// import bgOrangeLogo2 from "../../../../images/bgOrangeLogo2.svg"
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Tiktok from "../../../../images/newFooterImages/Tiktok.jsx"

import { Link, useNavigate } from 'react-router-dom'
import { Desktop, Mobile } from '../../responsive/Responsive'
import BGLogoComponent from '../../../../images/BGLogoComponent/BGLogoComponent'
import NewFooterV2 from './NewFooterV2/NewFooterV2.jsx';

const NewFooter = ({ primaryBackgroundColor , primaryColor , logoColor , logoType ,newFooterv2=true }) => {
  // currently using NewFooterV2 instead of NewFooter
  if(newFooterv2) return <NewFooterV2/>
  return <OldFooter primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} logoColor={logoColor} logoType={logoType}  />
}

export default memo(NewFooter)


const OldFooter = ({primaryBackgroundColor = '#FC3300', primaryColor = '#30313A', logoColor = "#FC3300", logoType = 'small'}) => {
  const navigate = useNavigate()

  return (
    <section style={{ backgroundColor: primaryBackgroundColor, color: primaryColor }} className={classes.section}>
      <div className={`${classes.container} margin_class `} >
        <div className={classes.image_container} > <BGLogoComponent type={logoType} color={logoColor} /> </div>
        <div className={classes.icons_container} >
          <h6 className={classes.links} ><Link to="/about" >About</Link></h6>
          <h6 className={classes.links}
            onClick={() => {
              // dispatch(clearPageNumber());
              // navigate("/browse-items", {
              //   state: { buyNow: true, title: "Buy now", from: 'buyNow' },
              // })
              navigate('/shop-now')
            }
            }
          >Shop</h6>
          <h6 className={classes.links} ><Link to="/auction?type=current">Find an Event</Link></h6>
          <h6 className={classes.links} ><Link to="/non-profit">For Non-Profits</Link></h6>
          <h6 className={classes.links} ><Link to="/brand">For Brands</Link></h6>
          <h6 className={classes.links} ><Link to="/faq">FAQ</Link></h6>
          <h6 className={classes.links} ><Link to="/blog">Blog</Link></h6>
          <h6 className={classes.links} ><a style={{ textDecoration: 'none', color: primaryColor }} href='mailto:info@boundlessgiving.com'>Contact</a></h6>
        </div>
        <>
          <Mobile>
            <div className={classes.social_icons}>
              <a href="https://www.facebook.com/boundlessgivingapp" target="_blank" rel="noreferrer"><FacebookRoundedIcon sx={{ width: '26px', height: '26px', color: primaryColor }} /></a>
              <a href="https://twitter.com/BoundlessGiving" target="_blank" rel="noreferrer"><TwitterIcon sx={{ width: '26px', height: '26px', color: primaryColor }} /></a>
              <a href="https://www.instagram.com/boundlessgiving/" target="_blank" rel="noreferrer"><InstagramIcon sx={{ width: '26px', height: '26px', color: primaryColor }} /></a>
              <a href="https://www.tiktok.com/@officialboundlessgiving" target="_blank" rel="noreferrer" sx={{ width: '26px', height: '26px' }}><Tiktok color={primaryColor} /></a>
            </div>
            <div className={classes.bottom_links_container}>
              <p style={{ borderBottom: `1px solid ${primaryColor}` }} className={classes.bottom_links} ><Link to={'/tos'}>Terms of Service</Link></p>
              <p style={{ borderBottom: `1px solid ${primaryColor}` }} className={classes.bottom_links}><Link to={'/privacy'}>Privacy Policy</Link></p>
            </div>
            <div><p className={classes.copy_right}>© Boundless Giving. All Rights Reserved.</p></div>
          </Mobile>
          <Desktop>
            <div className={classes.desktop_bottom_section} >
              <div className={classes.desktop_bottom_first_section}>
               <div><p className={classes.copy_right}>© Boundless Giving. All Rights Reserved.</p></div>
               <div className={classes.bottom_links_container}>
                   <p className={classes.bottom_links} ><Link to={'/tos'}>Terms of Service</Link></p>
                   <p className={classes.bottom_links}><Link to={'/privacy'}>Privacy Policy</Link></p>
               </div>
              </div>
               <div className={classes.social_icons}>
                  <a href="https://www.facebook.com/boundlessgivingapp" target="_blank" rel="noreferrer"><FacebookRoundedIcon sx={{ width: '26px', height: '26px', color: primaryColor }} /></a>
                  <a href="https://twitter.com/BoundlessGiving" target="_blank" rel="noreferrer"><TwitterIcon sx={{ width: '26px', height: '26px', color: primaryColor }} /></a>
                  <a href="https://www.instagram.com/boundlessgiving/" target="_blank" rel="noreferrer"><InstagramIcon sx={{ width: '26px', height: '26px', color: primaryColor }} /></a>
                  <a href="https://www.tiktok.com/@officialboundlessgiving" target="_blank" rel="noreferrer" sx={{ width: '26px', height: '26px' }}><Tiktok color={primaryColor} /></a>
              </div>
            </div>
          </Desktop>
        </>
      </div>
    </section>
  )
}