import React, { useState,useEffect, useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";

// import GlobalHeader from "../../molecules/GlobalHeader";
import AuctionCard from "../../atoms/AuctionCard";
import "./SearchResult.css";
// import LotsCard from "../../atoms/LotsCard/LotsCard";
// import classes from "../../../desktop/styles/auctionMain.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { Desktop, Mobile } from "../../responsive/Responsive";
// import Navbar from "../../../desktop/components/Navbar/Navbar";
// import SubNavbar from "../../../desktop/components/SubNavbar/SubNavbar";
// import Header from "../../../desktop/components/Header/Header";
// import Footer from "../../../desktop/components/Footer/Footer";
import BrowseCard from "../../molecules/BrowseCard";
// import moment from "moment-timezone";
// import EventNoteIcon from '@mui/icons-material/EventNote';
import { auctionSearch, changePage, loadMoreItems, resetAuction } from "../../../../redux/searchReducer";
import { useMediaQuery } from "react-responsive";
import HeaderV2 from "../../molecules/Header/HeaderV2";
// import SubHeader from "../../molecules/SubHeader/SubHeader";
import { mobileStyle } from "../../Home";
import { FormControl, InputAdornment, OutlinedInput, Pagination, styled } from "@mui/material";
import LoadingScreen from "../../LoadingScreen/Loading";
// import MobileSubFooter from "../../atoms/MobileSubFooter/MobileSubFooter";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import { Helmet } from "react-helmet-async";
import AuthContext from "../../../../store/auth-context";
import { config } from "../../../../config/config";
import NewProductCard from "../../molecules/NewProductCard/NewProductCard";
import HeaderV3 from "../../molecules/Header/HeaderV3/HeaderV3";
import PaginationButtons from "../../atoms/PaginationButtons/PaginationButtons";
import PaginationV2 from "../../molecules/PaginationV2/PaginationV2";
const {REACT_APP_URL} = config


const primaryBackgroundColor = "var(--pure-white)"
const primaryColor = mobileStyle.color[1]
const redColor = mobileStyle.backgroundColor[0]

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "red"
  },
  textAlign: 'center',
  fontFamily: 'DM Mono',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color:primaryColor, 
  textTransform:'capitalize',
});



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const SearchResult = () => {

  const { itemsList, auctionList, itemsTotal, itemsTotalPage, auctionsTotal, auctionsTotalPage, auctionPage, itemPage, loading } = useSelector(state=>state?.search)
  const [value, setValue] = useState(0);
  const [searchText,setSearchText] = useState('');
  const [showResult,setShowResult] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {userId} = useContext(AuthContext)

  const isDesktop = useMediaQuery({minWidth: 768})

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {state} = useLocation()

  const clickHandler = (i) =>
    navigate(
      `/lot/${i?.id}?auction_id=${i?.auction_id}&cause_id=${i?.auction?.cause_id}`
    );

    useEffect(() => {
      if(state?.search) setShowResult(true)
      return ()=>dispatch(resetAuction())
    }, [state?.search])

    const loadItems = (_,page) => {
      dispatch(loadMoreItems({searchTerm: state?.search ? state?.search : searchText, variable: 'itemsList', page: page, limit: 20,marketPlace:state?.marketPlace, userId: userId}))
      dispatch(changePage({type: 'item', page: page}))
      window.scrollTo({top:0,behavior:'smooth'})
    }

    const loadAuctions = (_,page) => {
      dispatch(loadMoreItems({searchTerm: state?.search ? state?.search : searchText, variable: 'auctionList', page, limit: 20}))
      dispatch(changePage({type: 'auction', page}))
      window.scrollTo({top:0,behavior:'smooth'})

    }
 
    const initiateSearch = async (searchTerm) => {
      dispatch(auctionSearch({ searchTerm, variable: 'itemsList', userId: userId }))
      dispatch(auctionSearch({ searchTerm, variable: 'auctionList' }))
      setShowResult(true)
    };
    
    const handleChangeInput=(e)=>{
      setSearchText(e.target.value)
    }
  
    const handleClick = (e) => {

      if (e.key === "Enter" && e.target.value.length >= 2) initiateSearch(e.target.value);
      if (!e.key && searchText.length >= 2) initiateSearch(searchText); 
    };
    const goBack = ()=>{
      if(showResult){
        dispatch(resetAuction())
        setShowResult(false)
      } else{
        navigate(-1)
      }
    }

  return (
    <>
    <Helmet prioritizeSeoTags>
      <title>Boundless Giving Search - Find What You Need</title>
      <meta 
        name="description" 
        content="Get opportunities to give back with Boundless Giving's search feature, where you can explore various organizations to find the best match for your generosity."
      />
      <link rel="canonical" href={`${REACT_APP_URL}/search`} />
    </Helmet>
    <div className="page_outer" style={{backgroundColor: primaryBackgroundColor}}>
         {/* <HeaderV2 logoType='black2' 
            primaryBackgroundColor={primaryBackgroundColor} 
            primaryColor={primaryColor} 
            containerStyle={{position:'fixed'}} 
            isSubheader={true}
            subHeaderTitle="Search" 
            subHeaderOnclick={goBack}
         /> */}
         <HeaderV3
          primaryBackgroundColor={primaryBackgroundColor}  
          isSubheader
          subHeaderTitle="Search" 
          subHeaderOnclick={goBack}
          position="absolute"
          />
      <div style={{backgroundColor: primaryBackgroundColor, minHeight:'100vh'}} className="container">
         {/* <SubHeader backgroundColor={primaryBackgroundColor} color={primaryColor} title='Search'/> */}
        <main style={{backgroundColor: primaryBackgroundColor, color:primaryColor}} className="margin_class search-page">
        {/* <Desktop> */}
       {(!showResult || isDesktop || (!auctionList?.length && !itemsList?.length )) && <div style={{marginBottom:'55px'}} className="search_content_container">
            <h1 className="default_search_heading">Search your heart out</h1>
          <section className="search-header">
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                placeholder="Search like you mean it"
                sx={{ 
                  padding: '2px 10px',
                  minHeight:'40px',
                  borderRadius:"5px",
                  border: `1px solid ${primaryColor}`,
                  color:primaryColor,
                  fontSize: '12px',
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: '18px',
                  fontFamily:'Instrument Sans'

                }}
                id="search-text-search-page"
                fullWidth
                onKeyDown={handleClick}
                onChange={handleChangeInput}
                endAdornment={
                  <InputAdornment position="start" >
                    <SearchIcon sx={{color:"#30313a7a", fontSize:"16px", fontWeight:400}} onClick={handleClick} />
                  </InputAdornment>
                }
              />
            </FormControl>
            {/* <p className="cancel-search">Cancel</p> */}
          </section>
          </div>}
          {/* </Desktop> */}
         {(showResult && (auctionList?.length || itemsList?.length )) ? <Box sx={{ width: "100%" }}>
            <Mobile><p className="default_search_heading">Results.</p></Mobile>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="search-result-tabs"
              sx={{ 
                justifyContent: "space-around",
                borderBottom:`1px solid ${primaryColor}`,
                ".MuiTabs-indicator": {
                  backgroundColor:redColor,
                },
               }}
              className="ansh-class"
            >
              <StyledTab
                label={`Store (${itemsTotal})`}
                {...a11yProps(0)}
              />
              <StyledTab
                label={`Auctions (${auctionsTotal})`}
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
             { loading ? <LoadingScreen/> : <>
              <Mobile>
              <div style={{marginTop:"13px"}} className="auction-items">
                {itemsList?.length ?
                  itemsList?.map((item) => (
                    // <LotsCard
                    //   primaryBackgroundColor={primaryBackgroundColor}
                    //   primaryColor={primaryColor}
                    //   key={item.id}
                    //   clickHandler={clickHandler}
                    //   i={item}
                    //   asset={item.auction_item_assets?.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))}
                    // />
                    // <BrowseCard key={item.id} {...item} banner={item?.auction_item_assets.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name} />
                    <NewProductCard  key={item.id} item={item} image={item?.assets?.find(img =>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name}  imageContainerClass='shopNowImageContainerClass' customItemNameClass="shopNowItemName" cardContainerClass="shopNowCardContainer"  />
                  )): 
                    <div className='no_result_container'>
                    <p style={{color:primaryColor, fontFamily:"Instrument Sans"}} className="no_results_text" >No results found</p>
                    </div>
                  }
              <PaginationV2 onLoadMore={loadItems} page={itemPage} totalPage={itemsTotalPage} />
                  
                  {/* {itemPage >= itemsTotalPage ? null : <div style={{textAlign: "center", padding: "20px 0", fontWeight: 600}} onClick={loadItems}>Load More</div>} */}
              </div>
              </Mobile>
              <Desktop>
              <div className="browse-page" style={{margin: "70px 0"}}>
              <div className="browse-items">
                {itemsList?.length > 0 &&
                  itemsList?.map((item) => (
                    // <BrowseCard key={item.id} {...item} banner={item?.auction_item_assets.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name} />
                    <NewProductCard  key={item.id} item={item} image={item?.assets?.find(img =>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name}  imageContainerClass='shopNowImageContainerClass' customItemNameClass="shopNowItemName" cardContainerClass="shopNowCardContainer"  />
                    
                  ))}
              </div>
                {itemsList.length === 0 && <div style={{marginTop: "50px", textAlign: "center"}} className="no_results_text">No Items Found</div>}
              </div>
              <PaginationV2 onLoadMore={loadItems} page={itemPage} totalPage={itemsTotalPage} />

                {/* {itemPage >= itemsTotalPage ? null : <div style={{textAlign: "center", padding: "20px 0", fontWeight: 600}} onClick={loadItems}>Load More</div>} */}
              </Desktop>
              </>}
            </TabPanel>
            <TabPanel value={value} index={1}>
             { loading ? <LoadingScreen/> : <>
              {/* <Mobile> */}
                                     
              {/* {auctionPage >= auctionsTotalPage ? null : <div style={{textAlign: "center", padding: "20px 0", fontWeight: 600}} onClick={loadAuctions}>Load More</div>} */}
                   { !auctionList.length
                     ? 
                     <div className='no_result_container'>
                     <p style={{color:primaryColor, fontFamily:"Instrument Sans"}} className="no_results_text" >No Event Found</p>
                     </div>
                     : ""}
              <div className="browse-page" style={{margin: isDesktop ?   "70px 0" : "13px 0",minHeight:'auto'}}>
              <div className="auction_grid_container">
               {/* <div style={{marginTop:"13px"}} className="auction-list" selected> */}
                {auctionList.map((item) => (
                  <AuctionCard
                    key={item.id}
                    auction={item}
                    primaryBackgroundColor={primaryBackgroundColor}
                    primaryColor={primaryColor}
                  />
                ))}
               
              </div>
              </div>
              {/* </Mobile> */}
              {/* <Desktop>
              <div className={`auctionDiv2 ${classes.dauctionMain_cards}`} style={{margin: "30px 0"}}>
                {auctionList.map(auction => (
                    <div onClick={() => navigate(`/event/${auction.id}`, { state: { auction: auction, image: auction?.image2, from: 'event' } })} style={{cursor: "pointer"}} className={classes.dauctionMain_card}>
                        <div className={classes.dauctionMain_card_image}>
                          <img src={auction.image1} alt="" />
                        </div>
                        <div className={classes.dauctionMain_card_content}>
                          <div>{auction.title}</div>
                          <div>
                          <EventNoteIcon style={{fontSize: "14px", marginRight: "4px", color: "gray"}} />{`${moment(auction?.start_date).format("MMMM DD")} - ${
                                    moment(auction?.end_date).format("MMMM DD")
                                    }
                          `}
                          </div>
                          <div>More Info</div>
                        </div>
                    </div>
                ))}
                </div>
                {auctionPage >= auctionsTotalPage ? null : <div style={{textAlign: "center", padding: "10px 0", fontWeight: 600}} onClick={loadAuctions}>Load More</div>}
                {auctionList.length === 0 && <div style={{marginTop: "50px", textAlign: "center"}}>No Event Found</div>}
              </Desktop> */}
              <PaginationV2 onLoadMore={loadAuctions} page={auctionPage} totalPage={auctionsTotalPage} />

              </>}
            </TabPanel>
          </Box> : 
          (showResult) ?
          <div style={{marginTop: "80px", textAlign: "center"}} className="no_results_text">No Result Found</div>
          : null}
        </main>
      </div>
      {/* <Desktop>
        <Footer />
      </Desktop> */}
     {/* <MobileSubFooter style={{padding:'2vw 5vw 7vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
           <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </div>
    </>
  );
};

export default SearchResult;
