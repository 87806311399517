import { useEffect, useState } from 'react';
import classes from './TextArea.module.css';
import inputClasses from '../Input/Input.module.css';

const TextArea = ({ value, onChange, label, name, width = "100%", required = false, style = {}, cols = 3, rows = 3, maxsize = null, iButton = false, infoSize = '16px', placeholder = "", infoBubbleText = "", disabled = false }) => {

    const [charLeft, setCharLeft] = useState(maxsize)
    useEffect(() => {
        if (!maxsize) return
        setCharLeft(maxsize - value?.length)
    }, [value, maxsize])
    return (
        <div className={classes.formTextareaContainer} style={{ width, ...style }}>
            <label className={inputClasses.label} htmlFor={name}>{label} {required && <span style={{ color: "red" }}>*</span>}</label>
            <textarea className={classes.formtextArea} placeholder={placeholder} required={required} maxLength={maxsize} name={name} cols={cols} rows={rows} onChange={onChange} value={value} disabled={disabled}></textarea>
            {maxsize && <div className={classes.formtextareaMaxChars}>Characters Left: {charLeft}</div>}
        </div>
    )
}

export default TextArea;