import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userServices from '../services/user-services';
import logger from '../utils/logger'

export const getAllBlogs = createAsyncThunk(
    'redux/getAllBlogs',
    async ({limit, page}) => {
        const {data} = await userServices.fetchAlBlogs( limit, page)
        return data
    }
)

const initialState = {
    allBlogs: {
        data: [],
        limit: 20,
        page: 1,
        total_count:0,
        total_page: 0,
    },
    loading: false,
    loadingMore:false,
    error: '',  
    trending:{}
}

const blogSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
        resetBlogs:(state,action)=>{
            // state=initialState
            state.allBlogs = {
                data: [],
                limit: 20,
                page: 1,
                total_count:0,
                total_page: 0,               
            }
            state.loading=false
            state.loadingMore=false
            state.error= ''
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllBlogs.pending, (state, action) => {
            state.allBlogs.page>1?state.loadingMore=true: state.loading = true;
             state.error = ''
         })

         builder.addCase(getAllBlogs.fulfilled, (state, action) => {
                state.allBlogs = {
                    ...state.allBlogs,
                    data: action.payload?.data,
                    limit: action.payload?.limit,
                    page: action.payload?.page,
                    total_count: action.payload?.total_count,
                    total_page: action.payload?.total_page
                }         
            state.loading = false;
            state.loadingMore = false;
            state.error = '';
            if(action.payload?.page === 1){
                state.trending = action?.payload?.data[0]
            };
        })

        builder.addCase(getAllBlogs.rejected, (state, action) => {
            state.loading = false;
            state.loadingMore = false;
            state.error = action.error.message;
            logger('./redux/eventReducer', 'getAllBlogs(): catch', action.error);
        })
 
    }
})

export default blogSlice.reducer;
export const {resetBlogs} = blogSlice.actions;