import { useNavigate } from "react-router-dom";
import "share-api-polyfill";
import { ChevronLeft } from "@mui/icons-material";
import IosShareIcon from "@mui/icons-material/IosShare";
import CartIcon from "../CartIcon/CartIcon";




import "./GlobalHeader.css";
import { Mobile } from "../../responsive/Responsive";

const GlobalHeader = ({
  primaryBtnCTA,
  PrimaryBtnContent,
  primaryBtnBehavior = "back",
  title,
  secondaryBtnCTA,
  SecondaryBtnContent,
  secondaryBtnBehavior = "share",
  iconColor = "white",
  bgColor = "#1A385C",
  addClasses = "",
  titleStyle={}
}) => {

  const navigate = useNavigate();
  const shareableData = {
    url: window.location.href,
    title,
  };
  const defaultPrimaryBtnCTA = (e) => {
    e.preventDefault();
    if (window.history.length <= 2) {
      navigate("/", {
        preventScrollReset: true
      })
      return
    }
    navigate(-1, {
      preventScrollReset: true
    });
  };

  const defaultSecondaryBtnCTA = (e) => {
    e.preventDefault();
    navigator.share(shareableData);
  };

  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={`global-header ${addClasses}`}
    >
      {primaryBtnBehavior === "none" ? (
        <span />
      ) : (
        <button
          className="global-header__action-btn"
          onClick={primaryBtnCTA || defaultPrimaryBtnCTA}
        >
          {PrimaryBtnContent ? (
            typeof PrimaryBtnContent === "string" ? (
              <span>{PrimaryBtnContent}</span>
            ) : (
              <PrimaryBtnContent sx={{ color: iconColor, fontSize: 32 }} />
            )
          ) : (
            <ChevronLeft sx={{ color: iconColor, fontSize: 32 }} />
          )}
        </button>
      )}
      <header className="global-header__title" style={{...titleStyle, color: iconColor }}>
        {title}
      </header>
      <span />
      {/* <Mobile>
        <CartIcon />
      </Mobile> */}

      {/* {secondaryBtnBehavior === "none" ? (
        <span />
      ) : (
        <button
          className="global-header__btn-secondary"
          onClick={secondaryBtnCTA || defaultSecondaryBtnCTA}
        >
          {SecondaryBtnContent ? (
            typeof SecondaryBtnContent === "string" ? (
              <span>{SecondaryBtnContent}</span>
            ) : (
              <SecondaryBtnContent sx={{ color: iconColor, fontSize: 24 }} />
            )
          ) : (
            <IosShareIcon sx={{ color: iconColor, fontSize: 24 }} />
          )}
        </button>
      )} */}


    </div>
  );
};

export default GlobalHeader;
