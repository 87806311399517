import React, { useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import CloseIcon from '@mui/icons-material/Close';
import InputMask from 'react-input-mask';
// import { Backdrop, CircularProgress, Dialog, DialogContent, DialogContentText } from "@mui/material";
// import GlobalHeader from './molecules/GlobalHeader';
// import PrimaryBtn from './ButtonPrimary';
import logger from '../../utils/logger';
import { Desktop, Mobile } from "./responsive/Responsive";
// import Navbar from "../desktop/components/Navbar/Navbar";
// import Header from "../desktop/components/Header/Header";
// import Footer from "../desktop/components/Footer/Footer";
// import SubNavbar from "../desktop/components/SubNavbar/SubNavbar";
import './ShippingInformation.css';
import { useEffect } from 'react';
import userServices from '../../services/user-services';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { Helmet } from "react-helmet-async"
import DialogMui from './molecules/Dialog/Dialog';
import constants from '../../constants/en';
import HeaderV2 from './molecules/Header/HeaderV2';
import SubHeader from './molecules/SubHeader/SubHeader';
import { mobileStyle } from './Home';
import { useMediaQuery } from 'react-responsive';
import NewButton from './atoms/NewButton/NewButton';
import NeedHelp from './atoms/NeedHelp/NeedHelp';
import LoadingScreen from './LoadingScreen/Loading';
import MobileSubFooter from './atoms/MobileSubFooter/MobileSubFooter';
import NewFooter from './molecules/NewFooter/NewFooter';
import Header from '../desktop/components/Header/Header';
import HeaderV3 from './molecules/Header/HeaderV3/HeaderV3';


const codesObj = {};

Country.getAllCountries().forEach((item) => {
  codesObj[item.isoCode] = item.phonecode;
});

const CaptureCardDetails = () => {
  const { state } = useLocation();
  const authCtx = useContext(AuthContext);
  //   const { itemId, causeId, auctionId } = state;
  const [toFirstName, setToFirstName] = useState('');
  const [toLastName, setToLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const phoneRef = useRef();
  const [country, setCountry] = useState('US');
  const [cityState, setCityState] = useState('');
  const [city, setCity] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('2');
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const [doneMessage, setDoneMessage] = useState(false)
  const [alertMsg, setAlertMsg] = useState(null)
  const [alert, setAlert] = useState(false);
  const [isLoading,setIsLoading]= useState(false)

  const primaryColor = mobileStyle.color[1];
  const primaryBackgroundColor = "var(--pure-white)";
  const redColor = mobileStyle.color[0];
  const isMobile = useMediaQuery({
    maxWidth: 768
  })

  const countries = useMemo(() => {
    return Country.getAllCountries().map((c) => {
      return {
        label: c.name,
        value: c.isoCode,
      };
    });
  }, []);

  const updatedState = (country) => {
    return State.getStatesOfCountry(country).map((s) => {
      return {
        label: s.name,
        value: s.isoCode,
      };
    });
  };

  const navigateTo = useNavigate();

  useEffect(() => {
    if (!state) {
      navigateTo('/');
    }
  }, []);

  useEffect(() => {
    if (!state?.edit) {
      if (authCtx?.isSavedShip && state.type === 'bid') {
        navigateTo(
          `/lot/${state.itemId}/place-bid?cause_id=${state.causeId}&auction_id=${state.auctionId}`,
          {state: {
            ...state
          }, replace: true }
        );
      } else if (authCtx?.isSavedShip && state.type === 'buy_now') {
        navigateTo('/bag', {
          state: {
            ...state
          },
          replace: true,
        });
      }
    }
    else {
      
      try{(async () => {
        setIsLoading(true)
        const { data } = await userServices.getUserShippingAddress(
          authCtx?.userId
        );
        if (data) {
          setToFirstName(data.to_firstname);
          setToLastName(data.to_lastname);
          setAddress1(data.address1);
          setAddress2(data.address2);
          setCity(data.city);
          setCountry(data.country);
          setPhone(data.phone);
          setZip(data.zip);
          setCityState(data.state);
          setIsLoading(false)
        }
      })();}
      catch(error){
         setAlertMsg("Something went wrong")
         setAlert(true)
      }
      finally{
        setIsLoading(false)
      }
    }
  }, [])

  const selectDeliveryOptiion = (e) => {
    setDeliveryOption(e.target.value);
  };

  const handleNavigate = () => {
    setDoneMessage(false)
    if (state) {
      if (state.type === 'bid') {
        navigateTo(
          `/lot/${state.itemId}/place-bid?cause_id=${state.causeId}&auction_id=${state.auctionId}`,
          {state: {
            ...state
          }, replace: true }
        );
      } else if (state.type === 'buy_now') {
        //redirect to bag screen
        navigateTo('/bag', {
          state: {
            ...state
          },
          replace: true,
        });
      } else if (state.type === 'cart') {
        navigateTo('/cart-checkout', { replace: true });
      } else if (state.type === 'from-bag') {
        navigateTo(-1, { replace: true })
      }
      else {
        navigateTo('/account');
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsOrderProcessing(true);
    try {
      const USZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
      const newPhoneNumber = phone.replace(/-/g, '');
      if (!zip.match(USZipCodeRegex)) {
        setIsOrderProcessing(false);
        setAlert(true)
        setAlertMsg('Invalid Zip Code')
      }
      else {
        setAlert(false)
        setAlertMsg(null)
        const obj = {
          to_firstname: toFirstName?.trim(),
          to_lastname: toLastName?.trim(),
          address1,
          address2,
          city,
          state: cityState,
          phone: newPhoneNumber,
          country: country,
          zip,
        };
        const { data } = await userServices.postShippingAddress(
          authCtx?.userId,
          obj
        );
        authCtx.setIsSavedShip(true)
        setIsOrderProcessing(false);
        setDoneMessage(true)
      }
    } catch (error) {
      logger('./pages/components/ShippingInformation.jsx', 'handleSubmit(): catch', error);
      alert(constants.APP_COPY_FAILED_SHIPPING_ADDRESS);
      setIsOrderProcessing(false);
    }
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  return (
    <div style={{backgroundColor:primaryBackgroundColor}}>
      <Helmet>
        <title>Shipping Information | Boundless Giving</title>
      </Helmet>

            {/* <HeaderV2 isSubHeader={!isOrderProcessing} subHeaderTitle='Shipping Information' logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:"fixed"}}/> */}
            <HeaderV3 isSubHeader={!isOrderProcessing} subHeaderTitle='Shipping Information' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} position="absolute" />

      {isOrderProcessing && (
        <LoadingScreen loadingText='Saving shipping information ...'/>
      )}
      {
        isLoading && (
          <LoadingScreen bgColor='rgb(240, 232, 227)'/>
        )
      }
      {<form onSubmit={handleSubmit}>
        <main style={{width:isMobile ? "":"45vw",backgroundColor: primaryBackgroundColor, color: primaryColor,margin:isMobile?"":"none",marginTop:isMobile?"110px":"50px",marginBottom:isMobile?"":"0"}}  className={`shipping-information-container ${!isMobile && "margin_class"}`}>

            <header style={{color:primaryColor}} className='shipping-info__heading'>Shipping Address</header>

          <div style={{marginTop:"20px"}} className='shipping-info-name'>
            <div style={{color:  primaryColor}} className='shipping-info_sub_heading'>
              Full Name <span style={{ color: redColor }}>*</span>
            </div>
            <input
              style={{border: `1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor }}
              onChange={(e) => setToFirstName(e.target.value)}
              value={toFirstName}
              type='text'
              name='first-name'
              autoComplete='first name'
              placeholder='First Name'
              required
            />
            <input
              onChange={(e) => setToLastName(e.target.value)}
              value={toLastName}
              type='text'
              name='last-name'
              autoComplete='last name'
              placeholder='Last Name'
              required
              style={{border: `1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor }}
            />
          </div>
          <div style={{marginTop: '37px'}} className='shipping-info-address'>
            <header className='shipping-info__heading'>
              Address
            </header>
            <input
              onChange={(e) => setAddress1(e.target.value)}
              value={address1}
              type='text'
              name='address1'
              autoComplete='address 1'
              placeholder='Address 1'
              required
              style={{border: `1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor }}
            />
            <input
              onChange={(e) => setAddress2(e.target.value)}
              value={address2}
              type='text'
              name='address2'
              autoComplete='address 2'
              placeholder='Address 2'
              style={{border: `1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor }}
            />
            <input
              onChange={(e) => setCity(e.target.value)}
              value={city}
              type='text'
              name='city'
              autoComplete='city'
              placeholder='City'
              required
              style={{border: `1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor }}
            />
            {/* <input
              onChange={(e) => setCityState(e.target.value)}
              value={cityState}
              type='text'
              name='state'
              autoComplete='state'
              placeholder='State'
              required
            /> */}
            <FormControl sx={{ width: 1, marginBottom: 3 }}>
              <Select
                required
                displayEmpty
                defaultValue={cityState || ''}
                name='state'
                value={cityState || ''}
                onChange={(e) => setCityState(e.target.value)}
                sx={{
                  fontSize: '0.8rem',
                  height: 48,
                  backgroundColor:primaryBackgroundColor ,
                  borderRadius: '8px',
                  border:  `1px solid ${primaryColor}`,
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                    outline: 'none',
                  },
                }}
              >
                <MenuItem disabled value=''>
                  <span
                    style={{
                      color: primaryColor,
                      // fontFamily: 'AvenirNext',
                      fontSize: '14px',
                    }}
                  >
                    Select State
                  </span>
                </MenuItem>
                {country
                  ? updatedState(country).map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                  : []}
              </Select>
            </FormControl>
            <div className='shipping-info_half_inp'>
              <input
                onChange={(e) => setZip(e.target.value)}
                value={zip}
                type='number'
                name='zip'
                autoComplete='zip'
                placeholder='Zip'
                required
                onWheel={(e) => e.target.blur()}
                style={{border: `1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor }}
              />
              <FormControl sx={{ m: 0, width: '48%' }}>
                <Select
                  required
                  displayEmpty
                  name='country'
                  value={country || ''}
                  onChange={(e) => {
                    setPhone('');
                    setCountry(country);
                  }}
                  sx={{
                    fontSize: '0.8rem',
                    height: 48,
                    backgroundColor: primaryBackgroundColor ,
                    border: `1px solid ${primaryColor}`,
                    borderRadius: '8px',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      outline: 'none',
                    },
                  }}
                >
                  <MenuItem disabled value=''>
                    <span
                      style={{
                        color: primaryColor,
                        // fontFamily: 'AvenirNext',
                        fontSize: '14px',
                      }}
                    >
                      Select Country
                    </span>
                  </MenuItem>
                  {countries.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{marginTop: '20px'}} className='shipping-info-phone'>
            <header className='shipping-info__heading'>
              Contact Number 
              {/* <span style={{ color: redColor }}>*</span> */}
            </header>
            <InputMask
                ref={phoneRef}
                mask="999-999-9999"
                placeholder={`+${codesObj[country] ? codesObj[country] : 'Phone'}`}
                maskChar=""
                value={phone}
                onChange={handlePhone}
                required
                style={{ marginBottom: '30px',border:`1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor  }}
            />
          </div>
            <NewButton type="submit" text={'SAVE'} backgroundColor={primaryBackgroundColor} color={redColor} style={{paddingBlock:'7px', width:isMobile?"100%":"40%", marginTop:'10px'}} />
            <NeedHelp primaryColor={primaryColor} style={{marginTop:'50px', marginBottom:"49px"}} />
        </main>
      </form>}

      <div>
        {/* <Dialog
          open={doneMessage}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ fontFamily: "AvenirNext" }}>
            <DialogContentText textAlign="left">
              <p className='alert-login-signup'>
                Successfully Updated Shipping Details.
              </p>
              <PrimaryBtn
                text="Okay"
                handleClick={handleNavigate}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog> */}
        <DialogMui isOpen={alert} message={alertMsg} ButtonText={'Close'} handleClick={() => { (setAlert(false)) }} />
        <DialogMui isOpen={doneMessage} message={"Successfully Updated Shipping Details"} ButtonText={'Okay'} handleClick={handleNavigate} />
      </div>
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </div>

  );
};

export default CaptureCardDetails;
