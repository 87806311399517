import React, { useState } from 'react'
import classes from "../../../../../desktop/styles/Home.module.css"
// import splashCaroselImage1 from '../../../../../../images/newHomeImages/splashCaroselImage1.jpeg'
import styles from './splashCarosel.module.css'
import { Carousel } from 'react-responsive-carousel';
import './splashCarosel.css'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ImageSkelton from '../../../../molecules/ImageSkelton/ImageSkelton';
import { imageUrls } from '../../../../../../constants/url';
const splashCaroselImage1 = imageUrls.home_page_splash_carosal_image_mobile
const SplashCarosel = ({mobileStyle={}}) => {
  const [bannerLoading, setBannerLoading] = useState(true)
  const backgroundColor = mobileStyle?.backgroundColor[0]
  const color = mobileStyle?.color[0]
  return (
    <section style={{backgroundColor:backgroundColor}} className={classes.dhero} >
      <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} >
        <div className={styles.carosel_container}>
          <img onLoad={() => setBannerLoading(false)} style={{display: bannerLoading ? 'none' : 'block'}} src={splashCaroselImage1} alt="CaroselOne" />
          <ImageSkelton width='96%' loadingState={bannerLoading} style={{position:"relative", margin:'auto', borderRadius:'15px', minHeight:'530px'}}/>
          <div style={{color:color, display:bannerLoading ? 'none' : 'block'}}>
           <h4>Therapy shopping:<br/> Now <br/> upto 80% more therapeutio</h4>
           <ExpandCircleDownOutlinedIcon sx={{fontSize:'48px', float:'left'}}/>
          </div>
        </div>
        <div className={styles.carosel_container}>
          <img onLoad={() => setBannerLoading(false)} style={{display: bannerLoading ? 'none' : 'block'}} src={splashCaroselImage1} alt="CaroselOne" />
          <ImageSkelton width='96%' loadingState={bannerLoading} style={{position:"relative", margin:'auto', borderRadius:'15px', minHeight:'530px'}}/>
          <div style={{color:color, display:bannerLoading ? 'none' : 'block'}}>
           <h4>Therapy shopping:<br/> Now <br/> upto 80% more therapeutio</h4>
           <ExpandCircleDownOutlinedIcon sx={{fontSize:'48px', float:'left'}}/>
          </div>
        </div>
        <div className={styles.carosel_container}>
          <img onLoad={() => setBannerLoading(false)} style={{display: bannerLoading ? 'none' : 'block'}} src={splashCaroselImage1} alt="CaroselOne" />
          <ImageSkelton width='96%' loadingState={bannerLoading} style={{position:"relative", margin:'auto', borderRadius:'15px', minHeight:'530px'}}/>
          <div style={{color:color, display:bannerLoading ? 'none' : 'block'}}>
           <h4>Therapy shopping:<br/> Now <br/> upto 80% more therapeutio</h4>
           <ExpandCircleDownOutlinedIcon sx={{fontSize:'48px', float:'left'}}/>
          </div>
        </div>
      </Carousel>
    </section>
  )
}

export default SplashCarosel