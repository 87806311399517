
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Desktop, Mobile } from '../../../responsive/Responsive';
import { fetchFeaturedItems } from '../../../../../redux/featuredItemsReducer';
// import BrowseCard from '../../../molecules/BrowseCard/BrowseCard';
import classes from './FeaturedItems.module.css'
import SectionContainer from '../../../molecules/SectionContainer/SectionContainer';
import { SwiperSlide } from 'swiper/react';
import NewProductCard from '../../../molecules/NewProductCard/NewProductCard';
import SwiperComponent from '../../../molecules/SwiperComponent/SwiperComponent';
import { useMediaQuery } from 'react-responsive';
import HomePageCarouselAction from '../../../molecules/HomePageCarouselAction/HomePageCarouselAction';
import CarouselSectionContainer from '../../../molecules/CarouselSectionContainer/CarouselSectionContainer';

function FeaturedItems({ title }) {
    const { featuredItems } = useSelector(state => state.featuredItems)
    const dispatch = useDispatch()
    const isTab = useMediaQuery({
        maxWidth: 1236
    })
    const isMobile = useMediaQuery({
        maxWidth: 800
    })
    useEffect(() => {
        dispatch(fetchFeaturedItems({ limit: 50, page: 1 }))
    }, [])

    if (!featuredItems?.data?.length) {
        return;
    }

    return (
        // <>
        //     <Desktop>
        //         {/* featured items */}

        //         {featuredItems?.data?.length > 0 ? <section className={style.dsection}>
        //             <h3 className={style.featured_h3}>{title}</h3>
        //             <div className={style.featured_dcurrent_cards}>
        //                 {
        //                     featuredItems?.data.map((item, index) => {
        //                         return (
        //                             <div className={style.featured_card} key={index}>
        //                                 <BrowseCard inStyle={{ height: '100%', width: '100%' }} {...item} banner={item?.auction_item_assets?.find(img => img.context_type === 'portrait')?.name} status={item?.status} />
        //                             </div>
        //                         )
        //                     })
        //                 }
        //             </div>
        //         </section> : null}
        //     </Desktop>
        //     <Mobile>
        //         {/* featured items */}

        //         {featuredItems?.data?.length > 0 ? <section style={{ margin: "20px 0px 0px" }} className="categories_sec">
        //             <div>
        //             <h3 className={style.featured_h3}>{title}</h3>
        //             </div>
        //             <div className={style.dcurrent_cards}>
        //                 {
        //                     featuredItems?.data.map((item, index) => {
        //                         return (
        //                                 <BrowseCard inStyle={{flexDirection: "column", gap: "15px", padding: "15px 0", minWidth: "210px"}} key={index} {...item} banner={item?.auction_item_assets?.find(img => img.context_type === 'portrait')?.name} status={item?.status} />
        //                         )
        //                     })
        //                 }
        //                 {
        //                     featuredItems?.data.map((item, index) => {
        //                         return (
        //                                 <BrowseCard inStyle={{flexDirection: "column", gap: "15px", padding: "15px 0", minWidth: "210px"}} key={index} {...item} banner={item?.auction_item_assets?.find(img => img.context_type === 'portrait')?.name} status={item?.status} />
        //                         )
        //                     })
        //                 }
        //             </div>
        //         </section> : null}
        //     </Mobile>
        // </>

        // New design for featured Items.

        <SectionContainer sectionBg='pure-white'>
            <CarouselSectionContainer 
                slidesPerView={`${isMobile ? 1 : isTab ? 2 : 3}`} 
                actionButtonText='VIEW ALL' 
                actionButtonHref='/shop-now' 
                title={title}
                headerPara={'50-80% of all proceeds from purchases go directly to charity.'}
            >
                {
                    featuredItems?.data?.map((item) => {
                        return (
                            <SwiperSlide key={item?.id}>
                                <NewProductCard item={item} />
                            </SwiperSlide>
                        )
                    })
                }
            </CarouselSectionContainer>
        </SectionContainer >
    )
}

export default FeaturedItems