import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet-async';
import { mobileStyle } from '../../Home';
// import HeaderV2 from '../Header/HeaderV2';
// import BrowseItemUpperHalf from '../BrowseItemUpperHalf/BrowsItemUpperHalf';
// import classes from '../../organisms/BrowseCauses/BrowseCause.module.css'
import { useMediaQuery } from 'react-responsive';
import BrowsePagesCardSection from '../BrowsePagesCardSection/BrowsePagesCardSection';
// import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import NewFooter from '../NewFooter/NewFooter';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from "@mui/icons-material";
// import BrowseItemsBanner from '../../atoms/BrowseItemsBanner/BrowseItemsBanner';
import { imageUrls } from '../../../../constants/url';
import HeaderV3 from '../Header/HeaderV3/HeaderV3';
import classes from './BrowsePagesLayout.module.css'
import BrowseItemsBannerV2 from '../../atoms/BrowseItemsBanner/BrowseItemsBannerV2/BrowseItemsBannerV2';
import { clearAllBrowseBySelectedFilters } from '../../../../redux/browseItemsReducer';
import { useDispatch } from 'react-redux';
const primaryColor = mobileStyle.color[1];

const BrowsePagesLayout = ({ children, params={}, loading=false, helmetTag="" }) => {
    const forMobile = useMediaQuery({
        maxWidth: 768
    })
    let browseBrands = imageUrls.shop_now_brands_you_love_desktop;
    let browseCause = imageUrls.shop_now_cause_you_care_desktop;
    let browseMerch = imageUrls.shop_now_our_merch_desktop;
    if(forMobile) {
        browseBrands = imageUrls.browse_brands_page_banner_mobile;
        browseCause = imageUrls.browse_cause_page_banner_mobile;
        browseMerch = imageUrls.browse_stores_page_banner_mobile;
    }
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const goBack = () => {
        navigate(-1, {
            preventScrollReset: true
        });
    }

    useEffect(() => {
        dispatch((clearAllBrowseBySelectedFilters()))
    }, [params?.from])

    const getBannerData = () => {
        switch (params?.from) {
            case 'browse-cause':
                return {
                    image: browseCause,
                    heading: 'Causes'
                }
            case 'browse-brands':
                return {
                    image: browseBrands,
                    heading: 'Shop By Brands'
                }
            case 'browse-store':
                return {
                    image: browseMerch,
                    heading: `Shop By Cause`
                }
            default:
                break;
        }
    }
    const bgColor = 'var(--pure-white)'
    return (
        <>
            {/* <Helmet prioritizeSeoTags>
                <title>Browse {helmetTag} | Boundless Giving</title>
            </Helmet> */}
            <div>
                <div className='browse-page' style={{ minHeight: (forMobile) ? "100vh" : "", backgroundColor: bgColor, justifyContent: (forMobile) ? 'space-between' : "" }}>
                    {/* <HeaderV2
                        primaryBackgroundColor={mobileStyle.backgroundColor[2]}
                        primaryColor={mobileStyle.color[1]}
                        containerStyle={{ position: 'fixed' }}
                        transparentNav
                    /> */}
                    <HeaderV3
                      primaryBackgroundColor={bgColor}
                      primaryColor={mobileStyle.color[1]}
                    />
                    {/* <BrowseItemUpperHalf params={params} /> */}
                    {/* <BrowseItemsBanner
                       image={getBannerData().image}
                       heading={getBannerData().heading}
                       primaryColor={primaryColor}
                    //    mobileHeadingStyle={{marginTop:"40px"}}
                    /> */}
                    <BrowseItemsBannerV2 image={getBannerData().image} >
                            <div className={classes.bannerContentContainer}>
                                <h1 className={`${classes.bannerHeading} ${['browse-brands','browse-store'].includes(params.from) ? classes.bannerHeadingColorForBrandAndStore : classes.bannerHeadingColor }`} >{getBannerData().heading}</h1>
                                <hr className={ ['browse-brands','browse-store'].includes(params.from)? classes.bannerUnderLineForBrnadsAndStore : classes.bannerUnderLine} />
                            </div>
                    </BrowseItemsBannerV2>
                    
                    <div className="margin_class" style={{ backgroundColor: bgColor, marginBlock: "45px" }}>
                    <div className='back-container' style={{marginBottom:'25px'}}>
                       <button style={{display:'flex',columnGap:'10px',justifyContent:'center',alignItems:'center',fontSize:forMobile ? '12px':'18px'}} onClick={goBack}><ChevronLeft style={{color:'rgba(252, 51, 0, 1)'}}/>Back</button> 
                    </div>
                        <BrowsePagesCardSection primaryBackgroundColor={bgColor} isLoading={loading} params={params} >
                            {children}
                        </BrowsePagesCardSection>
                    </div>

                </div>

            </div>
            {/* <div style={{width:"100%",backgroundColor:"#F0E8E3"}}>
            <MobileSubFooter style={{padding:'6vw 5vw 7vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/>
            </div> */}
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} /> 

        </>
    )
}

export default BrowsePagesLayout;