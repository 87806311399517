import React, { useState } from 'react'
import { mobileStyle } from '../../Home'
import ImageSkelton from '../../molecules/ImageSkelton/ImageSkelton'
import { useMediaQuery } from 'react-responsive'
import classes from './NewCardLayout.module.css';
import Image from '../Image/Image';
import BrowseItemsFavouriteButton from '../../molecules/BrowseItemsFavouriteButton/BrowseItemsFavouriteButton'

const NewCardLayout = ({image='', imageContainerStyle={}, children, neededLine=true, primaryColor=mobileStyle.color[1], onClick=()=>null, onCardClick=()=>null, alt='', favourites=false, auctionItem=null, handleFetchData=()=>null, updateFavourite=false}) => {
    const [imageLoading, setImageLoading] = useState(true)
    const isMobile = useMediaQuery({
        maxWidth: 768
       })
    return (
        <>

            {/* <Mobile> */}
                <div onClick={onCardClick} className="cart-card-container">
                    <div
                        className="cart-card-img_container"
                        style={imageContainerStyle}
                        onClick={onClick}
                    >
                        {/* {
                            favourites && 
                            <BrowseItemsFavouriteButton auctionItem={auctionItem} updateFavourite={updateFavourite} handleFetchData={handleFetchData}/>
                        } */}
                        <Image
                            className="cart-card-img"
                            src={image}
                            alt={alt}
                            style={{display:imageLoading ? "none" : 'block'}}
                            onLoad={() => setImageLoading(false)}
                        />
                        <ImageSkelton width='100%' loadingState={imageLoading} style={{height:"100%", borderRadius:"25px"}}/>
                    </div>
                    {children}
                </div>
                { neededLine && <hr className={classes.new_card_hr} style={{marginInline:'0px !important', color:primaryColor, width:isMobile ? '100%' : '65%' }} />}

            {/* </Mobile> */}

        </>
    )
}

export default NewCardLayout