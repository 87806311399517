import { useContext, useState } from "react";
import HeaderV2 from "../molecules/Header/HeaderV2";
import { Desktop, Mobile } from "../responsive/Responsive";
import "./nonProfit.css";
import NewFooter from "../molecules/NewFooter/NewFooter";
import { mobileStyle } from "../Home";
// import image from "../../../images/np-mobile-img.svg";
import NonprofitSignup from "../molecules/NonprofitSignup/NonprofitSignup";
import { imageUrls } from "../../../constants/url";
import { Helmet } from "react-helmet-async";
import { config } from '../../../config/config';
import AuthContext from "../../../store/auth-context";
import ConfirmAlertLogin from "../ConfirmAlertLogin";
import { useNavigate } from "react-router-dom";
import HeaderV3 from "../molecules/Header/HeaderV3/HeaderV3";
const {REACT_APP_URL} = config

const image = imageUrls.non_profit_desktop_mobile_img
// np stands for non-profit
const NonProfit = () => {
  const [orgSignupModal,setOrgSignupModal] = useState(false)
  const redColor = mobileStyle.backgroundColor[0];
  const primaryBackgroundColor = "var(--pure-white)";
  const {isLoggedIn} = useContext(AuthContext)
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const navigate = useNavigate()
  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false)
  }

  const handleCreateNonProfitClick = () => {
    // first check if the user is logged in or not
    if(!isLoggedIn) {
      setOpenConfirmAlert(true)
      return;
    }
    navigate('/non-profit/create')
  }

  return (
   <>
    <Helmet prioritizeSeoTags>
        <title>Fundraising Software for Nonprofits - Pop Up Shop Fundraiser</title>
        <meta 
          name="description" 
          content="Searching for online pop-up shop fundraisers? Boundless Giving is here to offer fundraising ideas and software for nonprofits to maximize their impact." 
        />
        <meta 
          name="keywords"
          content="Pop Up Shop Fundraise"
        />
        <link rel="canonical" href={`${REACT_APP_URL}/non-profit`} />
   </Helmet>
   <div style={{backgroundColor:primaryBackgroundColor}}>
    {/* <HeaderV2 primaryBackgroundColor="#F0E8E3" logoType="orange" primaryColor="red" containerStyle={{position:"fixed"}} /> */}
    <HeaderV3 primaryBackgroundColor="#F0E8E3" position="absolute" />
      <div className="np-container margin_class">
        <h1 className="np-heading">For Nonprofits</h1>
        {/* <h3 className="np-sub-heading">
           If you’re reading this, you’re probably a lot like us.
        </h3> */}
         <p className="np-subtext para_fontsize">
           <b>Welcome!</b> It seems you share our values and commitment to making a difference.
           Our team is driven by the goal of supporting meaningful causes, minimizing waste, safeguarding our environment, and contributing positively in as many ways as possible. 
         </p>
          <br /> 
          {/* <p className="np-subtext para_fontsize" >Thus, our name.</p> */}
        
        <h5 style={{ fontSize: "25px", fontWeight: "700", margin: "20px 0" }}>
          This is what brought our name to life.
        </h5>
        <p className="common-style para_fontsize">
           On our platform, nonprofits find a streamlined way to raise funds, with an emphasis on clarity and honesty. Ever wondered about those sales claiming to donate "a percentage" of profits? With us, the ambiguity disappears. You'll see precisely what portion of your contribution supports your chosen cause, ranging from 50-80% with our brand partners, and up to 80% through our comprehensive, self-managed platform.
        </p>
        <p className="common-style para_fontsize">
           We guarantee transparency: no hidden costs, unexpected charges, or complications. You'll have all the details from the start.
        </p>
        <p className="common-style para_fontsize">
           Designed with simplicity and user-friendliness in mind, the Boundless Giving platform offers a straightforward experience. Understanding that you'd rather not get bogged down by complex technology, we've made our platform exceptionally user-friendly, with input from our tech-savvy team and feedback from nonprofits who've tested and approved it.
        </p>
        {/* <h5 style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}>The Boundless Giving platform is intuitive and easy to use. Really.</h5>
        <p className="common-style para_fontsize">
        We know you don’t want to spend time in the weeds of some complex tech platform. You’re busy! So we have some amazing tech folks who designed our platform to be extremely intuitive, and some amazing nonprofits who gave it a test run to be sure that it is.
        </p> */}
        <Mobile>
        <h3 className="np-sub-heading">
        “It was so fast and easy to use.It’s the best software we've used to run a fundraiser.”
        </h3>
        <h4 className="para_list" style={{fontSize:"20px",paddingBottom:"30px",borderBottom:"1px solid red",fontFamily:"DM Mono"}}> <span>— </span> <span> Etalia Gold, Chaparral PFC Auction Chair</span></h4>
        <h5  style={{ fontSize: "20px", fontWeight: "700", margin: "30px 0" }}>Freedom. Flexibility. Partnership.</h5>
        <p style={{ fontSize: "20px", fontWeight: "400", margin: "30px 0" }}>We’d love to help you with:</p>
        <p  className="common-style para_fontsize para_list"><span>+</span><span>Online Events Including Auctions and Pop-Up Stores</span></p>
        <p className="common-style para_fontsize para_list"><span>+</span><span>Ongoing dedicated marketplace</span></p>
        <p className="common-style para_fontsize para_list"><span>+</span><span>Ticket Sales + Order Fulfillment </span></p>
        <p className="common-style para_fontsize para_list"><span>+</span><span>Direct Donations</span></p>
        <p className="common-style para_fontsize para_list" style={{marginBottom:"30px",marginTop:"35px"}}>And more. Just ask.</p>
        {/* <p style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}>How else are we different?</p>
        <p className="common-style para_fontsize">Here’s how we compare with some other platforms you may be aware of.</p> */}
        </Mobile>
          <div className="d_np_signup">
            <h3>Join as a Boundless Giving nonprofit today.</h3>
            <button onClick={()=>setOrgSignupModal(true)} >SIGN UP</button>
          </div>
        <Desktop>
          <h3 className="np-sub-heading">
        “It was so fast and easy to use. It’s the best software we've used to run a fundraiser.”
        </h3>
        <h4 style={{paddingBottom:"30px",borderBottom:"1px solid red",fontFamily:"DM Mono"}}> — Etalia Gold, Chaparral PFC Auction Chair</h4>
        <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
        <div style={{width:"70%"}}>
        <h5  style={{ fontSize: "20px", fontWeight: "700", margin: "30px 0" }}>Freedom. Flexibility. Partnership.</h5>
        <p style={{ fontSize: "20px", fontWeight: "400", margin: "30px 0" }}>We’d love to help you with:</p>
        <div style={{display:"flex",columnGap:"35px"}}>
          <div>
        <p  className="common-style para_fontsize para_list"><span>+</span><span>Online Events Including Auctions and Pop-Up Stores</span></p>
        <p className="common-style para_fontsize para_list"><span>+</span><span>Ongoing dedicated marketplace</span></p>
        <p className="common-style para_fontsize para_list"><span>+</span><span>Ticket Sales + Order Fulfillment </span></p>
        <p className="common-style para_fontsize para_list"><span>+</span><span>Direct Donations</span></p>
          {/* </div>
        <div> */}
        </div>
        </div>
        <p className="common-style para_fontsize" style={{marginBottom:"30px",marginTop:"35px"}}>And more. Just ask.</p>
        {/* <p style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}>How else are we different?</p>
        <p className="common-style para_fontsize">Here’s how we compare with some other platforms you may be aware of.</p> */}
       </div>
       <div style={{width:"40%", marginRight:"10%"}}>
        <img style={{width:'100%', maxWidth:"100%", height:"100%"}} src={image} loading='lazy' alt="Charity Fundraising Software" />
       </div>
        </div>
        </Desktop>
       {/* <Mobile>
       <div className="chart" style={{width:"100%"}}>
          <img src={chart} alt="" width="100%"/>
        </div>
       </Mobile> */}
       {/* <Desktop>
        <div className="chart" style={{width:"100%"}}>
           <div className="chart-head"><p>Boundless vs. The Rest</p></div>
            <img src={des_chart} alt="" width="100%"/>
        </div>
       </Desktop> */}
        
        {/* <h5  style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}>The best way to start: Tell us your goals. We’ll take it from there.</h5> */}
        {/* <p className="common-style para_fontsize">Need to generate additional funds? Broaden your support base? Increase awareness? Simplify logistics of existing fundraising events?</p> */}
        {/* <p className="common-style para_fontsize">(Though knowing nonprofits, the answer is usually “all of the above”) </p> */}
        {/* <p className="common-style para_fontsize" style={{marginBottom:"100px"}}><span style={{borderBottom:"1px solid red",cursor:"pointer"}} onClick={()=>setOrgSignupModal(true)}>Reach out</span>  today. We’re excited to get to know more about you, your needs, and what we can do to help.</p> */}
        <p className="common-style para_fontsize">
          We're here to support your fundraising efforts in various ways, including online events like auctions and pop-up stores, a dedicated marketplace, ticket sales and order fulfillment, direct donations, and more. If you have specific needs or ideas, we're open to discussion.
        </p>
        <p className="common-style para_fontsize">
          Let's partner to make a difference.
        </p>
        
           <div className="d_np_signup">
            <h3>Join as a Boundless Giving nonprofit today.</h3>
            {/* <button onClick={()=>setOrgSignupModal(true)}>SIGN UP</button> */}
            <button onClick={handleCreateNonProfitClick}>SIGN UP</button>
          </div>
       

      </div>
        

    <NewFooter primaryBackgroundColor={redColor} primaryColor={primaryBackgroundColor} logoColor={mobileStyle.color[1]} />

    <NonprofitSignup isOpen={orgSignupModal} onClose={()=>setOrgSignupModal(false)} onSubmitForm={()=>setOrgSignupModal(false)} />

    </div>

    <ConfirmAlertLogin
        openConfirmAlert={openConfirmAlert}
        handleCloseConfirmAlert={handleCloseConfirmAlert}
        context={{
          returnUrl:'/non-profit'
        }}
    />

   </>
  );
};

export default NonProfit;
