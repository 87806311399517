// import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField,InputAdornment, IconButton } from '@mui/material';
import React, { useState } from 'react';

export default function InputPassword({pass,onChange,placeholder='Password',className='',style={}}) {
    const [visible,setVisible] = useState(false);

    function toggleVisible() {
        setVisible(!visible);
    };
    function handleMouseDown(event) {
        event.preventDefault();
    };
    // function handleInput(event) {
    //     setPass(event.target.value);
    // };
    return (
        <TextField
            label={placeholder}
            id="outlined-start-adornment"
            sx={{ m: 1, width: "25ch" }}
            className={className}
            style={style}
            value={pass}
            onChange={onChange}
            type={visible ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleVisible}
                            onMouseDown={handleMouseDown}
                            edge="end"
                        >
                            {visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}
