import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Desktop, Mobile } from './responsive/Responsive';
import { mobileStyle } from './Home';
import NewButton from './atoms/NewButton/NewButton';

const ConfirmAlert = ({ openConfirmAlert, handleCloseConfirmAlert, alerMsg, handleConfirmTask }) => {
  return (
    <div>  
        <Dialog
            open={openConfirmAlert}
            onClose={handleCloseConfirmAlert}
            sx={{
                // ".MuiDialogContent-root": {padding: "14px 20px"},
                ".MuiPaper-root": {
                borderRadius: '25px',
                minWidth: '189px',
                paddingTop: '20px',
                justifyContent: 'space-between',
                paddingBottom: '32px',
                gap: '20px',
                backgroundColor:"#FCFCFC",
                zIndex:"1500",
                margin:'19px'
              }
            }}
        >
            <DialogContent>
                <DialogContentText textAlign="left">
                    <dd style={{ margin: "0px 0px", fontSize: "18px", textAlign: "center", color:mobileStyle.color[1] }} className='alert-login-signup'>
                       {alerMsg}
                       <div className='new_buttons_container bid_class'>
                        <div className='new_each_button'>
                        <NewButton onClick={handleCloseConfirmAlert} text={'No'} backgroundColor={mobileStyle.backgroundColor[0]} color={mobileStyle.color[2]} 
                          style={{
                            marginTop: "17px",
                            fontSize:'14px',
                            
                            width:'100%',
                            textTransform:'uppercase',
                            border:'0',
                            padding:'7px 30px'
                          }}
                        />
                        </div>
                        <div className='new_each_button'>
                         <NewButton onClick={handleConfirmTask} text={'Yes'} color={mobileStyle.color[0]} 
                          style={{
                            marginTop: "17px",
                            fontSize:'14px',
                            
                            width:'100%',
                            textTransform:'uppercase',
                            padding:'auto 30px'
                          }}
                        />
                        </div>
                        </div>
                    </dd>
                     
                </DialogContentText>
            </DialogContent>
        </Dialog>
        
    </div>
  );
};

export default ConfirmAlert;
