import React, { useEffect, useState } from 'react';
import style from './Section4a.module.css';
import {useDispatch,useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { getAuction, setFrom } from '../../../../../redux/eventReducer';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import moment from "moment-timezone";
import EventNoteIcon from '@mui/icons-material/EventNote';



function Section4a() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {auctions,isLoading,isLoadingMore} = useSelector(state=>state.auction)
    const [page,setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        dispatch(getAuction({page:page,limit:limit}))
    }, [])
    const GoToEvent=(id)=>{
        navigate(`/event/${id}`)
    }

    const handleLoadmore = async() => {
        setPage(page+1)
        await dispatch(getAuction({page:page+1, limit:limit}))
    }

    return (
        <section className={style.section4a} >
            <div>
                <h2 className={style.p1} >Shop ongoing events</h2>

                <p className={style.p3} >Check out these live events! <span className={style.click_all_events} ><Link to={'/auction?type=current'}>Explore now »</Link></span></p>

                <div className={style.event_carosel}>
                    {auctions?.data?.map((auction)=><div key={auction?.id} className={style.image_container}>
                        <img key={auction.id} onClick={()=>GoToEvent(auction.custom_url ? auction.custom_url : auction.id)} src={auction.image2} alt='event'/>
                      <div className={style.event_details}>
                        <div><strong>{auction.title}</strong></div>
                        <div>
                          <EventNoteIcon style={{fontSize: "14px", marginRight: "4px", color: "gray"}} />{`${moment(auction?.start_date).format("MMMM DD")} - ${
                          moment(auction?.end_date).format("MMMM DD")}`}
                        </div>
                       </div>
                    </div>)}
                   { page < auctions?.total_page ? <div
                     onClick={handleLoadmore}
                     className={style.more_button}>
                        <ArrowForwardIosTwoToneIcon sx={{backgroundColor:"#F43AB0", borderRadius:"100%", color:"white", height:'30px', width:'30px', padding:'6px', fontWeight:600, cursor:'pointer'}}/>
                    </div>
                    : <div
                       className={style.more_button}>
                       <ArrowForwardIosTwoToneIcon sx={{backgroundColor:"#f43ab054", borderRadius:"100%", color:"white", height:'30px', width:'30px', padding:'6px', fontWeight:600}}/>
                   </div>
                    } 
                </div>

            </div>
        </section>
    )
}

export default Section4a