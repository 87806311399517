import React from "react";
import Typography from "@mui/material/Typography";

const BiddingFaq = () => {
  return (
    <div style={{ margin: "20px 15px" }}>
      <hr
        style={{
          margin: 0,
          position: "fixed",
          width: "20%",
          left: "40%",
          right: "40%",
          border: "2px solid",
          borderRadius: "15px",
        }}
      />
      <br />
      <h1>How Bidding Works</h1>
      <hr />
      <div className="faq_text_container">
       
            <Typography>
<p>1. To bid in this auction, find your desired lot and choose “Place Bid.” </p>

<p>2. Select your bid amount. You may either place a bid at the “Next Bid'' option or choose a Maximum Bid amount that the Boundless Giving system will execute on your behalf when competing bids are placed. </p>

<p>3. Once your bid is placed, select “Continue to Confirm bid” </p>

<p>4. Your card will not be charged unless you win the item at the end of the auction. </p>

<p>5. You will receive a confirmation of your bid onscreen and via email. </p>
            </Typography>
          
      </div>
    </div>
  );
};

export default BiddingFaq;
