import { Snackbar, Slide } from '@mui/material';
// import { Check } from '@mui/icons-material';
import styles from './SnackBar.module.css'
import { memo } from 'react';
import Lottie from 'lottie-react';
// import animationJson from '../../../../images/animation.json';
import animationJson from '../../../../images/lottie/AnimationCheck.json';

const SnackBarMui = memo(({ open = false, closeAlert = () => null, duration = 2000, message = 'Success' }) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeAlert();
    };
    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
            TransitionComponent={SlideTransition}
            open={open} autoHideDuration={duration} onClose={handleClose}>
            <div className={styles.snackContainer}>
                <p className={styles.successMessage}>{message}</p>
                {/* <Check sx={{ color: 'green' }} /> */}
                 <Lottie style={{height: "30px"}}  animationData={animationJson} loop={0} />
            </div>
        </Snackbar>
    )
})

export default SnackBarMui;