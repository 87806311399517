import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalHeader from './molecules/GlobalHeader';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MailSVG from '../../images/mail.svg';
import EditSVG from '../../images/edit.svg'

import './ItemsBag.css';
import BagCard from './organisms/ItemsBag/BagCard';
import orderServices from '../../services/order-services';
import { createPayment, fetchSavedCard } from '../../services/payment-services';
import userServices from '../../services/user-services';
import AuthContext from '../../store/auth-context';
import logger from '../../utils/logger';
import { InitiateCheckout, Purchase } from '../../utils/meta-pixel-tracking';
import DialogMui from "./molecules/Dialog/Dialog";
import constants from '../../constants/en';
import HeaderV2 from './molecules/Header/HeaderV2';
// import SubHeader from './molecules/SubHeader/SubHeader';
import { useMediaQuery } from 'react-responsive';
import { mobileStyle } from './Home';
import NewButton from './atoms/NewButton/NewButton';
import NeedHelp from './atoms/NeedHelp/NeedHelp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import MobileSubFooter from './atoms/MobileSubFooter/MobileSubFooter';
import NewFooter from './molecules/NewFooter/NewFooter';
import HeaderV3 from './molecules/Header/HeaderV3/HeaderV3';

const primaryBackgroundColor = "var(--pure-white)"
const primaryColor = mobileStyle.color[1]
const redColor = mobileStyle.backgroundColor[0]

const ItemsBag = () => {
  const { state } = useLocation();
  const authCtx = useContext(AuthContext);
  const {
    type,
    user_id,
    store_item_id,
    item_name,
    feat_img,
    description,
    price,
    deliveryOption,
    product_sku_id,
    is_virtual,
    itemId,
    auctionId,
    fulfilment,
    quantity,
    charge_tax,
    causeId,
    cause_id,
    causeSharePercentage,
    productWeight,
    variant,
    vendor_sku_id,
    upc,
    auction_item_amount_given_dialog
  } = state;
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const [shippingRate, setShippingRate] = useState(0);
  const [tax, setTax] = useState(0)
  const [shipingData, setShippingData] = useState(null)
  const [isLoadingShipping, setIsLoadingShipping] = useState(false)
  const [cardDetails, setCardDetails] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null);
  const [tooltip1,setTooltip1] = useState(false);
  const [tooltip2,setTooltip2] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: 768
  })

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      setIsLoadingShipping(true)
      const { data } = await userServices.getUserShippingAddress(authCtx?.userId)
      setShippingData(data)
      setIsLoadingShipping(false)
    })()
  }, [])

  const getSalesTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      setTax(tax)
      return tax
    }catch(error) {
      logger('./pages/components/organisms/ItemsBag.jsx', 'getSalesTax()', {error:error});
      alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
      return false
    }
  };

  useEffect(() => {
   (async () => {
    const getShippingRate = async (itemId) => {
      if (is_virtual === 0 && fulfilment?.pickup_type === "ship") {
        // const shippingRate = await orderServices.getShippingRate(
        //   itemId
        // );
        // setShippingRate(quantity * (shippingRate?.data?.shipping_rate));
        /// for temporarily chaning the shipping rate to 9.99 as per the ticket BG-1337
        // setShippingRate(9.99)

        // new shipping rate logic
        const totalProductWeight = +productWeight * quantity
        const shippingRate = await orderServices.getShippingRate(totalProductWeight);
        setShippingRate(shippingRate?.data?.shipping_rate)
      }else{
        setShippingRate(0)
      }
    };
    
    const cardDetails = async () => {
      const card = await fetchSavedCard(authCtx.cusId, authCtx.cardId)
      setCardDetails({
        name: card.name,
        last4: card.last4,
        brand: card.brand
      })
    }
    const taxValue = await getSalesTax((store_item_id && charge_tax === 1) ? quantity*price : 0)
    if(taxValue === false) {
      navigate(-1);
      return
    }
    getShippingRate(itemId);
    cardDetails()
   })()
  }, [itemId]);

  const calcFinalPrice = (price, shipping_price, tax) => {
    let _price = price ? +price : 0;
    let _shipping_price = shipping_price ? +shipping_price : 0;
    let _tax = tax && tax.amount ? +tax.amount : 0;
    let total = _price + _shipping_price + _tax;
    const proccessingFees = Number((total * 0.03).toFixed(2));
    total += proccessingFees
    return { totalAmount: total, proccessingFees };
  };

  const calcCharityPercentage = () => {
    let charityAmount;
    charityAmount = Number(price) * causeSharePercentage * (Number(quantity) || 1)
    return charityAmount;
  }

  const makePayment=async(totalAmount)=>{
    try{

      let payload = {
        amount: Number(totalAmount.toFixed(2)),
      };
      
      const response = await createPayment(payload);
      //meta pixel tracking event
      let contents={
        name:item_name,
        id:itemId,
        quantity:quantity
      }
      response.success && Purchase({content_ids:itemId,contents,num_items:1,value:totalAmount.toFixed(2),currency:"USD"})
      return response
    }catch(error){
      logger('./pages/components/ItemsBag.jsx', 'makePayment(): catch1', error);
      return error
    }
  }



  // handle checkout
  const handleCheckout = async (e) => {
    
    e.preventDefault();
    setIsOrderProcessing(true);
    try {
      // deduct amount
      let { totalAmount,proccessingFees } = calcFinalPrice(quantity*price, shippingRate, tax)
      
      //meta pixel tracking
      InitiateCheckout({content_ids:itemId,num_items:1,value:totalAmount,contents:[{id:itemId,name:item_name,quantity}]})
     
        // create order using Advatix
        try {
          if (is_virtual === 0) {
            let orderPayload = {
              user_id: user_id,
              order_amount: +totalAmount.toFixed(2),
              subtotal: +quantity*price,
              tax: +tax.amount,
              shipping_rate: +shippingRate,
              proccessingFees: +proccessingFees.toFixed(2),
              items: [
                {
                  is_virtual: is_virtual,
                  price: +price,
                  quantity: quantity,
                  product_sku_id: product_sku_id,
                  auction_item_id: +itemId,
                  auction_id:auctionId,
                  cause_id: cause_id,
                  item_name: item_name,
                  vendor_sku_id: vendor_sku_id,
                  upc: upc
                },
              ],
            };
          let orderRes;
         let orderIds={}
         const confirmOrder={};
          if(fulfilment.name === "advatix"){
            orderRes=await orderServices.createAdvatixOrder(orderPayload);
            if(orderRes?.responseStatus && orderRes?.responseStatusCode === 200) {
              confirmOrder.advatixOrder=orderPayload.items;
              confirmOrder.advatix_amount= +totalAmount;
              orderIds.advatixOrderId=orderRes.BG_response;
            }
            else {
              totalAmount = 0
            }
          }
           else if(fulfilment.name === "self"){
            orderRes=await orderServices.createSelfOrder(orderPayload);
            confirmOrder.selfOrder=orderPayload.items;
            confirmOrder.self_order_amount= +totalAmount;
            orderIds.selfOrderId=orderRes.BG_response;
           }
           else if (fulfilment?.name === 'edi') {
            confirmOrder.ediOrder = [];
            orderIds.ediOrderId = []
            orderRes=await orderServices.createEdiOrder(orderPayload);
            if(orderRes?.status === 200 && (orderRes?.data?.path && orderRes?.data?.url)) {
              orderRes.BG_response.generatedFilePath = orderRes?.data?.path
              orderRes.BG_response.items = orderPayload?.items
              confirmOrder.ediOrder.push(orderRes?.BG_response)
              orderIds.ediOrderId.push(orderRes?.BG_response)
              
              // confirmOrder.ediOrder = orderPayload?.items;
              // confirmOrder.edi_order_amount = +totalAmount;
              // orderIds.ediOrderId = orderRes?.BG_response;
              // orderIds.ediOrderId.generatedFilePath = orderRes?.data?.path;
            }
            else {
              totalAmount = 0
            }
           }

           if(totalAmount <= 0) {
              setIsOrderProcessing(false);
              setErrorMessage(constants.APP_COPY_ORDER_FAILED);
              return
           }
         
            //update quantity
            let qtyUpdatePayload = {
              sku: product_sku_id,
              quantity: quantity,
              isStoreItem: store_item_id ? true : false,
              isVirtual: is_virtual ? true : false,
            };
            await orderServices.updateStoreItemQuantity(
              itemId,
              qtyUpdatePayload
            );

           let response=await makePayment(totalAmount);
           if(response.success){
            await orderServices.confirmOrder(orderIds,confirmOrder);
             setIsOrderProcessing(false);
             navigate('/checkout', {
               state: {
                 orderId: orderRes?.advatix_response?.orderdata?.orderId,
                 itemId: itemId,
                 auctionId: auctionId,
                 noOfEvent: 1,
                 charityAmount:auction_item_amount_given_dialog ? Number(calcCharityPercentage()).toFixed(2) : null
               },
               replace: true,
             });
           }else{
            await orderServices.updatePaymentFailure(orderIds);
            let qtyUpdatePayload = {
              sku: product_sku_id,
              quantity: quantity,
              isStoreItem: store_item_id ? true : false,
              isVirtual: is_virtual ? true : false,
              incrementStock:true
            };
            await orderServices.updateStoreItemQuantity(
              itemId,
              qtyUpdatePayload
            );
            setIsOrderProcessing(false);
            alert(constants.APP_COPY_PAYMENT_FAILED);

           }

            }
           else if (is_virtual === 1) {
            //create virtual order
            let virOrderPayload = {
              user_id: user_id,
              order_amount: totalAmount,
              subtotal: +quantity*price,
              tax: +tax.amount,
              shipping_rate: +shippingRate,
              proccessingFees: +proccessingFees.toFixed(2),
              items: [
                {
                  price: +price,
                  quantity: quantity,
                  product_sku_id: product_sku_id,
                  auction_item_id: +itemId,
                  cause_id: cause_id,
                  auction_id:auctionId
                },
              ],
            };
             let orderIds={};
             const confirmOrder={};
            const virOrder = await orderServices.createVirtualOrder(virOrderPayload);
            confirmOrder.virtualOrder=virOrderPayload.items;
            confirmOrder.virtual_amount= +totalAmount;
            orderIds.virtualOrderId=virOrder.BG_response;
            if (virOrder?.responseStatus) {
              //update quantity
              let qtyUpdatePayload = {
                sku: product_sku_id,
                quantity: quantity,
                isStoreItem: store_item_id ? true : false,
                isVirtual: is_virtual ? true : false,
              };
              await orderServices.updateStoreItemQuantity(
                itemId,
                qtyUpdatePayload
              );

              let response=await makePayment(totalAmount);
              if(response.success){
               await orderServices.confirmOrder(orderIds,confirmOrder);
               setIsOrderProcessing(false);
               navigate('/checkout', {
                 state: {
                   orderId: virOrder?.BG_response?.orderId,
                   itemId: itemId,
                   auctionId: auctionId,
                   noOfEvent: 1,
                   charityAmount: Number(calcCharityPercentage()).toFixed(2)
                 },
                 replace: true,
               });
              }else{
               await orderServices.updatePaymentFailure(orderIds);
               ///restore inventary
               let qtyUpdatePayload = {
                sku: product_sku_id,
                quantity: quantity,
                isStoreItem: store_item_id ? true : false,
                isVirtual: is_virtual ? true : false,
                incrementStock:true
              };
               await orderServices.updateStoreItemQuantity(
                 itemId,
                 qtyUpdatePayload
               )
               setIsOrderProcessing(false);
               alert(constants.APP_COPY_PAYMENT_FAILED);
   
              }
              
            } else {
              setIsOrderProcessing(false);
              setErrorMessage(virOrder?.responseMessage || constants.APP_COPY_ORDER_FAILED);
            }

          }
          
        } catch (error) {
          logger('./pages/components/ItemsBag.jsx', 'handleCheckout(): catch1', error);
          setErrorMessage(error?.response?.data?.responseMsg || error?.response?.data?.message || constants.APP_COPY_ORDER_FAILED)
          setIsOrderProcessing(false);
        }
      
    } catch (error) {
      setErrorMessage(constants.APP_COPY_ORDER_FAILED);
      logger('./pages/components/ItemsBag.jsx', 'handleCheckout(): catch2', error);
      setIsOrderProcessing(false);
    }
  };

  const handleEditShipping = () => {
    navigate('/shipping-info', {
      state: { ...state, edit: true, type: 'from-bag' },
    });
  }

  const handleUpdatePayment = () => {
    navigate('/payment', {
      state: {
        ...state,
        type: 'from-bag',
        update: true
      }
    })
  }

  return (
    <div className='cart-outer-container'>

        {/* <HeaderV2 logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:"fixed"}} isSubHeader subHeaderTitle="Checkout"/> */}
        <HeaderV3 primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} isSubHeader subHeaderTitle="Checkout" position='absolute' />

      <DialogMui message={errorMessage} isOpen={!!errorMessage} handleClick={() =>{ setErrorMessage(null);navigate(-1)}} ButtonText="Okay" />
      {isOrderProcessing && (
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOrderProcessing}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color='inherit' />
              <div
                style={{
                  fontStyle: 'italic',
                  fontSize: '13px',
                  marginTop: '15px',
                }}
              >
                Processing your order <span className='loader__dot'>.</span>
                <span className='loader__dot'>.</span>
                <span className='loader__dot'>.</span>
              </div>
            </div>
          </Backdrop>
        </div>
      )}
      <div style={{ padding:isMobile && "170px 5vw 55px 5vw", paddingTop: !isMobile ? "200px" : "170px", minHeight: "48vh", backgroundColor: primaryBackgroundColor }} className={` ${isMobile ? "bag-container" : "margin_class"}`}>
        <div className='bag-cards-container'>
          <BagCard
            title={item_name}
            image={feat_img}
            description={description}
            qty={quantity}
            price={((quantity)*(Number(price))).toFixed(2)}
            primaryColor={primaryColor}
            neededLine={isMobile}
            variant={variant}
          />
        </div>
        {/* <Mobile><br /></Mobile> */}
        <div style={{width:isMobile?"":"65%"}}>
          <div className='bag-order-summary' >
          <h1>Order Summary</h1>
          <div className='bag-summary-grid'>
            <div style={{display:'flex', flexDirection:'column', marginBottom:'35px'}}>
              <div>
                <div className="cart_subtotal_heading">Subtotal</div>
                <div className='bag-card-price'>${((quantity)*(Number(price))).toFixed(2)}</div>
              </div>
              { (Number(shippingRate).toFixed(2) > 0)  &&
                <div className="cart_subtotal_heading"> 
                <div>Shipping
                <Tooltip title="Based on 5-8 Day USPS shipping rates" placement="top-start" >
                   <span onTouchStart={()=>{setTooltip1(!tooltip1);setTooltip2(false)}}>
                    <InfoOutlinedIcon sx={{fontSize:'18px', color: primaryColor}} />
                   </span>
                   </Tooltip>
                   {(tooltip1) && <div className="tooltip-container" style={{width:"75%",top:"-25px",left:"2%"}}>
                    <p>Based on 5-8 Day USPS shipping rates.</p>
                  </div>
                  }
                </div>
                <div className='bag-card-price'>${Number(shippingRate).toFixed(2)}</div>
              </div>}
              {(tax?.amount > 0) && <div className="cart_subtotal_heading">
                <div>Tax <span style={{ fontSize: "0.8rem" }}>({`${ tax?.amount === 0 ? 0 : tax?.percentage}%`})</span></div>
                <div className='bag-card-price'>${tax?.amount}</div>
              </div>
              }
              <div className="cart_subtotal_heading">
                <div>Processing Fees <span style={{ fontSize: "0.8rem" }}>(3%)</span>
                <Tooltip title="Covers Stripe credit card processing fees so that more of your dollars can go directly to nonprofits on our platform." placement="top-start" >
                  <span onTouchStart={()=>{setTooltip1(false);setTooltip2(!tooltip2);}}>
                  <InfoOutlinedIcon sx={{fontSize:'18px', color: primaryColor}} />
                  </span>
                </Tooltip>
                {(tooltip2) && <div className="tooltip-container" style={{top:"-40px",left:"3%"}}>
                 <p>Covers Stripe credit card processing fees so that more of your dollars can go directly to nonprofits on our platform.</p>
                </div>
                }
                </div>
                <div className='bag-card-price'>${Number(calcFinalPrice(quantity*price, shippingRate, tax).proccessingFees).toFixed(2)}</div>
              </div>
            </div>
           <hr style={{ color:primaryColor, margin:'0', width:'100%', border:'1px solid'}}/>
            <div className='bag-summary-grid-total'>
              <div className="cart_subtotal_heading">Total</div>
              <div style={{gridTemplateColumns:'1fr'}} className='bag-card-price'>
                ${Number(calcFinalPrice(quantity*price, shippingRate, tax).totalAmount).toFixed(2)}
              </div>
            </div>
            {/* <hr style={{color:primaryColor, margin:'0', width:'100%', border:'1px solid'}} />
            <div style={{marginBlock:"25px", paddingTop:'0 !important'}} className='bag-summary-grid-total remove-padding-class'>
              <div className="cart_subtotal_heading">Amount Going To Charity</div>
              <div style={{gridTemplateColumns:'1fr'}} className='bag-card-price'>
                ${Number(calcCharityPercentage(price)).toFixed(2)}
              </div>
            </div>
            <hr /> */}
          </div>
        </div>
        <div className="shipping_payment_container">
        {cardDetails && (
          <div style={{width:"100%"}}>
            <div className='order_headings' style={{ marginTop: "42px", marginBottom:'17px' }}>Payment Method</div>
            <div className='bag_shipping_sec'>
              <div className='bag_shipping_content' style={{ fontSize: "1rem" }}>
                <div>{`${cardDetails.name}`}</div>
                <div>{`**** **** **** ${cardDetails.last4}`}</div>
                <div>{cardDetails.brand}</div>
              </div>
              <div
                onClick={handleUpdatePayment}
                className='bag_shipping_edit'>
                <img src={EditSVG} alt="edit" />
              </div>
            </div>
          </div>
        )}

        {!isLoadingShipping && (
          <div style={{width:"100%"}}>
            <div className='order_headings' style={{ marginTop: "42px", marginBottom:'17px' }}>Ship To This Address</div>
            <div className='bag_shipping_sec'>
              <div className='bag_shipping_content'>
                <div>{`${shipingData?.to_firstname} ${shipingData?.to_lastname}`}</div>
                <div>{`${shipingData?.address1}`}</div>
                <div>{shipingData?.city}, {shipingData?.state}, {shipingData?.zip}</div>
              </div>
              <div
                onClick={handleEditShipping}
                className='bag_shipping_edit'>
                <img src={EditSVG} alt="edit" />
              </div>
            </div>
          </div>
        )}
        </div>
        
        <NewButton onClick={handleCheckout} text={'PLACE ORDER'} backgroundColor={redColor} color={primaryBackgroundColor} 
           style={{width:isMobile?'100%':"50%",  fontSize:'16px',  lineHeight:"16px", paddingBlock:'9px', marginTop: "42px"}}
        />
        <NeedHelp primaryColor={primaryColor}/>
        </div>
      </div>
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />


    </div>
  );
};

export default ItemsBag;
