import { Player } from "@lottiefiles/react-lottie-player";
import CircularProgress from '@mui/material/CircularProgress';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import parseQuery from "query-string";
import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import authService from '../../services/auth-services';
import './VerifyEmail.css';
import { Desktop, Mobile } from "../components/responsive/Responsive";
import congrats from "../../images/congratsBlack.svg"
import BGLogoComponent from "../../images/BGLogoComponent/BGLogoComponent";
import NewButton from "../components/atoms/NewButton/NewButton";

function Success() {
    const toGo = useNavigate();
    return (
        <>
            <div className="ve_success" >
                <div>
                    <div style={{marginBottom:"30px",alignItems:"space-around"}}>
                        <img src={congrats} alt="" />
                    </div>
                    <h3>Paymnet Successfull.</h3>
                    <NewButton  text={'SHOP MORE'} backgroundColor="#F0E8E3" color="red" style={{paddingBlock:'7px', marginTop:'20px'}} onClick={()=>toGo('/shop-now')}/>
                    {/* <p style={{fontSize:"18px",fontWeight:"400"}}>Redirecting to site...</p> */}
                </div>
            </div>
        
        </>
       
    )
}
export default function RetryPayment() {
    const toGo = useNavigate();
    const url = useLocation();
    const [state,setState] = useState({msg:'Processing Payment...',success:false});
    useEffect(()=>{
        const {token} = parseQuery.parse(url.search);
        const redirectURL = JSON.parse(localStorage.getItem("redirect"))
        if(token  && !state.success) {
            setTimeout(async ()=>{
                 authService.retryPayment(token).then((res)=>{
                    if(res.status === 200){
                        setState({...state,msg:res?.data?.message,success:true})
                    }else{
                        setState({...state,msg:res?.response.data.message,success:false})
                    }
                 }).catch((error)=>{
                    console.log(error)
                    setState({...state,success:false})
                 })
               
            },2000);
        } else {//Invalid Url
            setTimeout(()=>{
                setState({...state,msg:'Invalid Url!'});
            },2000);
        }
    },[url]);

    return (
        <div className="verify_email">
           {!state.success?
                <div className="ve_success" style={{flexDirection:"column"}}>
                    <h6 style={{width:"85%",marginTop:"150px",textAlign:"center",fontWeight:"600"}}>{state.msg}</h6>
                    <NewButton  text={'GO TO APP'} backgroundColor="#F0E8E3" color="red" style={{paddingBlock:'7px', marginTop:'20px'}} onClick={()=>toGo("/")}/>
                </div>:
            <Success />
            }
        </div>
    )
}