"use client"

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import style from './smallCountDown.module.css'



export default function SmallCountdown({ end_time }) {
  const [timeLeft, setTimeLeft] = useState('')

  useEffect(() => {
    const endTime = moment(end_time)

    const timer = setInterval(() => {
      const now = moment()
      const duration = moment.duration(endTime.diff(now))

      if (duration.asSeconds() <= 0) {
        clearInterval(timer)
        setTimeLeft('ENDED')
      } else {
        const days = duration.days().toString().padStart(2, '0')
        const hours = duration.hours().toString().padStart(2, '0')
        const minutes = duration.minutes().toString().padStart(2, '0')
        const seconds = duration.seconds().toString().padStart(2, '0')
        setTimeLeft(`ENDS ${days}D ${hours}:${minutes}:${seconds}`)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [end_time])

  return (
    <div className={style.countdown}>
      {timeLeft}
    </div>
  )
}