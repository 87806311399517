import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import classes from "./CustomBreadcrumbs.module.css"

const CustomBreadcrumbs = ({prevLinks=[], current=""}) => {
  return (
    <Breadcrumbs sx={{margin: "20px 0", fontFamily: "Metropolis"}} aria-label="breadcrumb">
        {
            prevLinks.map((link, i) => (
            <Link
                key={i}
                className={classes.crumbs_link}
                to={link.href}
            >
                {link.text}
            </Link>
            ))
        }
        <Typography style={{fontFamily: "Metropolis"}} color="text.primary">{current}</Typography>
    </Breadcrumbs>
  )
}

export default CustomBreadcrumbs