import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import auctionServices from "../../services/auction-services";
import GlobalHeader from "./molecules/GlobalHeader/GlobalHeader";
import { format } from "date-fns"
import Box from "@mui/material/Box";
import logger from "../../utils/logger";
import { Desktop, Mobile } from "./responsive/Responsive";
import Navbar from "../desktop/components/Navbar/Navbar";
import SubNavbar from "../desktop/components/SubNavbar/SubNavbar";
import Header from "../desktop/components/Header/Header";
import Footer from "../desktop/components/Footer/Footer";
import BackContainer from "../desktop/components/BackContainer/BackContainer";
import { mobileStyle } from "./Home";
import SubHeader from "./molecules/SubHeader/SubHeader";
import HeaderV2 from "./molecules/Header/HeaderV2";
import classes from './ItemBidHistory.module.css'
import LoadingScreen from "./LoadingScreen/Loading";
import { useMediaQuery } from "react-responsive";
import MobileSubFooter from "./atoms/MobileSubFooter/MobileSubFooter";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";
function ItemBidHistory() {
  const navigate = useNavigate();
  const { auctionId } = useParams();
  const { itemId } = useParams();
  const location = useLocation();
  const [bidHistory, setBidHistory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const primaryBackgroundColor = mobileStyle.backgroundColor[1];
  const primaryColor = mobileStyle.color[2];
 const {itemName} = location.state;
 const isMobile = useMediaQuery({
  maxWidth: 980
})
  useEffect(() => {
    const getBidHistory = async (auctionId, item_id) => {
      try {
        await auctionServices
          .getBidHistoryOfItemById(auctionId, item_id)
          .then(
            (response) => {
              setBidHistory(response?.data);
              setIsLoading(false);
            },
            (error) => {
              logger('./pages/components/ItemBidHistory.jsx', 'getBidHistory(): catch1', error);
              setIsLoading(false);
              navigate("/not-found");
            }
          );
      } catch (err) {
        logger('./pages/components/ItemBidHistory.jsx', 'getBidHistory(): catch2', err);
        setIsLoading(false);
        navigate("/not-found");
      }
    };
    getBidHistory(auctionId, itemId);
  }, [itemId]);

  const backButtonClick = (e) => {
      e.preventDefault();
      if (window.history.length <= 2) {
        navigate("/", {
          preventScrollReset: true
        })
        return
      }
      navigate(-1, {
        preventScrollReset: true
      });
    };

  return (
    <>
      <div className='history_wrapper' style={{backgroundColor: primaryBackgroundColor}}>
             {/* <HeaderV2
                logoType="white2"
                primaryBackgroundColor={primaryBackgroundColor}
                primaryColor={primaryColor}
                containerStyle={{position:"fixed"}}
                isSubHeader
                subHeaderTitle="Bid Details"
              /> */}
             <HeaderV3 />
              
        <div >
            {isLoading ? (
              <LoadingScreen bgColor="#212529"/>
            ) : (
              <>
                <div className={`margin_class`}>
                  <main style={{ backgroundColor: primaryBackgroundColor,width:isMobile ? "100%":"50%" }} className={classes.item_bid_container}>
                    <div className={classes.bid_history_mobile_container}>
                      <div className={classes.headings_container}>
                        <p style={{ color: primaryColor }} className={classes.heading} >Bid History</p>
                        <h3 style={{ color: primaryColor }} className={classes.item_name} >{itemName}</h3>
                      </div>
                      <div style={{ color: primaryColor }} className={classes.bids_container} >
                        {bidHistory ?
                          (bidHistory).map((bid, idx) => (
                            <div key={idx} className={classes.bidder_amount} >
                              <div className={classes.top_section}>
                                <div className={classes.content_left}>
                                  <span className={classes.bid_data} >{bid.bidder}</span>
                                  <span className={classes.bid_data_heading}>Bidder</span>
                                </div>
                                <div className={classes.content_left}>
                                  <span className={classes.bid_data} >${Math.round(bid.bid_amount)}.00</span>
                                  <span className={classes.bid_data_heading}>Bid Amount</span>
                                </div>
                              </div>
                              <div className={classes.bottom_section} >
                                <p className={classes.bid_date} >{format(new Date(bid.bid_date), "E, d LLL yyyy, kk:mm:ss")}</p>
                              </div>
                            </div>
                          )) : <div style={{textAlign:"center"}}>{!isLoading && "No Bids Found!"}</div>}
                      </div>
                    </div>
                      <div>
                        <button onClick={backButtonClick} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, borderBottom: `1px solid ${primaryColor}`, marginTop:"38px", marginBottom:'50px' }} className="new_footer_btn" >
                          Back
                        </button>
                      </div>
                  </main>
                </div>
              </>
            )}
          
        </div>
      </div>
      {/* <MobileSubFooter style={{padding:'2vw 5vw 7vw 5vw'}} primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor="white"/> */}
      <div style={{width:'100%',backgroundColor:primaryBackgroundColor}}>
            <MobileSubFooter  style={{padding:'2vw 5vw 7vw 5vw'}} primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor}/>
     </div>
    </>
  );
}

export default ItemBidHistory;
