import React, { useState, useEffect } from 'react';
import auctionServices from '../services/auction-services';
import logger from '../utils/logger';

const AuctionContext = React.createContext({
    auctionCategoryList: []
});

export const AuctionContextProvider = (props) => {
    const [auctionCategoryList, setAuctionCategoryList] = useState([]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        await auctionServices.getAuctionCategoryList().then(
          (response) => {
            setAuctionCategoryList(response.data);
          },
          (error) => {
            logger('./store/auction-context.js','getCategory(): catch1',error);
            setAuctionCategoryList([]);
          }
        );
      } catch (err) {
        logger('./store/auction-context.js','getCategory(): catch2',err);
        setAuctionCategoryList([]);
      }
    };
    getCategory();
  }, []);
  
    useEffect(() => {
      //get cause api
    }, []);
  
    return (
      <AuctionContext.Provider
        value={{
            auctionCategoryList: auctionCategoryList,
        }}
      >
        {props.children}
      </AuctionContext.Provider>
    );
  };

export default AuctionContext;