import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import causeServices from '../services/cause-services'

export const fetchCausesHaveEvents = createAsyncThunk(
    'redux/fetchCausesHaveEvents',
    async ({ page, limit, from='',causeCategoryId='' }) => {
        let response;
        if(['browse-cause', 'shop-now'].includes(from)){
            response = await causeServices.getAllCausesHaveEvents(page, limit, from)
        }
        else if(from === 'causeCategory') {
            response = await causeServices.fetchCausesByCategory(causeCategoryId, limit, page);
        }
        return response
    }
)

const initialState = {
    causes: [],
    limit: 20,
    page: 1,
    total_count:0,
    total_page: 0,
    error: '', 
    loading:false,
}

const causesHaveEventSlice = createSlice({
    name: 'causesHaveEvents',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchCausesHaveEvents.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchCausesHaveEvents.fulfilled, (state, action) => {
            state.causes = action.payload.data
            state.limit = action.payload.limit
            state.page = action.payload.page
            state.total_count = action.payload.total_count
            state.total_page = action.payload.total_page
            state.loading = false
        })
        builder.addCase(fetchCausesHaveEvents.rejected, (state, action) => {
            state.causes = []
            state.loading = false
            state.error = action.error.message;
        })
    }
})

export default causesHaveEventSlice.reducer;