import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './Section1.module.css';
import { Desktop, Mobile } from '../../../responsive/Responsive';
import Navbar from '../../../../desktop/components/Navbar/Navbar';
import SubNavbar from '../../../../desktop/components/SubNavbar/SubNavbar';



function Section1({fundraise}) {
    const navigate = useNavigate()
    const GoToEvents = () => {
        navigate('/auction?type=current')
    }

    return (
        <section className={style.section1}>
            <div className={style.section_under}>
                {/* <Mobile> */}
                    {/* <div className={style.header}>
                        <div onClick={() => navigate('/')} >
                            <img src='https://images.leadconnectorhq.com/img/f_webp/q_90/r_1200/u_https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/63974eedb5d8bdb84f6edb42.png' alt='logo' />
                        </div>
                    </div> */}
                {/* </Mobile> */}
                {/* <Desktop> */}
                    <Navbar style={{ borderBottom: "none", padding: "0",maxWidth:'99%' }} />
                {/* </Desktop> */}
                    <SubNavbar style={{maxWidth: '100%',boxShadow: "none",justifyContent: "space-between"}} from={'home'} />

                <div className={style.section1_text_box} >
                    <h1>Engage Donors. <br/> Amplify Your Mission. <br /> Inspire Change. </h1>
                    <p>
                        Power your next fundraiser with Boundless Giving, where nonprofits come to fundraise better by doing less.
                    </p>
                    <Link to={"/get-started"}><button>Create Your Event</button></Link>

                </div>
            </div>

        </section>
    )
}

export default Section1