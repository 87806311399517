import { React } from 'react';
import { Fab } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';

const FavouritesButton = ({handleClick=()=>null, isFavourite=false, fabStyle={}, needOutline=true}) => {
    return (
        <div onClick={handleClick} >
            <Fab size="small" aria-label="like" 
              sx={{backgroundColor:'transparent !important',
                    border:'none !important',
                    zIndex:1,
                    boxShadow: 'none',
                    ...fabStyle
                  }} 
            >
                {
                    isFavourite ?
                    <Favorite sx={{ color: isFavourite ? "red" : 'white' }} />
                    :
                    needOutline ?
                    <FavoriteBorder sx={{ fill: "red" }} />
                    :
                    <Favorite sx={{ color: 'white' }} />

                }
            </Fab>
        </div>
    )
}

export default FavouritesButton;