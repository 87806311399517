import { useEffect, useState } from "react";
import NewButton from "../../../../atoms/NewButton/NewButton";
import SectionContainer from "../../../../molecules/SectionContainer/SectionContainer"
import classes from './BoundlessCauses.module.css';
import causeServices from "../../../../../../services/cause-services";
import { Link, useNavigate } from "react-router-dom";
import { Desktop, Mobile } from '../../../../responsive/Responsive';
import auctionServices from "../../../../../../services/auction-services";

const BoundlessCauses = ({ title }) => {
    const navigate = useNavigate()
    const [featuredCauses, setFeaturedCauses] = useState([])
    useEffect(() => {
        fetchFeaturedCauses()
    }, [])
    async function fetchFeaturedCauses() {
        try {
            const data = await causeServices.fetchFeaturedCauses(1, 4)
            setFeaturedCauses(data.featuredCauses)
        } catch (error) { }
    }
    if (!featuredCauses?.length) {
        return;
    }
    const handleCardClick = async (id) => {
        try {
            /// now we are finding the events related to this cause and then going to the first event in the array.
            const auctions = await auctionServices.getAuctionsByCauseId(id);
            if (auctions?.data?.length) {
                const causeStore = auctions?.data.find(item => item.is_store === 1)
                if(causeStore) {
                return navigate(`/store/${causeStore.custom_url ? causeStore.custom_url : causeStore.id}`)
                }
                
             const firstEvent = auctions?.data[0]
              if(firstEvent.market_place === '1') {
                    navigate(`/marketplace/${firstEvent?.custom_url ? firstEvent?.custom_url : firstEvent?.id}`)
                }
                else navigate(`/event/${firstEvent.custom_url ? firstEvent.custom_url : firstEvent.id}`
                )
            }
        } catch (error) {
         console.log(error,"error navigating to store/marketplace of cause")
        }
    }

    return (
        <SectionContainer sectionBg='pure-white'>
            <div className={classes.boundlessCausesContainer} >
                <div className={classes.causesLeft} >
                    <h2 className={`newTitleClass ${classes.causeTitle}`} >{title}</h2>
                    <div className={classes.paraPart} >
                        <p className={classes.para} >We created Boundless Giving to meaningfully support causes you care about, while reducing waste and minimizing impact on the planet we’re leaving to our kids.</p>
                        <p className={classes.para}>With up to 80% of every sale going to an impactful nonprofit, don’t just shop, shop generously.</p>
                        <Desktop>
                            <Link to={'/browse-causes'}><NewButton className="carouselCardButton" text={'VIEW ALL CAUSES'} /></Link>
                        </Desktop>
                    </div>
                </div>
                <div className={`${classes.causesRight} ${featuredCauses?.length === 1 ? classes.singleGrid : ''}`} >
                    {
                        featuredCauses?.map((cause) => {
                            return (
                                <div onClick={() => handleCardClick(cause?.id)} key={cause?.id} className={`${classes.featuredCauseImageContainer}  ${featuredCauses?.length === 1 ? classes.fullwidthImage : ''}`} >
                                    <img className={`${classes.bannerImage}`} src={cause?.image1} alt={cause?.cause_banner_alt} />
                                    <img className={classes.causeLogo} src={cause?.cause_logo} alt={cause?.cause_logo_alt} />
                                </div>
                            )
                        })
                    }
                </div>
                <Mobile>
                    <Link to={'/browse-causes'}><NewButton className="carouselCardButton" text={'VIEW ALL CAUSES'} /></Link>
                </Mobile>
            </div>
        </SectionContainer>
    )
}

export default BoundlessCauses;