import React,{useEffect} from "react";
import { useMediaQuery } from "react-responsive";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import auctionServices from '../../services/auction-services';

import Logo1 from "../../images/shortBlackLogo.svg";

import {Helmet} from "react-helmet-async"
import logger from "../../utils/logger";
import { Desktop, Mobile } from "./responsive/Responsive";
import Navbar from "../desktop/components/Navbar/Navbar";
import SubNavbar from "../desktop/components/SubNavbar/SubNavbar";
import Header from "../desktop/components/Header/Header";
import Footer from "../desktop/components/Footer/Footer";

import classes from './congrats.module.css'
import BGLogoComponent from "../../images/BGLogoComponent/BGLogoComponent";
import { mobileStyle } from "./Home";
import NewButton from "./atoms/NewButton/NewButton";
import NewCardLayout from "./atoms/NewCardLayout/NewCardLayout";

const primaryBackgroundColor = mobileStyle.backgroundColor[1];
const primaryColor = mobileStyle.color[2];

function CongratulationsScreen() {
  const isDesktop = useMediaQuery({minWidth: 768})
  useEffect(() => {
    isDesktop && window.scrollTo(0,500)
  }, [])
  const navigate = useNavigate();
  const { state } = useLocation();
  // const { name, description, feature_img, msg } = state;
  const handleGoToCurrentAuction = async () => {
    try {
      const { data } = await auctionServices.getAuctionById(state?.auctionId);
      navigate(`/event/${data.custom_url ? data.custom_url : state?.auctionId}`, {
        state: { auction: data, image: data?.image2, from: 'event' },
        replace: true, 
      });
    } catch (error) {
      logger('./pages/components/CongratulationsScreen.jsx','handleGoToCurrentAuction(): catch',error);
      navigate('/not-found', { replace: true });
    }
  };


  return (
    <>
      <Helmet>
        <title>Boundless Giving</title>
      </Helmet>
      <div style={{backgroundColor:primaryBackgroundColor}}>
      {/* <Desktop>
      <div className="page_outer congrats_page">
          <Navbar />
          <SubNavbar />
          <Header title="Congratulations" />
        <div className="container">
          <main>
            <img src={Logo1} alt="" style={{ width: 100, margin: 20 }} />
            <h3 className="heading">Congratulations!!</h3>
            <p style={{ fontSize: 15, textAlign: "center"  }}>You are the leading bidder</p>
            <div className="bid_img">
              <img src={state?.feature_img} alt="" />
            </div>

            <div className="lot_details" style={{ marginBottom: 30 }}>
              <h3>{state?.name}</h3>
            </div>
            <p style={{ fontSize: 15, textAlign: "center" }}>Check your email for Auction updates</p>
            <div className="links">
              <Link onClick={handleGoToCurrentAuction} replace={true}>Return to Event</Link>
              <Link to={`/lot/${state?.itemId}?auction_id=${state?.auctionId}&cause_id=${state?.causeId}`} replace={true} >Return to Bid</Link>
            </div>
          </main>
        </div>
          <Desktop><Footer /></Desktop>
      </div>
      </Desktop> */}
      {/* <Mobile> */}
        <div style={{backgroundColor:primaryBackgroundColor, color:primaryColor}} className={`${classes.main}`} >
           <div className={classes.logo_container} onClick={() => navigate('/')} >
              <BGLogoComponent type="large" color={primaryColor} height="100%" />
           </div>
           <div className={classes.content_container} >
               <h3 className={classes.heading} >You are the leading bidder!</h3>
               <p className={classes.sub_heading} >Check your email for auction updates.</p>
                <NewCardLayout image={state?.feature_img} imageContainerStyle={{paddingInline:!isDesktop && 0, padding:isDesktop && 0}} neededLine={false}>
                   <div className="cart-card-content">
                     <h1 >{state?.name}</h1>
                     <div className={classes.amount} >
                      <p  style={{fontWeight:700}} className={classes.price} >${state?.bidAmount}</p>
                      <p className={classes.price}>Bid</p>
                     </div>
                   </div>
                </NewCardLayout>
                <div className={classes.buttons_container} >
                  <NewButton 
                    onClick={handleGoToCurrentAuction} 
                    color={primaryColor} 
                    backgroundColor={primaryBackgroundColor} 
                    text={'RETURN TO EVENT'} 
                    style={{width:'100%', fontSize:"16px",  lineHeight:'16px', paddingBlock:'10px'}}
                  />
                  <NewButton 
                    onClick={() => navigate(`/lot/${state?.itemId}?auction_id=${state?.auctionId}&cause_id=${state?.causeId}`)} 
                    color={primaryColor} 
                    backgroundColor={primaryBackgroundColor} 
                    text={'RETURN TO BID'} 
                    style={{width:'100%', fontSize:"16px",  lineHeight:'16px', paddingBlock:'10px'}}
                  />
                </div>
           </div>
        </div>
      {/* </Mobile> */}
      </div>
    </>
  );
}

export default CongratulationsScreen;