import React from 'react';
import NaveBar from '../../../desktop/components/Navbar/Navbar';
import SubNaveBar from '../../../desktop/components/SubNavbar/SubNavbar';
import FooterForm from '../../../desktop/components/SubForm/SubForm';
import Footer from "../../../desktop/components/Footer/Footer"
import Header from '../../../desktop/components/Header/Header';
import style from '../../../desktop/styles/brandPartner.module.css';
// import AccordionMui from '../../atoms/AccordionMui/AccordionMui';
import brandPartner1 from '../../../../images/brandPartner1.png'
import appSs1 from '../../../../images/app_ss1.png';
import appSs2 from '../../../../images/app_ss2.png';
import appSs3 from '../../../../images/app_ss3.png';
import appSs4 from '../../../../images/app_ss4.png';
import CustomBreadcrumbs from '../../../desktop/components/CustomBreadcrumbs/CustomBreadcrumbs';
import PrimaryBtn from '../../ButtonPrimary';
import { Desktop, Mobile } from '../../responsive/Responsive';
import GlobalHeader from '../../molecules/GlobalHeader';

function BrandPartner() {
    const mailTo=()=>{
        window.open('mailto:email@example.com')
    }
    return (
    <section>
        <Desktop>
            <NaveBar />
            <SubNaveBar />
            <Header title="Become A Brand Partner" />
        <div style={{margin: "0 auto", width: "68%"}}>
            <CustomBreadcrumbs prevLinks={[{text: "Home", href: "/"}]} current="Become A Brand Partner" />
        </div>
        </Desktop>
        <Mobile>
            <GlobalHeader
            title="Become a Brand Partner"
            SecondaryBtnContent={()=><span></span>}
            secondaryBtnBehavior="none"
            />
        </Mobile>
        <div className={style.brand_partner_container}>
            <div>
                <img src={brandPartner1} alt='brand heading' />
                <p>
                    Our mission is to help a a wide swath of nonprofit organizations raise funds through online 
                    auctions and pop-up stores featuring high-quality products from well-known brands.
                    That'swhy we're looking to you.
                </p>
                <p>
                    As a brand partner, you will be contributing to a greater cause by supporting nonprofits 
                    that are dedicated to making a positive impact in their communities. By donating your surplus
                    inventory, you'll be able to clear out valuable space in your warehouse while simultaneously
                    helping organizations that are working hard to make a difference.
                </p>

                   <div className={style.contact_us}>
                     <PrimaryBtn text="Contact us" handleClick={mailTo} style={{padding: "15px 40px",fontSize: "16px",borderRadius: "5px", fontWeight: "700"}} />
                   </div>
                   
            </div>

            <div>
                <div className={style.image_container}>
                    <img src={appSs4} alt='ss'/>
                    <img src={appSs2} alt='ss'/>
                    <img src={appSs1} alt='ss'/>
                    <img src={appSs3} alt='ss'/>

                </div>
                <div>
                <p>
                 Our online platform is designed to make the process of donating and selling your surplus inventory as
                 <b> easy and seamless </b> as possible. Our team will handle the logistics of collecting and storing your 
                 donated goods, and we'll work with you to determine the best prices for each item in order to 
                 maximize the funds raised for our partner nonprofits.
                </p>
                <p>
                 In return for your generous donation, you'll receive <b> valuable exposure to a growing audience of 
                 socially-conscious consumers </b> who appreciate brands that care about making a positive
                 impact in their communities.<b> We'll feature your brand prominently </b> on our platform and
                 social media channels, and we'll work with you to develop a custom marketing plan that will help 
                 you achieve your specific brand goals.
                </p>
                <p>
                 If you're interested in becoming a brand partner and making a meaningful difference in your community,
                 please <u onClick={mailTo}> contact us today </u> to learn more about this exciting opportunity. Let's work together to create 
                 positive change and support the organizations that are doing good in the world!
                </p>

                </div>
                <div className={style.contact_us}>
                    <PrimaryBtn text="Contact us" handleClick={mailTo} style={{padding: "15px 40px",fontSize: "16px",borderRadius: "5px", fontWeight: "700"}} />
                </div>
            </div>

            {/* <div className={style.section2}>
                <h2>Ways to Sell at Boundless</h2>
                <div>
                    <img src={brandPartner2} alt='brand2' />
                    <div className={style.card_bottom}>
                        <h3>AUCTION</h3>
                        <p>Boundless Giving was founded with a mission to help nonprofits
                            increase fundraising impact and expand donor bases. We have
                            partnered with an array of brands who are looking to improve
                            their sustainability and philanthropic efforts by supplying
                            donated surplus and promotional goods into Boundless Giving
                            charitable pop-ups and auctions.</p>
                    </div>
                </div>
                <div>
                    <img src={brandPartner3} alt='brand2' />
                    <div className={style.card_bottom}>
                        <h3>PRIVATE SALES</h3>
                        <p>Boundless Giving was founded with a mission to help nonprofits
                            increase fundraising impact and expand donor bases. We have
                            partnered with an array of brands who are looking to improve
                            their sustainability and philanthropic efforts by supplying
                            donated surplus and promotional goods into Boundless Giving
                            charitable pop-ups and auctions.</p>
                    </div>
                </div>
                <div>
                    <img src={brandPartner4} alt='brand2' />
                    <div className={style.card_bottom}>
                        <h3>BUY NOW</h3>
                        <p>Boundless Giving was founded with a mission to help nonprofits
                            increase fundraising impact and expand donor bases. We have
                            partnered with an array of brands who are looking to improve
                            their sustainability and philanthropic efforts by supplying
                            donated surplus and promotional goods into Boundless Giving
                            charitable pop-ups and auctions.</p>
                    </div>
                </div>
                <div>
                    <img src={brandPartner5} alt='brand2' />
                    <div className={style.card_bottom}>
                        <h3>RETAIL LOCATIONS</h3>
                        <p>Boundless Giving was founded with a mission to help nonprofits
                            increase fundraising impact and expand donor bases. We have
                            partnered with an array of brands who are looking to improve
                            their sustainability and philanthropic efforts by supplying
                            donated surplus and promotional goods into Boundless Giving
                            charitable pop-ups and auctions.</p>
                    </div>
                </div>

            </div> */}
{/* 
            <div className={style.section3}>
                <h2>FAQ</h2>
                <div>
                    <AccordionMui title='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                     titleStyle={{color:'#113270'}}
                     titleVarient='subtitle2'
                        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, cLorem ipsum
                   dolor sit amet, consectetur adipiscing elit,et, consectetur Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit,,Lorem Lorem ipsum dolor sit amet, consectetur adipiscing
                     elit,onsectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,'
                     descriptionStyle={{color:'#6c6c6c'}}
                     descriptionVarient='caption' />
                </div>
                <div>
                    <AccordionMui  title='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                     titleStyle={{color:'#113270'}}
                     titleVarient='subtitle2'
                        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, cLorem ipsum
                   dolor sit amet, consectetur adipiscing elit,et, consectetur Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit,,Lorem Lorem ipsum dolor sit amet, consectetur adipiscing
                     elit,onsectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,'
                     descriptionStyle={{color:'#6c6c6c'}}
                     descriptionVarient='caption' />
                </div>
                <div>
                    <AccordionMui  title='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                     titleStyle={{color:'#113270'}}
                     titleVarient='subtitle2'
                        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, cLorem ipsum
                   dolor sit amet, consectetur adipiscing elit,et, consectetur Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit,,Lorem Lorem ipsum dolor sit amet, consectetur adipiscing
                     elit,onsectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,'
                     descriptionStyle={{color:'#6c6c6c'}}
                     descriptionVarient='caption' />
                </div>
                <div>
                    <AccordionMui  title='Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                     titleStyle={{color:'#113270'}}
                     titleVarient='subtitle2'
                        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, cLorem ipsum
                   dolor sit amet, consectetur adipiscing elit,et, consectetur Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit,,Lorem Lorem ipsum dolor sit amet, consectetur adipiscing
                     elit,onsectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,'
                     descriptionStyle={{color:'#6c6c6c'}}
                     descriptionVarient='caption' />
                </div>
            </div> */}
        </div>
        <Desktop>
            <FooterForm/>
            <Footer />
        </Desktop>
    </section>
    )
}

export default BrandPartner