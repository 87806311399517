import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Link, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import logger from '../../utils/logger';

import Image1 from "../../images/intro1.png"
import Image2 from "../../images/intro2.png"
import Image3 from "../../images/intro3.png"
import Image4 from "../../images/intro2.png"

import "./SplashSlider.css"

import $ from 'jquery';
import Auth from './Auth';

function SplashSlider() {
  let navigate = useNavigate();

  const handlers = useSwipeable({
    onSwiped: () => {
      $('.hideSkip').show();
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const showHide = (i) => {
    if (i === 2) {
      $('.hideSkip').hide();
      // $('.showStart').show();
    } else {
      // $('.showStart').hide();
    }
  };

  const handleClick = () => {
    logger("./pages/layouts/splashSlider.jsx", 'Guest user login', {})
    localStorage.setItem("guest_user_visited", true);
    navigate("/");
  }

  return (
    <>
      <div className='slider_sec' {...handlers}>
        <div
          className='container'
          style={{ position: 'relative', padding: '0px 0px 0px 0px' }}
        >
          <div
            style={{
              position: 'absolute',
              top: '4%',
              left: '10%',
              color: '#006883',
              zIndex: '3',
              textTransform: "uppercase"
            }}
          >
            <Link
              to=''
              className="hideSkip"
              onClick={handleClick}
            >
              {' '}
              Skip{' '}
            </Link>
          </div>
          <Carousel
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                  <div type="button" onClick={onClickHandler} title={label} className='start_btn_container'>
                      <div className='showStart'>Next</div>
                  </div>
              )
          }
          showIndicators={true} onChange={(i) => showHide(i)}>
            <div className='splash_image_container'>
              <img src={Image1} alt="" />
            </div>
            {/*<div className='splash_image_container'>
              <img src={Image2} alt="" />
              </div>*/}
            {/* <div className='splash_image_container'>
              <img src={Image3} alt="" />
            </div> */}
            <div className='splash_image_container'>
              <div className='splash_image_last' onClick={handleClick}>
                <img src={Image4} alt="" />
              </div>
            </div>
            {/* <Auth /> */}
          </Carousel>

          {/* <div className='start_btn_container'>
            <Link
              to='/auth'
              className='showStart'
            >
              {' '}
              Start{' '}
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default SplashSlider;
