import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'
import logger from '../utils/logger'


export const fetchFeaturedItems = createAsyncThunk(
    'redux/fetchFeaturedItems',
    async ({ page, limit}) => {
        const response = await auctionServices.getAllFeaturedItems(page, limit)
        return response
    }
)

const initialState = {
    featuredItems: {
        data: [],
        limit: 20,
        page: 1,
        total_count:0,
        total_page: 0,
    },
    featuredLoading: false,
    featuredLoadingMore:false,
    error: '', 
}

const featuredItemsSlice = createSlice({
    name: 'featuredItems',
    initialState,
    reducers: {
        resetFeaturedItems:(state,action)=>{
            // state=initialState
            state.featuredItems = {
                data: [],
                limit: 20,
                page: 1,
                total_count:0,
                total_page: 0,               
            }
            state.featuredLoading=false
            state.featuredLoadingMore=false
            state.error= ''
            state.from=''
        },

    },
    extraReducers: (builder) => {
    
        builder.addCase(fetchFeaturedItems.pending, (state, action) => {
           state.featuredItems.page>1?state.featuredLoadingMore=true: state.featuredLoading = true;
            state.error = ''
        })

        builder.addCase(fetchFeaturedItems.fulfilled, (state, action) => {
           if(action?.payload?.page == 1) {
            state.featuredItems = {
                ...state.featuredItems,
                data: action.payload?.data,
                limit: action.payload?.limit,
                page: action.payload?.page,
                total_count: action.payload?.total_count,
                total_page: action.payload?.total_page
            }
        }
        else if(action.payload?.page > 1){
            state.featuredItems = {
                ...state.featuredItems,
                data: [...state.featuredItems?.data, ...action.payload?.data],
                limit: action.payload?.limit,
                page: action.payload?.page,
                total_count: action.payload?.total_count,
                total_page: action.payload?.total_page
            }
         }
        state.featuredLoading = false;
        state.featuredLoadingMore = false;
        state.error = ''
        })
        builder.addCase(fetchFeaturedItems.rejected, (state, action) => {
            state.featuredLoading = false;
            state.featuredLoadingMore = false;
            state.error = action.error.message;
            logger('./redux/featuredItems', 'fetchFeaturedItems(): catch1', action.error);
        })
    },
})

export default featuredItemsSlice.reducer;
export const {resetFeaturedItems} = featuredItemsSlice.actions