import { Player } from "@lottiefiles/react-lottie-player";
import CircularProgress from '@mui/material/CircularProgress';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import parseQuery from "query-string";
import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import authService from '../../services/auth-services';
import './VerifyEmail.css';
import { Desktop, Mobile } from "../components/responsive/Responsive";
import congrats from "../../images/congratsBlack.svg"
import BGLogoComponent from "../../images/BGLogoComponent/BGLogoComponent";

function Success() {
    return (
        <>
       
       
            <div className="ve_success" >
                <div>
                    <div style={{marginBottom:"30px"}}>
                        <img src={congrats} alt="" />
                    </div>
                    <h3>Email verified.</h3>
                    <p style={{fontSize:"18px",fontWeight:"400"}}>Redirecting to site...</p>
                </div>
            </div>
        
        </>
       
    )
}
export default function VerifyEmail() {
    const toGo = useNavigate();
    const url = useLocation();
    const [state,setState] = useState({msg:'Verifying...',success:false});
    useEffect(()=>{
        const {token,email} = parseQuery.parse(url.search);
        const redirectURL = JSON.parse(localStorage.getItem("redirect"))
        if(token && email && !state.success) {
            setTimeout(async ()=>{
                const success = await authService.verifyEmail(token,email,state,setState,toGo);
                if(success) {
                    setState({...state, success: true, msg: "Email verified and signed up successfully!"})
                    setTimeout(() => {
                        if(redirectURL) {
                            localStorage.removeItem("guest_user_visited")
                            localStorage.removeItem("redirect")
                            window.location.replace(redirectURL)
                        } else {
                            window.location.reload()
                        }
                    }, 3 * 1000)
                } else {
                    setState({...state, success: false, msg: "Email verification failed!"})
                }
            },2000);
        } else {//Invalid Url
            setTimeout(()=>{
                setState({...state,msg:'Invalid Url!'});
                setTimeout(()=>{
                    setState({...state,msg:'Redirecting...'});
                    setTimeout(()=>{
                        toGo('/register');
                    },2000);
                },2000);
            },2000);
        }
    },[url]);

    return (
        <div className="verify_email">
            <div className="ve_logo"><BGLogoComponent type="large" color="black" height="85" /></div>
           {!state.success?
                    <>
                    {state.msg==='Invalid Url!'?
                        <ReportGmailerrorredRoundedIcon style={{color:'red',fontSize:'60px'}} />:
                        <CircularProgress style={{color:'red'}} thickness={5} />
                    }
                    <h3>{state.msg}</h3>
                </>:
            <Success />
            }
        </div>
    )
}