import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import searchServices from '../services/search-service';
import logger from '../utils/logger';


export const auctionSearch = createAsyncThunk(
    'redux/auctionSearch',
    async ({ searchTerm, variable,marketPlace, userId}) => {
        if (variable === 'itemsList') {
            const response = await searchServices.getAuctionItems(searchTerm, 1, 20,marketPlace, userId);
            return { response: response.data, variable, totalPage: response.totalPage, totalCount: response.totalCount }
        }
        if (variable === 'auctionList') {
            const response = await searchServices.getAuctionsList(searchTerm, 1, 20);
            return { response: response.data, variable, totalPage: response.totalPage, totalCount: response.totalCount }
        }
    }
)

export const loadMoreItems = createAsyncThunk(
    'redux/loadMoreItems',
    async ({ searchTerm, variable, page=1, limit=20 ,marketPlace, userId }) => {
        if (variable === 'itemsList') {
            const response = await searchServices.getAuctionItems(searchTerm, page, limit,marketPlace, userId);
            return { response: response.data, variable, totalPage: response.totalPage, totalCount: response.totalCount }
        }
        if (variable === 'auctionList') {
            const response = await searchServices.getAuctionsList(searchTerm, page, limit);
            return { response: response.data, variable, totalPage: response.totalPage, totalCount: response.totalCount }
        }
    }
)

const initialState = {
    auctionList: [],
    itemsList: [],
    error: '',
    loading: false,
    itemPage: 1,
    auctionPage: 1,
    itemsTotal: 0,
    itemsTotalPage: 1,
    auctionsTotal: 0,
    auctionsTotalPage: 1,
}


const auctionSearchSlice = createSlice({
    name: 'auction',
    initialState,
    reducers: {
        resetAuction:(state,action)=>{
            return initialState
        },
        changePage: (state, action) => {
            if(action.payload.type === 'item') {
                state['itemPage'] = action.payload.page
            } else if (action.payload.type === 'auction') {
                state['auctionPage'] = action.payload.page
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(auctionSearch.pending, (state, action) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(auctionSearch.fulfilled, (state, action) => {
            const { response, variable, totalCount, totalPage } = action.payload
            state[variable] = response
            state.itemPage = 1
            state.auctionPage = 1
            state.loading = false
            if(variable === 'itemsList') {
                state['itemsTotal'] = totalCount
                state['itemsTotalPage'] = totalPage
            } else {
                state['auctionsTotal'] = totalCount
                state['auctionsTotalPage'] = totalPage
            }
        })
        builder.addCase(auctionSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            logger('./redux/searchReducer', 'auctionSearch(): catch1', action.error);
        })
        builder.addCase(loadMoreItems.pending, (state, action) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(loadMoreItems.fulfilled, (state, action) => {
            const { response, variable } = action.payload
            state[variable] = [...response]
            state.loading = false;
        })
        builder.addCase(loadMoreItems.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            logger('./redux/searchReducer', 'auctionSearch(): catch1', action.error);
        })
    },
})

export default auctionSearchSlice.reducer;
export const {resetAuction, changePage} = auctionSearchSlice.actions




