import React from 'react'
import classes from "../../../../../desktop/styles/Home.module.css"
import styles from "./NonProfits.module.css"
import NewButton from '../../../../atoms/NewButton/NewButton'
import RoundedContainer from '../../../../atoms/RoundedContainer/RoundedContainer'
import TagHeading from '../../../../atoms/TagHeading/TagHeading'
import { useNavigate } from 'react-router-dom'
import { Desktop, Mobile } from '../../../../responsive/Responsive'

const NonProfits = ({mobileStyle={}}) => {
    const backgroundColor = mobileStyle?.backgroundColor[2]
    const color = mobileStyle?.color[0];
    const navigate = useNavigate()
    const handleNonProfitClick = () => {
        navigate("/non-profit")
    }
    const handleBrandClick = () => {
         navigate("/brand")
    }
    return (
        <>
        <Mobile>
        <section style={{backgroundColor:mobileStyle?.backgroundColor[0], paddingTop:'0px'}}  className={classes.dhero}>
            <div style={{position:'relative', paddingBlock:"33px"}}>
            <RoundedContainer style={{backgroundColor:backgroundColor, width:'96%'}}>
                <div style={{color: color}} className={styles.first_section} >
                    <TagHeading heading='Nonprofits'/>
                    <h3 style={{fontSize:"32px"}}>Your mission is our mission.</h3>
                    <p>50-80% of all proceeds from purchases go directly to your charity. <br /> No strings. No fine print.</p>                
                    <NewButton text={'HOW IT WORKS'} color={color} onClick={handleNonProfitClick} style={{fontSize:'14px',letterSpacing:"0"}}  backgroundColor={backgroundColor} />
                </div>
                    <hr style={{border:`1px solid ${color}`}} />                    
                <div style={{color: color}} className={styles.first_section} >
                     <TagHeading heading='Brands'/>
                     <h3 style={{fontSize:"32px"}} >Here, shopping a brand means shopping your values</h3>
                     <p>We can help you sell it, donate it, keep it out of landfills and do a whole lot of good. <br /> Here’s how.</p>

                    <NewButton text={'HOW IT WORKS'} color={color} onClick={handleBrandClick} style={{fontSize:'14px',letterSpacing:"0"}}  backgroundColor={backgroundColor} />

                </div>
            </RoundedContainer>    
          </div>
        </section>
        </Mobile>

        <Desktop>
            <section className={styles.desktop_section} style={{backgroundColor:mobileStyle?.backgroundColor[0]}}>
                <div className={`${styles.desktop_container} margin_class`} >
                    <RoundedContainer style={{backgroundColor:backgroundColor, height:'100%'}}>
                        <div style={{color: color}} className={styles.first_section} >
                        <div className={styles.inner_section}>
                        <TagHeading heading='Nonprofits'/>
                        <h3>Your mission is our mission.</h3>
                        <p>50-80% of all proceeds from purchases go directly to your charity. <br /> No strings. No fine print.</p>                
                        </div>
                        <NewButton text={'HOW IT WORKS'} color={color} onClick={handleNonProfitClick} style={{fontSize:'14px', margin:'5px 21px'}}  backgroundColor={backgroundColor} />
                        </div>
                    </RoundedContainer>
                    <RoundedContainer style={{backgroundColor:backgroundColor, height:'100%'}}>
                         <div style={{color: color}} className={styles.first_section} >
                         <div className={styles.inner_section} >
                          <TagHeading heading='Brands'/>
                          <h3 style={{width:'100%'}} >Here, shopping a brand means shopping your values.</h3>
                          <p>We can help you sell it, donate it, keep it out of landfills and do a whole lot of good. <br /> Here’s how.</p>
                          </div>
                          <NewButton text={'HOW IT WORKS'} color={color} onClick={handleBrandClick} style={{fontSize:'14px', margin:'5px 21px'}}  backgroundColor={backgroundColor} />
                          </div>
                    </RoundedContainer>
                </div>
            </section>
        </Desktop>
        </>
    )
}

export default NonProfits