import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';

import "./ConfirmAlertLogin.css";
import { Desktop, Mobile } from './responsive/Responsive';
import { mobileStyle } from './Home';
import NewButton from './atoms/NewButton/NewButton';
import { useMediaQuery } from 'react-responsive';

const ConfirmAlertLogin = ({ openConfirmAlert, handleCloseConfirmAlert, context=null }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 768 })

  const handleLoginClick = () => {
    if(context && context?.returnUrl) {
      navigate("/login", {state: {redirect: context?.returnUrl}})
    }
    else navigate("/login", {state: {redirect: context ? `/lot/${context.id}?auction_id=${context.auctionId}&cause_id=${context.causeId}` : null}})
  }

  const handleSignupClick = () => {
    if(context && context?.returnUrl) {
      navigate("/register", {state: {redirect: context?.returnUrl}})
    }
    else navigate("/register", {state: {redirect: context ? `/lot/${context.id}?auction_id=${context.auctionId}&cause_id=${context.causeId}` : null}})
  }

  return (
    <div>
        <Dialog
          open={openConfirmAlert}
          onClose={handleCloseConfirmAlert}
            sx={{
                // ".MuiDialogContent-root": {padding: "14px 20px"},
                ".MuiPaper-root": {
                borderRadius: '25px',
                minWidth: isDesktop ? '300px':'189px',
                paddingTop:isDesktop? '30px':'20px',
                justifyContent: 'space-between',
                paddingBottom:isDesktop? '42px':'32px',
                gap:isDesktop? '25px':'20px',
                backgroundColor:"#FCFCFC",
                zIndex:"1500",
                margin:'19px'
              }
            }}
        >
            <DialogContent>
                <DialogContentText textAlign="left">
                    <dd style={{ margin: "0px 0px", fontSize:isDesktop? "25px":"18px", textAlign: "center", color:mobileStyle.color[1] }} className='alert-login-signup'>
                       Please login or create an account to continue.
                       <div className='new_buttons_container'>
                        <NewButton onClick={handleLoginClick} text={'Login'} backgroundColor={mobileStyle.backgroundColor[0]} color={mobileStyle.color[2]} 
                          style={{
                            marginTop: "17px",
                            fontSize:isDesktop?'18px':"12px",
                            
                            width:'100%',
                            textTransform:'uppercase',
                            border:'0',
                            height:isDesktop?"45px":"100%"
                          }}
                        />
                         <NewButton onClick={handleSignupClick} text={'Register'} color={mobileStyle.color[0]} 
                          style={{
                            marginTop: "17px",
                            fontSize:isDesktop?'18px':"12px",
                            
                            width:'100%',
                            textTransform:'uppercase',
                            height:isDesktop?"45px":"100%"
                          }}
                        />
                        </div>
                    </dd>
                     
                </DialogContentText>
            </DialogContent>
        </Dialog>
       
    </div>
  );
};

export default ConfirmAlertLogin;
