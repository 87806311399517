import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

const Alert = ({open, message, handleConfirm}) => {
  return (
    <Dialog
    open={open}
    onClose={handleConfirm}
    fullWidth={true}
  >
    <DialogTitle>Alert</DialogTitle>
    <DialogContent sx={{ fontFamily: "AvenirNext" }}>
      <DialogContentText textAlign="left" sx={{ fontFamily: "AvenirNext", fontWeight: "600" }}>
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={handleConfirm}
        size="small"
        variant='outlined'
      >
        Okay
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default Alert