import {configureStore} from "@reduxjs/toolkit"
import itemsReducer from "./itemsReducer";
import searchReducer from "./searchReducer";
import eventReducer from "./eventReducer";
import cartReducer from "./cartReducer";
import completedEventReducer from "./completedEventReducer";
import previewEventReducer from "./previewEventReducer";
import featuredItemsReducer from "./featuredItemsReducer";
import blogReducer from "./blogReducer";
import causeReducer from "./causeReducer";
import browseItemsReducer from "./browseItemsReducer";
import categoryReducer from "./categoryReducer";
import favouritesReducer from "./favouritesReducer";
import shopNowFilterReducer from "./shopNowFilterReducer";
const store = configureStore({
    reducer: {
        items: itemsReducer,
        search:searchReducer,
        auction:eventReducer,
        cartDetails:cartReducer,
        completedAuction:completedEventReducer,
        previewAuction:previewEventReducer,
        featuredItems:featuredItemsReducer,
        blogs:blogReducer,
        causesHaveEvents:causeReducer,
        browseItems:browseItemsReducer,
        categories: categoryReducer,
        favourites: favouritesReducer,
        shopNow: shopNowFilterReducer
    }
})

export default store