import { Tooltip, ClickAwayListener } from '@mui/material';
import { Desktop, Mobile } from '../../responsive/Responsive'
import { useState } from 'react';
import constants from '../../../../constants/en';
import classes from './TooltipComponent.module.css'

const TooltipComponent = ({ children, text = '' }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    return (
        <>
            <Desktop>
                <Tooltip
                    title={
                        <span className='favouritesTooltip'>
                            {text}
                        </span>}
                    placement='top'
                >
                    {children}
                </Tooltip>
            </Desktop>
            <Mobile>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <span className={classes.tooltipMobile}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <span className='favouritesTooltip'>
                                    {text}
                                </span>}
                            placement='top'
                        >
                            <span onClick={handleTooltipOpen}>{children}</span>
                        </Tooltip>
                    </span>
                </ClickAwayListener>
            </Mobile>
        </>
    )
}

export default TooltipComponent;