import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'
import logger from '../utils/logger'

export const fetchCompletedAuction = createAsyncThunk(
    'redux/fetchCompletedAuction',
    async ({type, cause_category_id, page, limit}) => {
        const response = await auctionServices.getAllCompletedAuctionsList(page, limit)
        return response
    }
)

const initialState = {
    completedAuction: {
        data: [],
        limit: 20,
        page: 1,
        total_count:0,
        total_page: 0,
    },
    completedLoading: false,
    completedLoadingMore:false,
    error: '',
    from:'',  
}


const completedAuctionSlice = createSlice({
    name: 'completedAuction',
    initialState,
    reducers: {
        resetCompletedAuction:(state,action)=>{
            // state=initialState
            state.completedAuction = {
                data: [],
                limit: 20,
                page: 1,
                total_count:0,
                total_page: 0,               
            }
            state.completedLoading=false
            state.completedLoadingMore=false
            state.error= ''
            state.from=''
        },
        setCompletedFrom:(state, action) => {
            state.from = action.payload.from
        }

    },
    extraReducers: (builder) => {
    
        builder.addCase(fetchCompletedAuction.pending, (state, action) => {
           state.completedAuction.page>1?state.completedLoadingMore=true: state.completedLoading = true;
            state.error = ''
        })

        builder.addCase(fetchCompletedAuction.fulfilled, (state, action) => {
            state.completedAuction = {
                ...state.completedAuction,
                data: [...state.completedAuction?.data, ...action.payload?.data],
                limit: action.payload?.limit,
                page: action.payload?.page,
                total_count: action.payload?.total_count,
                total_page: action.payload?.total_page
            }
            state.completedLoading = false;
            state.completedLoadingMore = false;
            state.error = ''
        })
        builder.addCase(fetchCompletedAuction.rejected, (state, action) => {
            state.completedLoading = false;
            state.completedLoadingMore = false;
            state.error = action.error.message;
            logger('./redux/eventReducer', 'getCategory(): catch1', action.error);
        })
    },
})

export default completedAuctionSlice.reducer;
export const {resetCompletedAuction, setCompletedFrom} = completedAuctionSlice.actions