import React, { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import classes from './ImageInput.module.css';
import inputClasses from '../Input/Input.module.css';

const ImageInput = ({ label, multiple = false, accept = "image/*", images = [], handleChange, required = false, edit = false, onDelete = () => { }, max = 1, reset, setReset, loading = false, }) => {
    let inputRef = useRef();
    useEffect(() => {
        if (reset === "reload") {
            inputRef.current.value = null
            setReset("")
        }
    }, [reset])

    return (
        <div style={{ margin: "10px 0", width: '100%' }}>
            {label && <label className={inputClasses.label} >{label}{(accept === 'image/*' && required) && <span style={{ color: "red" }}>*</span>}</label>}
            <div className={classes.imageinputContainer}>
                {images.map((image, j) => (
                    <div key={image.name} style={edit ? { position: "relative" } : {}} className={`${accept === 'image/*' ? classes.imageinputImage : classes.imageinputVideo}`}>
                        {edit && <div className={classes.deleteImageIcon} onClick={() => onDelete(image?.name, j)}>&times;</div>}
                        {((accept === 'image/*') || (accept === ".png")) ? <img src={image.name} alt="" /> :
                            <video width="100%" height="100%">
                                <source src={image.name} type="video/mp4" />
                            </video>}
                    </div>
                ))}
                {
                    max && max === images.length ? null : (
                        <div className={classes.imageinputFileWrapper}>
                            {
                                loading ? <CircularProgress /> :
                                    <>
                                        <input ref={inputRef} onChange={handleChange} multiple={multiple} type="file" accept={accept} />
                                        +
                                    </>
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ImageInput;