import React, { useEffect, useState } from 'react';
import { Desktop, Mobile } from '../../responsive/Responsive';
import downArrow from "../../../../images/downArrow.svg";
import { Carousel } from 'react-responsive-carousel';
import classes from './BrowseItemsCauseSection.module.css';

const BrowseItemsCauseSection = ({ causeData={} }) => {
    const [isAboutCauseOpen, setIsAboutCauseOpen] = useState(true)
    
    return (
        <>
            { !Object.keys(causeData)?.length ? null : 
             <>
                <Desktop>
                    <div className={classes.main_section} >
                        <div className={classes.contianer} >
                            <h2>About Cause</h2>
                            <img
                                src={downArrow}
                                className={classes.arrow_image}
                                style={{transform: !isAboutCauseOpen && 'rotate(180deg)' }}
                                alt="Arrow"
                                onClick={() => setIsAboutCauseOpen(!isAboutCauseOpen)}
                            />
                        </div>
                        {
                            (isAboutCauseOpen) &&
                            <div className={classes.carousel_section}>
                                <div className={classes.image_section}>
                                    <Carousel
                                        // infiniteLoop={true}
                                        showArrows={false}
                                        showIndicators={causeData?.images?.length > 1}
                                        showThumbs={true}
                                        // onClickItem={null}
                                        onClickThumb={null}
                                    >
                                        {causeData?.images &&
                                            causeData?.images.map((i, idx) => (
                                                <div key={i}>
                                                    <img className={classes.cause_image} src={i} loading='lazy' alt='cause' />
                                                </div>
                                            ))}
                                    </Carousel>
                                </div>
                                <div className={classes.content_section}>
                                    <p className={classes.legal_name}>{`[${causeData?.legal_name}]`}</p>
                                    <p className={classes.about_cause_text} >{causeData?.about}</p>
                                </div>
                            </div>
                        }
                    </div>
                </Desktop>

                <Mobile>
                    <div style={{ margin: "5vw" }}>
                        <div className={classes.contianer} >
                            <h1>About Cause</h1>
                            <img
                                src={downArrow}
                                className={classes.arrow_image}
                                style={{ transform: !isAboutCauseOpen && 'rotate(180deg)'}}
                                alt=""
                                onClick={() => setIsAboutCauseOpen(!isAboutCauseOpen)}
                            />
                        </div>
                        {
                            (isAboutCauseOpen) &&
                            <div>
                                <div style={{ margin: "20px 0" }}>
                                    {/* <img src={causeData?.images[0]} alt="" style={{height:"100px",width:"150px",objectFit:"cover"}}/> */}
                                    <Carousel
                                        // infiniteLoop={true}
                                        showArrows={false}
                                        showIndicators={causeData?.images?.length > 1}
                                        showThumbs={true}
                                        // onClickItem={null}
                                        onClickThumb={null}
                                    >
                                        {causeData?.images &&
                                            causeData?.images.map((i, idx) => (
                                                <div key={i}>
                                                    <img className={classes.cause_image} src={i} loading='lazy' alt='cause' />
                                                </div>
                                            ))}
                                    </Carousel>
                                </div>
                                <p className={classes.legal_name}>{`[${causeData?.legal_name}]`}</p>
                                <p className={classes.about_cause_text} >{causeData?.about}</p>
                            </div>
                        }
                    </div>
                </Mobile>
            </>}
        </>
    )
}

export default BrowseItemsCauseSection;