import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { clearPageNumber } from "../../../../redux/itemsReducer";

const ShopMore = ({title=''}) => {
    const isMobile = useMediaQuery({
        maxWidth: 768
    })
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div className="bag-container-empty" style={{ marginTop: isMobile ? "100px" : "" }}>
            <div className="no-items">{title}</div>
            <div style={{ textAlign: 'center' }}>
                <button style={{ width: isMobile ? "" : "100px" }} onClick={() => {
                    dispatch(clearPageNumber());
                    // navigate("/browse-items", {
                    //   state: { buyNow: true, title: "Buy now", from: 'buyNow' },
                    // })
                    navigate('/shop-now')
                }

                } className='buy-checkout-btn-outline'>Keep shopping</button>
            </div>
        </div>
    )
}


export default ShopMore;