import React from 'react';
import style from './Section5.module.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';


function Section5({fundraise}) {
    return (
        <section className={style.section5}>
            <img src='https://assets.cdn.filesafe.space/gDomeI4d9kjTwwlhShAw/media/6422308da59a01ed7bb97d09.jpeg' alt='bg' />
            <div className={style.card_container}>
                <div className={style.card}>
                    <h2><strong>All-inclusive features</strong></h2>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b> Expand donor bases </b></p></div>
                        <p>Your event and its goods are discoverable by all users to help maximize impact.</p>
                    </div>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b> Real-time analytics </b></p></div>
                        <p>Access your event's data at any time without having to pay for it.</p>
                    </div>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b> Customer service </b></p></div>
                        <p>Some platforms charge for customer service. We don't.</p>
                    </div>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b> No long term contracts </b></p></div>
                        <p>There are no  long-term agreements to use Boundless Giving.</p>
                    </div>
                    
                    <div className={style.button_container}>
                        <Link to={"/get-started"}><button>SIGN UP</button></Link>
                    </div>

                </div>

            </div>
          
        </section>
    )
}

export default Section5