import { MenuItem, Select } from "@mui/material"
import { mobileStyle } from "../../Home"
import classes from './SelectDropDown.module.css'
import inputClasses from '../Input/Input.module.css'

const SelectDropDown = ({ options = [], value = '', name = '', onChange, disabledText, required = false, label }) => {
    const { backgroundColor } = mobileStyle;
    return (
        <div className={classes.dropDownContainer}>
            {label && <label className={inputClasses.label}>{label} {required && <span style={{color: "red"}}>*</span> }</label>}
            <Select
                required={required}
                displayEmpty
                defaultValue={value}
                name={name}
                value={value}
                onChange={onChange}
                sx={{
                    fontSize: '0.8rem',
                    height: 48,
                    backgroundColor: "var(--pure-white)",
                    borderRadius: '8px',
                    border: `1px solid ${backgroundColor[1]}`,
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 0,
                        outline: 'none',
                    },
                }}
            >
                {disabledText && <MenuItem disabled value=''>{disabledText}</MenuItem>}
                {options.length
                    ? options.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.key}
                        </MenuItem>
                    ))
                    : []}
            </Select>
        </div>
    )
}

export default SelectDropDown;