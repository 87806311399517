import React from 'react'
import { mobileStyle } from '../../pages/components/Home'

const BGLargeLogo2 = ({color=mobileStyle.color[0], width='429', height='242'}) => {
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 429 242" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.1637 194.603H31.8691H31.0193L24.9004 211.761H28.5547L29.8295 207.938H36.2883L37.563 211.761H41.3873L35.2685 194.603H34.1637ZM30.6793 205.135L32.9739 198.17L35.2685 205.135H30.6793Z" fill={color} />
                <path d="M54.7296 207.429L48.2708 194.603H43.5117V211.761H47.0811V198.935L53.5398 211.761H58.299V194.603H54.7296V207.429Z" fill={color} />
                <path d="M79.7136 195.452C78.4388 194.687 76.9091 194.348 75.1245 194.348C73.4248 194.348 71.8951 194.687 70.6203 195.452C69.2606 196.216 68.2407 197.236 67.4759 198.51C66.711 199.784 66.3711 201.313 66.3711 203.097C66.3711 204.371 66.626 205.645 67.051 206.664C67.4759 207.769 68.0708 208.703 68.9206 209.467C69.6855 210.232 70.6203 210.826 71.7251 211.251C72.8299 211.676 73.9347 211.931 75.2094 211.931C76.4842 211.931 77.674 211.676 78.6938 211.251C79.7986 210.826 80.7334 210.232 81.4983 209.467C82.2631 208.703 82.858 207.769 83.3679 206.664C83.7928 205.56 84.0478 204.371 84.0478 203.097C84.0478 201.313 83.7079 199.784 82.943 198.51C82.1781 197.236 80.9884 196.216 79.7136 195.452ZM79.5436 206.24C79.1187 207.174 78.5238 207.853 77.759 208.363C76.9941 208.873 76.1443 209.128 75.1245 209.128C74.1047 209.128 73.2548 208.873 72.49 208.363C71.7251 207.853 71.1302 207.174 70.7053 206.24C70.2804 205.305 70.0254 204.286 70.0254 203.097C70.0254 201.908 70.1954 200.888 70.6203 200.039C71.0452 199.189 71.6401 198.51 72.405 198C73.1698 197.491 74.0197 197.321 75.0395 197.321C76.0593 197.321 76.9091 197.575 77.674 198C78.4388 198.51 79.0337 199.189 79.4587 200.039C79.8836 200.888 80.1385 201.908 80.1385 203.097C80.2235 204.286 79.9686 205.305 79.5436 206.24Z" fill={color} />
                <path d="M97.5606 204.796C97.5606 206.24 97.2207 207.344 96.6258 208.023C95.9459 208.703 95.0961 209.043 93.9063 209.043C92.8015 209.043 91.8667 208.703 91.1868 208.023C90.5069 207.344 90.252 206.24 90.252 204.711V194.603H86.5977V204.116C86.5977 206.664 87.1925 208.618 88.3823 209.977C89.5721 211.336 91.4418 212.016 93.9063 212.016C96.3708 212.016 98.2405 211.336 99.4303 209.977C100.62 208.618 101.3 206.664 101.3 204.201V194.687H97.6456V204.796H97.5606Z" fill={color} />
                <path d="M103.51 197.406H109.034V211.761H112.773V197.406H118.297V194.603H103.51V197.406Z" fill={color} />
                <path d="M124.33 194.603H120.676V211.761H122.375H124.33H132.574V208.958H124.33V194.603Z" fill={color} />
                <path d="M138.693 204.371H146.937V201.653H138.693V197.406H147.532V194.603H138.693H136.739H135.039V211.761H136.739H138.693H147.787V208.958H138.693V204.371Z" fill={color} />
                <path d="M149.826 197.406H155.435V211.761H159.089V197.406H164.698V194.603H149.826V197.406Z" fill={color} />
                <path d="M174.386 194.603H172.602V211.761H176.341V205.135H184.159V202.332H176.341V197.406H184.754V194.603H176.341H174.386Z" fill={color} />
                <path d="M199.968 195.452C198.693 194.687 197.163 194.348 195.378 194.348C193.679 194.348 192.149 194.687 190.874 195.452C189.514 196.216 188.495 197.236 187.73 198.51C186.965 199.784 186.625 201.313 186.625 203.097C186.625 204.371 186.88 205.645 187.305 206.664C187.73 207.769 188.325 208.703 189.175 209.467C189.939 210.232 190.874 210.826 191.979 211.251C193.084 211.676 194.189 211.931 195.463 211.931C196.738 211.931 197.928 211.676 198.948 211.251C200.052 210.826 200.987 210.232 201.752 209.467C202.517 208.703 203.112 207.769 203.622 206.664C204.047 205.56 204.302 204.371 204.302 203.097C204.302 201.313 203.962 199.784 203.197 198.51C202.432 197.236 201.242 196.216 199.968 195.452ZM199.798 206.24C199.373 207.174 198.778 207.853 198.013 208.363C197.248 208.873 196.398 209.128 195.378 209.128C194.359 209.128 193.509 208.873 192.744 208.363C191.979 207.853 191.384 207.174 190.959 206.24C190.534 205.305 190.279 204.286 190.279 203.097C190.279 201.908 190.449 200.888 190.874 200.039C191.299 199.189 191.894 198.51 192.659 198C193.424 197.491 194.274 197.321 195.293 197.321C196.313 197.321 197.163 197.575 197.928 198C198.693 198.51 199.288 199.189 199.713 200.039C200.137 200.888 200.392 201.908 200.392 203.097C200.477 204.286 200.222 205.305 199.798 206.24Z" fill={color} />
                <path d="M217.897 204.116C218.832 203.691 219.512 203.097 220.022 202.332C220.532 201.568 220.787 200.718 220.787 199.699C220.787 198.68 220.532 197.83 220.022 197.066C219.512 196.301 218.832 195.707 217.897 195.282C216.963 194.857 215.858 194.688 214.668 194.688H207.02V211.846H210.674V204.711H211.269L216.708 211.761H221.637L215.348 204.711C216.283 204.626 217.133 204.456 217.897 204.116ZM214.583 197.406C215.433 197.406 216.113 197.576 216.623 198C217.133 198.425 217.303 198.935 217.303 199.699C217.303 200.464 217.048 200.973 216.623 201.398C216.113 201.823 215.518 201.993 214.583 201.993H210.674V197.406H214.583Z" fill={color} />
                <path d="M237.785 205.22H241.609V208.023C241.269 208.363 240.844 208.618 240.249 208.788C239.569 209.043 238.805 209.128 237.785 209.128C236.595 209.128 235.66 208.873 234.81 208.448C234.045 207.938 233.366 207.259 233.026 206.409C232.601 205.475 232.431 204.456 232.431 203.182C232.431 201.992 232.601 200.888 233.026 200.039C233.451 199.189 234.045 198.51 234.725 198C235.405 197.491 236.34 197.321 237.36 197.321C238.465 197.321 239.314 197.575 240.079 198.085C240.759 198.595 241.269 199.359 241.439 200.294H245.093C245.008 199.104 244.583 198.085 243.904 197.236C243.224 196.386 242.289 195.622 241.184 195.197C240.079 194.687 238.805 194.433 237.36 194.433C235.66 194.433 234.13 194.772 232.856 195.537C231.581 196.301 230.561 197.321 229.796 198.68C229.031 200.039 228.691 201.568 228.691 203.267C228.691 205.05 229.031 206.579 229.796 207.853C230.476 209.128 231.581 210.232 232.856 210.911C234.215 211.676 235.745 212.016 237.615 212.016C239.314 212.016 240.759 211.761 242.034 211.251C243.224 210.741 244.328 210.062 245.263 209.128V202.332H237.785V205.22Z" fill={color} />
                <path d="M261.071 195.452C259.796 194.687 258.267 194.348 256.482 194.348C254.782 194.348 253.252 194.687 251.978 195.452C250.618 196.216 249.598 197.236 248.833 198.51C248.068 199.784 247.729 201.313 247.729 203.097C247.729 204.371 247.983 205.645 248.408 206.664C248.833 207.769 249.428 208.703 250.278 209.467C251.043 210.232 251.978 210.826 253.083 211.251C254.187 211.676 255.292 211.931 256.567 211.931C257.842 211.931 259.031 211.676 260.051 211.251C261.156 210.826 262.091 210.232 262.856 209.467C263.621 208.703 264.215 207.769 264.725 206.664C265.15 205.56 265.405 204.371 265.405 203.097C265.405 201.313 265.065 199.784 264.3 198.51C263.536 197.236 262.346 196.216 261.071 195.452ZM260.901 206.24C260.476 207.174 259.881 207.853 259.116 208.363C258.352 208.873 257.502 209.128 256.482 209.128C255.462 209.128 254.612 208.873 253.847 208.363C253.083 207.853 252.488 207.174 252.063 206.24C251.638 205.305 251.383 204.286 251.383 203.097C251.383 201.908 251.553 200.888 251.978 200.039C252.403 199.189 252.998 198.51 253.762 198C254.527 197.491 255.377 197.321 256.397 197.321C257.417 197.321 258.267 197.575 259.031 198C259.796 198.51 260.391 199.189 260.816 200.039C261.241 200.888 261.496 201.908 261.496 203.097C261.581 204.286 261.326 205.305 260.901 206.24Z" fill={color} />
                <path d="M280.786 195.452C279.511 194.687 277.981 194.348 276.197 194.348C274.497 194.348 272.967 194.687 271.693 195.452C270.333 196.216 269.313 197.236 268.548 198.51C267.783 199.784 267.443 201.313 267.443 203.097C267.443 204.371 267.698 205.645 268.123 206.664C268.548 207.769 269.143 208.703 269.993 209.467C270.758 210.232 271.693 210.826 272.797 211.251C273.902 211.676 275.007 211.931 276.282 211.931C277.556 211.931 278.746 211.676 279.766 211.251C280.871 210.826 281.806 210.232 282.571 209.467C283.335 208.703 283.93 207.769 284.44 206.664C284.865 205.56 285.12 204.371 285.12 203.097C285.12 201.313 284.78 199.784 284.015 198.51C283.25 197.236 282.061 196.216 280.786 195.452ZM280.616 206.24C280.191 207.174 279.596 207.853 278.831 208.363C278.066 208.873 277.217 209.128 276.197 209.128C275.177 209.128 274.327 208.873 273.562 208.363C272.797 207.853 272.202 207.174 271.778 206.24C271.353 205.305 271.098 204.286 271.098 203.097C271.098 201.908 271.268 200.888 271.693 200.039C272.117 199.189 272.712 198.51 273.477 198C274.242 197.491 275.092 197.321 276.112 197.321C277.132 197.321 277.981 197.575 278.746 198C279.511 198.51 280.106 199.189 280.531 200.039C280.956 200.888 281.211 201.908 281.211 203.097C281.296 204.286 281.041 205.305 280.616 206.24Z" fill={color} />
                <path d="M299.398 195.622C297.953 194.942 296.338 194.603 294.384 194.603H291.494H290.134H287.84V211.761H290.049H291.494H294.384C296.253 211.761 297.953 211.421 299.398 210.742C300.842 210.062 301.947 209.043 302.712 207.769C303.477 206.494 303.902 204.965 303.902 203.182C303.902 201.398 303.477 199.954 302.712 198.68C301.947 197.321 300.842 196.386 299.398 195.622ZM299.568 206.07C299.143 206.919 298.463 207.599 297.613 208.108C296.763 208.618 295.658 208.873 294.469 208.873H291.494V197.491H294.384C295.658 197.491 296.678 197.745 297.528 198.17C298.378 198.68 299.058 199.274 299.483 200.124C299.908 200.973 300.163 201.908 300.163 203.012C300.248 204.201 299.993 205.22 299.568 206.07Z" fill={color} />
                <path d="M7.13867 237.498H22.8607V234.525H10.1131V171.753H22.8607V168.78H7.13867V237.498Z" fill={color} />
                <path d="M306.877 171.753H319.625V234.525H306.877V237.498H322.599V168.78H306.877V171.753Z" fill={color} />
                <path d="M51.0738 58.4404C51.0738 48.842 45.8897 42.896 37.1364 41.1972C44.36 40.008 49.629 33.9771 49.629 24.9732C49.629 13.5061 43.6801 5.60645 28.8929 5.60645H6.28711V78.5717H28.9779C44.19 78.6566 51.0738 71.1817 51.0738 58.4404ZM22.859 19.1972H26.6833C32.4622 19.1972 33.907 22.7647 33.907 27.4366C33.907 31.9385 32.5472 35.3362 26.6833 35.3362H22.859V19.1972ZM22.859 64.981V48.1624H27.1082C33.0571 48.1624 34.6718 51.5601 34.6718 56.6566C34.6718 62.008 33.0571 64.981 27.1082 64.981H22.859Z" fill={color} />
                <path d="M77.3346 79.5059C94.4164 79.5059 100.96 67.529 100.96 46.6333V37.6294C100.96 16.6487 94.3314 4.75684 77.3346 4.75684C60.3377 4.75684 53.709 16.6487 53.709 37.6294V46.6333C53.709 67.614 60.3377 79.5059 77.3346 79.5059ZM71.3007 29.39C71.3007 22.9344 72.3205 18.5175 77.3346 18.5175C82.3486 18.5175 83.3685 22.9344 83.3685 29.39V54.8727C83.3685 61.3283 82.3486 65.7453 77.3346 65.7453C72.3205 65.7453 71.3007 61.3283 71.3007 54.8727V29.39Z" fill={color} />
                <path d="M128.155 79.6759C146.087 79.6759 151.61 69.0582 151.61 52.5794V5.60645H134.104V53.9385C134.104 61.3285 133.424 65.6605 128.155 65.6605C122.886 65.6605 122.206 61.4134 122.206 53.9385V5.60645H104.699V52.4945C104.699 68.9732 110.138 79.6759 128.155 79.6759Z" fill={color} />
                <path d="M172.177 40.9424H172.687L189.429 78.6566H202.347V5.60645H186.539V39.2435H186.115L171.922 5.60645H156.455V78.6566H172.177V40.9424Z" fill={color} />
                <path d="M253.423 36.3555C253.423 16.9037 246.029 5.60645 229.627 5.60645H207.361V78.5717H229.627C245.944 78.5717 253.423 67.3593 253.423 47.8227V36.3555ZM236.086 53.8536C236.086 61.0736 233.621 65.2358 227.588 65.2358H224.443V19.0273H227.588C233.621 19.0273 236.086 23.1895 236.086 30.4095V53.8536Z" fill={color} />
                <path d="M294.129 64.5563H274.413V5.60645H257.416V78.6566H294.129V64.5563Z" fill={color} />
                <path d="M298.123 78.6566H335.091V64.5563H314.78V48.5022H329.822V34.5717H314.78V19.6219H335.091V5.60645H298.123V78.6566Z" fill={color} />
                <path d="M365.94 36.4402L359.142 32.7028C355.657 30.834 353.618 28.5406 353.618 24.8032C353.618 20.8958 355.487 18.3476 359.057 18.3476C363.221 18.3476 365.09 21.2356 365.09 27.1815V30.1545H380.048V27.2665C380.048 12.3167 372.399 4.67188 359.057 4.67188C346.564 4.67188 338.065 12.9962 338.065 25.9923C338.065 38.4788 344.779 43.7452 352.598 47.9923L358.717 51.39C363.136 53.8533 365.77 55.4672 365.77 59.6294C365.77 63.7066 363.646 65.9151 359.736 65.9151C355.232 65.9151 352.938 62.3476 352.938 56.9113V54.0232H337.98V56.8263C337.98 72.4557 346.819 79.6757 359.736 79.6757C372.654 79.6757 381.407 72.3707 381.407 58.2703C381.322 46.7182 375.543 41.6217 365.94 36.4402Z" fill={color} />
                <path d="M411.493 36.4402L404.694 32.7028C401.21 30.834 399.17 28.5406 399.17 24.8032C399.17 20.8958 401.04 18.3476 404.609 18.3476C408.774 18.3476 410.643 21.2356 410.643 27.1815V30.1545H425.6V27.2665C425.6 12.3167 417.952 4.67188 404.609 4.67188C392.117 4.67188 383.618 12.9962 383.618 25.9923C383.618 38.4788 390.332 43.7452 398.15 47.9923L404.269 51.39C408.689 53.8533 411.323 55.4672 411.323 59.6294C411.323 63.7066 409.198 65.9151 405.289 65.9151C400.785 65.9151 398.49 62.3476 398.49 56.9113V54.0232H383.533V56.8263C383.533 72.4557 392.372 79.6757 405.289 79.6757C418.207 79.6757 426.96 72.3707 426.96 58.2703C426.875 46.7182 421.096 41.6217 411.493 36.4402Z" fill={color} />
                <path d="M36.7123 153.83L37.987 160.286H49.3749V122.657H27.9589V135.398H33.3129V136.417C33.3129 142.873 32.0381 147.12 27.0241 147.12C22.18 147.12 20.7353 142.958 20.7353 136.417V110.85C20.7353 104.394 21.7551 100.232 26.7691 100.232C31.7832 100.232 32.803 104.394 32.803 110.85V115.691H49.3749V113.823C49.3749 96.7492 43.341 86.3862 26.7691 86.3862C10.1122 86.3862 3.22852 97.8534 3.22852 118.834V128.772C3.22852 149.753 9.34737 161.22 23.8797 161.22C29.6586 161.305 34.9276 158.842 36.7123 153.83Z" fill={color} />
                <path d="M71.3006 87.3203H54.2188V160.286H71.3006V87.3203Z" fill={color} />
                <path d="M100.025 129.537H99.5998L91.3563 87.3203H73.8496L89.9966 160.371H107.928L124.075 87.3203H108.268L100.025 129.537Z" fill={color} />
                <path d="M143.707 87.3203H126.625V160.286H143.707V87.3203Z" fill={color} />
                <path d="M194.612 160.371V87.3203H178.89V120.957H178.38L164.273 87.3203H148.721V160.371H164.528V122.656H164.953L181.78 160.371H194.612Z" fill={color} />
                <path d="M232.091 153.83L233.366 160.286H244.754V122.657H223.338V135.398H228.692V136.417C228.692 142.873 227.417 147.12 222.403 147.12C217.559 147.12 216.114 142.958 216.114 136.417V110.85C216.114 104.394 217.134 100.232 222.148 100.232C227.162 100.232 228.182 104.394 228.182 110.85V115.691H244.754V113.823C244.754 96.7492 238.72 86.3862 222.148 86.3862C205.491 86.3862 198.607 97.8534 198.607 118.834V128.772C198.607 149.753 204.726 161.22 219.259 161.22C225.037 161.305 230.222 158.842 232.091 153.83Z" fill={color} />
            </svg>

        </>
    )
}

export default BGLargeLogo2