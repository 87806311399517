import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../../images/orangeLogo.svg";

function Splace() {
  const navigate = useNavigate();
  // let guest_user_visited = JSON.parse(localStorage.getItem('guest_user_visited'));
  useEffect(() => {
    setTimeout(() => {
      // guest_user_visited ? navigate("/") : navigate("/splace-slider");
      navigate("/")
    }, 3000);
  });

  return (
    <>
      <div className="splace_sec">
        <div className="container">
          <div className="splace_inside">
            {/* <Link to="/splace-slider"> */}
              <img src={Logo} alt="" />
              <div style={{ marginTop: "-10px" }}>
                <span className="loader__dot splace_loader">.</span><span className="loader__dot splace_loader">.</span><span className="loader__dot splace_loader">.</span>
              </div>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Splace;
