import {Helmet} from "react-helmet-async"
import './Support.css';
import SupportPart from "./SupportPart";
import HeaderV2 from "../molecules/Header/HeaderV2";
import { mobileStyle } from "../Home";
import { useMediaQuery } from "react-responsive";
import MobileSubFooter from "../atoms/MobileSubFooter/MobileSubFooter";
import NeedHelp from "../atoms/NeedHelp/NeedHelp";
import NewFooter from "../molecules/NewFooter/NewFooter";
import HeaderV3 from "../molecules/Header/HeaderV3/HeaderV3";

export default function Support() {

      const isDesktop = useMediaQuery({minWidth: 768})
    
    return (
        <section className="customer_support_section" style={{ backgroundColor:mobileStyle.backgroundColor[1]}}>
            <Helmet>
                <title>Support | Boundless Giving</title>
            </Helmet>
                {/* <HeaderV2 isSubHeader={true} subHeaderTitle="Customer Support" containerStyle={{position:'fixed'}} logoType="white2" primaryBackgroundColor="#30313A" primaryColor="#F0E8E3"/> */}
                <HeaderV3 isSubHeader subHeaderTitle="Customer Support" primaryBackgroundColor="#30313A" primaryColor="#F0E8E3" position="absolute" />
            <div style={{ }} className="customer_support margin_class">
               
                    <div style={{display:'flex', flexDirection:"column", gap:'35px'}} >
                    <h5 style={{fontSize:"20px",lineHeight:"25px",fontWeight:"700",color:"#F0E8E3"}}>Need Customer Support?</h5>
                    <SupportPart/>
                    </div>
                    <NeedHelp primaryColor="#F0E8E3" style={{marginTop: "140px"}}/> 
           
            </div>
            <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

        </section>
    )
}

