import React, { useEffect,useContext } from 'react';
import { useLocation, useNavigate,useParams, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux'
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";
import './event.css';

// import BrowseItemUpperHalf from '../../molecules/BrowseItemUpperHalf/BrowsItemUpperHalf';
// import GlobalHeader from '../../molecules/GlobalHeader';
import {FilterContext} from "../../../../store/filter-context";
import {changePageNumber, resetItems} from '../../../../redux/itemsReducer'
import {Mobile, Desktop} from "../../responsive/Responsive"
// import Navbar from "../../../desktop/components/Navbar/Navbar"
// import SubNavbar from "../../../desktop/components/SubNavbar/SubNavbar"
// import Header from "../../../desktop/components/Header/Header"
// import Footer from "../../../desktop/components/Footer/Footer"
import auctionServices from '../../../../services/auction-services';
import { useState } from 'react';
// import BrowseByCards from '../../../desktop/components/BrowseByCards/BrowseByCards';
// import MobileFooter from "../../Footer"
// import BackContainer from '../../../desktop/components/BackContainer/BackContainer';
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import HeaderV2 from '../../molecules/Header/HeaderV2';
import { mobileStyle } from '../../Home';
import { useRef } from 'react';
import BrowseItems from '../BrowseItems/BrowseItems';
import NewButton from '../../atoms/NewButton/NewButton';
// import SubHeader from '../../molecules/SubHeader/SubHeader';
import LoadingScreen from '../../LoadingScreen/Loading';
// import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import EventBrowseItemsContent from '../../molecules/EventBrowseItemsContent/EventBrowseItemsContent';
import NewFooter from '../../molecules/NewFooter/NewFooter';
import AdminPreviewBanner from '../../atoms/AdminPreviewBanner/adminPreviewBanner';
import ESMBannerPart from '../../molecules/ESMBannerPart/ESMBannerPart';
import { getImageVariant, getImageWithoutVariant } from '../../../../utils/util-functions';
import EventCategoryCard from '../../molecules/EventCategoryCard/EventCategoryCard';
// import NewFooter from '../../molecules/NewFooter/NewFooter';
import { config } from '../../../../config/config';
// import RoundedContainer from '../../atoms/RoundedContainer/RoundedContainer';
// import TagHeading from '../../atoms/TagHeading/TagHeading';
import EventPageDetailsCard from '../../molecules/EventPageDetailsCard/EventPageDetailsCard';
import HeaderV3 from '../../molecules/Header/HeaderV3/HeaderV3';
import { clearAllBrowseBySelectedFilters } from '../../../../redux/browseItemsReducer';
import CustomCategorySection from '../../molecules/CategorySectionInEvent/CustomCategorySection';
const {REACT_APP_URL} = config

const primaryBackgroundColor = mobileStyle.backgroundColor[2];
const primaryColor = mobileStyle.color[1];
const redColor = mobileStyle.backgroundColor[0];

function Event() {
  const navigate = useNavigate()
  const {auctionId}=useParams()
  const [searchParams,setSearchParams] = useSearchParams();
  const [params,setParams]=useState({ auction:"", image:"", from:'',market_place: false,type:'',showIncomplete:false }); // type is to determine which type of event is this(marketplace,store aka charity store,event)
  const paramsData = useLocation().state;
  const search = useLocation().search;
  const admin_preview = new URLSearchParams(search).get('admin_preview');
  const [isLoading,setIsloading]=useState(false)
  const [customHeadingCategory,setCustomHeadingCategory]=useState([])
  const [category,setCategory]=useState([])
  const {reset} = useContext(FilterContext)
  const dispatch=useDispatch()
  const isDesktop = useMediaQuery({minWidth: 768})
  const mainref = useRef()
  const [oldEventImage, setOldEventImage] = useState(false);

  // const customCategoryFallbackImage='https://bg-assets-bucket.s3.amazonaws.com/Custom_Categories/fallback.png';
  // const auctionFallBackImage='https://bg-assets-bucket.s3.amazonaws.com/Event_9/auction_banner.png';
  // const buyNowFallBackImage='https://bg-assets-bucket.s3.amazonaws.com/Event_9/buynow_banner.png';
  
  let browseByButtonStyle = {}

  if(isDesktop) {
    browseByButtonStyle = {
      paddingInline: '40px',
      paddingBlock: '24px',
      border: `1px solid ${redColor}`,
      color: redColor,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      backgroundColor:primaryBackgroundColor,
      minWidth:"fit-content"
    }
  }
  else {
    browseByButtonStyle = {
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '12px',
      paddingBlock:'13px',
      border:'0px !important',
      minWidth:"fit-content"
    }
  }

  const fetchAuctionById = async(auctionId)=>{
    try{
      setIsloading(true)
      let showIncomplete = false;
      if(admin_preview === 'true' || paramsData?.admin_preview){
        showIncomplete = true
      }
      const data = await auctionServices.getAuctionById(auctionId,showIncomplete)  
      if(data.msg == 'Event coming soon'){
        navigate('/coming-soon',{state:{image1:data?.image1,image2:data?.image2,title:data?.name,start_date:data?.start_date}}, {replace: true})
        return
      } 
      // if(data?.data.market_place === "1" && !isDesktop){
      //   const donorImage = data.data?.image1
      //   const market_place = (data.data?.market_place === "1") ? true : false
      //   const title = data.data?.title
      //   const about = data.data?.about
      //   navigate("/browse-items", {
      //       state:
      //           { from: "donor", donor:data.data?.donor , donorImage,market_place,title,about}
      //   })
      // } 
      if(data?.data?.is_store === 0 && data?.data?.market_place === "0" && ( data?.data?.has_auction === "0" || data?.data?.has_buy_now === "0" ) ) setSearchParams('item-type=event')  
      setParams({auction:data?.data,image:data?.data?.image2,from:paramsData?.from ? paramsData?.from : 'event',market_place:(data?.data.market_place === "1" || paramsData?.market_place === true),type:data?.data.market_place === "1"?'Marketplace':data?.data.is_store?'Store':'Event',showIncomplete:showIncomplete})
    }catch(error){
      setIsloading(false)
      navigate('/not-found', {replace: true})
    }
  }



  useEffect(()=>{
    setIsloading(true)
    fetchAuctionById(auctionId)
  },[auctionId])

  useEffect(()=> {
    params?.auction.id && fetchCustomHeadingCategory(params?.auction.id)
    fetchCategory()
    params?.auction.id && setIsloading(false)
  },[params])

  useEffect(()=>{
    if(isDesktop && mainref.current) {
      mainref.current.scrollIntoView(true)
    }
  }, [mainref.current])

  useEffect(() => {
    // To support old images whcih does not have variant(small,medium,large).
    const img = new Image();
    if(!params?.auction?.image2) return
    img.src = params?.auction?.image2 + "_small";
    img.onerror = () => {
        setOldEventImage(true);
    };
    return () => { img.onerror = null }
}, [params?.auction?.image2])

  const fetchCustomHeadingCategory=async(auctionId)=>{
    setIsloading(true)
    // let data=await auctionServices.getCustomCategoryHeadingByAuctionId(auctionId)
    try {
      let data=await auctionServices.getCustomCategoryHeadingByAuctionIdHaveItem(auctionId)
      setCustomHeadingCategory(data.data)
    }catch(error) {
      setIsloading(false)
    }
  }
  const fetchCategory=async()=>{
    setIsloading(true)
    // let data=await auctionServices.getAuctionCategoryList()
    try {
      const response = await auctionServices.getAuctionCategoryListHaveItem(auctionId)
      setCategory(response.data)
    }
    catch(error) {
      setIsloading(false)
    }
  }
  
  useEffect(() => {
    reset()
    dispatch(changePageNumber(1)) /// for changing page number of browseItems page
    dispatch(resetItems())
    dispatch((clearAllBrowseBySelectedFilters()))
    localStorage.removeItem("browseScroll")
  }, [])

  const handleMoveToTop= () => {
    window.scrollTo({top: 0,behavior: 'smooth'});
  }

  return (
    <div style={{backgroundColor:primaryBackgroundColor}}>
      <Helmet prioritizeSeoTags>
        <title>Browse Event | Boundless Giving</title>
        <link rel="canonical" href={`${REACT_APP_URL}/event/${auctionId}`} />
      </Helmet>

        {/* <HeaderV2 logoType='black'  primaryBackgroundColor={ primaryBackgroundColor }  primaryColor={mobileStyle.color[3]} containerStyle={{ position:'fixed'}} transparentNav={ true } /> */}
      <HeaderV3/>
      {
          isLoading && <LoadingScreen bgColor='#F0E8E3'/>
       }
      {/* <div className='browse-page-containers' style={{padding:(params.market_place && !isDesktop) ? "0":"5vw 5vw", backgroundColor:(!params.market_place && !isDesktop) ? primaryBackgroundColor : ''}}> */}
      <div style={{ backgroundColor: primaryBackgroundColor}}>
        
       {!isLoading  && 
      //  <BrowseItemUpperHalf params={ ( paramsData?.from === 'donor') ? paramsData : params} />
       Object.keys(params?.auction).length ? 
       <ESMBannerPart
          eventData={params?.auction}
          image={oldEventImage ? getImageWithoutVariant(isDesktop ? params?.auction?.image2 : params?.auction?.image1) : getImageVariant(isDesktop ? params?.auction?.image2 : params?.auction?.image1)}
          itemType={'event'}
          showIncomplete={params?.showIncomplete}
          showDetailsOverBanner={params?.auction?.show_details_over_banner === 1}
      />
      : null
       }
       {/* <Mobile> */}
        { (params?.type ==='Event') ?<>
          {/* Browse Items Up For Auction */}
        {!isLoading  && <section className='event-page-lower-part'>
        {/* { params?.auction?.has_auction === '1' && <div >
          <div className="page_heading">
            <h3>
              Browse Items Up For Auction
            </h3>
          </div>
          <div
            className="buy_now_div">
            <img className='image'
              onClick={() =>
                navigate("/browse-items", {
                  state: {...params,from:'event-auction'},
                })
              }
              src={params.auction.auction_image_key?params.auction.auction_image_key:auctionFallBackImage} alt="" />
          </div>
        </div>} */}
        

        {/* Browse Items You Can Buy Now */}
        {/* { params?.auction?.has_buy_now === '1' &&  <div >
          <div className="page_heading">
            <h3>
            Browse Our Shop
            </h3>
          </div>
          <div
            className="buy_now_div">
            <img className='image'
              onClick={() =>
                navigate("/browse-items", {
                  state: { ...params, from: 'event-byNow' },
                })
              }
              src={params.auction.buy_now_image_key?params.auction.buy_now_image_key:buyNowFallBackImage} alt="" />
          </div>
        </div>} */}

        {
          params?.auction?.show_details_over_banner === 0 &&
          <EventPageDetailsCard eventData={params.auction} showIncomplete={admin_preview == 'true'}/>
        }


        <Mobile>
        {/* //// browse items buttons for mobile */}
        { params?.auction?.has_auction === '1' || params?.auction?.has_buy_now === '1' ?
           <section style={{ backgroundColor:primaryBackgroundColor}}>
            <div className="mobile_browse_items_container">
            <h5 style={{color:primaryColor,letterSpacing:"0",fontWeight:"700",fontSize:"22px"}}>Browse This Event</h5>
            <div className="buttons_container">
            { params?.auction?.has_auction === '1' &&
            <NewButton
               onClick={() =>
                // navigate("/browse-items", {
                //   state: {...params,from:'event-auction'},
                // })
                navigate(`/event/${auctionId}/browse-items?item-type=event-auction${admin_preview ? `&showIncomplete=${true}` : '' }`)
              }
              text={params?.auction?.auction_btn_name} backgroundColor={primaryBackgroundColor} color={redColor} style={{width:"100%", textTransform:'uppercase'}} />}
              {/* <NewButton text={`${params.auction?.title} SHOP`} backgroundColor={primaryBackgroundColor} color={redColor} style={{width:"100%", textTransform:'uppercase', letterSpacing:'1.12px'}} /> */}
            { params?.auction?.has_buy_now === '1' &&
              <NewButton
               onClick={() =>
                // navigate("/browse-items", {
                //   state: { ...params, from: 'event-byNow' },
                // })
                navigate(`/event/${auctionId}/browse-items?item-type=event-byNow${admin_preview ? `&showIncomplete=${true}` : '' }`)
              }
              text={params?.auction?.buy_now_btn_name} backgroundColor={primaryBackgroundColor} color={redColor} style={{width:"100%", textTransform:'uppercase', letterSpacing:'1.12px'}} /> }
            </div>
            </div>
           </section>
        : ""}
       </Mobile>

       {/* Browse items buttons for deskop */}
 
  { (params?.auction?.has_auction === '1' || params?.auction?.has_buy_now === '1') &&
   <Desktop>
       <div style={{backgroundColor:mobileStyle.backgroundColor[0]}} className="desktop_browse_section_main_container">
           <div className="desktop_browse_buttons_wrapper margin_class">
             { params?.auction?.has_buy_now === '1' &&
              <>
              <EventBrowseItemsContent 
                 tagText='Browse'
                 headingText={(params?.auction?.buy_now_label == "")?'Our Shop':params?.auction?.buy_now_label}
                 buttonText={(params?.auction?.buy_now_btn_name == "") ? 'Shop Now':params?.auction?.buy_now_btn_name}
                 onClick={() =>
                  // navigate("/browse-items", {
                  //   state: { ...params, from: 'event-byNow' },
                  // })
                  navigate(`/event/${auctionId}/browse-items?item-type=event-byNow${admin_preview ? `&showIncomplete=${true}` : '' }`)
                }
                roundedContainerStyle={{
                  borderRadius:"25px"
                }}
               />
               </>
            }
            { params?.auction?.has_auction === '1' &&
                <>
               <EventBrowseItemsContent 
                 tagText='Browse'
                 headingText={(params?.auction?.auction_label == "")?'Items up for Auction':params?.auction?.auction_label}
                 buttonText={(params?.auction?.auction_btn_name == "")?'Explore the Auction':params?.auction?.auction_btn_name}
                 onClick={() =>
                  // navigate("/browse-items", {
                  //   state: {...params,from:'event-auction'},
                  // })
                  navigate(`/event/${auctionId}/browse-items?item-type=event-auction${admin_preview ? `&showIncomplete=${true}` : '' }`)
                }
                roundedContainerStyle={{
                  borderRadius:"25px"
                }}
               />
               </>
            }
           </div>
       </div>
    </Desktop>}

        {/*  Browse By custom Category heading*/}

       {/* {customHeadingCategory?.map((heading)=><div key={heading.id}>
          <div className="page_heading">
            <h3>
              Browse By {heading?.name}
            </h3>
          </div>
          <div className='image-container'>
            {heading?.auction_custom_categories?.map((category, i) =><div key={category.id}  className='sub-category'>
              <img className='sub-category-images'
              onClick={() =>
                navigate("/browse-items", {
                  state: { ...params, from: 'event-customCategory',customCategoryId:category.id},
                })
              }
              src={category.image_key?category.image_key:customCategoryFallbackImage} alt="" />
              <h6>{category?.category_name}</h6>
            </div>)}
          </div>
        </div>)} */}

        {/*  Browse By Category*/}
        {/* { category && category.length > 0 &&<div>
           <div className="page_heading">
            <h3>
              Browse By Category
            </h3>
          </div>
          <div className='image-container'>
            {category?.map((category, i) =><div key={category.auction_item_category_id}  className='sub-category'>
              <img className='sub-category-images'
              onClick={() =>
                navigate("/browse-items", {
                  state: { ...params, from: 'event-category',categoryId:category.auction_item_category_id},
                })
              }
              src={require(`../../../../images/categoryIcons/100x100/${category.auction_item_category.image}`)} alt="" />
              <h6>{category?.auction_item_category.name}</h6>
            </div>)}
          </div>
        </div>} */}


        {/* section for browse by custom category and  category */}
        {/* <Mobile> */}
        <div style={{backgroundColor:"rgb(240, 232, 227)"}} className="browse_category_main_container">
          {/* browse by custom category */}
          <div className='browse_category_section margin_class' >
          <CustomCategorySection 
                  admin_preview={admin_preview}
                  auctionId={auctionId}
                  browseByButtonStyle={browseByButtonStyle}
                  customHeadingCategory={customHeadingCategory}
                  primaryBackgroundColor={primaryBackgroundColor}
                  redColor={redColor}
                 />
         {/*  Browse By Category*/}
        { category && category.length > 0 &&
        <div className='custom_category_container'>
          {
            category?.length > 1 ? <h5 className='custom_category_heading' >Category </h5> : null
          }
          <div className='image-container'>
            <div style={{display:"flex",gap:'10px'}} className='sub-category'>
            {category?.map((category, i) =>
               <NewButton
               key={i}
               onClick={() =>
                // navigate("/browse-items", {
                //   state: { ...params, from: 'event-category',categoryId:category?.category_id,title:category?.category?.name,image:require(`../../../../images/categoryIcons/1200x480/${category?.category?.image}`)},
                // })
                navigate(`/event/${auctionId}/browse-items?item-type=event-category&category=${category?.category_id}${admin_preview ? `&showIncomplete=${true}` : '' }`)
              }
              style={browseByButtonStyle}
               text={category?.category?.name} backgroundColor={redColor} color={primaryBackgroundColor}
              />
              )}
              </div>
          </div>
        </div>}
        </div>
        </div>
        {/* </Mobile> */}
        </section>
        }
        {/* {!isLoading && <section style={{backgroundColor:"rgb(240, 232, 227)"}} className='to_top_btn_container'>
                <button onClick={handleMoveToTop} style={{borderBottom:`1px solid ${primaryColor}`}} className='to_top_btn'>Return to Top</button>
        </section>} */}
        </> : 
        
        <BrowseItems market_place={params.market_place || paramsData?.market_place || params.type === 'Store'} donor={params.auction.donor} auction={params.auction} eventParams={params} />
        }
        {/* </Mobile> */}

        {/* { !params.market_place && <Desktop>
        {!isLoading && <div ref={mainref} className='dbuy-lower-items-container'>
         { params?.auction?.has_auction === '1' && <div onClick={() =>
              navigate("/browse-items", {
                state: {...params,from:'event-auction'},
              })
            } style={{cursor: "pointer"}}>
            <div
            className="buy_now_div">
            <img className='image'
              src={params.auction.auction_image_key?params.auction.auction_image_key:auctionFallBackImage} alt="" />
          </div>
            <div className="page_heading">
              <h3>
                Browse Items Up For Auction
              </h3>
            </div>
          </div>}

          { params?.auction?.has_buy_now === '1' && <div onClick={() =>
              navigate("/browse-items", {
                state: { ...params, from: 'event-byNow' },
              })
            } style={{cursor: "pointer"}}>
          <div
            className="buy_now_div">
            <img className='image'
              src={params.auction.buy_now_image_key?params.auction.buy_now_image_key:buyNowFallBackImage} alt="" />
          </div>
            <div className="page_heading">
              <h3>
              Browse Our Shop
              </h3>
            </div>
          </div>}
          </div>}
          {!isLoading && customHeadingCategory?.map((heading)=>
          <div key={heading.id} style={{marginTop: "40px"}}>
              <h3 style={{marginBottom: "25px", fontWeight: 600}}>
                    Browse By {heading?.name}
              </h3>
          <div className='deventbrowse_cards'>
            {heading?.auction_custom_categories?.map((category, i) =>
            <BrowseByCards onClick={() => {
              navigate("/browse-items", {
                state: { ...params, from: 'event-customCategory',customCategoryId:category.id, customCategoryImage:category.image_key?category.image_key:customCategoryFallbackImage, title:category?.category_name},
              }) 
            }} key={category.id} name={category.category_name} image={category.image_key?category.image_key:customCategoryFallbackImage} id={category.id} imageFolder="categoryIcons" useFolder={false} />
            )}
          </div>
        </div>)}
        {category && category.length > 0 && 
        <>
        {!isLoading && <div style={{marginTop: "40px"}}>
           <h3 style={{marginBottom: "25px", fontWeight: 600}}>Browse By Category</h3>
            <div className="deventbrowse_cards">
                {category?.map((i, idx) => (
                    <BrowseByCards onClick={() => {
                      navigate("/browse-items", {
                        state: { ...params, from: 'event-category', categoryId:i.auction_item_category_id}
                      })
                    }} key={i.auction_item_category_id} name={i.auction_item_category.name} image={i.auction_item_category.image} id={i.auction_item_category_id} imageFolder="categoryIcons" />
                  ))}
            </div>
          </div>}
          </>}
        </Desktop>} */}
          { (params?.showIncomplete && params.type === 'Event' )&&    <AdminPreviewBanner type={params?.type} title={params?.auction?.title}/>}
        {(params.type === 'Event') && <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />
 }        
      </div> 
    
      {/* <Desktop>
       {!isLoading && <Footer style={{marginTop: "100px"}} />} 
      </Desktop> */}
      {/* <Mobile>
        <MobileFooter />
      </Mobile> */}
    </div>
  )
}

export default Event
