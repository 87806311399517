import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contextFrom: null,
    pageNumber: 1,
    selectedCategory:null,
    customCategoryHeading:'All',
    customCategoryHeadingId:null,
    categoryName:'',
    categoryType: '',
    searchText:'',
    sliceIndex: 4,
    showSearchBar:false
}

const itemsSlice = createSlice({
    name: "items",
    initialState: initialState,
    reducers: {
        resetItems: (state, action) => {
            return initialState
        },
        changePageNumber: (state, action) => {
            state.pageNumber = action.payload
        },
        clearPageNumber: (state, action) => {
            state.pageNumber = 1
        },
        setContextFrom: (state, action) => {
            state.contextFrom = action.payload
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload.id
            state.categoryName = action.payload.name
            state.categoryType = action.payload.categoryType
            if(action.payload.customCategoryHeading) {
                state.customCategoryHeading = action.payload.customCategoryHeading
            }
            if(action.payload.customCategoryHeadingId) {
                state.customCategoryHeadingId = action.payload.customCategoryHeadingId
            }
        },
        clearCategoryDetails: (state, action) => {
            state.categoryName = ''
            state.categoryType = ''
            state.selectedCategory = null
            state.customCategoryHeading = ''
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload
        },
        clearSearchText: (state, action) => {
            state.searchText = ''
        },
        clearAttribute: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
    }
})

export default itemsSlice.reducer
export const {changePageNumber, resetItems, clearPageNumber, setContextFrom, setSelectedCategory, clearCategoryDetails, setSearchText, clearSearchText, clearAttribute} = itemsSlice.actions