import { setSortValue } from '../../../../redux/shopNowFilterReducer';
import NewButton from '../../atoms/NewButton/NewButton';
import classes from './ShopNowSortScreen.module.css'
import { CloseRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';

const ShopNowSortScreen = ({ handleShowSort = () => null }) => {
    const dispatch = useDispatch()
    const { selectedSortValue } = useSelector(state => state.shopNow);
    const onChange = (event) => {
        dispatch(setSortValue({sortValue:event.target.value}))
    }
    return (
        <div className={classes.sortScreen} >
            <div className={classes.sortScreenHeader} >
                <h2 className={`newTitleClass ${classes.filterHeading}`} >SORT</h2>
                <CloseRounded className={classes.clearSingleIcon} onClick={handleShowSort} />
            </div>
            <fieldset id="sort-group" className={classes.sortContainer} >
                <div className={classes.sortSection} >
                    <div className={classes.sortGroup} >
                        <span className={classes.sortName} >FEATURED</span>
                        <label className={classes.squareRadio} >
                            <input checked={selectedSortValue === 'relevance'} onChange={onChange} value="relevance" name="sort-group" type="radio" className={classes.sortRadio} />
                            <span className={classes.customRadio}></span>
                        </label>
                    </div>
                </div>
                <hr />
                <div className={classes.sortSection} >
                    <p className={classes.groupName}>PRICE</p>
                    <div className={classes.sortGroup} >
                        <span className={classes.sortName} >lowest first</span>
                        <label className={classes.squareRadio} >
                            <input checked={selectedSortValue === 'lowestPrice'} onChange={onChange} value="lowestPrice" name="sort-group" type="radio" className={classes.sortRadio} />
                            <span className={classes.customRadio}></span>
                        </label>
                    </div>
                    <div className={classes.sortGroup} >
                        <span className={classes.sortName} >highest first</span>
                        <label className={classes.squareRadio} >
                            <input checked={selectedSortValue === 'highestPrice'} onChange={onChange} value="highestPrice" name="sort-group" type="radio" className={classes.sortRadio} />
                            <span className={classes.customRadio}></span>
                        </label>
                    </div>
                </div>
                <hr />
                <div className={classes.sortSection} >
                    <p className={classes.groupName}>Date listed</p>
                    <div className={classes.sortGroup} >
                        <span className={classes.sortName} >newest first</span>
                        <label className={classes.squareRadio} >
                            <input checked={selectedSortValue === 'newestFirst'} onChange={onChange} value="newestFirst" name="sort-group" type="radio" className={classes.sortRadio} />
                            <span className={classes.customRadio}></span>
                        </label>
                    </div>
                    <div className={classes.sortGroup} >
                        <span className={classes.sortName} >oldest first</span>
                        <label className={classes.squareRadio} >
                            <input checked={selectedSortValue === 'oldestFirst'} onChange={onChange} value="oldestFirst" name="sort-group" type="radio" className={classes.sortRadio} />
                            <span className={classes.customRadio}></span>
                        </label>
                    </div>
                </div>
            </fieldset>
            <NewButton text="VIEW PRODUCTS" onClick={handleShowSort} className="sortScreenSubmit" />
        </div>
    )
}

export default ShopNowSortScreen