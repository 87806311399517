import React, { useEffect, useState,memo } from 'react'
import styles from './HeaderV2.module.css'
import MenuIcon from '@mui/icons-material/Menu';
// import bgBlackLogo from '../../../../images/newHomeImages/bgLogo.png'
// import bgWhiteLogo from '../../../../images/newHomeImages/whiteLogo.svg'
// import bgOrangeLogo from "../../../../images/orangeLogo.svg"
// import bgBlackLogo2 from '../../../../images/newHomeImages/blackLogo2.svg'
// import bgWhiteLogo2 from "../../../../images/bgWhiteLogo2.svg"
import { AnimatePresence, motion } from 'framer-motion';
import classes from "../../../desktop/components/SubNavbar/SubNavbar.module.css"
import CartIcon from "../CartIcon/CartIcon";
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { clearPageNumber } from '../../../../redux/itemsReducer';
import { useDispatch } from 'react-redux';
import { Desktop, Mobile } from '../../responsive/Responsive';
import { mobileStyle } from '../../Home';
import BGLogoComponent from '../../../../images/BGLogoComponent/BGLogoComponent';
import { useMediaQuery } from 'react-responsive';
import SubHeader from '../SubHeader/SubHeader';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FavouriteIcon from '../FavouriteIcon/FavouriteIcon';

const HeaderV2 = ({primaryBackgroundColor='#FC3300',primaryColor='#30313A',logoType='white1', containerStyle={position:'sticky'}, transparentNav=false, isSubHeader=false, subHeaderTitle='', subHeaderOnclick, subHeaderDesktop=true, subHeaderMobile=true }) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('')
  const [color, setColor] = useState(primaryColor)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const linkStyle = {
    borderBottom: `2px solid ${color}`
  }

  // let logoImage
  let logoStyle={}
  let type='large'
    switch (logoType) {
      case 'white1':
        // logoImage = bgWhiteLogo
        type='large'
        break;
      case 'orange':
        // logoImage = bgOrangeLogo
        type='large'
        break;
      case 'black2':
        // logoImage = bgBlackLogo2
        logoStyle = {width:'30%'}
        type='small'
        break;
      case 'white2':
        // logoImage = bgWhiteLogo2
        logoStyle = {width:'30%'}
        type='small'
      break;
      default:
        // logoImage = bgBlackLogo
        type='large'
        break;
    }
  
  useEffect(() => {
    if( transparentNav) setBackgroundColor("transparent")
    else setBackgroundColor(primaryBackgroundColor)
  }, [transparentNav])

        window.onscroll = () => {
          const scroll = window.scrollY;
          if(transparentNav && scroll > 60) {
            setBackgroundColor(primaryBackgroundColor)
            // setColor(mobileStyle.color[1])
            checkColor(primaryBackgroundColor)
          }
          if(transparentNav && scroll < 60) {
            setBackgroundColor("transparent")
            setColor(primaryColor)
          }
          else setBackgroundColor(primaryBackgroundColor)
          if(transparentNav && mobileMenu === true) {
            setBackgroundColor(primaryBackgroundColor) 
            // setColor(mobileStyle.color[1])
            checkColor(primaryBackgroundColor)
          }
        }

  useEffect(() => {
    const scroll = window.scrollY;
    if(transparentNav && scroll < 60) {
      if(mobileMenu === true) {
        setBackgroundColor(primaryBackgroundColor) 
        // setColor(mobileStyle.color[1])
        checkColor(primaryBackgroundColor)
      }
      else {
        setBackgroundColor("transparent");
        setColor(primaryColor)
      }
    }
    else setBackgroundColor(primaryBackgroundColor) 
  }, [mobileMenu])

  const checkColor = (primaryBackgroundColor) => {
    // if the backgroundColor is white then we change the color to black if needed, else we set the color to inital color
    if(primaryBackgroundColor === mobileStyle.backgroundColor[2]) {
      setColor(mobileStyle.color[1])
    }
    else {
      setColor(primaryColor)
    }
  }

  return (
    <>
    {isMobile ? 
    /// navbar for mobile
    <div className={styles.container} style={{ backgroundColor: backgroundColor,...containerStyle }} >
      <div className={`${styles.section} margin_class`} style={{ backgroundColor: backgroundColor }} >
        {!mobileMenu && <div className={styles.logo_wrapper} >
          {/* <img src={logoImage} style={logoStyle} alt="logo" onClick={()=>navigate("/")}/> */}
          <div onClick={()=>navigate("/")} style={logoStyle}> <BGLogoComponent type={type} color={color} /> </div>
        </div>}
        <div className={styles.hambergur_wrapper} >
          <div>
            {!mobileMenu && <div className={styles.icon_wrapper}>
              <Link to={'/search'} >
                <SearchIcon sx={{ color: color, fontSize: '1.5rem' }} />
              </Link>
              <FavouriteIcon
                badgeStyle={{ 
                  width: "19px", 
                  height: '19px', 
                  marginLeft: '18px', 
                  top: '41px', 
                  backgroundColor:transparentNav ? mobileStyle.color[2] : mobileStyle.color[0],
                  color:transparentNav ? mobileStyle.color[0] : mobileStyle.color[2],
                }}
                color={color} fontSize={'1.5rem'}
              />
              <CartIcon
                badgeStyle={{ 
                  width: "19px", 
                  height: '19px', 
                  marginLeft: '18px', 
                  top: '41px', 
                  backgroundColor:transparentNav ? mobileStyle.color[2] : mobileStyle.color[0],
                  color:transparentNav ? mobileStyle.color[0] : mobileStyle.color[2],
                }}
                color={color} fontSize={'1.5rem'} />
              <Link to={'/account'}>
                <AccountCircleOutlinedIcon sx={{ color: color, fontSize: '1.5rem' }} />
              </Link>
              <div onClick={() => {
                setMobileMenu(!mobileMenu)
              }}>
                <MenuIcon sx={{ color: color, fontSize: '2.2rem' }} />
              </div>
            </div>}
            {
              mobileMenu && <div style={{width:"100vw"}} onClick={() => {
                setMobileMenu(!mobileMenu)
              }}>
                <p style={{marginLeft:"78%"}}><CancelOutlinedIcon sx={{ color: color, fontSize: '2.2rem',textAlign:"right" }} /></p>
              </div>
            }
            <AnimatePresence>
              {mobileMenu ?
                <motion.div transition={{ duration: 0.2 }} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }} style={{ backgroundColor: backgroundColor }} className={`${classes.mobile_nav_links_animater} ${styles.mobile_nav_links_animater}`}>
                  <div className={`${styles.navbar_dropdown} margin_class`} style={{ backgroundColor: backgroundColor, color: color, height: '100vh' }} >
                    <h4 style={linkStyle} ><Link to="/about">About</Link></h4>
                    <h4 style={linkStyle}
                    onClick={() => {
                      // dispatch(clearPageNumber());
                      // navigate("/browse-items", {
                      //   state: { buyNow: true, title: "Buy now", from: 'buyNow' },
                      // })
                      navigate('/shop-now')
                    }
                    }
                    >Shop</h4>
                    <h4 style={linkStyle}><Link target="_self" to="/auction?type=current" rel='noopener noreferrer'>Find an Event</Link></h4>
                    <h4 style={linkStyle}><Link to="/non-profit">Nonprofits</Link></h4>
                    <h4 style={linkStyle}><Link to="/brand">Brands</Link></h4>
                    {/* <h4 style={linkStyle}><Link to="/about">Our Team</Link></h4> */}
                    <h4 style={linkStyle}><Link to="/faq">FAQ</Link></h4>
                    <h4 style={linkStyle}><Link to="/blog">Blog</Link></h4>
                  </div>
                </motion.div>
                : null}
            </AnimatePresence>
          </div>
        </div>
      </div>
        {(isSubHeader || subHeaderTitle !== '' ) && <SubHeader title={subHeaderTitle} onClick={subHeaderOnclick} backgroundColor={primaryBackgroundColor} color={color} />}
      {mobileMenu && <div onClick={() => setMobileMenu(false)} className={classes.for_menu_close} style={{ backdropFilter: "none", backgroundColor: 'transparent' }} />}
    </div>
    :
       /// navbar for desktop
      <section className={styles.desktop_section} style={{backgroundColor: backgroundColor,...containerStyle}}>
        <div style={{backgroundColor: backgroundColor}} className={`${styles.desktop_container} margin_class`} >
          <div className={styles.desktop_logo_container} onClick={()=>navigate("/")}  >
            <BGLogoComponent type={type} color={color} />
          </div>
          <div style={{backgroundColor: backgroundColor, color: color}} className={styles.desktop_links_section} >
            <h4 ><Link to="/about">About</Link></h4>
            <h4
                onClick={() => {
                // dispatch(clearPageNumber());
                // navigate("/browse-items", {
                //    state: { buyNow: true, title: "Buy now", from: 'buyNow' },
                //   })
                  navigate('/shop-now')
                 }
                }
            >Shop</h4>
            <h4><Link target="_self" to="/auction?type=current" rel='noopener noreferrer'>Find an Event</Link></h4>
            <h4><Link to="/non-profit">Nonprofits</Link></h4>
            <h4><Link to="/brand">Brands</Link></h4>
            <h4><Link to="/blog">Blog</Link></h4>
            <div className={styles.desktop_icons_container} >
            <Link to={'/search'} >
                <SearchIcon sx={{ color: color, fontSize: '1.5rem' }} />
              </Link>
              <FavouriteIcon
                badgeStyle={{ 
                  width: "19px", 
                  height: '19px', 
                  marginLeft: '18px', 
                  top: '41px', 
                  backgroundColor:transparentNav ? mobileStyle.color[2] : mobileStyle.color[0],
                  color:transparentNav ? mobileStyle.color[0] : mobileStyle.color[2],
                }}
                color={color} fontSize={'1.5rem'}
              />
              <CartIcon
                badgeStyle={{ 
                  width: "19px", 
                  height: '19px', 
                  marginLeft: '18px', 
                  top: '41px', 
                  backgroundColor:transparentNav ? mobileStyle.color[2] : mobileStyle.color[0],
                  color:transparentNav ? mobileStyle.color[0] : mobileStyle.color[2],
                }}
                color={color} fontSize={'1.5rem'} />
              <Link to={'/account'}>
                <AccountCircleOutlinedIcon sx={{ color: color, fontSize: '1.5rem' }} />
              </Link>
            </div>
          </div>
        </div>
        {(isSubHeader || subHeaderTitle !== '' ) && 
          <>
           {
            subHeaderDesktop && 
                <Desktop>
                  <SubHeader title={subHeaderTitle} onClick={subHeaderOnclick} backgroundColor={primaryBackgroundColor} color={color} />
                </Desktop>
            }
            {
              subHeaderMobile && 
                <Mobile>
                  <SubHeader title={subHeaderTitle} onClick={subHeaderOnclick} backgroundColor={primaryBackgroundColor} color={color} />
                </Mobile>
            }
          </>
        }
      </section>
    }
    </>
  )
}

export default memo(HeaderV2) ;