import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {  useNavigate, useParams, useLocation } from "react-router-dom";
import auctionServices from "../../services/auction-services";

import "./AuctionOverview.css";
import moment from "moment-timezone";
import logger from "../../utils/logger";
import { mobileStyle } from "./Home";
import HeaderV2 from "./molecules/Header/HeaderV2";
import LoadingScreen from "./LoadingScreen/Loading";
import Image from "./atoms/Image/Image";
import NewFooter from "./molecules/NewFooter/NewFooter";
import MobileSubFooter from "./atoms/MobileSubFooter/MobileSubFooter";
import AdminPreviewBanner from "./atoms/AdminPreviewBanner/adminPreviewBanner";
import { Helmet } from "react-helmet-async";
import { config } from "../../config/config";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";
const {REACT_APP_URL} = config

function AuctionOverview() {
  const navigate = useNavigate();
  const { auction_id } = useParams();
  const [auctionDetails, setAuctionDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const params = useLocation().state;
  const primaryBackgroundColor = mobileStyle.backgroundColor[1];
  const primaryColor = mobileStyle.color[2];

  useEffect(() => {
    const getAuctionDetails = async (auctionId) => {
      
      try {
        setIsLoading(true)
        await auctionServices.getAuctionById(auctionId,params?.showIncomplete).then(
          (response) => {
            // console.log(response?.data)
            setAuctionDetails(response?.data);
            // setTimeLeft(calculateTimeLeft(response?.data?.end_date));
            setIsLoading(false);
          },
          (error) => {
            setIsLoading(true)
            logger('./pages/components/AuctionOverview.jsx', 'getAuctionDetails(): catch1', error);
            setIsLoading(false);
            navigate("/not-found");
          }
        );
      } catch (err) {
        setIsLoading(true)
        logger('./pages/components/AuctionOverview.jsx', 'getAuctionDetails(): catch2', err);
        setIsLoading(false);
        navigate("/not-found");
      }
    };
    getAuctionDetails(auction_id);
  }, [auction_id]);

  const getNews = () => {
    window.location.href = process.env.REACT_APP_MAILTO;
  };

  const handleClick = () => {
    // navigate(`/event/${auctionDetails.custom_url ? auctionDetails.custom_url : auctionDetails.id}`, {state:{from:'event'}})
    navigate(-1)
  }

  // if (isLoading) return null;
  const getEventType=()=>{
    if(auctionDetails?.market_place ==='1'){
      return 'Marketplace'
    }else if(auctionDetails?.is_store){
      return 'Store'
    }else {
      return 'Event'
    }
  }

  return (
    <>
    <Helmet>
        <title>{auctionDetails?.title}</title>
        <meta 
          name="description" 
          content={auctionDetails?.about}
        />
        <link rel="canonical" href={`${REACT_APP_URL}${window.location.pathname}`} />
      </Helmet>
    {
       isLoading && 
        <LoadingScreen bgColor="rgb(48, 49, 58)"/>
     }
      {/* <Desktop>
        <Navbar />
        <SubNavbar />
        <Header title="About Event" />
        <div className="dabout_event_container">
          <div className="dbanner">
            <img
              src={auctionDetails?.image2}
              alt="img"
              className="dbanner_img"
            />
            <div className="devent_date_container">
              <p style={{ display: "flex", justifyContent: "space-between", margin: '0px' }}>
                <span style={{ fontWeight: 400, marginRight: '10px' }}>Event starts: </span>
                {moment(auctionDetails?.start_date).format("MMMM DD, YYYY")}
                {", "}
                {moment(auctionDetails?.start_date)
                  .tz("America/Los_Angeles")
                  .format("ha z")}
                .{" "}
              </p>
              <p style={{ display: "flex", justifyContent: "space-between", margin: '0px' }}>
                <span style={{ fontWeight: 400, marginRight: '10px' }}>Event ends: </span>
                {moment(auctionDetails?.end_date).format("MMMM DD, YYYY")}
                {", "}
                {moment(auctionDetails?.end_date)
                  .tz("America/Los_Angeles")
                  .format("ha z")}
                .{" "}
              </p>
            </div>
          </div>
          <div className="devent_details">
            <h5 style={{ fontWeight: '600' }}>Event Title</h5>
            <p>{auctionDetails?.title}</p>
          </div>
          <div className="devent_details">
            <h5 style={{ fontWeight: '600' }}>About the Event</h5>
            <p>{auctionDetails?.about}</p>
          </div>

          <div style={{ marginTop: '20px' }}>
            {auctionDetails?.video && (
              <video
                width="100%"
                height="auto"
                style={{ marginTop: 0, borderRadius: "15px" }}
                controls
                autoplay
                muted
              >
                <source src={auctionDetails?.video} type="video/mp4" />
              </video>
            )}
          </div>

          <div className="devent_details">
            <h5 style={{ fontWeight: '600' }}>Shipping Info</h5>
            <p>{auctionDetails?.shipping_info}</p>
          </div>
          <div className="devent_details">
            <h5 style={{ fontWeight: '600' }}>Event Terms And Conditions</h5>
            <p>{auctionDetails?.terms_info}</p>
          </div>
        </div>
        <Footer style={{ marginTop: "100px" }} />
      </Desktop> */}
      <div className="page_outer auction_page">

        <div style={{backgroundColor:primaryBackgroundColor, color:primaryColor}} className="container">
          {/* <HeaderV2
            logoType="white2"
            primaryBackgroundColor={primaryBackgroundColor}
            primaryColor={primaryColor}
            containerStyle={{position:'fixed'}}
            isSubHeader
            subHeaderTitle="Overview"
          /> */}
          <HeaderV3
            primaryBackgroundColor={primaryBackgroundColor}
            primaryColor={primaryColor}
            isSubHeader
            subHeaderTitle="Overview"
            position="absolute"
          />
          
          <div className="mobile_overview_container margin_class">
            <div className="mobile_card_container">{
              (auctionDetails?.event_logo) ? 
              <img src={auctionDetails?.event_logo} loading='lazy' alt={auctionDetails?.auction_logo_alt} /> 
               :
              <Image alt={auctionDetails?.auction_logo_alt} src={`${auctionDetails?.image2}_small`}/>
            }
              
              <h1>{auctionDetails?.title}</h1>
              {(auctionDetails?.market_place == '0') && (!auctionDetails?.is_store ) &&<p>
                {moment(auctionDetails?.start_date).format("MMMM DD, YYYY")} 
                 <span style={{marginInline:'2px'}}>-</span> 
                 {moment(auctionDetails?.end_date).format("MMMM DD, YYYY")}
              </p>}
            <div className="about_auction_wrapper">
              <h5>About this {getEventType()}</h5>
              <p className="para_fontsize">{auctionDetails?.about}</p>
            </div>
            </div>
            <div className="about_auction_wrapper">
              <h5>Shipping Info</h5>
              <p className="para_fontsize">{auctionDetails?.shipping_info}</p>
            </div>
            <div className="about_auction_wrapper">
              <h5>{getEventType()} Terms and Conditions</h5>
              {/* <p className="para_fontsize">{auctionDetails?.terms_info}</p> */}
              <p className="para_fontsize" dangerouslySetInnerHTML={{__html : auctionDetails?.terms_info}}/>
            </div>
            <div className="to_top_btn_container">
              <button onClick={handleClick} style={{borderBottom:`1px solid ${primaryColor}`, color:primaryColor, margin:'0'}} className='to_top_btn' >Return to {getEventType()}</button>
            </div>
          </div>
           {/* <MobileSubFooter style={{padding:'2vw 5vw 7vw 5vw'}}  primaryBackgroundColor={primaryBackgroundColor} primaryColor="white"/> */}
        </div>
        <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />
        {
          (auctionDetails?.status === 'building') && <AdminPreviewBanner type={params?.type || "Event"} title = {auctionDetails?.title}/> 
        }
      </div>
    </>
  );
}

export default AuctionOverview;
