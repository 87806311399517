import moment from "moment"
import { mobileStyle } from "../../Home"
import RoundedContainer from "../../atoms/RoundedContainer/RoundedContainer"
import TagHeading from "../../atoms/TagHeading/TagHeading"
import classes from './EventPageDetailsCard.module.css'
import { useNavigate } from "react-router-dom"

const EventPageDetailsCard = ({ eventData, itemType = "event", showIncomplete = false }) => {
    const { backgroundColor } = mobileStyle
    const redBG = backgroundColor[0]
    const navigate = useNavigate()
    const handleEventOverview = () => {
        let url = ''
        switch (itemType) {
            case 'marketplace':
                url = 'marketplace-overview'
                break;
            case 'store':
                url = 'store-overview'
                break;
            default:
                url = 'event-overview'
                break;
        }
        navigate(`/${url}/${eventData?.id}`, { state: { showIncomplete: showIncomplete ? true : false } })
    }
    const handleCauseOverview = () => {
        if (itemType === 'marketplace') return navigate(`/about-donor/${eventData?.donor_id}`)
        navigate(`/about-cause/${eventData?.cause_id}?auction_id=${eventData?.id}`, { state: { auctionDetails: eventData } })
    }
    return (
        <section className={classes.eventDetailsSection}>
            <div className={classes.eventDetailsMarginContainer}>
                {/* <RoundedContainer customClassName='eventDetailsCardContainer'>
                    {['event', 'store'].includes(itemType) && <TagHeading heading={eventData.causeDetails.legal_name} customClass="eventDetailsCard" />}
                    <h1 style={{ color: redBG, position: "relative" }} className={`${classes.auctionTitle} ${classes.textCenter}`} >
                        {eventData.title}
                    </h1>
                    {(itemType === 'event') ? new Date(eventData?.start_date) > new Date() ? (
                        <p className={`mobile_auction_ends ${classes.textCenter} ${classes.marginBottom0}`} style={{ color: redBG }}>
                            {" "}
                            Opens {" "}
                            {moment(eventData?.start_date).format("MMMM Do, YYYY")}
                            {", "}
                            {moment(eventData?.start_date)
                                .tz("America/Los_Angeles")
                                .format("ha z")}
                            .{" "}
                        </p>
                    ) : (
                        <p className={`mobile_auction_ends ${classes.textCenter} ${classes.marginBottom0}`} style={{ color: redBG }}>
                            {" "}
                            Ends On{" - "}
                            {moment(eventData?.end_date).format("MMMM Do, YYYY")}
                            {", "}
                            {moment(eventData?.end_date)
                                .tz("America/Los_Angeles")
                                .format("ha z")}
                            .{" "}
                        </p>
                    ) : ""}
                    <div>
                        {
                            eventData.status === "complete" &&
                            <p className={`${classes.completed_alert} ${classes.textCenter} ${classes.marginBottom0}`}>
                                This event is now closed.
                            </p>
                        }
                    </div>
                    <div className="info_buttons" style={{ color: redBG }}>
                        <button onClick={handleEventOverview}
                            style={{
                                borderBottom: `1px solid ${redBG}`,
                                color: redBG,
                            }}
                            className={`info_btn ${classes.info_btn_class}`} >{itemType} Overview
                        </button>
                        <button onClick={handleCauseOverview}
                            style={{
                                borderBottom: `1px solid ${redBG}`,
                                color: redBG,
                            }}
                            className={`info_btn ${classes.info_btn_class}`}>{itemType === 'marketplace' ? 'About This Donor' : 'About This Cause'}
                        </button>
                    </div>
                </RoundedContainer> */}
                 <div className={`info_buttons`}>
                        <button onClick={handleEventOverview}
                            className={`info_btn ${classes.info_btn_class}`} >{itemType} Overview
                        </button>
                        <button onClick={handleCauseOverview}
                            className={`info_btn ${classes.info_btn_class}`}>{itemType === 'marketplace' ? 'About This Donor' : 'About This Cause'}
                        </button>
                    </div>
            </div>
        </section>
    )
}

export default EventPageDetailsCard;