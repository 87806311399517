import { Menu } from "@mui/material";
import NewButton from "../NewButton/NewButton";
import { ExpandMore } from "@mui/icons-material";

const DropDownButton = ({children, open, anchorEl, handleClose=()=>null, handleClick=()=>null, dropDownText='' }) => {
    return (
        <>
            <NewButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className='browse_shop_now_class'
                onClick={handleClick}
                text={dropDownText}
                Icon={ExpandMore}
                style={{
                    backgroundColor: 'white'
                }}
            >
            </NewButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                // anchorPosition={"bottom"}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '.Mui-selected': {
                        backgroundColor: "rgba(48, 49, 58, 0.10)"
                    },
                    '.MuiMenu-list': {
                        paddingTop: 0,
                        paddingBottom: 0
                    },
                    '.MuiMenu-paper': {
                        minWidth: '150px',
                        marginTop: '5px',
                        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.10)',
                        borderRadius: '8px',
                    }
                }}
                PaperProps={{
                    style: {
                      maxHeight: 80 * 4.5,
                    //   width: '20ch',
                    },
                  }}
            >
                {children}
            </Menu>
        </>
    )
}

export default DropDownButton;