import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classes from "./Navbar.module.css"
import logo_alone from "../../../../images/bgBlackLogo.png"
import AuthContext from '../../../../store/auth-context'
import { useContext } from 'react'
import authService from '../../../../services/auth-services'
import CartIcon from '../../../components/molecules/CartIcon/CartIcon'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Navbar = ({style={}, cartColor=null}) => {
  const authCtx = useContext(AuthContext)
  const navigate = useNavigate()
  const handleLogout = () => {
    authService.logout();
    window.location.reload();
  };
  return (
    <nav className={classes.dnavbar} style={{...style}}>
    <div style={{cursor: "pointer"}} onClick={() => navigate("/")} className={classes.dbrand}>
      <img className={classes.dlogo} src={logo_alone} alt="" />
      <span style={{fontSize: "0.7rem", marginLeft: "7px"}}>BETA</span>
    </div>
    <ul className={classes.dnav_links}>
      <li><Link target= "_self" to="/blog" rel='noopener noreferrer' style={{color: "inherit"}}>BLOG</Link></li>
      <li><Link to="/about">ABOUT</Link></li>
      <li><Link to="/support">HELP</Link></li>
      {
        authCtx.isLoggedIn ? <li style={{cursor: "pointer"}} onClick={handleLogout}>LOG OUT</li> : 
        <li><Link to="/login">LOG IN</Link></li>
      }
      {
        authCtx.isLoggedIn && <div onClick={() => navigate("/account")} className={classes.dnav_account}><AccountCircleIcon sx={{fontSize: "35px"}} /></div>
      }
      <CartIcon color={cartColor ? cartColor : "black"} />
    </ul>
  </nav>
  )
}

export default Navbar