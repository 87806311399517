import { Alert } from "@mui/material"

const AdminPreviewBanner = ({type="event",title}) =>{
    return (
        <div className="admin_preview_banner" >
          <Alert  severity="info">
          This {type} is in preview mode and reflects how your {title} will appear once live.
          </Alert>
      </div>
    )
}

export default AdminPreviewBanner