import React, { useState } from 'react'
// import { Desktop, Mobile } from '../../../../responsive/Responsive'
// import styles from '../SplashCarosel/splashCarosel.module.css'
// import commonClasses from "../../../../../desktop/styles/Home.module.css"
// import splashCaroselImage1 from '../../../../../../images/newHomeImages/splashCaroselImage1.jpeg'
// import Section2 from '../../../CreateEvent/Components/Section2'
// import ImageSkelton from '../../../../molecules/ImageSkelton/ImageSkelton'
// import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
// import RoundedContainer from '../../../../atoms/RoundedContainer/RoundedContainer'
// import NewButton from '../../../../atoms/NewButton/NewButton'
// import classes from './HostingSection.module.css'
// import AccordionMui from '../../../../atoms/AccordionMui/AccordionMui'
// import TagHeading from '../../../../atoms/TagHeading/TagHeading'
import { useMediaQuery } from 'react-responsive'
import MakeAnImpactTop from '../../../../molecules/MakeAnImpactTop/MakeAnImpactTop'
import { imageUrls } from '../../../../../../constants/url'

const splashCaroselImage1 = imageUrls.home_page_splash_carosal_image_mobile

const HostingSection = ({ mobileStyle = {} }) => {
    const [bannerLoading, setBannerLoading] = useState(true)
    const backgroundColor = mobileStyle?.backgroundColor[2]
    const color = mobileStyle?.color[0]
    const isDesktop = useMediaQuery({
        minWidth: 768
    })
    return (
        <>
            {/* <Desktop>
                <Section2 />
            </Desktop>

            <Mobile> */}
                {/* <section style={{ backgroundColor: mobileStyle?.backgroundColor[0] }} className={commonClasses.dhero}>
                    <div className='margin_class' style={{paddingBlock:isDesktop ? '118px' : '30px'}}>
                        <RoundedContainer style={{ backgroundColor: backgroundColor, gap:'15px', width:isDesktop && '100%', paddingInline:isDesktop && '40px'}}>
                            <div style={{ color: color }} className={classes.hosting_main} >
                                <TagHeading heading='Fundraising Built Better'/>
                                <h3>Set up your next fundraising event in as little as 10 minutes. <br /> <Mobile><br /></Mobile>
                                    We make it that easy.
                                </h3>
                            </div>
                            <hr style={{ border: `1px solid ${color}` }} />

                            <AccordionMui
                                globalStyle={{ boxShadow: "none", background: backgroundColor }}
                                summaryStyle={{padding:"0px"}}
                                detailsStyle={{padding:"0px"}}
                                titleStyle={{
                                    color: color,
                                    fontFamily: 'Instrument Sans',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '25px',
                                    letterSpacing: '-0.4px'
                                }}
                                title={
                                    <div className={classes.content_container}>
                                     <TagHeading style={{fontSize:'18px'}} heading='Learn more about'/>
                                      <p>Online Auctions</p>
                                    </div>
                                    }
                                description={
                                    <p style={{
                                        color: color,
                                        fontFamily: 'Instrument Sans',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '-0.36px'
                                    }}>
                                        We have all the tools you need for success, from ticket sales to listings to secure 	transactions to seamless fulfillment, including white-glove service.	No gavel needed.
                                    </p>
                                }
                                icon={<ExpandCircleDownOutlinedIcon sx={{width:"29px", height:'29px', color:color}} />}
                            />

                            <hr style={{ border: `1px solid ${color}` }} />
                            <AccordionMui
                                globalStyle={{ boxShadow: "none", background: backgroundColor }}
                                summaryStyle={{padding:"0px"}}
                                detailsStyle={{padding:"0px"}}
                                titleStyle={{
                                    color: color,
                                    fontFamily: 'Instrument Sans',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '25px',
                                    letterSpacing: '-0.4px'
                                }}
                                title={
                                 <div className={classes.content_container}>
                                    <TagHeading style={{fontSize:'18px'}} heading='Learn more about'/>
                                    <p>Pop-Up Shops: <br /> Limited time events</p>
                                 </div>   
                            }
                                description={
                                    <p style={{
                                        color: color,
                                        fontFamily: 'Instrument Sans',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '-0.36px'
                                    }}>
                                        Who doesn’t love a good pop-up shop? Especially when you you don’t have to leave
                                        your home. Or look for parking.
                                    </p>
                                }
                                icon={<ExpandCircleDownOutlinedIcon sx={{width:"29px", height:'29px', color:color}} />}
                            />

                            <hr style={{ border: `1px solid ${color}` }} />
                            <AccordionMui
                                globalStyle={{ boxShadow: "none", background: backgroundColor }}
                                summaryStyle={{padding:"0px"}}
                                detailsStyle={{padding:"0px"}}
                                titleStyle={{
                                    color: color,
                                    fontFamily: 'Instrument Sans',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '25px',
                                    letterSpacing: '-0.4px'
                                }}
                                title={
                                <div className={classes.content_container}>
                                    <TagHeading style={{fontSize:'18px'}} heading='Learn more about'/>
                                    <p>Not Sure:<br /> Maybe all of the above?</p>
                                </div>
                            }
                                description={
                                    <p style={{
                                        color: color,
                                        fontFamily: 'Instrument Sans',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '-0.36px'
                                    }}>
                                        Let’s talk. We’re excited to get to know more about you so we can help figure out
                                        what’s right for you. We can even help you streamline all your annual fundraising
                                        events so they’re all in the same place, working the same way, no matter who’s
                                        running them. (You can breathe a sign of relief now.)
                                    </p>
                                }
                                icon={<ExpandCircleDownOutlinedIcon sx={{width:"29px", height:'29px', color:color}} />}
                            />
                        </RoundedContainer>
                    </div>
                </section> */}

                <MakeAnImpactTop desktopContainerStyle={{marginTop:"0"}} btn={true}/>

                

            {/* </Mobile > */}
        </>
    )
}

export default HostingSection