import { memo, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ConfirmAlertLogin from "../../ConfirmAlertLogin";
import { useSelector } from 'react-redux';
import AuthContext from "../../../../store/auth-context";
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import './CartIcon.css';
import { useMediaQuery } from "react-responsive";
import { imageUrls } from "../../../../constants/url";

const {header_cart_img} = imageUrls

function CartIcon({color="white", fontSize="35px", badgeStyle={}, iconClassName=''}) {
    const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const cartCount = useSelector(state => state.cartDetails.count)

    const handleCloseConfirmAlert = () => {
        setOpenConfirmAlert(false)
        navigate("/cart")
    }

    const isDesktop = useMediaQuery({
        minWidth: 768
      })

    return (
        <>
            {/* {
                authCtx?.isLoggedIn ? ( */}
                    <div>
                        {(!isDesktop && pathname === '/account') ? null : <button type="button" onClick={() => {
                            // if (!authCtx.isLoggedIn) {
                            //     setOpenConfirmAlert(true);
                            //     return
                            // }
                            navigate("/cart")
                        }
                        }>
                            <span style={badgeStyle} className="badge">{cartCount}</span>
                            {/* <ShoppingCartOutlinedIcon sx={{ color: color, fontSize: fontSize }} /> */}
                            <img src={header_cart_img} style={{ color: color }} className={`icon ${iconClassName}`} alt="" />
                        </button>}
                        <ConfirmAlertLogin
                            openConfirmAlert={openConfirmAlert}
                            handleCloseConfirmAlert={handleCloseConfirmAlert} />
                    </div>
                 {/* ) : null
            } */}
        </>
    )
}

export default memo(CartIcon)