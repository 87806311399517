import axios from "axios";
import { headerKey } from "./api-header";
import { config } from '../config/config.js';
const API_BASE_URL = config.REACT_APP_BASE_URL;

export const createCard = async (cardDetails) => {
  const redirectUrl = await axios.post(
    `${API_BASE_URL}/api/v1/payment/capture-card`,
    cardDetails,
    {
      headers: { ...headerKey },
    }
  );

  return redirectUrl;
};

export const updateCard = async (cardDetails) => {
  const redirectUrl = await axios.post(
    `${API_BASE_URL}/api/v1/payment/update-card`,
    cardDetails,
    {
      headers: { ...headerKey },
    }
  );

  return redirectUrl;
}

export const createPayment = async (payload) => {
  const response = await axios.post(`${API_BASE_URL}/api/v1/payment/create-payment`, payload, {
    headers: { ...headerKey }
  });
  return response.data;
}

export const fetchSavedCard = async (cusID, cardID) => {
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/payment/get-card-details/${cusID}/${cardID}`)
  return data
}
export const deletePaymentDetails = async ()=>{
  const response= await axios.delete(`${API_BASE_URL}/api/v1/payment/payment-details`,{
    headers: { ...headerKey }
  })
  return response
}
