import { useEffect, useRef, useState } from "react";
import ImageSkelton from "../ImageSkelton/ImageSkelton";
import classess from "./newProductCardForFlashSale.module.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const getCauseAmount = (item) => {
  let amt = 0;
  const causeSharePercentage = item?.donor?.revenue_splits
    ? +item?.donor?.revenue_splits[0]?.cause_rev_share_percent / 100 || 0.8
    : 0.8;
  if (item?.store_item_id) {
    amt = Number(item?.store_item.price * causeSharePercentage);
  } else {
    if (item?.highestBid?.length) {
      amt = Number(item?.highestBid[0]?.max_bid * causeSharePercentage);
    } else if (item?.buy_now_price) {
      amt = Number(item?.buy_now_price * causeSharePercentage);
    } else {
      amt = Number(item?.price * causeSharePercentage);
    }
  }
  return amt;
};

export default function NewProductCardForFlashSale({
  item,
  image,
  needXref = true,
  handleClick,
}) {
  const elemRef = useRef();
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  useEffect(() => {
    (() => {
      let price = item?.store_item?.price;
      let listPrice = item?.store_item?.list_price;
      if (!listPrice) return;
      setDiscountPercentage(
        Math.round(((+listPrice - +price) / +listPrice) * 100)
      );
    })();
  }, []);
  const handleNavigateClick = () => {
    // navigate(`/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${item?.auction?.cause_id}`)
    if (elemRef.current) {
      localStorage.setItem(
        "browseScroll",
        elemRef.current.offsetTop - elemRef.current.scrollTop
      );
    }
    let xrefAuctionId;
    let cause_id = item?.auction?.cause_id;
    if (item?.auction_auction_item_xrefs?.length && needXref) {
      xrefAuctionId = item?.auction_auction_item_xrefs[0]?.auction_id;
      cause_id = item?.auction_auction_item_xrefs[0]?.auction?.cause?.id;
      navigate(
        `/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${cause_id}&xref_auction_id=${xrefAuctionId}`
      );
    } else {
      navigate(
        `/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${cause_id}`
      );
    }
  };

  return (
    <div
      ref={elemRef}
      className={classess.product_card}
      onClick={handleClick ? handleClick : handleNavigateClick}
    >
      <div className={classess.product_image}>
        <img
          src={
            image ||
            item?.auction_item_assets?.find(
              (img) => img.context_type === "portrait"
            )?.name
          }
          alt=""
          onLoad={() => setImageLoading(false)}
          style={{ display: imageLoading ? "none" : "block" }}
        />
        <ImageSkelton
          loadingState={imageLoading}
          style={{ height: "100%", aspectRatio: "auto" }}
        />
        {discountPercentage ? (
          <span className={classess.discount_tag}>-{discountPercentage}%</span>
        ) : null}
        {item?.auction_item_amount_given_dialog === 1 ? (
          <div className={classess.donation_impact}>
            <span className={classess.impact_label}>[DONATION IMPACT]</span>
            <span className={classess.impact_amount}>
              ${getCauseAmount(item).toFixed(2)}
            </span>
          </div>
        ) : null}
      </div>

      <div className={classess.product_info}>
        <h3 className={classess.product_name}>{item?.name}</h3>
        <div className={classess.live_now_container}>
          <h3 className={classess.product_name}>{item.donor?.org_name}</h3>
          {moment(item.auction.start_date).isBefore(moment())?<div className={classess.live_now}>Live now</div>:<div>{moment().format('MM/DD/YY')}</div>}
        </div>
        <div className={classess.product_price}>
          <span className={classess.current_price}>
            ${item?.store_item?.price}
          </span>
          {item?.store_item?.list_price && (
            <span className={classess.original_price}>
              ${item?.store_item?.list_price}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
