import { ArrowBack, ArrowForward } from "@mui/icons-material";
import NewButton from "../../atoms/NewButton/NewButton";
import classes from './HomePageCarouselAction.module.css';
import { Link } from "react-router-dom";

const HomePageCarouselAction = ({centerButtonText='', centerButtonHref='', displayMobileClass='displayMobileClass'}) => {
    return (
        <div className={classes.cardsAction}>
            <div id='pagination-el' className={classes.bulletsContainer} ></div>
            {centerButtonText !== "" && <Link className={classes.link} to={centerButtonHref}><NewButton className='carouselCardButton' text={centerButtonText} /></Link>}
            <div className={`${classes.swipperPagination} ${classes[displayMobileClass]}`}>
                <button className={classes.swiperNavigationButton} id='swiper-back' ><ArrowBack className={classes.navigateArrow} /></button>
                <button className={classes.swiperNavigationButton} id='swiper-forward'><ArrowForward className={classes.navigateArrow} /></button>
            </div>
        </div>
    )
}

export default HomePageCarouselAction;