import React,{useRef} from 'react'
import {Desktop} from "../responsive/Responsive"
import Navbar from "../../desktop/components/Navbar/Navbar"
import Footer from "../../desktop/components/Footer/Footer"
import CircularProgress from '@mui/material/CircularProgress';
import classes from "./LetsStartFunding.module.css"
import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SubNavbar from '../../desktop/components/SubNavbar/SubNavbar';
import { validEmail } from '../../../utils/reg-ex';
import userServices from '../../../services/user-services';

const LetsStartFunding = () => {
  const [iframeLoading, setIframeLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [messageSentNotification, setMessageSentNotification] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [formDetails, setFormDetails] = useState({
    user_name:'',
    user_email:'',
    organisation:'',
    phone:''
  })
  const form = useRef()
  // const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
  const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
  const onFinishLoad = () => {
    setTimeout(() => {
      setIframeLoading(false)
    }, 800)
  }

  const handleChange = (e) => {
    setFormDetails({...formDetails,[e.target.name]:e.target.value})
  }

  const sendEmail = async(e) => {
    e.preventDefault();
    setEmailError(false)
    setNameError(false)
    setPhoneError(false)
    if(!formDetails?.user_name ) {
      setNameError(true)
      return;
    }
    if( !formDetails?.user_email){
        setEmailError(true);
        return;
    }
    if (formDetails?.user_name?.trim().length < 1) {
       setNameError(true)
       return;
      }
    if (!validEmail.test(formDetails?.user_email)) { 
      setEmailError(true) 
      return;
    }
    if(formDetails.phone?.trim()?.length >0){
      if (!formDetails?.phone?.match(phoneRegex)) {
        setPhoneError(true) 
        return;
      }
    }
    setLoading(true)
    setEmailError(false)
    setNameError(false)
    setPhoneError(false)
         try{
              const {data} = await userServices.sendGetStartedEmail(formDetails)
              if(data?.status==200){
                  setLoading(false)
                  setFormDetails({
                    user_name:'',
                    user_email:'',
                    organisation:'',
                    phone:''
                  })
                  setEmailError(false)
                  setNameError(false)
                  setPhoneError(false)
                  setMessageSentNotification(true)
                  setTimeout(() => {
                    setMessageSentNotification(false)
                  },8000)
                }
                else{
                  setEmailError(false)
                  setNameError(false)
                  setPhoneError(false)
                  setLoading(false)
                  setFormDetails({
                    user_name:'',
                    user_email:'',
                    organisation:'',
                    phone:''
                  })
                }
                 }catch(error) {
                  setEmailError(false)
                  setNameError(false)
                  setPhoneError(false)
                  setLoading(false)
                  setFormDetails({
                    user_name:'',
                    user_email:'',
                    organisation:'',
                    phone:''
                  })
                 }
          };

  return (
    <main className={classes.container}>
      <div className={classes.nav_container}>
        <Desktop>
              <Navbar style={{borderBottom: "none", padding: "0", color: "white"}} cartColor="white" />
              <SubNavbar style={{boxShadow: "none",color: "white" , zIndex: "10",position:"relative",justifyContent:"space-between"}}/>
        </Desktop>
      </div>
      <section className={classes.hero_section} >
        <div className={classes.hero_image}></div>
      </section>
      <section className={`${classes.main_section} ${classes.hero_content}`}>
        <h1>Let's Start Fundraising</h1>
        <p>Better tools. Easier functionality. Customized solutions.</p>
        {/* <div className={classes.hero_labels_container}>
            <div>Auctions</div>
            <div>Popups</div>
            <div>And More</div>
        </div> */}
      </section>
      <section className={classes.main_form}>
        <div className={classes.main_form_box}>
          <div className={classes.heading}>
            <h1>Get started with your event today</h1>
          </div>
          <div className={classes.content}>
            <div className={classes.content_elements}>
              <div className={classes.list_element}>
                <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "19px" }} /></div>
                <div className={classes.list_element_text}>
                  <div className={classes.list_element_head}>Features that raise money</div>
                  <div className={classes.list_element_info}>We took the best fundraising tools and made them better: auctions, fundraising, ticket sales, and more.</div>
                </div>
              </div>
              <div className={classes.list_element}>
                <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "19px" }} /></div>
                <div className={classes.list_element_text}>
                  <div className={classes.list_element_head}>Transparent pricing</div>
                  <div className={classes.list_element_info}>You keep 70-80% of all event sales.¹</div>
                </div>
              </div>
              <div className={classes.list_element}>
                <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "19px" }} /></div>
                <div className={classes.list_element_text}>
                  <div className={classes.list_element_head}>No hidden fees</div>
                  <div className={classes.list_element_info}>We don't charge nonprofits extra for payment processing or <br /> platform features.</div>
                </div>
              </div>
              <div className={classes.list_element}>
                <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "19px" }} /></div>
                <div className={classes.list_element_text}>
                  <div className={classes.list_element_head}>No long term contracts</div>
                  <div className={classes.list_element_info}> There are no long-term agreements to use Boundless Giving.</div>
                </div>
              </div>
              <div className={classes.list_element}>
                <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "19px" }} /></div>
                <div className={classes.list_element_text}>
                  <div className={classes.list_element_head}>Add-on services</div>
                  <div className={classes.list_element_info}>We provide fulfillment² and white glove³ services as needed.</div>
                </div>
              </div>
              <div className={classes.list_element}>
                <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "19px" }} /></div>
                <div className={classes.list_element_text}>
                  <div className={classes.list_element_head}>Customer service</div>
                  <div className={classes.list_element_info}>Some platforms charge for customer service. We don't.</div>
                </div>
              </div>
            </div>
          {/* <div className={classes.iframe_container}> */}
         <form ref={form} onSubmit={sendEmail} className={classes.iframe_container}>
            {/* <iframe 
              src="https://api.leadconnectorhq.com/widget/form/ktut5ipryGGlLjnyG0jG" 
              style={{width:"100%", height: "100%", display: iframeLoading ? 'none' : 'initial', opacity: iframeLoading ? 0 : 1, transition: "0.3s"}}
              id="inline-ktut5ipryGGlLjnyG0jG" 
              data-layout="{'id':'INLINE'}"
              data-trigger-type="alwaysShow"
              data-trigger-value=""
              data-activation-type="alwaysActivated"
              data-activation-value=""
              data-deactivation-type="neverDeactivate"
              data-deactivation-value=""
              data-form-name="Request Access"
              data-height="700"
              data-layout-iframe-id="inline-ktut5ipryGGlLjnyG0jG"
              data-form-id="ktut5ipryGGlLjnyG0jG"
              onLoad={onFinishLoad}
            >
              </iframe> */}
            {  !messageSentNotification &&
            <>
             <div style={{width:'92%'}} >
             <input style={{width:'100%'}} value={formDetails?.user_name} onChange={handleChange} className={classes.contact_input} placeholder='Your name*' type="text" name="user_name" />
             { nameError && <p className={classes.error}>Name is required</p >}
             </div>
             <input value={formDetails?.organisation} onChange={handleChange} className={classes.contact_input} placeholder='Company/Cause Name' type="text" name="organisation" />
             <div style={{width:'92%'}}>
             <input style={{width:'100%'}} value={formDetails?.user_email} onChange={handleChange} className={classes.contact_input} placeholder='Email*' type="email" name="user_email"/>
             { emailError && <p className={classes.error}>Please provide a valid email</p >}
             </div>
             <div style={{width:'92%'}}>
             <input style={{width:'100%'}} value={formDetails?.phone} onChange={handleChange} className={classes.contact_input} placeholder='Phone number' type="text" name="phone" />
             { phoneError && <p className={classes.error}>Please provide a valid phone number</p >}
             </div>
             
             <div className={classes.button_wrapper}>
            <button className={classes.form_button} type='submit' >
            {loading ? 
             <CircularProgress sx={{width:'20px !important',height:'20px !important',color:'white'}}/>
             : <p>REQUEST ACCESS</p>} 
            </button>
            </div>
            </>
            }
             {messageSentNotification && 
              <div style={{marginTop:'22px', fontWeight:600}}>
                 <p style={{textAlign:'center', fontSize:"24px" }}>Thanks for your interest!
                 A member of our Boundless team will reach
                 out soon with first steps towards building your event.</p>
             </div>
            }
            {/* {iframeLoading ? 
              <div className={classes.loader_container}>
              <CircularProgress sx={{color: "#F43AB0"}} />
              </div>
            : null} */}
        </form>
          {/* </div> */}
          </div>
        </div>
        <div className={classes.reference_container}>
          <ol>
            <li>Our fees are 10% of all event sales. Optional add-on services increase but never exceed 20%.</li>
            <li>Fulfillment is an optional service if you want us to handle all of the merchandise storage, picking, packing, and shipping.</li>
            <li>White glove is an optional service if you want a dedicated account manager to create your event, list your items, and <br/> keep you updated on your fundraiser.</li>
          </ol>
        </div>
      </section>
   <Desktop>
       <div className={classes.footer_wrapper}> 
         <Footer />
       </div>
   </Desktop>
    </main>
  )
}

export default LetsStartFunding