import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classes from "../../../../desktop/styles/Home.module.css"
import { Desktop } from '../../../responsive/Responsive'
import ImageSkelton from '../../../molecules/ImageSkelton/ImageSkelton'
import { imageUrls } from '../../../../../constants/url'

const Banner = () => {
  const bannerRight = imageUrls.home_page_banner_section_right;
  const bannerLeft = imageUrls.home_page_banner_section_left;
    const [bannerLoading, setBannerLoading] = useState(true)
  return (
    <>
    {/* <Desktop> */}
      <section className={classes.dhero}>
          {/* <div className={classes.dhero_left}>
            <div>
              <div>Shop to Give</div>
              <div>Delve into auctions and pop-up stores and <br /> 
              support a greater cause
              </div>
             
              <div style={{cursor: "pointer", backgroundColor: `var(--primary-btn-clr)`,
                color: `var(--white)`}}> <Link to="/auction?type=current">Explore Events</Link></div>
             
            </div>
           
          </div> */}
          <div className={classes.dhero_right}>
            <Link to="/create-event" target="_self">
            <img onLoad={() => setBannerLoading(false)} loading='lazy'  style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", display: bannerLoading ? 'none' : 'block'}} src={bannerLeft} alt="" />
            </Link>            
            <ImageSkelton loadingState={bannerLoading}/>
          </div>
          <div className={classes.dhero_right}>
            <a href="https://programs.boundlessgiving.com/" target="_blank"  rel="noreferrer">
            <img onLoad={() => setBannerLoading(false)} loading='lazy'  style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", display: bannerLoading ? 'none' : 'block'}} src={bannerRight} alt="" />
            </a>
            <ImageSkelton loadingState={bannerLoading}/>
          </div>
        </section>
        {/* </Desktop> */}
    </>
  )
}

export default Banner
