import constants from "../../../../../constants/en"
import BGLogoComponent from "../../../../../images/BGLogoComponent/BGLogoComponent"
import { mobileStyle } from "../../../Home"
import SectionContainer from "../../SectionContainer/SectionContainer"
import classes from './NewFooterV2.module.css'
import Input from '../../../atoms/Input/Input'
import NewButton from "../../../atoms/NewButton/NewButton"
import { Link } from 'react-router-dom'
import { Instagram } from "@mui/icons-material"
import { Desktop, Mobile } from "../../../responsive/Responsive"
import FaceBook from '../../../../../images/newFooterImages/facebookVector.png'
import TwitterX from '../../../../../images/newFooterImages/twitterX.png'
import { memo } from "react"
const { APP_FOOTER_COPIES: { para, subscribePara, pageLinks, CopyrightText }, APP_SOCIAL_LINKS:{FACEBOOK_LINK, TWITER, INSTAGRAM} } = constants

const NewFooterV2 = () => {
    const { color } = mobileStyle
    return (
        <SectionContainer>
            <div className={classes.footerContainer} >
                <div className={classes.footerTopPart} >
                    <div className={classes.topLogoSide} >
                        <Desktop>
                            <div className={classes.logoSection}>
                                <div className={classes.logoContainer} > <BGLogoComponent type={'small'} color={color[1]} /> </div>
                                <p className={classes.logoPara} >{para}</p>
                            </div>
                        </Desktop>
                        <div className={classes.newsLetterSection} >
                            <h2 className={`newTitleClass ${classes.newsLetterTitle}`} >JOIN OUR NEWSLETTER</h2>
                            <div className={classes.newsLetterPart}>
                                <form
                                 className={`${classes.newsLetterForm} validate`}
                                 action="https://boundlessgiving.us21.list-manage.com/subscribe/post?u=e523e179961f63c6ced52602f&amp;id=19066d7026&amp;f_id=0080c5e1f0%22" 
                                 method="post" 
                                 id="mc-embedded-subscribe-form" 
                                 name="mc-embedded-subscribe-form" 
                                 target="_blank" 
                                 noValidate
                                >
                                    <Input name="email" customClass="subscribeForm" placeholder="ENTER YOUR EMAIL" />
                                    <NewButton type="submit" text={'SUBSCRIBE'} className="subscribeButton" />
                                </form>
                                <p className={classes.logoPara} >{subscribePara}</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.topMenuAndAddressSide} >
                        <div className={classes.menuPart} >
                            <h2 className={`newTitleClass ${classes.newsLetterTitle}`} >MENU</h2>
                            <ul>
                                {
                                    pageLinks.map((item) => {
                                        return (
                                            <li key={item.link} className={classes.footerLink}> <Link to={item.link}>{item.label}</Link> </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={classes.addressPart} >
                            <h2 className={`newTitleClass ${classes.newsLetterTitle}`} >CONTACT</h2>
                            <div className={classes.addressSection} >
                                <div className={classes.address}>
                                    <p className={classes.addressHeading} >Address</p>
                                    <p className={classes.addressPara} >Boundless Giving, Inc.</p>
                                    <p className={classes.addressPara} >22287 Mulholland Hwy 570</p>
                                    <p className={classes.addressPara} >Calabasas, CA 91302</p>
                                </div>
                                <div className={classes.address}>
                                    <p className={classes.addressHeading} >PHONE</p>
                                    <p className={classes.addressPara} >323-790-6585</p>
                                </div>
                                <div className={classes.address}>
                                    <p className={classes.addressHeading} >EMAIL</p>
                                    <p className={classes.addressPara} ><Link target="_blank" to={'mailto:info@boundlessgiving.com'}>info@boundlessgiving.com</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Mobile>
                    <div className={classes.logoSection}>
                        <div className={classes.logoContainer} > <BGLogoComponent type={'small'} color={color[1]} /> </div>
                        <p className={classes.logoPara} >{para}</p>
                    </div>
                </Mobile>
                <div className={classes.footerBottomPart} >
                    <p className={classes.copyRightText} >{CopyrightText}</p>
                    <div className={classes.termsAndPolicy} >
                        <Link to={'/tos'} className={classes.footerLink}>TERMS OF SERVICE</Link>
                        <Link to={'/privacy'} className={classes.footerLink}>PRIVACY POLICY</Link>
                    </div>
                    <div className={classes.socialIcons} >
                        <a target="_blank" rel="noreferrer" href={FACEBOOK_LINK} > <img className={classes.icon} src={FaceBook} alt="Facebook" /> </a>
                        <a target="_blank" rel="noreferrer" href={INSTAGRAM}> <Instagram className={classes.icon} /> </a>
                        <a target="_blank" rel="noreferrer" href={TWITER}><img className={classes.icon} src={TwitterX} alt="X" /> </a>
                    </div>
                </div>
            </div>
        </SectionContainer>
    )
}

export default memo(NewFooterV2);