import axios from "axios";
import { headerKey } from "./api-header";
import { config } from "../config/config.js";
import logger from "../utils/logger";
const API_BASE_URL = config.REACT_APP_BASE_URL;

const getAuctionItems = async (search, page=1, limit=20,marketPlace, userId) => {
  const auctionItems = await axios.get(
    `${API_BASE_URL}/api/v1/auction/search-item`,
    {
      params: {
        search,
        page: page,
        limit: limit,
        market_place:marketPlace?true:null,
        userId
      },
    },
    {
      headers: { ...headerKey },
    }
  );
  logger('./services/search-services.js','getAuctionItems(): res',auctionItems);
  return {
    data: auctionItems.data.data,
    totalPage: auctionItems.data.total_page,
    totalCount: auctionItems.data.total_count
  };
};

const getAuctionsList = async (search, page, limit=20) => {
  const auctionList = await axios.get(
    `${API_BASE_URL}/api/v1/auction/search-auction`,
    {
      params: {
        search,
        page: page,
        limit: limit,
      },
    },
    {
      headers: { ...headerKey },
    }
  );

  return {
    data: auctionList.data.data,
    totalPage: auctionList.data.total_page,
    totalCount: auctionList.data.total_count
  };
};

const searchServices = {
  getAuctionItems,
  getAuctionsList,
};

export default searchServices;
