import {config} from "../config/config"

const {IMAGES_BASE_URL} = config

const shop_now_fallBack_desktop = `${IMAGES_BASE_URL}a79d39b911e58e2f364e44c6ea8e537c34e53f5511b18eab76996123d451ed9f`
const shop_now_fallBack_desktop_low_quality = `${IMAGES_BASE_URL}51975b8032af78549a643be8152b52822e30c30462f15eb19a1d0f10c68c9abc`
const shop_now_fallBack_mobile = `${IMAGES_BASE_URL}6be9d246dbe0b5d1d40cd90a40545f467d6f03524f9c7d8a1b57a8201aad306b`
const shop_now_fallBack_mobile_low_qaulity = `${IMAGES_BASE_URL}da7f1dab0c0e35fdb3e5f65643455c2473346d551da4b5a9689aad43172b1db7`
const home_page_desktop_market_place_banner = `${IMAGES_BASE_URL}9a227c4ab461d98e1f564306298481503acab315a6562bea11c177c018d5004e`
const non_profit_desktop_mobile_img = `${IMAGES_BASE_URL}991b5999cc6b57c76a34f0c49d98df82457c09635aaf4b834b101f7a9991eb8b`
const non_profit_desktop_chart_img = `${IMAGES_BASE_URL}84856139d36448af0656ae4bd8295ed10de3f09d688ec2320bdbfb9afcd4af46`
const bg_rayan = `${IMAGES_BASE_URL}b6c7556eca6033a5be7137439f4c3ea6d3b71e8ad2491e8af6d99a640b4670aa`
const bg_nick = `${IMAGES_BASE_URL}c7428c43c566b7db237092965e50115a8a2f21d56d45c8d2e96958fa6f91e3e3`
const bg_brandon = `${IMAGES_BASE_URL}29b65b4bd0dab661d7065c13c843eaaceb43a8bf4f526bdc9cb7a2422b916682`
const bg_neil = `${IMAGES_BASE_URL}593c22a0a5b59b22e6280b4d6b2a5ea6c995a066fb98a50a8b657ea01f8a7f93`
const bg_julie = `${IMAGES_BASE_URL}84f275f730d306a7302076c7e41ac846483efa0afed75643116cdda76f316b5a`
const shop_now_brands_you_love_desktop = `${IMAGES_BASE_URL}ee3b60e74ebfc509fc14cba6108b90d09830c8c2ccd103d29048dd9d927b6d70`
const shop_now_brands_you_love_desktop_low_quality = `${IMAGES_BASE_URL}a9adf5aaf5280b8fcf4cd06d2ffee343e9e74d9792e540ae4fe30d2df182f4ac`
const shop_now_cause_you_care_desktop = `${IMAGES_BASE_URL}0893483932ec321850f8e1b38fef907a5c58cd47fa48c346e7dd11aa09c002a7`
const shop_now_cause_you_care_desktop_low_quality = `${IMAGES_BASE_URL}8ab2716e3fc1a14752f077e20b31e42c92b3a57adc6898782ffa0ba5b1bac96b`
const shop_now_our_merch_desktop = `${IMAGES_BASE_URL}d5e4d334760af620fef384cf66c554a811dc5eac56367397af775c7c2ab143e7`
const shop_now_our_merch_desktop_low_quality = `${IMAGES_BASE_URL}4a86ae291e50543b0f347dbd1c03264f44d1fad8ad0aa9675dd77e69febe5868`
const featured_event_background_desktop = `${IMAGES_BASE_URL}74ce1b22f308e4e2fcc4b91f41f7d70c9a7aad19aacf495e589a62b981a3dd19`
const three_ways_to_impact_desktop_bottom_img_left = `${IMAGES_BASE_URL}53e862f24e1f7dd52b7b2862b7c935dae401799bd5e299f570c830f7485709d4`
const three_ways_to_impact_desktop_bottom_img_right = `${IMAGES_BASE_URL}8e0834dcc9fdad97aaa08a5c117c1ba1a519a1f80fcda4393800b476dff4c6fc`
const logo_bg_big_black = `${IMAGES_BASE_URL}beccb2ffd9cc5ccd87397a6908d47adf5714a5d2f217a617c1e2f2419d3a6995`
const logo_bg_big_orange = `${IMAGES_BASE_URL}88aa3a77dd1f95a7a61de56102a625c5d0b137c6934625079d1889cfacc87faa`
const logo_bg_big_white = `${IMAGES_BASE_URL}889c8dfb6196806a63bae1e5229fb3277e552581de91678eb693f98b6f9ce6d1`
const logo_bg_small_black = `${IMAGES_BASE_URL}83b1d89666b9e795fa1718e54e6c4380d4f460ff84ae635a9169dc2d2550978a`
const logo_bg_small_orange = `${IMAGES_BASE_URL}8deb59f93ee3e42a2f156a554d3d6c7a045aef6708a05db0c528c6ddcf79e0d8`
const logo_bg_small_white = `${IMAGES_BASE_URL}f667c4c8607366302fe0f632b08348a9ced317a7500d76afec41bbb9bed2c342`
const payment_detail_visa = `${IMAGES_BASE_URL}a5718d3baa8669110d37f7f8f12dee0daf8ea19309569c0d889309b488c71e65`
const payment_detail_master_card = `${IMAGES_BASE_URL}dbd5d3f08d0dc924e217b30d6ff31fdfb39609f8a6d1c9e13e7735f0a9ae7460`
const payment_detail_pay_pal = `${IMAGES_BASE_URL}686fa4a8a8f768eacd4a75452113ba615d12aee9dd484e59bf4d4d00a681600d`
const payment_detail_american_express = `${IMAGES_BASE_URL}d7e1a42ea7d1741a06fa97f01c8622f09eca5e2787a3715c2c937e03ac49ec24`
const home_page_mobile_market_place_banner = `${IMAGES_BASE_URL}2f751e35140a3fda5a22622e496dd283e05dc54500dd660a0b23dfb43c15c86e`
const non_profit_mobile_chart_img = `${IMAGES_BASE_URL}0e3ad9339a1d004fc3fd5f86f42a30baf16cee0d78a5bc536de76b30350fcf7e`
const shop_now_brands_you_love_mobile = `${IMAGES_BASE_URL}f73fdd80b0b983fca83549a314d85ff16a76a49cb788d605df40842e7b63abe0`
const shop_now_brands_you_love_mobile_low_quality = `${IMAGES_BASE_URL}3385ba7cadfd55db28cd3e8e63277fbf19e4d5080335f4d1a478e239fb943fce`
const shop_now_cause_you_care_mobile = `${IMAGES_BASE_URL}4dedccaec94b471608256fcd5356c5445e36cd756514b75e683a75b1b8fa80cf`
const shop_now_cause_you_care_mobile_low_quality = `${IMAGES_BASE_URL}e0e37d56951b6503e1e06fc58aa0e8ffc375d184a286dcd8773bf1c42b52aed9`
const shop_now_our_merch_mobile = `${IMAGES_BASE_URL}65ccf77a257771069ad8ed5d57f4da93f4733061d5836da6f055eea7ab83ede6`
const shop_now_our_merch_mobile_low_quality = `${IMAGES_BASE_URL}fe2de9dd7b159de077417a87a2496b217445ed7039548ba96ba0cbb08dad0aef`
const home_page_splash_carosal_image_mobile = `${IMAGES_BASE_URL}0f53d83b66b5070ed0a4949742b6afab0c15249f9241641f77da2ec57ec9445a`
const home_page_event_ferrari_wheel = `${IMAGES_BASE_URL}a1093fea2a28380f49c7de54819e604b6d2a68274373ccd4183ffc640cebb35e`
const home_page_banner_section_left = `${IMAGES_BASE_URL}24c85ee758ef37641cbb5333f9b6291344d8c5924643501e9f4901f83affb36e`
const home_page_banner_section_right = `${IMAGES_BASE_URL}9560241fa49bce19a110c2d8f45be3b5d02e352ae2f0e39e8c00c7dabc0b777d`
const shop_now_desktop_banner = `${IMAGES_BASE_URL}2de893d467674c3ccaec888cc16ad81ea33c775915b4b1ad6b0f3b167c531fc9`
const shop_now_desktop_banner_low_quality = `${IMAGES_BASE_URL}e922bb0e696c7fbb833c11be8245127dca75f5363316d8bfd4f7113d11e55b75`
const shop_now_mobile_banner = `${IMAGES_BASE_URL}00d6482efb11f8f893c9492d8251af25093f87862603a873e86d8b7d2d616f4b`
const shop_now_mobile_banner_low_quality = `${IMAGES_BASE_URL}8544bce6f4bf5af386d382f61150ddb91e1c818a442e54412e0591b3fd9a8b5d`
const browse_brands_page_banner_mobile = `${IMAGES_BASE_URL}374f02014262c0a48cb2fbd6c8c17b2f559315a614a1dcb80b784cccb6ca3bfd`
const browse_cause_page_banner_mobile = `${IMAGES_BASE_URL}6ce34770537cbe5466709b59c75c394f2d3040fa6a57a29ab2585a377047eb42`
const browse_stores_page_banner_mobile = `${IMAGES_BASE_URL}3086da0b06d3ecd51f4aae59788dd364f9f78ebc5f728632b6a350f3719f2e55`
const home_about_us_section_image = `${IMAGES_BASE_URL}84748084a9fd1e1b9d47606ba07b924a8ea44b948beab241d5f3be4af09879fa`
const shop_now_v3_banner_image_desktop = `${IMAGES_BASE_URL}b6f0d74dc4677105b7fd30e67019c26d4c4df6f8d414f35c0d6a31fc04c94819`
const shop_now_v3_banner_image_mobile = `${IMAGES_BASE_URL}1e994b8d6b8268bd5e3e9bbe9e835b737e48a000d70a9f820764ec571d4806b4`
const header_cart_img = `${IMAGES_BASE_URL}6271382a19a4ec02f0f384c92a0809a2b8bd5f11d64e743bd434f6297460c017`
const header_search_img = `${IMAGES_BASE_URL}9db856f5fce4adf071f8323180bc8ef5196df6c7a5e48c2dfba280dd496981c4`
const header_account_img = `${IMAGES_BASE_URL}ec8adc69f9d07024cc27ef00aa188a4ff918ab0ed7f50bd88947c96004ee419b`
export const imageUrls = {
    shop_now_fallBack_desktop,
    shop_now_fallBack_mobile,
    home_page_desktop_market_place_banner,
    non_profit_desktop_mobile_img,
    non_profit_desktop_chart_img,
    bg_rayan,
    bg_brandon,
    bg_julie,
    bg_neil,
    bg_nick,
    shop_now_brands_you_love_desktop,
    shop_now_brands_you_love_desktop_low_quality,
    shop_now_cause_you_care_desktop,
    shop_now_cause_you_care_desktop_low_quality,
    shop_now_our_merch_desktop,
    shop_now_our_merch_desktop_low_quality,
    shop_now_brands_you_love_mobile,
    shop_now_brands_you_love_mobile_low_quality,
    shop_now_cause_you_care_mobile,
    shop_now_cause_you_care_mobile_low_quality,
    shop_now_our_merch_mobile,
    shop_now_our_merch_mobile_low_quality,
    featured_event_background_desktop,
    three_ways_to_impact_desktop_bottom_img_left,
    three_ways_to_impact_desktop_bottom_img_right,
    logo_bg_big_black,
    logo_bg_big_orange,
    logo_bg_big_white,
    logo_bg_small_black,
    logo_bg_small_orange,
    logo_bg_small_white,
    home_page_event_ferrari_wheel,
    home_page_splash_carosal_image_mobile,
    home_page_mobile_market_place_banner,
    payment_detail_american_express,
    payment_detail_master_card,
    payment_detail_pay_pal,
    payment_detail_visa,
    non_profit_mobile_chart_img,
    home_page_banner_section_left,
    home_page_banner_section_right,
    shop_now_desktop_banner,
    shop_now_mobile_banner,
    browse_brands_page_banner_mobile,
    browse_cause_page_banner_mobile,
    browse_stores_page_banner_mobile,
    shop_now_fallBack_mobile_low_qaulity,
    shop_now_fallBack_desktop_low_quality,
    shop_now_mobile_banner_low_quality,
    shop_now_desktop_banner_low_quality,
    home_about_us_section_image,
    shop_now_v3_banner_image_mobile,
    shop_now_v3_banner_image_desktop,
    header_account_img,
    header_cart_img,
    header_search_img
}