import React, { useEffect, useState } from 'react'
import styles from './SectionBlog.module.css'
import userServices from '../../../../../services/user-services'
import { useNavigate } from 'react-router-dom'

const SectionBlog = () => {

  const [blogs, setBlogs] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    fetchBlogs()
  }, [])

  const fetchBlogs = async () => {
    const { data } = await userServices.fetchAlBlogs(10, 1)
    setBlogs(data.data)
  }
  const handleNavigate = (id) => {
    navigate(`/blog/${id}`)
  }

  if (blogs?.length < 1) {
    return
  }

  return (
    <div className={styles.container} >
      <div className={styles.content_wrapper}>
        <div >
        <h2 className={styles.heading} >
          <strong>From the blog</strong>
        </h2>
        <div className={styles.card_wrapper} >
          {
            blogs?.map((blog) => {
              return (
                <div key={blog?.id} className={styles.card} >
                  <img src={blog?.image} loading='lazy' alt="" />
                  <div className={styles.card_bottom}>
                    <h4>{blog?.title}</h4>
                    <p>{blog?.subtitle}</p>
                    <h6 onClick={() => handleNavigate(blog?.pretty_url)} >Continue..</h6>
                  </div>
                </div>
              )
            })
          }
        </div>
        </div>
      </div>
    </div>
  )
}

export default SectionBlog
