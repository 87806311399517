import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userServices from '../services/user-services';
import logger from '../utils/logger';
import { getGuestUserId } from '../utils/util-functions';


export const getCartItems = createAsyncThunk(
    'redux/getCartItems',
    async ({loggedIn=false}) => {
        let response;
        if(!loggedIn) {
            if(getGuestUserId()) {
                response = await userServices.getGuestUserCartItems(getGuestUserId())
                return response
            }
            else {
                throw new Error('No Guest User Id Available')
            }
        }
        else {
            response =await userServices.getUserCartItems()
            return response
        }
    }
)

const initialState = {
    data:[],
    count:0,
    itemRemoved:false,
    error: ''
   
}


const cartSlice = createSlice({
    name: 'cartDetails',
    initialState,
    reducers: {
        resetCart: (state, action) => {
            state.data=[]
            state.count=0
            state.itemRemoved=false
            state.error=''
        }
    },
    extraReducers: (builder) => {
    
        builder.addCase(getCartItems.fulfilled, (state, action) => {

            state.data=action.payload.data;
            state.count=action.payload.count;
            state.itemRemoved=state.itemRemoved?true:action.payload.itemRemoved;
          
        })
        builder.addCase(getCartItems.rejected, (state, action) => {
            state.error = action.error.message;
            logger('./redux/cartReducer', 'getCartItems(): catch1', action.error);
        })
    },
})

export default cartSlice.reducer;
export const {resetCart} = cartSlice.actions;


