import React, { useState } from "react";
import { Desktop, Mobile } from "../../responsive/Responsive";
import downArrow from "../../../../images/downArrow.svg";
import NewButton from "../../atoms/NewButton/NewButton";
import { useNavigate } from "react-router-dom";
import NonprofitSignup from "../NonprofitSignup/NonprofitSignup";

const MakeAnImpactTop = ({desktopContainerStyle, children,btn=true,Q1=true,Q2=true,Q3=true}) => {
    const [q1, setq1] = useState(Q1);
    const [q2, setq2] = useState(Q2);
    const [q3, setq3] = useState(Q3);
    const [orgSignupModal,setOrgSignupModal] = useState(false)
    const navigate = useNavigate();
    const handleModalClick = () => {
        setOrgSignupModal(true)
    }
    const handleShopNowClick = () => {
        navigate('/shop-now')
    }
    return (
        <>
            <Mobile>
                <div className="mb-lm-container">
                    <div className="mb-ln-qs-container">
                        <h1>3 Ways To Make an Impact</h1>
                        <div className="mb-qs-container">
                            <div className="mb-question">
                                <h5>Explore Our Marketplace</h5>
                                <img
                                    className="impact_uparrow"
                                    style={{transform:q1 && "rotate(180deg)"}}
                                    src={downArrow}
                                    alt="Arrow"
                                    onClick={() => setq1(!q1)}
                                />
                            </div>
                            {q1 && (
                                <div className="mb-ans-container">
                                    <p>
                                        You Shop Therefore You Give. That’s right. The setup is
                                        simple. We are a store built on turning stuff you buy into
                                        fundraising dollars. No round-ups. No 5% goes to charity.
                                        We’re talking minimum 50% and as much as 80% of every dollar
                                        you spend goes to a designated charity. It’s not that it’s
                                        too good to be true, it’s that no one’s thought of this
                                        approach before.
                                    </p>
                          {btn && <NewButton text={"SHOP NOW"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 15px",border:"1px solid #FC3300",fontSize:"12px"}} onClick={handleShopNowClick}/>}
                                </div>
                            )}
                        </div>
                        <div className="mb-qs-container">
                            <div className="mb-question">
                                <h5>Run An Event</h5>
                                <img
                                    className="impact_uparrow"
                                    style={{transform:q2 && "rotate(180deg)"}}
                                    src={downArrow}
                                    alt="Arrow"
                                    onClick={() => setq2(!q2)}
                                />
                            </div>
                            {q2 && (
                                <div className="mb-ans-container">
                                    <p>
                                        Charities large and small use the Boundless platform to run
                                        their online events. Auctions, pop-up stores, tickets to see
                                        “High School Musical” at the high school musical, direct
                                        donations from people who just want to help, community
                                        parties like Mom’s Night Out - you think, Boundless can do
                                        it.
                                    </p>

                                    {btn &&   <NewButton text={"Contact Us To Learn More"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 5px",border:"1px solid #FC3300",fontSize:"12px",width:"100%"}} onClick={handleModalClick}/>}


                                </div>
                            )}

                        </div>
                        <div className="mb-qs-container">
                            <div className="mb-question">
                                <h5>
                                    Run An Event <br />
                                    (with our help)
                                </h5>
                                <img
                                    className="impact_uparrow"
                                    style={{transform:q3 && "rotate(180deg)"}}
                                    src={downArrow}
                                    alt="Arrow"
                                    onClick={() => setq3(!q3)}
                                />
                            </div>
                            {q3 && (
                                <div className="mb-ans-container">
                                    <p>
                                        Putting on your own events is hard. You don’t have the
                                        staff, no one knows how to use a computer, someone donated a
                                        bunch of stuff and your church told you you can’t store in
                                        the office for one more day. It’s too much and you need
                                        help. That’s where Boundless Giving comes in - we don’t just
                                        provided the tech, we help run your event from every
                                        possible angle. Perhaps most importantly, we introduce you
                                        to brands to get you the stuff you need to sell so that you
                                        can start raising beaucoup dolares.
                                    </p>

                                    {btn &&   <NewButton text={"Contact Us To Learn More"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 5px",border:"1px solid #FC3300",fontSize:"12px",width:"100%"}} onClick={handleModalClick}/>}

                                </div>
                            )}

                        </div>
                    </div>
                    {children}
                </div>
            </Mobile>
            <Desktop>
                <div className="des-lm-outer-container " style={{...desktopContainerStyle}}>
                    <div className="des-lm-container margin_class">
                        <h1>3 Ways To Make an Impact</h1>
                        <div className="des-ln-qs-container">
                            <div className="des-qs-container">
                                <div className="des-question">
                                    <p>Explore Our Marketplace</p>
                                    <img
                                        className="impact_uparrow"
                                        style={{transform:q1 && "rotate(180deg)"}}
                                        src={downArrow}
                                        alt="Arrow"
                                        onClick={() => setq1(!q1)}
                                    />
                                </div>
                                {q1 && (
                                    <div className="des-ans-container">
                                        <p className="para_fontsize">
                                            You Shop Therefore You Give. That’s right. The setup is
                                            simple. We are a store built on turning stuff you buy into
                                            fundraising dollars. No round-ups. No 5% goes to charity.
                                            We’re talking minimum 50% and as much as 90% of every
                                            dollar you spend goes to a designated charity. It’s not
                                            that it’s too good to be true, it’s that no one’s thought
                                            of this approach before.
                                        </p>
                                        {btn &&   <NewButton text={"SHOP NOW"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 18px",border:"1px solid #FC3300",fontSize:"18px"}} onClick={handleShopNowClick}/>}

                                    </div>
                                )}
                            </div>
                            <div className="des-qs-container">
                                <div className="des-question">
                                    <p>Run An Event</p>
                                    <img
                                        className="impact_uparrow"
                                        style={{transform:q2 && "rotate(180deg)"}}
                                        src={downArrow}
                                        alt="Arrow"
                                        onClick={() => setq2(!q2)}
                                    />
                                </div>
                                {q2 && (
                                    <div className="des-ans-container">
                                        <p className="para_fontsize">
                                            Charities large and small use the Boundless platform to
                                            run their online events. Auctions, pop-up stores, tickets
                                            to see “High School Musical” at the high school musical,
                                            direct donations from people who just want to help,
                                            community parties like Mom’s Night Out - you think,
                                            Boundless can do it.
                                        </p>
                                        {btn &&   <NewButton text={"Contact Us To Learn More"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 18px",border:"1px solid #FC3300",fontSize:"18px"}} onClick={handleModalClick}/>}


                                    </div>
                                )}
                            </div>
                            <div className="des-qs-container">
                                <div className="des-question">
                                    <p>Run An Event (with our help)</p>
                                    <img
                                        className="impact_uparrow"
                                        style={{transform:q3 && "rotate(180deg)"}}
                                        src={downArrow}
                                        alt="Arrow"
                                        onClick={() => setq3(!q3)}
                                    />
                                </div>
                                {q3 && (
                                    <div className="des-ans-container">
                                        <p className="para_fontsize">
                                            Putting on your own events is hard. You don’t have the
                                            staff, no one knows how to use a computer, someone donated
                                            a bunch of stuff and your church told you you can’t store
                                            in the office for one more day. It’s too much and you need
                                            help. That’s where Boundless Giving comes in - we don’t
                                            just provided the tech, we help run your event from every
                                            possible angle. Perhaps most importantly, we introduce you
                                            to brands to get you the stuff you need to sell so that
                                            you can start raising beaucoup dolares.
                                        </p>

                                        {btn &&   <NewButton text={"Contact Us To Learn More"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 18px",border:"1px solid #FC3300",fontSize:"18px"}} onClick={handleModalClick}/>}


                                    </div>
                                )}
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </Desktop>
            {/* <SignupEmailCampign isOpen={orgSignupModal} onClose={()=>setOrgSignupModal(false)} onSubmitForm={()=>setOrgSignupModal(false)} /> */}
            <NonprofitSignup isOpen={orgSignupModal} onClose={()=>setOrgSignupModal(false)} onSubmitForm={()=>setOrgSignupModal(false)} />
        </>
    )
}

export default MakeAnImpactTop