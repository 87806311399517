import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import classes from "./SubNavbar.module.css"
import {Link, useNavigate, useLocation} from "react-router-dom"
import { auctionSearch, resetAuction } from '../../../../redux/searchReducer'
import { useDispatch, useSelector } from 'react-redux'
import { clearPageNumber } from '../../../../redux/itemsReducer';
import { setFrom } from '../../../../redux/eventReducer';
import { Desktop, Mobile } from '../../../components/responsive/Responsive';
import MenuIcon from '@mui/icons-material/Menu';
import { AnimatePresence, motion } from 'framer-motion';

const matchPath = (currPath, path) => {
  if(path === '/get-started' && currPath.startsWith("/get-started")) return true
  else return false
}

const SubNavbar = ({style={}, from=''}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const [noResult, setNoResult] = useState(false);
  const [searchTerm, setSearchTerm] = useState("")
  const [searching, setIsSearching] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)


  const initiateSearch = async (searchTerm) => {
    let marketPlace= !location.state?.auction?true:false
    dispatch(auctionSearch({ searchTerm, variable: 'itemsList',marketPlace }))
    dispatch(auctionSearch({ searchTerm, variable: 'auctionList' }))
    setNoResult(!noResult);
  };

  const { itemsList, auctionList } = useSelector(state => state.search)

  useEffect(() => {
    if(searching) {
        setNoResult(false);
        setIsSearching(false)
        navigate("/search-result", {state: {search: searchTerm,marketPlace:!location.state?.auction?true:false}});
        setSearchTerm("")
    }
  }, [itemsList, auctionList])

  const handleClick = (e) => {
    if (e.key === "Enter" && e.target.value.length >= 2){
      setIsSearching(true)
      initiateSearch(e.target.value)
    };
  };

  const handleNavigate = () => {
    if(from === 'home'){
      dispatch(setFrom({from:'home'}))
    }
    navigate("/auction?type=current")
  }

  return (
    <>
    <Desktop>
    <div className={classes.dsub_navbar} style={{...style}}>
    <div className={classes.dsub_nav_links}>
    <Link target= "_self" to="/auction?type=current"  rel='noopener noreferrer' style={{color: "inherit"}}>EVENTS</Link>
      {/* <div onClick={handleNavigate}>EVENTS</div> */}
      <div onClick={() =>{ 
        dispatch(clearPageNumber());
        navigate("/browse-items", {
          state: { buyNow: true, title: "Buy now", from: 'buyNow' },
        })}
      } style={{color: style?.color}}>SHOP NOW</div>
      <div onClick={() =>{
        dispatch(clearPageNumber());
        navigate("/browse-items", {
          state: { title: "Auctions", from: 'auction' },
        })}
      }>AUCTIONS</div>
      
      {/* <Link target= "_self" to={{pathname:"/browse-items",state:{ buyNow: true, title: "Buy now", from: 'buyNow'}}}  rel='noopener noreferrer' style={{color: "inherit"}}>SHOP NOW</Link>
      <Link target= "_self" to="/browse-items" state={ {title: "Auctions", from: 'auction'} } rel='noopener noreferrer' style={{color: "inherit"}}>AUCTIONS</Link> */}
      
      {/* <Link target={(matchPath(location.pathname,"/get-started")) ? "_self" : "_blank"} to="/get-started" rel='noopener noreferrer' style={{color: "inherit"}}>CREATE AN EVENT</Link> */}
      <Link to="/create-event" rel='noopener noreferrer' style={{color: "inherit"}}>CREATE AN EVENT</Link>
      <Link target= "_self" to="/brand-partner" rel='noopener noreferrer' style={{color: "inherit"}}>BECOME A BRAND PARTNER</Link>
    </div>
    <div>
      <div className={classes.dsearch_container}>
        <input onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} onKeyDown={handleClick} type="text" placeholder="Search" />
        <SearchIcon />
      </div>
    </div>
  </div>
  </Desktop>

  {/* //// for mobile version */}

    {/* <Mobile> */}
   <div  className={classes.mobile_wrapper}>
    <div onClick={() => setMobileMenu(!mobileMenu)}className={classes.menu_icon} >
      <MenuIcon sx={{zIndex:90}}/>
    </div>    
    <div>
 <AnimatePresence>
    { mobileMenu ?
     <motion.div transition={{ duration: 0.2 }} initial={{ height: 0 }} animate={{ height: 300 }} exit={{ height: 0 }} className={classes.mobile_nav_links_animater}>
      <div className={classes.mobile_nav_links}>
      <Link target= "_self" to="/auction?type=current"  rel='noopener noreferrer' style={{color: "inherit"}}>EVENTS</Link>
      {/* <div onClick={handleNavigate}>EVENTS</div> */}
      <div onClick={() =>{ 
        dispatch(clearPageNumber());
        navigate("/browse-items", {
          state: { buyNow: true, title: "Buy now", from: 'buyNow' },
        })}
      } style={{color: style?.color}}>SHOP NOW</div>
      <div onClick={() =>{
        dispatch(clearPageNumber());
        navigate("/browse-items", {
          state: { title: "Auctions", from: 'auction' },
        })}
      }>AUCTIONS</div>
      
      {/* <Link target= "_self" to={{pathname:"/browse-items",state:{ buyNow: true, title: "Buy now", from: 'buyNow'}}}  rel='noopener noreferrer' style={{color: "inherit"}}>SHOP NOW</Link>
      <Link target= "_self" to="/browse-items" state={ {title: "Auctions", from: 'auction'} } rel='noopener noreferrer' style={{color: "inherit"}}>AUCTIONS</Link> */}
      
      {/* <Link target={(matchPath(location.pathname,"/get-started")) ? "_self" : "_blank"} to="/get-started" rel='noopener noreferrer' style={{color: "inherit"}}>CREATE AN EVENT</Link> */}
      <Link to="/create-event" rel='noopener noreferrer' style={{color: "inherit"}}>CREATE AN EVENT</Link>
      <Link target= "_self" to="/brand-partner" rel='noopener noreferrer' style={{color: "inherit"}}>BECOME A BRAND PARTNER</Link>
      <Link target= "_self" to="/blog" rel='noopener noreferrer' style={{color: "inherit"}}>BLOG</Link>
      <div className={classes.dsearch_container}>
        <input onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} onKeyDown={handleClick} type="text" placeholder="Search" />
        <SearchIcon />
      </div>
      </div>
    </motion.div> : null}
  </AnimatePresence>
    </div>
    {mobileMenu && <div onClick={() => setMobileMenu(false)} className={classes.for_menu_close} />}
    {/* </Mobile> */}
    </div>
  </>
  )
}

export default SubNavbar