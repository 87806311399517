import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import home_selected from "../../images/home_selected.png";
import auction_selected from "../../images/auction_selected.png";
import search_selected from "../../images/search_selected.png";
import account_selected from "../../images/account_selected.png";

import home from "../../images/home.png";
import auction from "../../images/auction.png";
import search from "../../images/search.png";
import account from "../../images/account.png";
import {Mobile} from "./responsive/Responsive"

import "./Footer.css"

function Footer() {
  const [selected, setSelected] = useState(0);
  const [navLinks, setNavLinks] = useState([]);
  const location = useLocation();
  const footerWhitelist = [
    // "/", 
    // "/search",  
    // "/account", 
    // "/auction",
    // "/about-cause",

    // "/browse-items",
    "/bid-edit-confirmation",
    // "/lot",
    // "/history",
    // "/my-bids",
    // "/order/track",
    // '/event',
    // "/cart",
    // "/coming-soon"
  ];

  useEffect(() => {
    if (location.pathname === "/") setSelected(0);
    else if (location.pathname === "/auction") setSelected(1);
    else if (location.pathname === "/search") setSelected(2);
    else if (location.pathname === "/account") setSelected(3);
    else setSelected(-1);
  }, [location]);

  useEffect(() => {
    const newNavLinks = [
      {
        name: "Home",
        href: "/",
        icon: selected === 0 ? home_selected : home,
      },
      {
        name: "Events",
        href: "/auction",
        icon: selected === 1 ? auction_selected : auction,
      },
      {
        name: "Search",
        href: "/search",
        icon: selected === 2 ? search_selected : search,
      },
      {
        name: "Account",
        href: "/account",
        icon: selected === 3 ? account_selected : account,
      },
    ];

    setNavLinks(newNavLinks);
  }, [selected]);

  const GeneralNavBar = () => {
    return (
      <Mobile>
        <div className="navbar-fixed-bottom">
          <ul>
            {navLinks.map((navLink, idx) => (
              <li key={idx}>
                <Link
                  to={navLink.href}
                  className="footerLink"
                  onClick={() => {
                    setSelected(idx);
                  }}
                  key={idx}
                >
                  <img src={navLink.icon} alt="home" />
                  <p className={selected === idx ? "selected" : ""}>
                    {navLink.name}
                  </p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Outlet />
      </Mobile>
    );
  };

  if (footerWhitelist.some(item => location.pathname.includes(item)))
  //if(["/", "/search", "/account", "/auction"].includes(location.pathname))
    return <GeneralNavBar />;
  // else if (["/lot"].includes(location.pathname)) 
  //   return <LotNavBar />;
  // else if (["/bid-confirmation"].includes(location.pathname))
  //   return <BidConfNavBar />;
  else return <Outlet />;
}

export default Footer;
