import React from 'react';
import style from './Section4.module.css';


function Section4({fundraise}) {
    return (
        <section className={style.section4}>
            <div>
                <p className={style.p1}><strong>Let's make an impact together</strong></p>
                <p className={style.p2}>What kind of cause will you support with your fundraising efforts?</p>
                <div className={style.image_container}>
                    <div>
                        <img src='https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6419bece3394f6e1f874b8b5.jpeg' alt='img'/>
                        <p>Animals</p>
                    </div>
                    <div>
                        <img src='https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6419bec33394f605b474b8b2.jpeg' alt='img'/>
                        <p>Arts</p>
                    </div>
                    <div>
                        <img src='https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6419beceb9269f33f9de4d3a.jpeg' alt='img'/>
                        <p>Culture</p>
                    </div>
                    <div>
                        <img src='https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6419bec3be568bcec1c206bd.jpeg' alt='img'/>
                        <p>Education</p>
                    </div>
                    <div>
                        <img src='https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6419bec3efca2358353adf33.jpeg' alt='img'/>
                        <p>And More</p>
                    </div>

                </div>
                <p className={style.p3}>It takes less than ten minutes to create your first event. Start fundraising for your cause today.</p>
                <div className={style.button_container}>
                    <button onClick={fundraise}>CREATE YOUR EVENT</button>
                </div>
            </div>
        </section>
    )
}

export default Section4