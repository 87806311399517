import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classes from './MobileBanner.module.css'
// import bannerImage from '../../../../../../images/newHomeImages/mobileBanner2.svg'
// import shopNowFallbackDesktop from '../../../../../../images/shopNowFallBackImage/shopNowFallbackDesktop.svg'
import NewButton from '../../../../atoms/NewButton/NewButton';
import ImageSkelton from '../../../../molecules/ImageSkelton/ImageSkelton';
import { Desktop, Mobile } from '../../../../responsive/Responsive';
import { imageUrls } from '../../../../../../constants/url';
import SectionContainer from '../../../../molecules/SectionContainer/SectionContainer';
import SwiperComponent from '../../../../molecules/SwiperComponent/SwiperComponent';
import { SwiperSlide } from 'swiper/react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const bannerImage = imageUrls.shop_now_fallBack_mobile;
const bannerImageLowQuality = imageUrls.shop_now_fallBack_mobile_low_qaulity;
const shopNowFallbackDesktop = imageUrls.shop_now_fallBack_desktop;
const shopNowFallbackDesktopLowQuality = imageUrls.shop_now_fallBack_desktop_low_quality;
const MobileBanner = ({ mobileStyle }) => {
    const [bannerLoading, setBannerLoading] = useState(true)
    const [bannerPreviewLoading, setBannerPreviewLoading] = useState(true)
    // let color = mobileStyle?.color[1];
    // const navigate = useNavigate()
    // const handleClick = () => {
    //     navigate('/shop-now')
    // }
    return (
        // <section style={{position:"relative"}} >
        //     <Desktop>
        //        <img onLoad={() => setBannerLoading(false)}  src={shopNowFallbackDesktop} alt="Shopping For Good" className={classes.banner_image_desktop} style={{display: bannerLoading ? 'none' : 'block'}} />
        //        <img onLoad={() => setBannerPreviewLoading(false)}  src={shopNowFallbackDesktopLowQuality} alt="Shopping For Good" className={classes.banner_image_desktop} style={{display: (bannerPreviewLoading ||!bannerLoading ) ? 'none' : 'block'}} />
        //        <span><ImageSkelton width='100%' loadingState={bannerPreviewLoading} style={{ marginInline:'auto', marginTop:'10%', minHeight:'80vh'}}/></span>
        //     </Desktop>
        //     <Mobile>
        //        <img onLoad={() => setBannerLoading(false)}  src={bannerImage} alt="Shopping For Good" className={classes.banner_image} style={{display: bannerLoading ? 'none' : 'block'}} />
        //        <img onLoad={() => setBannerPreviewLoading(false)}  src={bannerImageLowQuality} alt="Shopping For Good" className={classes.banner_image} style={{display: (bannerPreviewLoading ||!bannerLoading )   ? 'none' : 'block'}} />
        //        <span><ImageSkelton width='100%' loadingState={bannerPreviewLoading} style={{ marginInline:'auto', marginTop:'23%', minHeight:'80vh'}}/></span>
        //     </Mobile>
        //     <span className={classes.shade} style={{margin:"0px !important"}}></span>
        //     <div className={`${classes.banner_container} margin_class`} style={{ display:(bannerLoading && bannerPreviewLoading) ? 'none' : 'block'}}>
        //         <div className={classes.banner_content} >
        //             <p className={classes.main_heading} style={{color:color}} >
        //                <Mobile> Where your <br /> heart and <br /> your wallet <br /> both find themselves in the right place.</Mobile>
        //                <Desktop>Where your heart <br /> and your wallet both <br /> find themselves in <br /> the right place.</Desktop>
        //                 </p>
        //              <NewButton text={'SHOP NOW'} onClick={handleClick} color={color} />
        //         </div>
        //     </div>
        // </section>
        <SwiperComponent spaceBetween={0}>
            <SwiperSlide>
                <section className={classes.bannerMain}>
                    <Desktop>
                        <img onLoad={() => setBannerLoading(false)} src={shopNowFallbackDesktop} alt="Shopping For Good" className={classes.banner_image} style={{ display: bannerLoading ? 'none' : 'block' }} />
                        <img onLoad={() => setBannerPreviewLoading(false)} src={shopNowFallbackDesktopLowQuality} alt="Shopping For Good" className={classes.banner_image} style={{ display: (bannerPreviewLoading || !bannerLoading) ? 'none' : 'block' }} />
                        <span><ImageSkelton width='100%' loadingState={bannerPreviewLoading} style={{ marginInline: 'auto', minHeight: '500px' }} /></span>
                    </Desktop>
                    <Mobile>
                        <img onLoad={() => setBannerLoading(false)} src={bannerImage} alt="Shopping For Good" className={classes.banner_image} style={{ display: bannerLoading ? 'none' : 'block' }} />
                        <img onLoad={() => setBannerPreviewLoading(false)} src={bannerImageLowQuality} alt="Shopping For Good" className={classes.banner_image} style={{ display: (bannerPreviewLoading || !bannerLoading) ? 'none' : 'block' }} />
                        <span><ImageSkelton width='100%' loadingState={bannerPreviewLoading} style={{ marginInline: 'auto', marginTop: '23%', minHeight: '617px' }} /></span>
                    </Mobile>
                    <span className={classes.shade} style={{ margin: "0px !important" }}></span>
                    <section className={classes.banner_container}>
                        <SectionContainer sectionBg='transparent' childClass="bannerHeroText">
                            <div className={classes.banner_content} >
                                <p className={classes.main_heading} >
                                    {/* Where your heart and your wallet both find themselves in the right place. */}
                                    Where 50-80% of all sales go directly to charity
                                </p>
                                <p className={classes.sectionPara}>
                                    {/* <br className={classes.subHeadingSeperator} />  */}
                                    Don’t just shop. Shop generously.
                                </p>
                                <Link to={'/shop-now'} ><NewButton text={'SHOP NOW'} className='homeBanner' /></Link>
                            </div>
                        </SectionContainer>
                    </section>
                </section>
            </SwiperSlide>
            <Desktop>
                <div className={`${classes.bannerPagination}`}>
                    <button className={classes.bannerNavigationButton} id='swiper-back' ><ArrowBack className={classes.navigateArrow} /></button>
                    <button className={classes.bannerNavigationButton} id='swiper-forward'><ArrowForward className={classes.navigateArrow} /></button>
                </div>
            </Desktop>
            <Mobile>
                <div id='pagination-el' className={`${classes.bulletsContainer} bannerBulletContainer`} ></div>
            </Mobile>
        </SwiperComponent>
    )
}

export default MobileBanner