import { Helmet } from "react-helmet-async";
import BrowseItemsBannerV2 from "../../../atoms/BrowseItemsBanner/BrowseItemsBannerV2/BrowseItemsBannerV2";
import HeaderV3 from "../../../molecules/Header/HeaderV3/HeaderV3";
import SectionContainer from "../../../molecules/SectionContainer/SectionContainer";
import { config } from "../../../../../config/config";
import classes from './ShopNowV3.module.css';
import { imageUrls } from "../../../../../constants/url";
import { useMediaQuery } from "react-responsive";
import BrowseByDropDown from "../../../atoms/BrowseByDropDown/BrowseByDropDown";
// import PaginationButtons from "../../../atoms/PaginationButtons/PaginationButtons";
import { CloseRounded, FilterList, SyncAlt } from "@mui/icons-material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Desktop, Mobile } from "../../../responsive/Responsive";
import { useState, useEffect,useRef } from "react";
import auctionServices from "../../../../../services/auction-services";
import donorServices from "../../../../../services/donor-services";
import { fetchCausesHaveEvents } from '../../../../../redux/causeReducer';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllFilter, clearFilter, fetchShopNowProducts, setPage, setShopNowSearchText } from "../../../../../redux/shopNowFilterReducer";
import NewProductCard from "../../../molecules/NewProductCard/NewProductCard";
import { CircularProgress, Pagination } from "@mui/material";
import LoadingScreen from "../../../LoadingScreen/Loading";
import NewFooterV2 from "../../../molecules/NewFooter/NewFooterV2/NewFooterV2";
import SwipableDrawerComponent from "../../../atoms/SwipableDrawerComponent/SwipableDrawerComponent";
import ShopNowSortScreen from "../../../molecules/ShopNowSortAndFilterScreenScreen/ShopNowSortScreen";
import ShopNowFilterScreenMobile from "../../../molecules/ShopNowSortAndFilterScreenScreen/ShopNowFilterScreenMobile";
import PaginationV2 from "../../../molecules/PaginationV2/PaginationV2";
import SearchInput from "../../../molecules/SearchInput/SearchInput";
const { REACT_APP_URL } = config
const { shop_now_v3_banner_image_desktop, shop_now_v3_banner_image_mobile } = imageUrls
const ShopNowV3 = () => {
    let image = shop_now_v3_banner_image_desktop;
    const isMobile = useMediaQuery({
        maxWidth: 768
    })
    const is1100 = useMediaQuery({
        minWidth: 1100
    })
    let grid = { rows: 4, fill: 'row' }
    if (isMobile) {
        image = shop_now_v3_banner_image_mobile
        grid = { rows: 1, fill: 'row' }
    }
    const dispatch = useDispatch()
    const scrollContainerRef = useRef();
    const [allCategories, setAllCategories] = useState([])
    const [allBrands, setAllBrands] = useState([])
    const [showSortScreen, setShowSortScreen] = useState(false)
    const [sortScreenType, setSortScreenType] = useState('sortScreen')
    const { causes, loading } = useSelector(state => state.causesHaveEvents);
    const { selectedFilters, selectedFiltersObj, products, totalPage, totalCount, isShopNowLoading, page, selectedSortValue, searchText } = useSelector(state => state.shopNow);
    const [isLoading, setIsLoading] = useState(false)
    let limit = 60;
    if(isMobile) {
        limit = 50;
    }
    let scroller;
    useEffect(() => {
        getAllCategories()
        getAllBrands()
        dispatch(fetchCausesHaveEvents({ page: 1, limit: 100, from: "shop-now" }))
        return () => {
            clearTimeout(scroller)
        }
    }, [])
    useEffect(() => {
        if (products?.length > 0) {
            scrollToPosition()
        }
    },[products])
    useEffect(() => {
        dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName:true, searchText:searchText }))//filterByName is for fetching products with same custom_category names.
    }, [selectedFilters, selectedSortValue])
    const scrollToPosition = () => {
        const pos = JSON.parse(localStorage.getItem("browseScroll"))
        if (pos && !isShopNowLoading) {
            scroller = setTimeout(() => {
                window.scrollTo({ top: pos, behavior: "smooth" })
                localStorage.removeItem("browseScroll")
            }, 500)
        }
    }
    const getAllCategories = async () => {
        try {
            setIsLoading(true)
            const data = await auctionServices.getAllCategoriesAndCustomCategories(true, true)// First true is for sending showEvery where query. This will check the status show_everywhere in custom_categories. Second true is for grouping custom category by name.
            setAllCategories(data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }
    const getAllBrands = async () => {
        try {
            setIsLoading(true)
            const { data } = await donorServices.featuredDonors()
            setAllBrands(data?.donor)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const handleClearRemoveFromFilter = (value, arrayType) => {
        if (arrayType === 'all') {
            dispatch(clearAllFilter())
        }
        else {
            dispatch(clearFilter({ key: arrayType, value: value }))
        }
    }

    const checkFiltersPresent = () => {
        let filtersPresent = false
        for (let key in selectedFilters) {
            if (selectedFilters[key]?.length) {
                filtersPresent = true;
                break;
            }
        }
        return filtersPresent;
    }

    const handleShowSort = (screenType = '') => {
        setShowSortScreen(!showSortScreen)
        setSortScreenType(screenType)
    }

    const loadMore = (event, newPage) => {
        window.scrollTo({ top: 500, behavior: "smooth" });
        dispatch(setPage({ page: newPage }))
        dispatch(fetchShopNowProducts({ page: newPage, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName:true, searchText:searchText  }))
    }

    const checkAddedProduct = (item) => {
        let value = false
        if(selectedFilters?.causes?.length && item?.auction_auction_item_xrefs?.length) {
            for(let xrefObj of item?.auction_auction_item_xrefs) {
                if(selectedFilters?.causes?.includes(xrefObj?.auction?.cause?.id)) {
                    value = true;
                    break
                }
            }
        }
        return value;
    }

    const handleSearchChange = (e) => {
        dispatch(setShopNowSearchText({searchText:e.target.value}))
    }

    const onIconClick = () => {
        onSearchClick(null, true)
    }

    const onSearchClick = (e, clickByIcon=false) => {
        if ((e?.key === "Enter" || clickByIcon) && searchText?.length > 2) {
            dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName:true, searchText:searchText  }))
        }
    }

    const clearSearchData = () => {
        if(!searchText && !searchText?.length) return;
        dispatch(setShopNowSearchText({searchText: ""}))
        dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName:true }))
    }

    const scrollFilters = ()=>{
        if(scrollContainerRef.current) scrollContainerRef.current.scrollLeft+=90
    }

    return (
        <div>
            <Helmet prioritizeSeoTags>
                <title>Charitable Shopping For Goods at Boundless Giving</title>
                <meta
                    name="description"
                    content="At Boundless Giving, explore the range of charity shop fundraising ideas where 50-80% of all proceeds from charitable shopping for goods go directly to charity."
                />
                <meta
                    name="keywords"
                    content="Shopping For Good"
                />
                <link rel="canonical" href={`${REACT_APP_URL}/shop-now`} />
            </Helmet>
            {
                isLoading ? <LoadingScreen bgColor="var(--red-color)" /> :
                    <>
                        <HeaderV3 />
                        <BrowseItemsBannerV2 image={image} >
                            <div className={classes.bannerContentContainer}>
                                <h1 className={classes.bannerHeading} >Boundless Shop</h1>
                                <p className={classes.bannerPara} >50-80% of all proceeds from purchases go directly to charity.</p>
                            </div>
                        </BrowseItemsBannerV2>

                        <SectionContainer childClass={'paddingBottomZero'}>
                            <div className={classes.headerContainer}>
                                <div className={classes.searchBarMobile}>
                                    <SearchInput 
                                        // fullWidth={false} 
                                        outlinedInputStyle={{padding: "5px 12px"}}
                                        onChange={handleSearchChange} 
                                        onClick={onSearchClick} 
                                        value={searchText} 
                                        onIconClick={onIconClick}
                                        clearSearchData={clearSearchData}
                                        needToClear={true}
                                    />
                                </div>
                                <div className={classes.filtersSection} >
                                    {/* <Desktop> */}
                                        <div className={classes.filters} >
                                            {/* <h2 className={`newTitleClass ${classes.filterHeading}`} >SHOP BY</h2> */}
                                            <div ref={scrollContainerRef} className={classes.filterButtons} style={{scrollBehavior:'smooth'}} >
                                              <BrowseByDropDown filterArray={allCategories} filterType={"categoryFilter"} dropDownText={"CATEGORY"} selectedFilters={selectedFilters} />
                                              <BrowseByDropDown filterArray={allBrands} filterType={"brandsFilter"} dropDownText={"BRANDS"} selectedFilters={selectedFilters} />
                                              <BrowseByDropDown filterArray={causes} filterType={"causeFilter"} dropDownText={"CAUSES"} selectedFilters={selectedFilters} />
                                            </div>
                                            <span className={classes.rightArrow}>
                                                <KeyboardArrowRightIcon  onClick={scrollFilters} />
                                            </span>
                                        </div>
                                    {/* </Desktop> */}
                                    {/* <Mobile>
                                        <button onClick={() => handleShowSort('filterScreen')} className={classes.sortButton} >
                                            <FilterList className={classes.filterIcon} />
                                            FILTER
                                        </button>
                                    </Mobile> */}
                                    <div className={classes.searchAndSortContainer}>
                                       <button onClick={() => handleShowSort('sortScreen')} className={classes.sortButton} >
                                           <SyncAlt className={classes.sortIcon} />
                                           SORT
                                       </button>
                                       <div className={classes.searchBar}>
                                          <SearchInput 
                                            fullWidth={true} 
                                            outlinedInputStyle={{padding: "5px 12px",minWidth:is1100?'210px':'0'}}
                                            onChange={handleSearchChange} 
                                            onClick={onSearchClick} 
                                            value={searchText} 
                                            onIconClick={onIconClick}
                                            clearSearchData={clearSearchData}
                                            needToClear={true}
                                        />
                                       </div>
                                    </div>
                                </div>
                                
                                <hr className={classes.line} />
                                <div className={classes.filtersOutputSection} >
                                    <div className={classes.filterdOutputContainer}>
                                        <div className={classes.filterdOutputs} >
                                            {
                                                selectedFiltersObj?.category?.map((obj) => (
                                                    <div key={obj.id} className={classes.outPut}><span className={classes.outSpan}>{obj?.name}</span> <CloseRounded className={classes.clearSingleIcon} onClick={() => handleClearRemoveFromFilter(obj, 'category')} /></div>
                                                ))
                                            }
                                            {
                                                selectedFiltersObj?.customCategory?.map((obj) => (
                                                    <div key={obj.id} className={classes.outPut}><span className={classes.outSpan}>{obj?.name}</span> <CloseRounded className={classes.clearSingleIcon} onClick={() => handleClearRemoveFromFilter(obj, 'customCategory')} /></div>
                                                ))
                                            }
                                            {
                                                selectedFiltersObj?.brands?.map((obj) => (
                                                    <div key={obj.id} className={classes.outPut}><span className={classes.outSpan}>{obj?.name}</span> <CloseRounded className={classes.clearSingleIcon} onClick={() => handleClearRemoveFromFilter(obj, 'brands')} /></div>
                                                ))
                                            }
                                            {
                                                selectedFiltersObj?.causes?.map((obj) => (
                                                    <div key={obj.id} className={classes.outPut}><span className={classes.outSpan}>{obj?.name}</span> <CloseRounded className={classes.clearSingleIcon} onClick={() => handleClearRemoveFromFilter(obj, 'causes')} /></div>
                                                ))
                                            }
                                            {checkFiltersPresent() && <button onClick={() => handleClearRemoveFromFilter(null, 'all')} className={classes.clearAllButton}><span>CLEAR ALL</span></button>}
                                        </div>
                                    </div>
                                    <Desktop>
                                        <p className={classes.itemNumberPara} >Showing {totalCount} products.</p>
                                    </Desktop>
                                </div>
                            </div>

                            {/* Products section start */}
                            {
                                isShopNowLoading ? <div className={classes.progressContainer}><CircularProgress sx={{ color: 'var(--red-color)' }} /></div> :
                                products?.length === 0 ? <div className={classes.noProductsContainer}><h3 className={classes.noProductsText}>Coming Soon</h3></div> :
                                    <div className={classes.productsSection}>
                                        <div className={classes.productsGridContainer} >
                                            {
                                                products?.map((item) => {
                                                    return (
                                                        <NewProductCard
                                                         item={item?.auction_item} 
                                                         imageContainerClass='shopNowImageContainerClass' 
                                                         customItemNameClass="shopNowItemName" 
                                                         cardContainerClass="shopNowCardContainer"
                                                         needXref={checkAddedProduct(item?.auction_item)}
                                                         key={item?.id}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                        <PaginationV2 customContainerClass={classes.shopNowPagination} totalPage={totalPage} page={page} onLoadMore={loadMore} />
                                        <hr className={classes.productSectionLine}/>
                                    </div>
                            }
                            {/* Products section end */}
                        </SectionContainer>
                    </>
            }
            <SwipableDrawerComponent open={showSortScreen} setOpen={setShowSortScreen} >
                {
                    sortScreenType === "filterScreen" ?
                        // <Mobile>
                        //     <ShopNowFilterScreenMobile handleShowSort={handleShowSort} categories={allCategories} brands={allBrands} causes={causes} />
                        // </Mobile>
                        null
                        :
                        <ShopNowSortScreen handleShowSort={handleShowSort} />
                }
            </SwipableDrawerComponent>
            {!isLoading && <NewFooterV2 />}
        </div>
    )
}

export default ShopNowV3;