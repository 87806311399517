import React, { useContext, useEffect, useState } from 'react'
import classes from './ShopNow.module.css'
import { mobileStyle } from '../../Home'
import HeaderV2 from '../../molecules/Header/HeaderV2';
import BrowseItemUpperHalf from '../../molecules/BrowseItemUpperHalf/BrowsItemUpperHalf';
import { useMediaQuery } from 'react-responsive';
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import ShopSection from '../../molecules/ShopSection/ShopSection';
import { useNavigate } from 'react-router-dom';
import { auctionSearch } from '../../../../redux/searchReducer';
import { useDispatch } from 'react-redux';
import donorServices from '../../../../services/donor-services';
import logger from '../../../../utils/logger';
import { fetchFeaturedItems } from '../../../../redux/featuredItemsReducer';
import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import CauseContext from '../../../../store/cause-context';
import AuctionContext from '../../../../store/auction-context';
import ShopNowFeaturedItems from '../../molecules/ShopNowFeaturedItems/ShopNowFeaturedItems';
import DesktopCardLayout from '../../atoms/DesktopCardLayout/DesktopCardLayout';
import { config } from '../../../../config/config';
import NewFooter from '../../molecules/NewFooter/NewFooter';

const ASSETS_BASE_URL = config.ASSETS_BASE_URL
const desktopImage = `${ASSETS_BASE_URL}/User_2/Auction/7edeaa83b39d6f366a86fef92ba45fddfe0c722db4a567d2f487ebc0701ababe`
const mobileImage = `${ASSETS_BASE_URL}/User_2/Auction/0c177c15e5412fab722bfd96f82bece28dc91e65d8f4a09240b6d2cee1096f88`
const ShopNow = () => {
    const primaryBackgroundColor = mobileStyle.backgroundColor[2];
    const primaryColor = mobileStyle.color[1];
    const isDesktop = useMediaQuery({ minWidth: 768 })
    const auctionCxt = useContext(AuctionContext);
    const causeCtx = useContext(CauseContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const location = useLocation();
    const [searchText, setSearchText] = useState('');
    const [featuredDonors, setFeaturedDonors] = useState([])
    // const { featuredItems } = useSelector(state => state.featuredItems)

    const params = {
        from: 'shop-now',
        image: isDesktop ? desktopImage : mobileImage,
        heading: 'Boundless Shop',
        about: '50-80% of all proceeds from purchases go directly to charity.',
    }

    const getFeaturedDonors = async () => {
        try {
            const data = await donorServices.featuredDonors();
            setFeaturedDonors(data?.data?.donor)
        } catch (error) {
            logger('./pages/components/ShopNow.jsx', 'getFeaturedDonors(): catch', error);
            //   setIsLoading(false);
        }
    }
    useEffect(() => {
        getFeaturedDonors()
    }, [])


    const initiateSearch = async (searchTerm) => {
        dispatch(auctionSearch({ searchTerm, variable: 'itemsList' }))
        dispatch(auctionSearch({ searchTerm, variable: 'auctionList' }))
        navigate("/search", { state: { search: searchText } });
    };

    const handleCauseClick = (causeData) => {
        // navigate("/browse-causes", {
        //     state: {
        //         cause_category_id: causeData?.id,
        //         title: causeData?.name,
        //         from: 'causeCategory',
        //         image: require(`../../../../images/causeIcons/1200x480/${causeData.image}`)
        //     },
        // })
        navigate("/browse-causes")
    }

    const handleChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleClick = (e) => {
        if (e.key === "Enter" && e.target.value.length >= 2) initiateSearch(e.target.value);
        if (!e.key && searchText.length >= 2) initiateSearch(searchText);
    };

    useEffect(() => {
        dispatch(fetchFeaturedItems({ limit: 15, page: 1 }))
    }, [])

    const handleCardClick = (donor) => {
        // navigate(`/marketplace/${donor?.auctions[0]?.custom_url ? donor?.auctions[0]?.custom_url : donor?.auctions[0]?.id}`,
        //     { state: { auction: donor?.auctions[0], image: donor?.auctions[0].image2, title: donor?.auctions[0].title, from: 'donor', market_place: true } })
        navigate(`/marketplace/${donor?.auctions[0]?.custom_url ? donor?.auctions[0]?.custom_url : donor?.auctions[0]?.id}`)
    }

    const handleCategoryClick = (category) => {
        // navigate("/browse-items", {
        //     state: {
        //         auction_category_id: category?.id,
        //         title: category?.name,
        //         from: 'auctionCategory',
        //         image: require(`../../../../images/categoryIcons/1200x480/${category?.image}`)
        //     },
        // })
        navigate(`/${category?.id}/browse-items?item-type=browse-category`)
    }
    const specialArray = []
    // const specialArray = [{id: 1, name:'brand1'}, {id: 2, name:'brand2'}, {id: 3, name:'brand3'}, {id: 4,name:'brand4'}, {id: 5,name: 'brand5'}, {id: 6,name: 'brand6'}, {id: 7, name:'brand7'}, {id: 8, name:'brand8'}, {id: 9,name:'brand9'}, {id: 10,name:'brand10'}]

    return (
        <>
            <div style={{ backgroundColor: primaryBackgroundColor, minHeight: '100vh' }} >
                <HeaderV2
                    logoType={(isDesktop) ? 'black' : 'black2'}
                    primaryBackgroundColor={primaryBackgroundColor}
                    primaryColor={primaryColor}
                    transparentNav
                    containerStyle={{ position: 'fixed' }}
                />
                <div className={`${classes.shop_now_container}`}>
                    <BrowseItemUpperHalf params={params} />
                    <div className={`${classes.shop_now_bottom_container}`} >
                        <div className={`${classes.mobile_padding_container} ${classes.section1_container}  margin_class`} >
                            <FormControl variant="outlined" fullWidth>
                                <OutlinedInput
                                    placeholder="Search"
                                    sx={{
                                        padding: '2px 10px',
                                        height: isDesktop ? '50px':"40px",
                                        borderRadius: "5px",
                                        border: `1px solid ${primaryColor}`,
                                        color: primaryColor,
                                        fontSize: isDesktop ? '18px' : '14px',
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        fontFamily: 'Instrument Sans',
                                        flexDirection: 'row-reverse'
                                    }}
                                    id="search-text-search-page"
                                    fullWidth
                                    onKeyDown={handleClick}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end" >
                                            <SearchIcon sx={{ color: "#30313a7a", fontSize: isDesktop ? '18px' : '14px', fontWeight: 600 }} />
                                            {/* onClick={handleClick} */}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className={`margin_class ${classes.donor_section} ${classes.mobile_padding_container}`}>
                            <h5 style={{ color: mobileStyle.color[1] }} className={classes.donor_heading} >Shop Brands You Love</h5>
                            <div className={classes.donor_container}>
                                {
                                    featuredDonors?.map((donor, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <DesktopCardLayout image={donor?.featured_icon} onCardClick={() => handleCardClick(donor)} 
                                                  mainContainerStyle={{ gap: "10px", minWidth: "196px" }} 
                                                  imageContainerStyle={{padding: '45px', backgroundColor: 'white'}}
                                                  imageStyle={{borderRadius:"0"}}
                                                >
                                                    <div className="cart-card-content">
                                                        <h1 style={{ textAlign: "center", lineHeight:'24px', fontWeight:400 }} >{donor.org_name}</h1>
                                                    </div>
                                                </DesktopCardLayout>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* This commented part is the old shop now section we have, which is not using temparorily */}
                        <>
                            <section className={classes.mobile_padding_container} style={{ backgroundColor: "rgba(212, 180, 184, 0.50)" }}>
                                <ShopNowFeaturedItems />
                            </section>

                            {specialArray?.length ?
                                <section style={{ backgroundColor: primaryColor }} className={`${classes.section3_container} ${classes.mobile_padding_container}`} >
                                    <div className={`${classes.section3_main}`} >
                                        <ShopSection
                                            primaryColor={primaryBackgroundColor}
                                            primaryBackgroundColor={primaryBackgroundColor}
                                            list={specialArray}
                                            // onClick={(data) => handleFeaturedItemClick(data)}
                                            title='Annual Day of Giving'
                                            useFolder={false}
                                        />
                                    </div>
                                </section>
                                : null}

                            {causeCtx?.causeCategoryList?.length ?
                                <section className={classes.section5_container} >
                                    <div className={` margin_class ${classes.section5_main} ${classes.mobile_padding_container}`} >
                                        <ShopSection
                                            primaryColor={primaryColor}
                                            list={causeCtx?.causeCategoryList}
                                            onClick={(data) => handleCauseClick(data)}
                                            imageFolder={'causeIcons'}
                                            title='Browse by Causes You Care About'
                                            from='cause'
                                        />
                                    </div>
                                </section>
                                : null}

                            {auctionCxt?.auctionCategoryList?.length ?
                                <section className={classes.section4_container} >
                                    <div className={` margin_class ${classes.section4_main} ${classes.mobile_padding_container}`} >
                                        <ShopSection
                                            list={auctionCxt?.auctionCategoryList}
                                            onClick={(data) => handleCategoryClick(data)}
                                            imageFolder={'categoryIcons'}
                                            title='Browse by Categories'
                                        />
                                    </div>
                                </section>
                                : null}
                        </>
                    </div>
                </div>
                {/* <MobileSubFooter style={{padding:'6vw 5vw 2vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
                <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />


            </div>
        </>
    )
}

export default ShopNow