import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import userServices from '../services/user-services';
import logger from '../utils/logger';


export const getFavouriteItems = createAsyncThunk(
    'redux/getFavouriteItems',
    async () => {
        const response =await userServices.getUserFavouriteItems()
        return response
    }
)

const initialState = {
    data:[],
    count:0,
    itemRemoved:false,
    loading:false,
    error: ''
}


const favouriteSlice = createSlice({
    name: 'userFavourites',
    initialState,
    reducers: {
        resetFavourites: (state, action) => {
            state.data=[]
            state.count=0
            state.itemRemoved=false
            state.error=''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFavouriteItems.pending, (state, action) => {
            state.data= [];
            // state.count= 0;
            state.itemRemoved= false;
            state.error = '';
            state.loading = true;
          
        })
        builder.addCase(getFavouriteItems.fulfilled, (state, action) => {
            state.data=action.payload.data;
            state.count=action.payload.count;
            state.itemRemoved=state.itemRemoved?true:action.payload.itemRemoved;
            state.loading = false
          
        })
        builder.addCase(getFavouriteItems.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false
            logger('./redux/cartReducer', 'getFavouriteItems(): catch1', action.error);
        })
    },
})

export default favouriteSlice.reducer;
export const {resetFavourites} = favouriteSlice.actions;


