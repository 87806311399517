import React, { useState, useContext, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

// import rightArrow from "../../images/rightArrow.png";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import authService from "../../services/auth-services";
import AuthContext from "../../store/auth-context";
// import GlobalHeader from "./molecules/GlobalHeader";
import ConfirmAlertLogin from "./ConfirmAlertLogin";
import {Helmet} from "react-helmet-async"
// import { Desktop, Mobile } from "./responsive/Responsive";
// import Navbar from "../desktop/components/Navbar/Navbar";
// import SubNavbar from "../desktop/components/SubNavbar/SubNavbar";
// import Header from "../desktop/components/Header/Header";
// import BackContainer from "../desktop/components/BackContainer/BackContainer";
import classes from "../desktop/styles/accountPage.module.css"
// import Footer from "../desktop/components/Footer/Footer";
import userServices from "../../services/user-services";
import { Alert, CircularProgress } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import PrimaryBtn from "./ButtonPrimary";
import { fetchSavedCard,deletePaymentDetails } from "../../services/payment-services";
import ConfirmAlert from "./ConfirmAlert";
// import AccountNavbar from "../desktop/components/AccountNavbar/AccountNavbar";
import HeaderV2 from "./molecules/Header/HeaderV2";
// import SubHeader from "./molecules/SubHeader/SubHeader";
import { useMediaQuery } from "react-responsive";
import NewFooter from "./molecules/NewFooter/NewFooter";
import { mobileStyle } from "./Home";
import {config} from "../../config/config";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";
const {REACT_APP_URL} = config


const DAccount = ({ctx, handleAddShipping}) => {
  const [isLoadingShipping, setIsLoadingShipping] = useState(true)
  const [shippingData, setShippingData] = useState(null)

  useEffect(() => {
    if(ctx?.isLoggedIn) {
      (async () => {
        setIsLoadingShipping(true)
        const { data } = await userServices.getUserShippingAddress(ctx?.userId)
        setShippingData(data)
        setIsLoadingShipping(false)
      })()
    }
  }, [])


  return (
    <>
          {ctx?.isLoggedIn ? (
            <>
              <h5>Account Information</h5>
              <hr />
              <div className={classes.daccount_content_info}>
                <h2>{ctx?.userInfo?.first_name +
                    " " +
                    ctx?.userInfo?.last_name}</h2>
                <h6>{ctx?.userInfo?.email}</h6>
              </div>
              <div className={classes.daccount_content_shipping}>
                  <h5>Shipping Information</h5>
                  <hr />
                  {isLoadingShipping ? <CircularProgress sx={{color: "#1a385c"}} /> : shippingData ? (
                      <div>
                        <div>{`${shippingData?.to_firstname} ${shippingData?.to_lastname}`}</div>
                        <div>{`${shippingData?.address1}`}</div>
                        <div>{shippingData?.city}, {shippingData?.state}, {shippingData?.zip}</div>
                        <button onClick={handleAddShipping} type="button" style={{marginTop: "10px", padding: "5px 10px"}} className={classes.daccount_add_btn}>Edit Shipping Info</button>
                      </div>
                  ) : 
                  <div>
                    <div style={{margin: "15px 0"}}>No Shipping Info found!</div>
                    <button onClick={handleAddShipping} type="button" className={classes.daccount_add_btn}>Add Shipping Information</button>
                  </div>}
              </div>
            </>
          ) : <>
            <Alert severity="warning">Please <Link to="/login">Log In</Link> to continue.</Alert>
          </>}
    </>
  )
}

const DPayment = ({ctx, setOpenConfirmAlert}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [cardDetails, setCardDetails] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    if(!ctx?.isLoggedIn) return 
    (async () => {
      setIsLoading(true)
      try {
         const card = ctx?.cusId &&  await fetchSavedCard(ctx?.cusId, ctx?.cardId)
        if(!card) {
          setCardDetails(null)
        } else {
          setCardDetails({
            name: card.name,
            last4: card.last4,
            brand: card.brand
          })
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setCardDetails(null)
      }
    })()
  }, [isOpen])


  const handlePayment=()=>{
    ctx?.isLoggedIn ? (navigate("/payment",{
      state:{
        type:"add"
      }
    })) : setOpenConfirmAlert(true);
  }

  const handleDeletePayment=()=>{
  setIsOpen(true)
  }
  const confirmDeletePayment=async()=>{
    await deletePaymentDetails()
    setIsOpen(false)
    ctx?.setCardId(null)
    ctx?.setCusId(null)
  }


  return (
    <>
          {ctx?.isLoggedIn ? (
            <>
              <h5>Payment Information</h5>
              <hr />
              <div className={classes.daccount_content_shipping}>
                  {isLoading ? <CircularProgress sx={{color: "#1a385c"}} /> : cardDetails ? (
                    <div className='bag_shipping_content' style={{ fontSize: "1rem" }}>
                      <div>{`${cardDetails.name}`}</div>
                      <div>{`**** **** **** ${cardDetails.last4}`}</div>
                      <div>{cardDetails.brand}</div>
                      <div>
                        <button onClick={handlePayment} type="button" style={{marginTop: "10px", padding: "5px 10px"}} className={classes.daccount_add_btn}>Change Payment Method</button>
                        <button onClick={handleDeletePayment} type="button" style={{marginTop: "10px", padding: "5px 10px"}} className={classes.daccount_add_btn}><DeleteOutlineOutlinedIcon sx={{color:'white'}}/></button>
                      </div>
                    </div>
                  ) : 
                  <div>
                    <div style={{margin: "15px 0"}}>No Payment Info found!</div>
                    <button onClick={handlePayment} type="button" className={classes.daccount_add_btn}>Add Payment Information</button>
                  </div>}
              </div>

              <ConfirmAlert
               alerMsg="Confirm to delete payment details"
               openConfirmAlert={isOpen}
               handleCloseConfirmAlert={()=>setIsOpen(false)}
               handleConfirmTask={confirmDeletePayment}
             />

            </>
          ) : <>
            <Alert severity="warning">Please <Link to="/login">Log In</Link> to continue.</Alert>
          </>}
    </>
  )
}

function Account() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState(state?.from ? state?.from : "info")
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const handleClickHistory = () => {
    authCtx?.isLoggedIn ? navigate("/history") : setOpenConfirmAlert(true);
  };
  const handleClickMyBid = () => {
    authCtx?.isLoggedIn ? navigate("/my-bids") : setOpenConfirmAlert(true);
  };
  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false);
  };

  const handleAddShipping=()=>{
    authCtx?.isLoggedIn ? (navigate("/shipping-info",{
      state:{
        edit:"add"
      }
    })) : setOpenConfirmAlert(true);
  }

  const handleLogout = () => {
    authService.logout();
    // navigate("/login");
    window.location.reload();
  };
  const customerSupport = () => {
    navigate('/support');
  };

  const handleClickMycart = () => {
    // authCtx?.isLoggedIn ? (navigate("/cart",{
    // })) : setOpenConfirmAlert(true);
    navigate("/cart")
  }

  const handleClickMyFavourites = () => {
    authCtx?.isLoggedIn ? (navigate("/favorites")) : setOpenConfirmAlert(true);
  }

  const handleAddPayment=()=>{
    if(authCtx.cusId && authCtx.cardId) {
      authCtx?.isLoggedIn ? navigate("/payment-methods", {
        state:{
          type:"add"
        }
      }) : setOpenConfirmAlert(true)
    } else {
      authCtx?.isLoggedIn ? (navigate("/payment",{
        state:{
          type:"add"
        }
      })) : setOpenConfirmAlert(true);
    }
  }

  const getTabComponent = () => {
    switch(currentTab) {
      case "info":
        return <DAccount ctx={authCtx} handleAddShipping={handleAddShipping} />
      case "payment":
        return <DPayment ctx={authCtx} setOpenConfirmAlert={setOpenConfirmAlert} />
      default:
        return ""
    }
  }

  const changePreferences = () => {
    authCtx?.isLoggedIn ? navigate('/preferences') : setOpenConfirmAlert(true)
  }

  const handleTabInfoChange = () => {
    setCurrentTab("info")
  }
  
   const handleTabPaymentChange = () => {
    setCurrentTab("payment")
  }

  return (
    <div style={{backgroundColor: "#30313A"}}>
      <Helmet>
        <title>Boundless Giving - Manage Your Fundraising Account</title>
        <meta 
          name="description" 
          content="Effortlessly manage your account with Boundless Giving, where you can access your cart, bid history, shipping info, payment methods, and more. Login now!" 
        />
         <link rel="canonical" href={`${REACT_APP_URL}/account`} />
      </Helmet>
      {/* <HeaderV2 logoType="white2" primaryBackgroundColor="#30313A" primaryColor="#F0E8E3" containerStyle={{position:"fixed"}} isSubHeader={true} subHeaderTitle="Account"/> */}
      <HeaderV3 primaryBackgroundColor="#30313A" isSubHeader subHeaderTitle="Account" position="absolute" primaryColor="#F0E8E3"/>

      {/* <SubHeader title="Account" backgroundColor="#30313A" top='100px' color="#F0E8E3"/> */}


        <div className={`acc_page margin_class`} style={{backgroundColor:"#30313A", paddingTop:isMobile ? '70px' : '80px'}}>
          <div className="acc-container ">

                <div className={ `${isMobile?"":"margin_class"}`} style={{marginTop:isMobile ? "60px":'45px'}}/>
            {authCtx?.isLoggedIn && (
                <div className="user_info" style={{backgroundColor:"#30313A",color:"#F0E8E3"}}>
                  <h5 style={{fontWeight:"700",color:"#F0E8E3",fontSize:"20px",lineHeight:"25px"}}>
                    {authCtx?.userInfo?.first_name +
                      " " +
                      authCtx?.userInfo?.last_name}
                  </h5>
                  <p style={{fontWeight:"400",color:"#F0E8E3",fontSize:"12px",lineHeight:"18px"}}>{authCtx?.userInfo?.email}</p>
                </div>
              )}
            <main style={{color:"#F0E8E3", paddingInline:isMobile?"":"0"}}>
              <div className="acc_links">
              <div
                  className="logout_option"
                  // to="/my-bids"
                  onClick={handleClickMycart}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>My Cart</h5>
                  <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>
                <div
                  className="logout_option"
                  // to="/my-bids"
                  onClick={handleClickMyFavourites}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>My Favorites</h5>
                  <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>
                <div
                  className="logout_option"
                  // to="/my-bids"
                  onClick={handleClickHistory}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>History</h5>
                   <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>
                <div
                  className="logout_option"
                  // to="/my-bids"
                  onClick={handleClickMyBid}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>My Bids</h5>
                   <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>
                <div
                  className="logout_option"
                  onClick={handleAddShipping}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>Shipping Information</h5>
                   <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>
                <div
                  className="logout_option"
                  onClick={handleAddPayment}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>Payment Methods</h5>
                   <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>
                <div
                  className="logout_option"
                  onClick={changePreferences}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>Preferences</h5>
                   <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>

                <div
                  className="logout_option"
                  onClick={customerSupport}
                >
                  <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>Customer Support</h5>
                   <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                </div>
                
                {authCtx?.isLoggedIn ? (
                  <div  onClick={handleLogout} style={{borderBottom:'1px solid',marginTop:"25px",width:"fit-content", cursor:'pointer',fontSize:"18px"}}>
                    Logout
                  </div>
                ) : (
                  <div
                    style={{cursor:'pointer'}}
                    className="logout_option"
                    onClick={() => navigate("/login")}
                  >
                    <h5 style={{fontSize:"18px",fontWeight:"700",letterSpacing:"-2%"}}>Login</h5>
                     <ChevronRightOutlinedIcon style={{color:"#F0E8E3"}}/>
                  </div>
                )}
              </div>
              <ConfirmAlertLogin
                openConfirmAlert={openConfirmAlert}
                handleCloseConfirmAlert={handleCloseConfirmAlert}
              />
            </main>
          </div>
        </div>
      {/* <Desktop> */}
        {/* <div className={classes.daccount_container}>
          <div>
            <Navbar />
            <SubNavbar />
            <Header title="Account" />
            <BackContainer style={{width: "75vw"}} />
        <AccountNavbar
          currentTab={currentTab}
          setOpenConfirmAlert={setOpenConfirmAlert}
          handleTabInfoChange={handleTabInfoChange}
          handleTabPaymentChange={handleTabPaymentChange}
          from="account"
        >
          {getTabComponent()}
          </AccountNavbar>
            </div>
          <Footer />
        </div> */}
      {/* </Desktop> */}
  
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </div>
  );
}

export default Account;
