import React from "react";
import { useNavigate } from "react-router-dom";
// import Error from "../../images/error.jpg";
import { Desktop, Mobile } from "./responsive/Responsive";
import { useMediaQuery } from "react-responsive";
import BGLogoComponent from "../../images/BGLogoComponent/BGLogoComponent";
import { mobileStyle } from "./Home";
import NewButton from "./atoms/NewButton/NewButton";
import { Helmet } from "react-helmet-async";

function NotFound() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({minWidth: 768})
  return (
    <>
    {/* <Mobile> */}
    <Helmet>
        <title>404 - Not Found</title>
        <meta name="description" content="Sorry, the page you are looking for does not exist." />
        <meta name="status" content="404" />
        <meta name='errorpage' content='true' />
        <meta name='errortype' content='404 - Not Found' />
        <meta name='prerender-status-code' content='404' /> 
    </Helmet>
    <div style={{backgroundColor:"#30313A"}}>
      <div style={{height:"100vh",backgroundColor:"#30313A",padding:"40px",fontFamily:"Instrument Sans", maxWidth:'500px', marginInline:'auto'}}>
        <Desktop>
        <div style={{width:"100%",height:"150px",marginBottom: isDesktop ? '75px' : "35px"}} >
              <BGLogoComponent type="large" color={mobileStyle.color[2]} height="100%" />
        </div>
        </Desktop>
        <h3 style={{color:"#FC3300",fontSize:"32px",fontWeight:"400",lineHeight:"35px",marginTop:!isDesktop && "100px",marginBottom:"20px"}}>404</h3>
        <h3 style={{color:"#F0E8E3",fontSize:"32px",fontWeight:"400",lineHeight:"35px",marginBottom: !isDesktop && "150px"}}>Page not <br style={{display:isDesktop ? "none" : 'block'}} /> found</h3>
        <NewButton 
           text={'GO HOME'} 
           onClick={() => navigate("/")} 
           color={mobileStyle.color[2]} 
           backgroundColor={mobileStyle.backgroundColor[1]} 
           style={{marginTop:isDesktop && '55px', fontSize:"16px", textTransform:'uppercase', width:isDesktop ? '46%' : "100%"}} />
      </div>
      </div>
    {/* </Mobile> */}
    {/* <Desktop>
    <div
        style={{
          textAlign: "center",
          display: "grid",
          height: "90vh",
          justifyContent: "center",
          gridTemplateRows:'80% 10% 10%'
        }}
      >
        <img style={{maxWidth:'100%',maxHeight:'100%' }} src={Error} alt="error" />
        Page Not found!
        <div className="go_home">
          <button type="button" onClick={() => navigate("/")}>
            {" "}
            Go Home{" "}
          </button>
        </div>
      </div>
    </Desktop> */}
     
    </>
  );
}

export default NotFound;
