import React from 'react'
import "./BagCard.css"
// import { Desktop, Mobile } from '../../responsive/Responsive'
import NewCardLayout from '../../atoms/NewCardLayout/NewCardLayout'

const formatDes = (text) => {
  if(text.length > 150) {
    return text.substr(0, 150) + "..."
  }
  return text
}

const BagCard = ({title, description, price, qty, image, primaryColor,neededLine=true, variant=null}) => {
  return (
    <>
      <NewCardLayout image={image} neededLine={neededLine}>
      <div className="cart-card-content" style={{gap:'5px'}}>
                    <h1>{title}</h1>
                    {
                      variant && 
                      <div style={{ marginTop: "-10px", fontSize: "12px", display: "flex", gap: "10px", marginBottom: "10px" }}>
                        {
                          Object.keys(variant).map((key) => (
                            <span key={key} style={{ color: primaryColor }}>{key}: {variant[key]}</span>
                        ))
                        }
                      </div>
                    }
                    <div className="cart-card-qty_price">
                      <div className="cart-card-remove_price">
                        <h5 style={{color:primaryColor,fontWeight:"600"}} >${price}</h5>
                      </div>
                    </div>
                  </div>
                  <div style={{marginTop:'5px'}} className="mobile_quantity_wrapper">
                    <span className="quantity_text">Qty</span>
                    <span className="quantity_text">{qty}</span>
                  </div>
      </NewCardLayout>

    </>
  )
}

export default BagCard