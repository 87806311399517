import { useLocation } from "react-router-dom";

const { createContext, useState, useEffect } = require("react");

export const FilterContext = createContext({
    groupBy: "",
    sortBy: "Recommended",
    setGroupBy: () => {},
    setSortBy: () => {},
    reset: () => {},
    priceRange: [0, 25000],
    handlePriceChange: () => {},
    contextFilterd:false,
    setContextFilterd: () => {}
})

export const FilterContextProvider = (props) => {
    const [data, setData] = useState({
        groupBy: "",
        sortBy: "Recommended",
        contextFilterd:false
    })
    const [priceRange, setPriceRange] = useState([0, 25000]);
    const location = useLocation().pathname
    const setGroupBy = (value) => {
        let final = value
        if(value === "auctionCategory") {
            final = "itemCategory"
        } else if (value === "causeCategory") {
            final = "causeType"
        } else {
            final = value
        }
        setData({...data, groupBy: final})
    }

    const setSortBy = (value) => {
        setData({...data, sortBy: value})
    }

    const reset = () => {
        setData({
            groupBy: "",
            sortBy: "Recommended",
            contextFilterd:false
        })
        setPriceRange([0,25000])
    }

    const handlePriceChange = (e, val) => {
      return setPriceRange([...val]);
    }

    const setContextFilterd = (value) => {
        setData({...data, contextFilterd:value})
    }

    // useEffect(() => {
    //     if(!data?.contextFilterd && (location.includes('store')) ) {
    //         setGroupBy('itemCategory')
    //     }
    // },[location])

    return (
    <FilterContext.Provider value={{sortBy: data.sortBy, groupBy: data.groupBy, setGroupBy, setSortBy, reset, priceRange, handlePriceChange, setContextFilterd}}>
        {props.children}
    </FilterContext.Provider>
    )
} 