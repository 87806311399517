import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet-async"
import Header from "../../../desktop/components/Header/Header"
import blogStyle from './Blog.module.css';
import moment from "moment"
import Pagination from '@mui/material/Pagination';
import { useSelector, useDispatch } from 'react-redux'
import { getAllBlogs } from '../../../../redux/blogReducer';
import LoadingScreen from '../../LoadingScreen/Loading';
import HeaderV2 from '../../molecules/Header/HeaderV2';
import { mobileStyle } from '../../Home';
import RoundedContainer from '../../atoms/RoundedContainer/RoundedContainer';
import commoneCardClasses from '../HomePageComponents/NewHomePageComponents/FeaturedEvents/FeaturedEvents.module.css'
import NewFooter from '../../molecules/NewFooter/NewFooter';
import { useMediaQuery } from 'react-responsive';
import { config } from "../../../../config/config";
import HeaderV3 from '../../molecules/Header/HeaderV3/HeaderV3';
import PaginationV2 from '../../molecules/PaginationV2/PaginationV2';
const {REACT_APP_URL} = config

const Blog = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(16)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const primaryColor = mobileStyle.color[0];
    const primaryBackgroundColor = "var(--pure-white)";

    const isMobile = useMediaQuery({
        maxWidth: 768
    })

    let trendingTitleLimit = 55;
    let trendingSubtitleLimit = 180;

    if(isMobile) {
        trendingTitleLimit = 40;
        trendingSubtitleLimit = 100;
    }

    useEffect(() => {
        dispatch(getAllBlogs({ limit: limit, page: page }))
    }, [])
    const { allBlogs, loading, loadingMore, trending } = useSelector(state => state.blogs)
    const paginationCount = Math.ceil(allBlogs?.total_count / limit)

    const loadMore = async (e, paginationPage) => {
        if (paginationPage === page) {
            return
        }
        setPage(paginationPage)
        dispatch(getAllBlogs({ limit: limit, page: paginationPage }))
        window.scrollTo({ top: 100, behavior: "smooth" });
    }

    const handleNavigate = (id) => {
        navigate(`/blog/${id}`)
    }

    return (

        <>
            <Helmet prioritizeSeoTags>
                {/* <title>Blog | Boundless Giving</title> */}
                <title>Blog - Inspiring Stories of Nonprofits and Social Impact</title>
                <meta 
                  name="description" 
                  content="Explore the latest blog posts at Boundless Giving, which feature inspiring stories of philanthropy, social impact, and charitable endeavors. Join us now!" 
                />
                <meta 
                  name="keywords"
                  content="Pop Up Shop Fundraise"
                />
                <link rel="canonical" href={`${REACT_APP_URL}/blog`} />
            </Helmet>
            <div className={blogStyle.section} >
                {
                    loading ?
                        <LoadingScreen bgColor="#F0E8E3" /> :
                        <div>
                            {/* <HeaderV2 primaryBackgroundColor={primaryBackgroundColor} logoType='orange' primaryColor={primaryColor} containerStyle={{ position: 'fixed' }} subHeaderTitle="Blog" subHeaderDesktop={false} /> */}
                            <HeaderV3 primaryBackgroundColor={primaryBackgroundColor}  primaryColor={primaryColor}  subHeaderTitle="Blog" isSubHeader subHeaderDesktop={false} />
                           
                            <div className={`${blogStyle.container} margin_class`}>
                                {
                                    (allBlogs && allBlogs?.data?.length < 1) ?
                                        <Header title="No Blogs found" headerStyle={{ backgroundColor: "white", color: mobileStyle.color[0] }} />
                                        :
                                        <>
                                            <h1 className={blogStyle.heading} >Blog</h1>
                                            {Object.keys(trending).length < 1 ? null :
                                                <RoundedContainer customClassName={'custom_blog_container'}>
                                                    <div className={`${commoneCardClasses.card_main} ${blogStyle.trending_blog_card}`} onClick={() => handleNavigate(trending?.pretty_url)} >
                                                        <div className={blogStyle.trending_card_image}>
                                                            <img src={trending?.image} className={commoneCardClasses.featured_image} loading='lazy' alt={trending?.blog_alt} />
                                                        </div>
                                                        <div className={`${commoneCardClasses.card_bottom_section} ${blogStyle.card_content} ${blogStyle.trending_card_content}`} >
                                                            <div className={`${blogStyle.content_top} ${blogStyle.trending_content_top}`}>
                                                                <h3 className={`${blogStyle.trending_title} ${blogStyle.title}`} >{trending?.title?.length > trendingTitleLimit ? `${trending?.title?.slice(0, trendingTitleLimit)}...` : trending?.title}</h3>
                                                                <span className={blogStyle.trending_subtitle} >{trending?.subtitle?.length > trendingSubtitleLimit ? `${trending?.subtitle?.slice(0, trendingSubtitleLimit)}...` : trending?.subtitle}</span>
                                                            </div>
                                                            <div className={blogStyle.card_content_bottom}>
                                                                <p className={blogStyle.trending_author} >{trending?.user?.first_name} {trending?.user?.last_name}</p>
                                                                <small className={blogStyle.created_at}>{moment(trending?.created_at).format("MMMM Do YYYY")}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </RoundedContainer>
                                            }
                                            <>
                                                {

                                                    allBlogs && allBlogs?.data?.length > 0 ?
                                                        <div className={blogStyle.blogcards_grid} >
                                                            {allBlogs?.data?.map((blog) => {
                                                                return (
                                                                    <React.Fragment key={blog.id}>
                                                                        {blog?.id != trending.id ?
                                                                            <RoundedContainer customClassName={'custom_blog_container'}>
                                                                                <div className={commoneCardClasses.card_main} onClick={() => handleNavigate(blog?.pretty_url)} >
                                                                                    <div className={blogStyle.card_image}>
                                                                                        <img src={blog?.image} className={commoneCardClasses.featured_image} loading='lazy' alt={blog?.blog_alt} />
                                                                                    </div>
                                                                                    <div className={`${commoneCardClasses.card_bottom_section} ${blogStyle.card_content}`} >
                                                                                        <div className={blogStyle.content_top}>
                                                                                            <h3 className={blogStyle.title} >{blog?.title?.length > 38 ? `${blog?.title?.slice(0, 38)}...` : blog?.title}</h3>
                                                                                            <small className={blogStyle.subtitle} >{blog?.subtitle?.length > 80 ? `${blog?.subtitle?.slice(0, 80)}...` : blog?.subtitle}</small>
                                                                                        </div>
                                                                                        <div className={blogStyle.card_content_bottom}>
                                                                                            <p className={blogStyle.trending_author} >{blog?.user?.first_name} {blog?.user?.last_name}</p>
                                                                                            <small className={blogStyle.created_at}>{moment(blog?.created_at).format("MMMM Do YYYY")}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </RoundedContainer>
                                                                            : null}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </>
                                            {
                                                allBlogs && allBlogs?.data?.length > 0 &&
                                                <PaginationV2 totalPage={paginationCount} page={page} onLoadMore={loadMore} />
                                                // <div className={blogStyle.pagination_container} >
                                                //     <Pagination
                                                //         count={paginationCount}
                                                //         onChange={loadMore}
                                                //         page={page}
                                                //     />
                                                // </div>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                }
            </div>
            <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[0]} primaryColor={primaryBackgroundColor} logoColor={mobileStyle.color[1]} />
        </>


        // <div className={blogStyle.section} >
        //     {loading ?
        //         <div>
        //             <Backdrop
        //                 sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //                 open={loading}
        //             >
        //                 <div
        //                     style={{
        //                         display: "flex",
        //                         flexDirection: "column",
        //                         justifyContent: "center",
        //                         alignItems: "center",
        //                     }}
        //                 >
        //                     <CircularProgress color="inherit" />
        //                 </div>
        //             </Backdrop>
        //         </div> :
        //         <div>
        // <Helmet>
        //     <title>Blog | Boundless Giving</title>
        // </Helmet>
        //             <Mobile>
        //                 <GlobalHeader
        //                     title="Blog"
        //                     SecondaryBtnContent={() => <span></span>}
        //                     secondaryBtnBehavior="none"
        //                 />

        //             </Mobile>
        //             <Desktop>
        //                 <Navbar />
        //                 <SubNavbar />
        //                 {(allBlogs && allBlogs?.data?.length > 0) && Object.keys(trending).length > 0 ? <Header title="Blog"  /> : null}
        //             </Desktop>
        //             <div className={blogStyle.container}>
        //                 {
        //                   (allBlogs && allBlogs?.data?.length < 1)  ? <Header title="No Blogs found" headerStyle={{ backgroundColor: "white" }} /> :

        //                        Object.keys(trending).length < 1 ? null : <div onClick={() => handleNavigate(trending?.pretty_url)} className={blogStyle.trending_blogcard} >
        //                             <div className={blogStyle.imageContainer} >
        //                                 <img src={trending?.image} loading='lazy' alt="" />
        //                             </div>
        //                             <div className={blogStyle.about_trending_blog} >
        //                                 <p className={blogStyle.created_at} >{moment(trending?.created_at).format("MMMM Do YYYY")}</p>
        //                                 <h3>{trending?.title}</h3>
        //                                 <p style={{wordWrap: "break-word"}}>{trending?.subtitle}</p>
        //                                 <p className={blogStyle.trending_author} >{trending?.user?.first_name} {trending?.user?.last_name}</p>
        //                             </div>
        //                         </div>
        //                 }
        //                      <>
        //                     {

        //                        allBlogs && allBlogs?.data?.length > 0 ?
        //                 <div className={blogStyle.blogcards_grid} >
        //                       {  allBlogs?.data?.map((blog) => {
        //                                 return (
        //                                 <React.Fragment key={blog.id}>
        //                                   { blog?.id != trending.id ?
        //                                    <div onClick={() => handleNavigate(blog?.pretty_url)}  className={blogStyle.card_main} >
        //                                         <div className={blogStyle.card_image_main}>
        //                                             <img src={blog?.image} loading='lazy' alt="" />
        //                                         </div>
        //                                         <div className={blogStyle.card_content} >
        //                                             <p className={blogStyle.created_at}>{moment(blog?.created_at).format("MMMM Do YYYY")}</p>
        //                                             <h3>{blog?.title}</h3>
        //                                             <p>{blog?.subtitle}</p>
        //                                             <p className={blogStyle.trending_author} >{blog?.user?.first_name} {blog?.user?.last_name}</p>
        //                                         </div>
        //                                     </div>
        //                                     : null} 
        //                                     </React.Fragment>
        //                                 )
        //                             })}
        //                 </div>
        //                      :
        //                          null
        //                     //   <h2 style={{textAlign:"center", fontWeight:600, marginTop:"50px"}} >No More Blogs</h2>
        //                     }
        //                     </>
        //                {
        //                   allBlogs && allBlogs?.data?.length > 0 && <div style={{ fontSize: "1.1rem", marginTop: "30px", fontWeight: 600, display: 'flex', justifyContent: 'center' }} >
        //                     <Pagination
        //                         count={paginationCount}
        //                         onChange={loadMore}
        //                         page={page}
        //                     />
        //                 </div>
        //                }
        //             </div>
        //             <Desktop>
        //                 <Footer style={{ marginTop: "100px" }} />
        //             </Desktop>
        //         </div>}
        // </div>
    )
}

export default Blog
