import React, { useContext } from 'react';
import { Desktop, Mobile } from '../../../responsive/Responsive';
import CauseContext from '../../../../../store/cause-context';
import { useNavigate } from 'react-router-dom';
import BrowseByCards from '../../../../desktop/components/BrowseByCards/BrowseByCards';
import classes from './BrowsByCause.module.css';

function BrowsByCause({ title, containerStyle, mobileStyle = { backgroundColor: [], color: [] } }) {
    const causeCtx = useContext(CauseContext);
    const navigate = useNavigate()
    const mobileBackground = mobileStyle.backgroundColor[2]
    const mobileCardBackground = mobileStyle.backgroundColor[0]
    const mobileTitleColor = mobileStyle.color[1]
    const mobileColor = mobileStyle.color[2]
    return (
        <>
            <Desktop>
                {/* <section style={{ ...containerStyle, padding: "10px 0" }}>
                    <div className={`${classes.dsection} ${classes.dcurrent_events} `}>
                        <h3>{title}</h3>
                        <div className={classes.dbrowse_cards}>
                            {causeCtx.causeCategoryList?.map((i) => (
                                <BrowseByCards onClick={() => {
                                    navigate("/browse-items", {
                                        state: {
                                            cause_category_id: i?.id,
                                            title: i?.name,
                                            from: 'causeCategory',
                                            image: require(`../../../../../images/causeIcons/1200x480/${i.image}`)
                                        },
                                    })
                                }} key={i?.id} name={i?.name} image={i?.image} id={i?.id} imageFolder="causeIcons" />
                            ))}
                        </div>
                    </div>
                </section> */}

                <section className={classes.desktop_section} style={{backgroundColor:mobileBackground}} >
                    <div className={`margin_class ${classes.desktop_container}`} >
                        {/* <h3 style={{color:mobileTitleColor}} className={classes.desktop_section_heading} >Browse</h3> */}
                        <div className={classes.desktop_content} >
                            <h5 className={classes.desktop_heading}>{title}</h5>
                            <div className={classes.desktop_card_wrapper} >
                            {causeCtx.causeCategoryList?.map((i) => (
                                <BrowseByCards onClick={() => {
                                    // navigate("/browse-items", {
                                    //     state: {
                                    //         cause_category_id: i?.id,
                                    //         title: i?.name,
                                    //         from: 'causeCategory',
                                    //         image: require(`../../../../../images/causeIcons/1200x480/${i.image}`)
                                    //     },
                                    // })
                                    navigate(`/${i?.id}/browse-items?item-type=cause-category`)
                                }} key={i?.id} name={i?.name} image={i?.image} id={i?.id} imageFolder="causeIcons" />
                            ))}
                            </div>
                        </div>
                    </div>
                </section>

            </Desktop>
            <Mobile>
                {/* Browse By Cause margin: "20px 0px 15px 0px", */}
                <div style={{  backgroundColor: mobileBackground,  borderBottom:`5px solid ${mobileBackground}` }} className="categories_sec">
                    <div className="page_heading" style={{ marginBottom: "5px", display: 'flex', flexDirection: "column" }} id="page_heading_coming_soon">
                        {/* <h3 className={classes.mobile_main_heading} style={{ width: '100%', textAlign: 'start', color: mobileTitleColor }}>Browse</h3> */}
                        <h5 className={classes.mobile_heading} style={{ width: '100%', textAlign: 'start', color: mobileTitleColor, }}>{title}</h5>
                    </div>
                    <section className="scrollCategory" style={{ maxHeight: "150px", overflowY: 'hidden', paddingBlock: '10px' }}>
                        <div className="categories_list">
                            <div className="category_container" style={{marginBottom:"15px"}}>
                                {causeCtx.causeCategoryList?.map((i) => (
                                    // <div
                                    //     style={{ margin: "0px 13px 0 0", cursor: "pointer" }}
                                    //     key={i.id}
                                    //     onClick={() =>
                                    //         navigate("/browse-items", {
                                    //             state: {
                                    //                 cause_category_id: i?.id,
                                    //                 title: i?.name,
                                    //                 from: 'causeCategory',
                                    //                 image: require(`../../../../../images/causeIcons/1200x480/${i.image}`)
                                    //             },
                                    //         })
                                    //     }
                                    // >
                                    //     <img
                                    //         src={require(`../../../../../images/causeIcons/100x100/${i.image}`)}
                                    //         alt="cause-category"
                                    //         style={{border:`1px solid ${mobileColor}`, borderRadius: "20px" }}
                                    //     />
                                    //     <p style={{color:mobileColor}} className={classes.card_title}> {i.name} </p>
                                    // </div>

                                    <div
                                        key={i.id}
                                        onClick={() =>
                                            // navigate("/browse-items", {
                                            //     state: {
                                            //         cause_category_id: i?.id,
                                            //         title: i?.name,
                                            //         from: 'causeCategory',
                                            //         image: require(`../../../../../images/causeIcons/1200x480/${i.image}`)
                                            //     },
                                            // })
                                            navigate(`/${i?.id}/browse-items?item-type=cause-category`)
                                        }
                                        style={{ padding: '0px 32px', borderRadius: "25px", height: 'fit-content', minWidth: 'fit-content', backgroundColor: mobileCardBackground }}>
                                        <p style={{ color: mobileColor }} className={classes.card_title}>{i.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </Mobile>
        </>
    )
}

export default BrowsByCause