import React, { useEffect } from "react";
import Router from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ScrollToTop from "./pages/components/ScrollToTop";
import logger from "./utils/logger";
import {config} from "./config/config";
import Footer from "./pages/components/Footer";
import { Provider } from "react-redux";
import store from "./redux/store"
import { HelmetProvider } from "react-helmet-async"

function App() {
  function handleResize() {
    
    const hrefArray = window.location.href.split("/")
    let allowedAddress = hrefArray.length > 4 && (
      hrefArray[4].startsWith("reset-pwd") || 
      hrefArray[4].startsWith("auth") || 
      hrefArray[4].startsWith("order"));
    if(allowedAddress)
      return '';

    // if(Number(window.screen.width) >= 768) {
    //   window.open(config.EVENTS_PAGE_BIG_SCREEN_URL,'_self');
    //   logger('App.js', 'large screen detected, redirecting', window.screen.width);
    // }
    return '';
    
  }
  useEffect(()=>{
    handleResize();
  },[]);

  return (
    <HelmetProvider>
      <div id="App" >
          <Provider store={store}>
            <ScrollToTop />
            <Router />
            <Footer />
          </Provider>
      </div>
    </HelmetProvider>
  );
}

export default App;
