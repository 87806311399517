import React from 'react';
import { Mobile } from '../../responsive/Responsive';
import { ChevronLeft } from "@mui/icons-material";
import style from './MobileSubheading.module.css';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


function SubHeader({ title = '', backgroundColor = 'white', color = 'black',top, onClick }) {
    const navigate = useNavigate();

    const goBack = () => {
        if (window.history.length <= 1) {
            navigate("/", {
                preventScrollReset: true
            })
            return
        }
        navigate(-1, {
            preventScrollReset: true
        });
    }
    return (
            <div className={style.sub_header} style={{ backgroundColor: backgroundColor }}>
                <span style={{color: color}}>
                    <ChevronLeft sx={{ fontSize: 32}} onClick={onClick ? onClick : goBack} />
                </span>
                <p style={{ color: color }}>{title}</p>
            </div>
    )
}

export default SubHeader