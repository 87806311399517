import React from 'react'
import classes from "./SubForm.module.css"
import { Desktop, Mobile } from '../../../components/responsive/Responsive'
import NoData from '../../../components/NoData'

const SubForm = ({title="Register for updates about upcoming Boundless events and news"}) => {
  return (
    <>
    <Desktop>
    <form action="https://boundlessgiving.us21.list-manage.com/subscribe/post?u=e523e179961f63c6ced52602f&amp;id=19066d7026&amp;f_id=0080c5e1f0%22" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" method='post' className={classes.dfooter_form}>
        <div className={classes.dfooter_form_head}>
        {title?.slice(0,45)} <br />
        {title?.slice(45)}
        </div>
        <div className={classes.dfooter_form_content}>
        <div className={classes.dfooter_form_inputs}>
            <div className={classes.dfooter_form_input}>
            <div>EMAIL ADDRESS</div>
            <input type="text" required placeholder="Your Email" name='EMAIL' />
            </div>
            <div className={classes.dfooter_form_input}>
            <div>TITLE</div>
            <input type="text" placeholder="Add Title" />
            </div>
            <div className={classes.dfooter_form_input}>
            <div>FIRST NAME</div>
            <input type="text" required placeholder="Your First Name" name='FNAME' />
            </div>
            <div className={classes.dfooter_form_input}>
            <div>LAST NAME</div>
            <input type="text" required placeholder="Your Last Name" name='LNAME' />
            </div>
        </div>
        <div className={classes.dfooter_form_condition}>
            By Subscribing you are agreeing to Boundless Giving's Privacy Policy. You may unsubscribe from Boundless <br />
            Giving communications by clicking the "Unsubscribe" link in any of our emails.
        </div>
        <div className={classes.dfooter_sub}>
            <button type="submit">Subscribe Now</button>
        </div>
        </div>
    </form>
    </Desktop>
    <Mobile>
    <div style={{margin: "0px", paddingTop: "20px"}}>
      <NoData/>
    </div>
    </Mobile>
    </>
  )
}

export default SubForm
