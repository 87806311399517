import React from 'react'
import NewButton from '../../../../atoms/NewButton/NewButton'
import { Desktop, Mobile } from '../../../../responsive/Responsive'
import styles from './BGLearnMore.module.css'
import BGLogoComponent from '../../../../../../images/BGLogoComponent/BGLogoComponent'
import BGLargeLogo2 from '../../../../../../images/BGLogoComponent/BGLargeLogo2'
import { useNavigate } from 'react-router-dom'


const BgLearnMore = ({ mobileStyle = { backgroundColor: [], color: [] } }) => {
    const navigate = useNavigate()
    const backgroundColorMobile = mobileStyle.backgroundColor[0]
    const backgroundColorDesktop = mobileStyle.backgroundColor[2]
    const color = mobileStyle.color[2]
    const textStyle = {
        color: color,
        fontFamily: "Instrument Sans",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "-0.36px",
    }
    const handleClick = () => {
        navigate("/learn-more")
    }
    return (
        <>
        <Mobile>
        <section style={{ backgroundColor: backgroundColorMobile }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: "1rem", padding: "33px 13px" }}>
                <p style={textStyle}>We created Boundless Giving to meaningfully support causes you care about, while reducing waste and minimizing impact on the planet we’re leaving to our kids.
                    <br /><br /> With up to 80% of every sale going to an impactful nonprofit, don’t just shop, shop generously.</p>
                <NewButton text={'LEARN MORE'} onClick={handleClick} style={{fontSize:"14px"}} />
            </div>
        </section>
        </Mobile>
        <Desktop>
            <section style={{backgroundColor:backgroundColorDesktop}} className={styles.desktop_section}>
                <div className={`${styles.desktop_container} margin_class`} >
                    <div className={styles.desktop_para_container}>
                        <p style={{color:mobileStyle.color[1]}} className={styles.desktop_para} >We created Boundless Giving to meaningfully
                          support causes you care about, while reducing waste
                          and minimizing impact on the planet we’re leaving to our kids. <br /><br />
                          With up to 80% of every sale going to an impactful nonprofit, don’t
                          just shop, shop generously.
                        </p>
                        <button className={styles.learn_more_btn} onClick={handleClick}>LEARN MORE</button>
                    </div>
                    <div className={styles.logo_container} >
                        <BGLargeLogo2 color={mobileStyle.color[0]} />
                    </div>
                </div>
            </section>
        </Desktop>
        </>
    )
}

export default BgLearnMore