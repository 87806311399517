import React, { useState, useEffect, useContext, useRef } from 'react';
import {useDispatch} from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {  useParams, useNavigate, useLocation } from 'react-router-dom';

// import mail from '../../../../images/mail.png';

// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { Dialog, DialogContent, DialogContentText } from "@mui/material";
// import Accordion from 'react-bootstrap/Accordion';
import auctionServices from '../../../../services/auction-services';
import causeServices from '../../../../services/cause-services';
import AuthContext from '../../../../store/auth-context';
import userServices from '../../../../services/user-services';
// import { calculateTimeLeft } from '../../../../utils/util-functions';
// import GlobalHeader from '../../molecules/GlobalHeader/GlobalHeader';
import ConfirmAlertLogin from '../../ConfirmAlertLogin';
import CarouselCard from '../../atoms/CarouselCard/CarouselCard';
import ItemInfo from '../../molecules/ItemInfo/ItemInfo';
// import CustomAccordion from '../../atoms/Accordion/CustomAccordion';
import logger from '../../../../utils/logger';
import { Helmet } from "react-helmet-async"
import {Mobile, Desktop} from "../../responsive/Responsive"
// import Navbar from "../../../desktop/components/Navbar/Navbar"
// import Footer from "../../../desktop/components/Footer/Footer"
// import BackContainer from "../../../desktop/components/BackContainer/BackContainer"
// import SubNavbar from "../../../desktop/components/SubNavbar/SubNavbar"
import "./ItemDetails.css"
import { AddToCart, ViewContent } from '../../../../utils/meta-pixel-tracking';
import { getCartItems } from '../../../../redux/cartReducer';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import constants from '../../../../constants/en';
// import classes from "../../../desktop/styles/Home.module.css"
import HeaderV2 from '../../molecules/Header/HeaderV2';
import { mobileStyle } from '../../Home';
import { useMediaQuery } from 'react-responsive';
// import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import NewButton from '../../atoms/NewButton/NewButton';
// import SubHeader from '../../molecules/SubHeader/SubHeader';
import DialogMui from '../../molecules/Dialog/Dialog';
import LoadingScreen from '../../LoadingScreen/Loading';
import ItemDetailCardLayout from '../../molecules/ItemDetailCardLayout/ItemDetailCardLayout';
import NewFooter from '../../molecules/NewFooter/NewFooter';
import AdminPreviewBanner from '../../atoms/AdminPreviewBanner/adminPreviewBanner';
import SnackBarMui from '../../atoms/SnackBar/SnackBar';
import { config } from '../../../../config/config';
import HeaderV3 from '../../molecules/Header/HeaderV3/HeaderV3';
const {REACT_APP_URL} = config

const mainBackgroundColor = "var(--pure-white)";
const mainColor = mobileStyle.color[1]

const FooterButtons = ({
  onClickBid = null,
  onClickBuy = null,
  buyNow,
  disabled = false,
  highlight
}) => {
  const isDesktop = useMediaQuery({
    minWidth: 768
  })
  return (
    <>
    {/* <Desktop>
      {buyNow ? (
        <div
          className='main-btn-container-one'
        >
          <div
            onClick={onClickBid}
            className={`main-btn-button ${!disabled && 'main-btn-button-outline'}`}
            style={
              disabled
                ? { backgroundColor: '#787878c4' }
                : {}
            }
          >
            Place Bid
          </div>
          <div
            onClick={onClickBuy}
            className="main-btn-button"
            style={
              disabled
                ? { backgroundColor: '#787878c4' }
                : { boxShadow: highlight === 'Y' ? '0 5px 18px #1a385c' : 'border-box' }
            }
          >
            Buy Now
          </div>
        </div>
      ) : (
        <div className='main-btn-container-one'>
          <div
            className='main-btn-button'
            onClick={onClickBid}
            style={disabled ? { backgroundColor: '#787878c4' } : {}}
          >
            Place Bid
          </div>
        </div>
      )}
      </Desktop> */}
      {/* <Mobile> */}
      {buyNow ? (
        <div
          className='main-btn-container-one'
        >
          {/* <div
            onClick={onClickBid}
            className={`main-btn-button ${!disabled && 'main-btn-button-outline'}`}
            style={
              disabled
                ? { backgroundColor: '#787878c4' }
                : {}
            }
          >
            Place Bid
          </div> */}
          <NewButton 
           text={'PLACE BID'}
           onClick={onClickBid} 
           color={mainBackgroundColor} 
           backgroundColor={disabled ? '#787878c4' : mobileStyle.backgroundColor[0] }
           style={{width:'100%', borderRadius:"25px", padding:'5px 21px', fontSize:'16px', fontFamily:'Instrument Sans'}}
          />
          {/* <div
            onClick={onClickBuy}
            className="main-btn-button"
            style={
              disabled
                ? { backgroundColor: '#787878c4' }
                : { boxShadow: highlight === 'Y' ? '0 5px 18px #1a385c' : 'border-box' }
            }
          >
            Buy Now
          </div> */}
          <NewButton
           text={'BUY NOW'} 
           onClick={onClickBuy} 
           color={disabled ? mainBackgroundColor: mobileStyle.color[0]} 
           backgroundColor={disabled ? '#787878c4' : mainBackgroundColor } 
           style={{width:'100%', borderRadius:"25px", padding:'5px 21px', fontSize:'16px', fontFamily:'Instrument Sans'}}
         />
        </div>
      ) : (
        <div className='main-btn-container-one' style={{textAlign:isDesktop ? "left" :"center",justifyContent:isDesktop?"flex-start":"center"}}>
          {/* <div
            className='main-btn-button'
            onClick={onClickBid}
            style={disabled ? { backgroundColor: '#787878c4' } : {}}
          >
            Place Bid
          </div> */}
          <NewButton 
           text={'PLACE BID'}
           onClick={onClickBid} 
           color={ disabled ? mainBackgroundColor : mobileStyle.color[0]} 
           backgroundColor={disabled ? '#787878c4' : mainBackgroundColor } 
           style={{width:'100%', borderRadius:"25px", padding:'5px 21px', fontSize:'16px', fontFamily:'Instrument Sans'}}
          />
        </div>
      )}
      {/* </Mobile> */}
    </>
  );
};

function ItemDetails() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const { itemId } = useParams();
  const [lotDetails, setLotDetails] = useState({ status: 'cancelled' });
  const [bidDetails, setBidDetails] = useState();
  const [causeData, setCauseData] = useState(null);
  const [checkUserBid, setCheckUserBid] = useState(null);

  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5, setIsLoading5] = useState(true);

  const search = useLocation().search;
  const auctionId = new URLSearchParams(search).get('auction_id');
  const xrefAuctionId = new URLSearchParams(search).get('xref_auction_id');
  const causeId = new URLSearchParams(search).get('cause_id');
  const highlight = new URLSearchParams(search).get('highlight');
  // const [timeLeft, setTimeLeft] = useState(null);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [highestBidAmt, setHighestBidAmt] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [selectedVaraint, setSelectedVariant] = useState(null);
  const [auction_active, setAuctionActive] = useState(false);
  const [isUpcoming, setIsUpcoming] = useState(true);
  const [cartItem,setCartItem]=useState(false)
  const [modal,setModal]=useState({isOpen:false,message:''})
  const [curentImage, setCurrentImage] = useState(0);
  const [quantity,setQuantity] = useState(1);
  const [refreshDetails, setRefreshDetails] = useState(false);
  const [loading,setLoading]= useState(false);
  const [auctionComplete,setAuctionComplete]= useState(false);
  const [landscapeImage,setLandscapeImage] = useState([]);
  const [itemCauses,setItemCauses] = useState([]);
  const [selectedCauseId,setSelectedCauseId] = useState("");
  // const [imageLoading, setImageLoading] = useState(true)
  const [xrefCauseData, setXrefCauseData] = useState(null)
  const can_choose_cause = useRef();
  const paramsStateData = useLocation().state
  const [hasQuantity, setHasQuantity] = useState([])
  const [alertState, setAlertState] = useState({isOpen:false, message:''})
  const [eventStatus, setEventStatus] = useState('')

  const refreshPage = () => {
    setLoading(true)
    setRefreshDetails(!refreshDetails)
  }
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const isDesktop = useMediaQuery({
    minWidth: 768
  })

  const handleCloseAlert = () => {
    setAlertState({isOpen: false, message: ''})
  }

  const getCausesOfItem = async () =>{
     try {
      const data = await causeServices.getItemRelatedCauses(itemId);
      if(data.causes.length === 1){
      if(!can_choose_cause.current){
        setSelectedCauseId(data?.causes[0]?.id)
      }
    }
      setItemCauses(data.causes)
     }
     catch(error) {}
  }

  useEffect(() => {
    const getLotDetails = async (auction_id, item_id) => {
      try {
        await auctionServices
          .getAuctionItemDetailsById(auction_id, item_id, xrefAuctionId)
          .then(
            (response) => {
              setLotDetails(response?.data);
              setLandscapeImage(response?.data?.assets?.filter(img=>img.context_type==='portrait'))
              const a_start_date = response.data.auction.start_date;
              const a_end_date = response.data.auction.end_date;
              let thisEventStatus = response?.data?.auction?.status
              if(response?.data?.auction?.status === 'complete' && new Date(a_end_date) < new Date()){
                      setAuctionComplete(true)
              }
              if(response?.data?.auction_auction_item_xrefs?.length) {
                thisEventStatus = response?.data?.auction_auction_item_xrefs[0]?.auction?.status
                setXrefCauseData(response?.data?.auction_auction_item_xrefs[0]?.auction?.cause)
                if( response?.data?.auction_auction_item_xrefs[0]?.auction?.status !== 'complete' && new Date(response?.data?.auction_auction_item_xrefs[0]?.auction?.end_date) > new Date() ) {
                  setAuctionComplete(false)
                }
                else if(response?.data?.auction_auction_item_xrefs[0]?.auction?.status === 'complete' && new Date(response?.data?.auction_auction_item_xrefs[0]?.auction?.end_date) < new Date()) {
                  setAuctionComplete(true)
                }
              }
              setEventStatus(thisEventStatus)
              if(response?.data?.auction?.market_place === "0" && (response?.data?.can_choose_cause === 1 || response?.data?.can_choose_cause === 0)){
                        setSelectedCauseId(causeId)
              }
              if(response?.data?.can_choose_cause === 1 ){
                can_choose_cause.current = true
              }else{
                can_choose_cause.current = false
              }
              if (response?.data?.store_item_id && !response?.data?.variants?.length && response?.data?.store_item?.quantity_in_stock <= 0) {
                // Is store_item but variants !present & store_item qty <= 0
                setAuctionActive(false);
              }
              //  else if (response?.data?.store_item_id && response?.data?.variants?.length && response?.data?.variants[0].quantity_in_stock <= 0) {
              //   // Is store_item & variants present & 1st variant's qty <= 0
              //   setAuctionActive(false);
                
              // } 
              else {
                if(response?.data?.auction_auction_item_xrefs?.length) {
                  setAuctionActive(
                    response.data.status === 'active' &&
                    response?.data?.auction_auction_item_xrefs[0]?.auction?.status === 'live' &&
                    new Date() >= new Date(response?.data?.auction_auction_item_xrefs[0]?.auction?.start_date) &&
                    new Date() < new Date(response?.data?.auction_auction_item_xrefs[0]?.auction?.end_date)
                  );
                }
                else {
                setAuctionActive(
                  response.data.status === 'active' &&
                  response.data?.auction?.status === 'live' &&
                  new Date() >= new Date(a_start_date) &&
                  new Date() < new Date(a_end_date)
                );
              }
             }
              setIsUpcoming(
                response.data.status === 'active' &&
                new Date() < new Date(a_start_date) 
              );
              setIsLoading1(false);
              //meta pixel tracking
              ViewContent("Product",response?.data?.name)
            },
            (error) => {
              logger('./pages/components/organisms/ItemDetails.jsx', 'getLotDetails(): catch1', error);
              setIsLoading1(false);
              navigate('/not-found');
            }
          );
      } catch (err) {
        logger('./pages/components/organisms/ItemDetails.jsx', 'getLotDetails(): catch2', err);
        setIsLoading1(false);
        navigate('/not-found');
      }
    };
    const getCauseInfo = async (cause_id) => {
      try {
        await causeServices.causeInfo(cause_id).then(
          (response) => {
            setCauseData(response?.data);
            setIsLoading2(false);
          },
          (error) => {
            logger('./pages/components/organisms/ItemDetails.jsx', 'getCauseInfo(): catch1', error);
            setCauseData(null);
            setIsLoading2(false);
          }
        );
      } catch (err) {
        logger('./pages/components/organisms/ItemDetails.jsx', 'getCauseInfo(): catch2', err);
        setCauseData(null);
        setIsLoading2(false);
      }
    };
    const checkUserBid = async (uid, item_id) => {
      try {
        await userServices.checkUserBidItem(uid, item_id).then(
          (response) => {
            if(response?.data.length > 0 ){
              setSelectedCauseId(response?.data[0]?.cause_id)
            }
            setCheckUserBid(response?.data);
            setIsLoading3(false);
          },
          (error) => {
            logger('./pages/components/organisms/ItemDetails.jsx', 'checkUserBid(): catch1', error);
            setIsLoading3(false);
            navigate('/not-found');
          }
        );
      } catch (err) {
        logger('./pages/components/organisms/ItemDetails.jsx', 'checkUserBid(): catch2', err);
        setIsLoading3(false);
        navigate('/not-found');
      }
    };
    const getBidDetails = async (auction_id, item_id) => {
      try {
        await auctionServices.getBidDetailsOfItemById(auction_id, item_id).then(
          (response) => {
            setBidDetails(response);
            setIsLoading4(false);
          },
          (error) => {
            logger('./pages/components/organisms/ItemDetails.jsx', 'getBidDetails(): catch1', error);
            setIsLoading4(false);
          }
        );
      } catch (err) {
        logger('./pages/components/organisms/ItemDetails.jsx', 'getBidDetails(): catch2', err);
        setIsLoading4(false);
      }
    };
    const getHighestBiddingAmt = async (item_id) => {
      try {
        await auctionServices.getHighestBidOfItem(item_id).then(
          (response) => {
            setHighestBidAmt(response?.data[0]?.bid_amount);
            setIsLoading5(false);
          },
          (error) => {
            logger('./pages/components/organisms/ItemDetails.jsx', 'getHighestBiddingAmt(): catch1', error);
            setIsLoading5(false);
          }
        );
      } catch (err) {
        logger('./pages/components/organisms/ItemDetails.jsx', 'getHighestBiddingAmt(): catch2', err);
        setIsLoading5(false);
      }
    };
    getLotDetails(auctionId, itemId);
    getCauseInfo(causeId);
    authCtx.isLoggedIn ? checkUserBid(authCtx.userId, itemId) : setIsLoading3(false);;
    getBidDetails(auctionId, itemId);
    getHighestBiddingAmt(itemId);
    getCausesOfItem()
   setTimeout(()=>{
      setLoading(false)
   },1000)
  }, [auctionId, itemId, causeId, refreshDetails]);

  // useEffect(() => {
  //   let id;
  //   if (!lotDetails?.store_item_id) {
  //     id = setTimeout(() => {
  //       setTimeLeft(calculateTimeLeft(lotDetails?.end_date));
  //     }, 1000);
  //   }
  //   else {
  //     id = setTimeout(() => {
  //       setTimeLeft(calculateTimeLeft(lotDetails?.auction?.end_date));
  //     }, 1000);
  //   }
  //   return () => {
  //     clearTimeout(id);
  //   };
  // });

  function handleQuantityIncrement (){
        if(lotDetails?.variants.length > 0){
          if(selectedVaraint?.quantity_in_stock === quantity){
             return
          }
          else{
            setQuantity(quantity + 1)
          }
        }else{
          if(lotDetails?.store_item?.quantity_in_stock === quantity){
            return
          }else{
            setQuantity(quantity + 1)
          }
        }
 }

 function handleQuantityDecrement(){
  if(quantity === 1){
    return
  }else{
    setQuantity(quantity - 1)
  }
 }
  const [drawerPostion, setDrawerPostion] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerPostion(open);
  };

  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false);
  };

  const handleBuyNow = () => {
    if (!lotDetails?.buy_now_price) return;
    if (!authCtx?.isLoggedIn) {
      setOpenConfirmAlert(true);
      return;
    }
    // if(lotDetails?.auction?.market_place === "1" && lotDetails?.can_choose_cause === 1){
    //   if(selectedCauseId === ""){
    //     setModal({isOpen:true,message:constants.APP_COPY_SELECT_CAUSE})
    //     return
    //   }
    // }
    const bidCause = getAuctionItemCauseId()
    if (authCtx?.cardId && authCtx?.cusId && lotDetails?.is_virtual === 0) {
      navigate(`${authCtx.isSavedShip ? '/bag' : '/shipping-info'}`, {
        state: {
          type: 'buy_now',
          user_id: authCtx.userId,
          store_item_id: lotDetails?.store_item_id
            ? lotDetails?.store_item_id
            : null,
          item_name: lotDetails?.name,
          feat_img: lotDetails?.assets && lotDetails?.assets?.find(img=>img.context_type==="portrait")?.name,
          description: lotDetails?.description,
          price:
            lotDetails?.store_item_id && lotDetails?.variants.length === 0
              ? lotDetails?.store_item?.price
              : lotDetails?.store_item_id &&
                lotDetails?.variants &&
                lotDetails?.variants.length > 0 &&
                selectedVaraint
                ? selectedVaraint?.price
                : lotDetails?.buy_now_price,
          product_sku_id:
            lotDetails?.store_item_id && lotDetails?.variants.length === 0
              ? lotDetails?.store_item?.sku
              : lotDetails?.store_item_id &&
                lotDetails?.variants &&
                lotDetails?.variants.length > 0 &&
                selectedVaraint
                ? selectedVaraint?.sku
                : lotDetails?.sku,
            is_virtual: lotDetails?.is_virtual,
            itemId,
            auctionId,
            causeId,
            auction:lotDetails?.auction,
            fulfilment:lotDetails.fulfilment,
            quantity: quantity,
            charge_tax: lotDetails?.store_item_id
            ? lotDetails?.store_item?.charge_tax
            : 1 ,
            // cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId,
            cause_id:bidCause,
            causeSharePercentage: ((Number(lotDetails?.donor?.revenue_splits[0]?.cause_rev_share_percent) / 100) || 0.8),
            productWeight: lotDetails?.weight,
            vendor_sku_id: lotDetails?.vendor_sku_id,
            upc: lotDetails?.upc,
            auction_item_amount_given_dialog:lotDetails?.auction_item_amount_given_dialog
          },
          
          replace: true,
        });
    } else if (
      authCtx?.cardId &&
      authCtx?.cusId &&
      lotDetails?.is_virtual === 1
    ) {
      navigate(`${authCtx.isSavedShip ? '/bag' : '/shipping-info'}`, {
        state: {
          type: 'buy_now',
          user_id: authCtx.userId,
          store_item_id: lotDetails?.store_item_id
            ? lotDetails?.store_item_id
            : null,
          item_name: lotDetails?.name,
          feat_img: lotDetails?.assets && lotDetails?.assets[0]?.name,
          description: lotDetails?.description,
          price:
            lotDetails?.store_item_id && lotDetails?.variants.length === 0
              ? lotDetails?.store_item?.price
              : lotDetails?.store_item_id &&
                lotDetails?.variants &&
                lotDetails?.variants.length > 0 &&
                selectedVaraint
                ? selectedVaraint?.price
                : lotDetails?.buy_now_price,
          product_sku_id:
            lotDetails?.store_item_id && lotDetails?.variants.length === 0
              ? lotDetails?.store_item?.sku
              : lotDetails?.store_item_id &&
                lotDetails?.variants &&
                lotDetails?.variants.length > 0 &&
                selectedVaraint
                ? selectedVaraint?.sku
                : lotDetails?.sku,
          is_virtual: lotDetails?.is_virtual,
          itemId,
          auctionId,
          causeId,
          fulfilment:lotDetails.fulfilment,
          quantity: quantity,
          charge_tax: lotDetails?.store_item_id
            ? lotDetails?.store_item?.charge_tax
            : 1 ,
          cause_id:bidCause,
          causeSharePercentage: ((Number(lotDetails?.donor?.revenue_splits[0]?.cause_rev_share_percent) / 100) || 0.8),
          productWeight: lotDetails?.weight,
          vendor_sku_id: lotDetails?.vendor_sku_id,
          upc: lotDetails?.upc,
          auction_item_amount_given_dialog:lotDetails?.auction_item_amount_given_dialog
        },
      });
    } else {
      navigate('/payment', {
        state: {
          type: 'buy_now',
          user_id: authCtx.userId,
          store_item_id: lotDetails?.store_item_id
            ? lotDetails?.store_item_id
            : null,
          item_name: lotDetails?.name,
          feat_img: lotDetails?.assets && lotDetails?.assets[0]?.name,
          description: lotDetails?.description,
          price:
            lotDetails?.store_item_id && lotDetails?.variants.length === 0
              ? lotDetails?.store_item?.price
              : lotDetails?.store_item_id &&
                lotDetails?.variants &&
                lotDetails?.variants.length > 0 &&
                selectedVaraint
                ? selectedVaraint?.price
                : lotDetails?.buy_now_price,
          product_sku_id:
            lotDetails?.store_item_id && lotDetails?.variants.length === 0
              ? lotDetails?.store_item?.sku
              : lotDetails?.store_item_id &&
                lotDetails?.variants &&
                lotDetails?.variants.length > 0 &&
                selectedVaraint
                ? selectedVaraint?.sku
                : lotDetails?.sku,
          is_virtual: lotDetails?.is_virtual,
          itemId,
          auctionId,
          causeId,
          fulfilment:lotDetails.fulfilment,
          quantity: quantity,
          charge_tax: lotDetails?.store_item_id
            ? lotDetails?.store_item?.charge_tax
            : 1 ,
          cause_id:bidCause,
          causeSharePercentage: ((Number(lotDetails?.donor?.revenue_splits[0]?.cause_rev_share_percent) / 100) || 0.8),
          productWeight: lotDetails?.weight,
          auction_item_amount_given_dialog:lotDetails?.auction_item_amount_given_dialog
        },
      });
    }
  };

  const getFinalCauseAndAuctionId = () => {
    let finalCauseId = lotDetails?.auction?.cause?.id;
    let finalAuctionId = lotDetails?.auction?.id
    if(can_choose_cause.current && selectedCauseId) {
      finalCauseId = selectedCauseId;
    }
    if(xrefAuctionId || lotDetails.auction_auction_item_xrefs?.length) {
      finalAuctionId = xrefAuctionId; /// if there is xrefAuctionId in url then the item is an assigned item and dosen't have this event id in it. It is in auctionAuctionItemXref table.
      finalCauseId = lotDetails.auction_auction_item_xrefs[0]?.auction?.cause_id /// if there is xrefAuctionId then the item is an assigned item. So we make the cause id as default cause, which is added by creating the event/store/marketplace
    }
    return {
      finalCauseId,
      finalAuctionId
    }
  }

  const handleAddToCart = async () => {
    // if(!authCtx.isLoggedIn){
    //   setOpenConfirmAlert(true);
    //   return
    // }
    // if(lotDetails?.auction?.market_place === "1" && lotDetails?.can_choose_cause === 1 && !xrefAuctionId){
    //   if(selectedCauseId === ""){
    //     setModal({isOpen:true,message:constants.APP_COPY_SELECT_CAUSE})
    //     return
    //   }
    // }
      let auction_item_id = lotDetails.id;
      let item_sku = "";
      // let auctionId = lotDetails?.auction?.id
      if(lotDetails.variants.length > 0){
        item_sku = selectedVaraint?.sku;
      }else if(lotDetails.store_item !== null){
        item_sku = lotDetails.store_item.sku
      }else{
        item_sku = lotDetails.sku
      }
      // let causeToSave = (!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId
      // if(xrefAuctionId || lotDetails.auction_auction_item_xrefs?.length) {
      //   auctionId = xrefAuctionId; /// if there is xrefAuctionId in url then the item is an assigned item and dosen't have this event id in it. It is in auctionAuctionItemXref table.
      //   causeToSave = lotDetails.auction_auction_item_xrefs[0]?.auction?.cause_id /// if there is xrefAuctionId then the item is an assigned item. So we make the cause id as default cause, which is added by creating the event/store/marketplace
      // }
      const {finalAuctionId,finalCauseId} = getFinalCauseAndAuctionId()
      const body = {
        auction_item_id,
        item_sku,
        quantity:quantity,
        // cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId,
        cause_id:finalCauseId,
        auction_id:finalAuctionId
      }
      let data;
      if(!authCtx.isLoggedIn) {
        // Here we will create a cart for guest user.
        data = await userServices.addGuestUserCart(body)
      }else {
        data= await  userServices.addToCart(body)
      }
     if(data?.status === 200){
      setModal({isOpen:true,message:data.data.message})
      if(!authCtx.isLoggedIn) {
        localStorage.setItem('guest_user_id', data.data.guest_user_id)
      }
      setCartItem(true)
      dispatch(getCartItems({loggedIn: authCtx.isLoggedIn}))
     //calc price to meta pixel
      let value=  lotDetails?.store_item_id && lotDetails?.variants.length === 0
              ? lotDetails?.store_item?.price
              : lotDetails?.store_item_id &&
                lotDetails?.variants &&
                lotDetails?.variants.length > 0 &&
                selectedVaraint
                ? selectedVaraint?.price
                : lotDetails?.buy_now_price;
      //meta pixel tracking add to cart
    AddToCart({content_ids:auction_item_id,content_name:lotDetails?.name,value,contents:[{id:auction_item_id,name:lotDetails?.name,quantity}],currency:"USD"})
    }else{
       setModal({isOpen:true,message:data.response.data.message})
      }     
    }
    
    const handleCloseModal=()=>{
    setModal({isOpen:false,message:""})
    }

  const handleImageChange = (offset) => {
    if(offset === -1) {
      if(curentImage===0){
        setCurrentImage(landscapeImage.length-1)
      }else{
        setCurrentImage(curentImage - 1)
      }
    }
    else if (offset === 1) {
      if(curentImage===(landscapeImage.length-1)){
        setCurrentImage(0)
      }else{
        setCurrentImage(curentImage + 1)
      }
    }
  }

  const handleGoToEvent= () => {
    if(lotDetails.auction_auction_item_xrefs?.length) {
      let xrefParam = lotDetails.auction_auction_item_xrefs[0]?.auction?.custom_url ? lotDetails.auction_auction_item_xrefs[0]?.auction?.custom_url : lotDetails.auction_auction_item_xrefs[0]?.auction?.id
      navigate(`/event/${xrefParam}`, {state:{from:'event'}})
    }else {
      navigate(`/event/${lotDetails?.auction?.custom_url ? lotDetails?.auction?.custom_url : auctionId}`, {state:{from:'event'}})
    }
  }

  const getCauseName = () => {
    if(xrefCauseData) {
      return xrefCauseData?.legal_name
    }
    else {
      // (!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].legal_name : causeData.legal_name
      if(can_choose_cause.current && itemCauses.length === 1) {
        return itemCauses[0].legal_name;
      }
      else {
        return causeData.legal_name;
      }
    }
  }

  const getAuctionItemCauseId = () => {
    // (!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId
    let causeForAuction = lotDetails?.auction?.cause?.id;
    if(!can_choose_cause.current && itemCauses.length === 1) {
      causeForAuction = itemCauses[0].id;
    }
    else if (selectedCauseId) {
      causeForAuction = selectedCauseId;
    }
    return causeForAuction;
  }

  return (
    <>
      {isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5 ? (
       <LoadingScreen bgColor='#F0E8E3'/>
      ):
      <>
        <Helmet prioritizeSeoTags>
              <title>{lotDetails?.name} | Boundless Giving</title>
              <meta name="description" content={lotDetails?.short_description} />
              <link rel="canonical" href={`${REACT_APP_URL}${window.location.pathname}${window.location.search}`} />
        </Helmet>
        <div className='page_outer lot_page'>
        <div className='container'>
          {/* <Mobile> */}
          
            {/* <HeaderV2 logoType='black2' primaryBackgroundColor="#EFE7E2" primaryColor={mobileStyle.color[1]} containerStyle={{position:'fixed'}} isSubHeader subHeaderTitle='Item Details' /> */}
            <HeaderV3 primaryBackgroundColor="var(--pure-white)" isSubHeader subHeaderTitle='Item Details' position='absolute' />
            {/* <SubHeader backgroundColor={mobileStyle.backgroundColor[2]} color={mobileStyle.color[1]} title='Item Details'/> */}
            {/* <div style={{marginTop:'55px'}}></div> */}
          {/* </Mobile> */}
          {/* <Desktop>
            <Navbar />
            <SubNavbar />
            <BackContainer className='itemdetails-container' />
          </Desktop> */}
          {lotDetails && (
            <main className='margin_class itemdetails-container'>
              {/* <div className='itemdetails-content-container'> */}
                <Mobile>
                  <CarouselCard lotDetails={lotDetails} />
                </Mobile>
                {/* <Desktop> */}
                  {/* <div className='itemdetails-dimages-container'>
                    <div className='itemdetails-dimages-image'>
                      {(lotDetails?.assets && (landscapeImage.length>1))&&<div onClick={() => handleImageChange(-1)} className='itemdetails-dimages-left'><ChevronLeftIcon /></div>}
                      <img style={{display:imageLoading?'none': 'block'}} onLoad={() => {setImageLoading(false)}} src={landscapeImage && landscapeImage[curentImage]?.name} alt="" />
                      <div className={classes.skeleton} style={{height: "320px", width: "100%", aspectRatio: '1.15', display: imageLoading ? 'block' : 'none'}}></div>
                      {(lotDetails?.assets && (landscapeImage.length>1))&&<div onClick={() => handleImageChange(1)} className='itemdetails-dimages-right'><ChevronRightIcon /></div>}
                    </div>
                    <div className='itemdetails-dimages-thumb'>
                    {
                      landscapeImage.map((i, idx) => (
                        <div onClick={() => setCurrentImage(idx)} key={i.name}>
                          <img style={{display:imageLoading?'none': 'block'}} onLoad={() => {setImageLoading(false)}} src={i.name} alt="item" />
                          <div className={classes.skeleton} style={{height: "100%", width: "100%", aspectRatio: '1.15', display: imageLoading ? 'block' : 'none'}}></div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                  <ItemDetailCardLayout alt={lotDetails?.auction_item_alt} imageArray={landscapeImage} landscapeImage={landscapeImage && landscapeImage[curentImage]?.name} onThumbNailClick={(idx) => {setCurrentImage(idx)}} >
                {/* </Desktop> */}
                <div className='itemdetails-content'>
                  <div>
                    {lotDetails.status === 'active' && checkUserBid && !auctionComplete &&
                      checkUserBid.length > 0  ?
                      (
                        +checkUserBid[0].bid_amount === +highestBidAmt ? (
                          <Alert
                            icon={false}
                            // variant='outlined'
                            style={{ backgroundColor: '#D4B4B880',
                                      display:"flex", justifyContent:'center',
                                      fontFamily:"Instrument Sans",
                                      marginBottom:'10px'
                                    }}
                            severity='success'
                          >
                            You are the highest bidder!
                          </Alert>
                        ) : (
                          <Alert
                            icon={false}
                            // variant='outlined'
                          style={{ backgroundColor: '#D4B4B880',
                            display:"flex", justifyContent:'center',
                            fontFamily:"Instrument Sans",
                            marginBottom:'10px'
                          }}
                            severity='error'
                          >
                            You have been outbid.
                          </Alert>
                        )
                      ) : ""
                      }
                    {/* {lotDetails.status === 'sold' && !auction_active && !lotDetails.store_item_id && !auctionComplete && (
                      <Alert
                        icon={false}
                        variant='outlined'
                        style={{ backgroundColor: '#ffebe9' }}
                        severity='error'
                      >
                        Sorry! This item is sold out.
                      </Alert>
                    )} */}
                    {
                      !auction_active && !lotDetails.store_item_id ? 
                      (new Date(lotDetails?.end_date) < new Date()) ? 
                      <Alert
                      icon={false}
                      // variant='outlined'
                      style={{ backgroundColor: '#D4B4B880',
                         display:"flex", justifyContent:'center',
                         fontFamily:"Instrument Sans",
                         marginBottom:'10px'
                    }}
                      severity='error'
                    >
                      Bidding Has Closed.
                    </Alert> : (lotDetails.status === 'sold' && !auctionComplete) && <Alert
                        icon={false}
                        // variant='outlined'
                        // style={{ backgroundColor: '#ffebe9' }}
                        style={{ backgroundColor: '#D4B4B880',
                           display:"flex", justifyContent:'center',
                          fontFamily:"Instrument Sans",
                          marginBottom:'10px'
                      }}
                        severity='error'
                      >
                        Sorry! This item is sold out.
                      </Alert> : null
                    }
                    {!auction_active  && lotDetails.store_item_id && (
                       !hasQuantity ? 
                      <Alert
                        icon={false}
                        // variant='outlined'
                        // style={{ backgroundColor: '#ffebe9' }}
                        style={{ backgroundColor: '#D4B4B880',
                                display:"flex", justifyContent:'center',
                                fontFamily:"Instrument Sans",
                                marginBottom:'10px'
                        }}
                        severity='error'
                      >
                        Sorry! The item is currently out of stock.
                      </Alert>
                      :
                      auctionComplete &&
                      <Alert
                        icon={false}
                        // variant='outlined'
                        // style={{ backgroundColor: '#ffebe9' }}
                        style={{ backgroundColor: '#D4B4B880',
                                display:"flex", justifyContent:'center',
                                fontFamily:"Instrument Sans",
                                marginBottom:'10px'
                        }}
                        severity='error'
                      >
                        Sorry! This event is not live.
                      </Alert>
                    )}
                 {/* {(new Date(lotDetails?.end_date) < new Date())&&!auction_active && lotDetails?.store_item === null && (
                      <Alert
                        icon={false}
                        variant='outlined'
                        style={{ backgroundColor: '#ffebe9' }}
                        severity='error'
                      >
                        Bidding Has Closed.
                      </Alert>
                    )} */}
                  </div>
                    <ItemInfo
                      auctionId={auctionId}
                      itemId={itemId}
                      lotDetails={lotDetails}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                      selectedVaraint={selectedVaraint}
                      setSelectedVariant={setSelectedVariant}
                      bidDetails={bidDetails}
                      // timeLeft={timeLeft}
                      highlight={highlight}
                      setAuctionActive={setAuctionActive}
                      // causeName= {(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].legal_name : causeData.legal_name}
                      causeName= {getCauseName()}
                      cartItem={cartItem}
                      setCartItem={setCartItem}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      handleQuantityIncrement={handleQuantityIncrement}
                      handleQuantityDecrement= {handleQuantityDecrement}
                      refreshPage={refreshPage}
                      loading={loading}
                      auctionComplete={auctionComplete}
                      xrefAuction={lotDetails?.auction_auction_item_xrefs[0]?.auction}
                      auction_active={auction_active}
                      xrefCauseData={xrefCauseData}
                      hasQuantity={hasQuantity}
                      setHasQuantity={setHasQuantity}
                      setOpenConfirmAlert={setOpenConfirmAlert}
                      getFinalCauseAndAuctionId={getFinalCauseAndAuctionId}
                      setModal={setModal}
                      alertState={alertState}
                      setAlertState={setAlertState}
                      eventStatus={eventStatus}
                    />
                      {
                  (lotDetails.can_choose_cause === 1 && itemCauses.length > 0 && !xrefAuctionId) ? 
                  <FormControl sx={{ mt:isDesktop ? 5: 0, width: 1, flex: 1 }} style={{marginBottom: isDesktop ? "25px": '10px'}}>
                      <InputLabel id='cause-select-label'>
                        Select Cause
                      </InputLabel>
                      <Select
                        labelId="cause-select-label"
                        id="cause-select"
                        value={selectedCauseId}
                        onChange={(e)=>{
                          setSelectedCauseId(e.target.value)}}
                        // sx={{ backgroundColor: "white" }}
                        label="Select Cause"
                      >
                        {itemCauses.map((cause) => (
                            <MenuItem key={cause.id} value={cause.id}>
                              {cause.legal_name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  : ""
                }
                      {((lotDetails.status === 'sold' && !auction_active && !lotDetails.store_item_id) || 
                      (!auction_active && !isUpcoming && lotDetails.store_item_id)) || (auctionComplete) || eventStatus !== "live" || lotDetails?.auction?.status !== 'live' ? null : (
                        (lotDetails.store_item !== null && auction_active ) ?
                          (auction_active && !auctionComplete && !cartItem ) ? 
                          <div style={{textAlign:isDesktop?"left":"center",marginBottom:"20px", marginTop:'20px'}} className="add_to_cart_btn">
                          {/* <Desktop>
                          <button onClick={ handleAddToCart}>Add to cart</button>
                          </Desktop> */}
                          {/* <Mobile> */}
                          <NewButton text={'ADD TO CART'} onClick={handleAddToCart} backgroundColor={mainBackgroundColor} color={mobileStyle.color[0]} style={{width:'100%', borderRadius:"25px", padding:'5px 21px', fontSize:'16px', fontFamily:'Instrument Sans'}} />
                          {/* </Mobile> */}
                        </div> :  
                        <div style={{textAlign:isDesktop?"left":"center",marginBottom:"20px", marginTop:'20px'}} className="go_to_cart_btn">
                          {/* <Desktop>
                          <button   onClick={
                            ()=> {
                              if(!authCtx.isLoggedIn){
                                setOpenConfirmAlert(true);
                                return
                              }
                              navigate("/cart")}
                            }>Go to cart</button>
                            </Desktop> */}
                          
                          {/* <Mobile> */}
                          <NewButton text={'GO TO CART'}
                           onClick={
                             ()=> {
                              //  if(!authCtx.isLoggedIn){
                              //    setOpenConfirmAlert(true);
                              //    return
                              //   }
                                navigate("/cart")}}
                                backgroundColor={mainBackgroundColor} color={mobileStyle.color[0]} style={{width:'100%', borderRadius:"25px", padding:'5px 21px', fontSize:'16px', fontFamily:'Instrument Sans'}}
                                />
                          {/* </Mobile> */}
                        </div> : ""
                      )}                   
                    {/* {lotDetails?.store_item_id &&
                      (Object.keys(lotDetails?.variants).length > 0 && !selectedVaraint ? (
                        <div className='main-btn-container-one' style={{ zIndex: '9' }}>
                          <div className='main-btn-button' style={{ backgroundColor: '#787878c4' }} onClick={() => { }}>
                            Buy Now
                          </div>
                        </div>
                      ) : (
                        <div className='main-btn-container-one' style={{ zIndex: '9' }}>
                          <div
                            className='main-btn-button'
                            style={!auction_active ? { backgroundColor: '#787878c4' } : {}}
                            disabled={auction_active}
                            onClick={auction_active ? handleBuyNow : () => { }}
                          >
                            Buy Now
                          </div>
                        </div>
                      ))} */}

                    {eventStatus === "live" && !auctionComplete && !lotDetails?.store_item_id && ((lotDetails.auction?.status!=='paused')) &&((new Date(lotDetails?.end_date) > new Date())||!auctionComplete) &&
                      ((new Date(lotDetails?.start_date) > new Date()) ||
                        new Date(lotDetails?.end_date) < new Date() ||
                        lotDetails.status !== 'active' ? (
                        <FooterButtons buyNow={+lotDetails?.buy_now_price} disabled={true} highlight={highlight} />
                      ) : checkUserBid && checkUserBid.length > 0 ? (
                        <div
                          className="main-btn-container-one"
                          style={{ zIndex: '9' }}
                        >
                          {/* <Desktop>
                          <div
                            className='main-btn-button'
                            style={{
                              margin: '0 7px',
                              background: '#ffff',
                              border: '1px solid var(--primary-btn-clr)',
                              color: 'var(--primary-btn-clr)',
                            }}
                            onClick={() =>
                              {                              
                                if(selectedCauseId == "" && lotDetails?.auction?.market_place === "1" && lotDetails?.can_choose_cause === 1){
                                  setModal({isOpen:true,message:constants.APP_COPY_SELECT_CAUSE})
                                  return
                                  }else{
                                    navigate(
                                      `/lot/${itemId}/place-bid?auction_id=${auctionId}&item_id=${itemId}&cause_id=${causeId}`,
                                      {
                                        state: {
                                          userId: authCtx?.userId,
                                          bidDetails: bidDetails.data,
                                          user_bid: checkUserBid && checkUserBid.length > 0 ? checkUserBid[0] : null,
                                          cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId
                                        },
                                      }
                                      )
                                    }
                                    
                                  }
                                }
                                >
                            {+checkUserBid[0].bid_amount === +highestBidAmt ? (
                              "Go to My Bid"
                              ) : (
                                "Bid Again"
                                )
                              }
                          </div>
                          </Desktop> */}
                          {/* <Mobile> */}
                          <NewButton
                            text={
                              +checkUserBid[0].bid_amount === +highestBidAmt ? (
                                "GO TO MY BID"
                                ) : (
                                  "BID AGAIN"
                                  )
                                  
                                }
                                onClick={() =>
                                  {                              
                                    if(selectedCauseId == "" && lotDetails?.auction?.market_place === "1" && lotDetails?.can_choose_cause === 1){
                                      setModal({isOpen:true,message:constants.APP_COPY_SELECT_CAUSE})
                                      return
                                    }else{
                                      navigate(
                                        `/lot/${itemId}/place-bid?auction_id=${auctionId}&item_id=${itemId}&cause_id=${causeId}`,
                                        {
                                          state: {
                                            userId: authCtx?.userId,
                                            bidDetails: bidDetails.data,
                                            user_bid: checkUserBid && checkUserBid.length > 0 ? checkUserBid[0] : null,
                                            cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId
                                          },
                                        }
                                        )
                                      }
                                      
                                    }
                                  }
                                  backgroundColor={mobileStyle.backgroundColor[0]} color={mainBackgroundColor} style={{width:'100%', borderRadius:"25px", padding:'7px 21px', fontSize:'14px', fontFamily:'Instrument Sans', height:"fit-content"}}
                                  />
                              {/* </Mobile> */}
                          {lotDetails?.buy_now_price && (
                            <>
                            {/* <Desktop>
                            <div className='main-btn-button' style={{ margin: '0 7px', boxShadow: highlight === 'Y' ? '0 5px 18px #1a385c' : 'border-box' }} onClick={handleBuyNow}>
                              Buy Now
                            </div>
                            </Desktop> */}
                            {/* <Mobile> */}
                            <NewButton text={'BUY NOW'} onClick={handleBuyNow} backgroundColor={mainBackgroundColor} color={mobileStyle.color[0]} style={{width:'100%', borderRadius:"25px", padding:'5px 21px', fontSize:'16px', height:"fit-content"}} />
                            {/* </Mobile> */}
                            </>
                          )}
                        </div>
                      ) : ((authCtx?.cardId && authCtx?.cusId)) ? (
                        <FooterButtons
                          buyNow={+lotDetails?.buy_now_price}
                          onClickBid={() =>
                            { 
                              // if(lotDetails?.auction?.market_place === "1" && lotDetails?.can_choose_cause === 1){
                              //   if(selectedCauseId === ""){
                              //     setModal({isOpen:true,message:constants.APP_COPY_SELECT_CAUSE})
                              //     return
                              //   }
                              // }
                              const bidCause = getAuctionItemCauseId()
                              if(!authCtx?.isSavedShip && lotDetails?.process_winner == 1) {
                                navigate('/shipping-info', {
                                  // state: { type: 'bid', itemId, auctionId, causeId, cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId },
                                  state: { type: 'bid', itemId, auctionId, causeId, cause_id:bidCause },
                                })
                              } else {
                                navigate(`/lot/${itemId}/place-bid?cause_id=${causeId}&auction_id=${auctionId}`, {
                                  // state: { type: 'bid', itemId, auctionId, causeId, cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId },
                                  state: { type: 'bid', itemId, auctionId, causeId, cause_id:bidCause },
                                })
                              }
                            }
                          }
                          highlight={highlight}
                          onClickBuy={handleBuyNow}
                        />
                      ) : authCtx.isLoggedIn ? (
                        <FooterButtons
                          buyNow={+lotDetails?.buy_now_price}
                          onClickBid={() =>{
                              // if(selectedCauseId === "" && lotDetails?.auction?.market_place === "1" && lotDetails?.can_choose_cause === 1){
                              //   setModal({isOpen:true,message:constants.APP_COPY_SELECT_CAUSE})
                              //   return
                              // }
                              const bidCause = getAuctionItemCauseId()
                            if(lotDetails.process_winner == 0){
                              navigate(`/lot/${itemId}/place-bid?cause_id=${causeId}&auction_id=${auctionId}`, {
                                // state: { type: 'bid', itemId, auctionId, causeId, cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId },
                                state: { type: 'bid', itemId, auctionId, causeId, cause_id:bidCause },
                              })
                            }else{
                              navigate('/payment', {
                                // state: { type: 'bid', itemId, causeId, auctionId,cause_id:(!can_choose_cause.current && itemCauses.length === 1) ? itemCauses[0].id : selectedCauseId  },
                                state: { type: 'bid', itemId, causeId, auctionId,cause_id:bidCause },
                              })
                            }
                          }
                          }
                          highlight={highlight}
                          onClickBuy={handleBuyNow}
                        />
                      ) : (
                        <FooterButtons
                          buyNow={+lotDetails?.buy_now_price}
                          onClickBid={() => setOpenConfirmAlert(true)}
                          onClickBuy={() => setOpenConfirmAlert(true)}
                          highlight={highlight}
                        />
                      ))}
                      <NewButton text={'KEEP SHOPPING'}
                          onClick={
                             ()=> {
                              navigate(-1, {
                              preventScrollReset: true
                              });}}
                           backgroundColor={mainBackgroundColor} color={mobileStyle.color[0]} style={{width:'100%', borderRadius:"25px", padding:'5px 21px', fontSize:'16px', fontFamily:'Instrument Sans', marginTop:'5px'}}/>
                </div>
                </ItemDetailCardLayout>
              {/* </div> */}
                {/* <Mobile> */}
                  {/* <Accordion defaultActiveKey="0">
                    <CustomAccordion
                      title={'Item Description'}
                      descriptions={lotDetails?.description}
                      errMsg={'No description available!'}
                      eventKey="0"
                      key={1}
                    />
                    <CustomAccordion
                      title={'About the Cause'}
                      descriptions={[<b key={1} style={{ fontWeight: 600 }}>Cause: {causeData?.legal_name}</b>, <br key={2}></br>, <br key={3}></br>, causeData?.about]}
                      errMsg={'No cause found!'}
                      eventKey="1"
                      key={2}
                    />
                    <CustomAccordion
                      title={'About the Donor'}
                      descriptions={[<b key={1} style={{ fontWeight: 600 }}>Donor: {lotDetails?.donor?.org_name}</b>, <br key={2}></br>, <br key={3}></br>, lotDetails?.donor?.about]}
                      errMsg={'No donor found!'}
                      eventKey="3"
                      key={3}
                    />
                  </Accordion> */}


                    <div className='about_item_container'>
                       <div className="about_item_wrapper">
                         <p style={{color:mainColor}} className="item_heading">More About This Item</p>
                         <span style={{color:mainColor}} className='para_fontsize para_about_cause' >
                          {lotDetails?.description ? lotDetails?.description : "No description available!"}
                          </span>
                       </div>
                      { lotDetails?.auction?.market_place !== "1" &&
                       causeData && 
                       <div className="about_item_wrapper">
                         <p style={{color:mainColor}} className="item_heading">About The Cause</p>
                         <span style={{color:mainColor}} className='para_fontsize para_about_cause' >
                          <b>{causeData?.legal_name}</b>
                          {/* <br /> */}
                          <span>
                            {causeData?.about ? causeData?.about : "No details available"}
                          </span>
                         </span>
                       </div>
                       }
                       { lotDetails?.donor && <div className="about_item_wrapper">
                         <p style={{color:mainColor}} className="item_heading">
                           About The Donor
                         </p>
                         <span style={{color:mainColor}} className='para_fontsize para_about_cause' >
                         <b style={{ fontWeight: 600 }}> {lotDetails?.donor?.org_name}</b>
                         {/* <br /> */}
                          {lotDetails?.donor?.about ? lotDetails?.donor?.about : "No details available"}
                         </span>
                       </div>}
                    </div>


                {/* </Mobile> */}
                {/* <Desktop>
                  <div className='itemdetails-about-container'>
                    <div>
                      <h4>Item Description</h4>
                      <p>{lotDetails?.description}</p>
                    </div>
                    { causeData?.legal_name ?
                      <div>
                      <h4>About The Cause You're Supporting: {causeData?.legal_name}</h4>
                      <p>{causeData?.about}</p>
                    </div>:null}
                   {lotDetails?.donor?.org_name && <div>
                      <h4>About The Donor: {lotDetails?.donor?.org_name}</h4>
                      <p>{lotDetails?.donor?.about}</p>
                    </div>}
                  </div>
                </Desktop> */}
                <Mobile>
                  {/* <div
                    className='lot_contact'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',

                    }}
                  >
                    <img
                      src={mail}
                      onClick={(e) => {
                        window.location.href = process.env.REACT_APP_MAILTO;
                        e.preventDefault();
                      }}
                      alt=''
                    />
                    <>
                      <p onClick={() => navigate('/faq')}><b>FAQ</b></p>
                  </>
                </div> */}
               
                </Mobile>
        <Desktop>
          {lotDetails.auction?.status!=='building' && <p style={{ textAlign: "left", marginTop:'78px', paddingBottom:"40px",width:"ft-content" }}>
               <button onClick={handleGoToEvent} style={{borderBottom:`1px solid #30313A`, margin:'0'}} className='to_top_btn'>Return to Event</button>
           </p>}
         </Desktop>
        </main>
          )}
          
        </div>
        {/* <Desktop>
          <Footer style={{marginTop: "20px"}} />
        </Desktop> */}
    {/* <MobileSubFooter primaryBackgroundColor={mainBackgroundColor} primaryColor={mainColor} style={{padding:"2vw 5vw"}}/> */}
    <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />
      </div>
      </>}
      <ConfirmAlertLogin
        openConfirmAlert={openConfirmAlert}
        handleCloseConfirmAlert={handleCloseConfirmAlert}
        context={{
          id: itemId,
          auctionId: auctionId,
          causeId: causeId
        }}
      />

     <DialogMui isOpen={modal.isOpen} message={modal.message} ButtonText='Close' handleClick={handleCloseModal} >
     </DialogMui>

      {/* <Dialog
          open={modal.isOpen}
        >
          <DialogContent sx={{ fontFamily: "AvenirNext" }}>
            <DialogContentText textAlign="left">
              <p style={{margin: "10px 20px", fontSize: "20px", textAlign: "center"}} >
              {modal.message} ppp
              </p>
              
              <button style={{marginTop: "10px"}} onClick={handleCloseModal} className="modal-close-button">Close</button>
            </DialogContentText>
          </DialogContent>
        </Dialog> */}
 {/* {lotDetails?.auction?.status === 'building' && <AdminPreviewBanner title={lotDetails?.auction?.title}/>} */}
 {eventStatus === 'building' && <AdminPreviewBanner title={lotDetails?.auction?.title}/>}
 <SnackBarMui open={alertState.isOpen} closeAlert={handleCloseAlert} message={alertState.message} />
    </>
  );
}

export default ItemDetails;
