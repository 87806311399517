import React,{useState} from 'react';
import DialogMui from '../Dialog/Dialog';
import style from './NonprofitSignup.module.css';
import userServices from '../../../../services/user-services';
import { useEffect } from 'react';


function NonprofitSignup({ isOpen,backgroundColor="#F0E8E3",isBrand,onClose,onSubmitForm}) {
    const [formData,setFormData]=useState({})
    const [error,setError]= useState('');
    const [loading,setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const submitNonprofitForm = async ()=>{
        if(!formData.contact_person || !formData.org_name || !formData.email) {
           return setError('All fields are required')
        }
        if( !formData.email?.includes('@')) {
            return setError('Enter a valid email')
        }
       
        try{
            setLoading(true)
            await userServices.brandOrNonprofitContact({...formData,is_brand:isBrand})
            // onSubmitForm()
            setFormData({})
            setSuccessAlert(true)
        }catch(error){
           setError('Something went wrong')
           setSuccessAlert(false)
        }finally{
            setLoading(false)
        }
    }
    const handleChange = (e)=>{
        if(!e.target) return
        setFormData({...formData,[e.target.name]:e.target.value})
        setError(false)
    }
    const handleClose = () => {
        onClose()
        setSuccessAlert(false);
    }
   
    return (
        <DialogMui onClose={handleClose} handleClick={successAlert ? handleClose : submitNonprofitForm} style={{  backgroundColor:backgroundColor, minWidth:'30vw', maxWidth:'400px'}} ButtonText={successAlert ? 'Close' : 'Submit'} buttonBgColor={isBrand?'#30313A':'#FC3300'} buttonColor='white' isOpen={isOpen} loading={loading} textStyle={{margin:"0px"}} contentRootStyle={{padding:"14px 0px"}}>
            <div className={`${style.nonprofit_signup_container} ${isBrand && style.brand_signup}`}>
                {!successAlert ? <>
                {!isBrand && <h3>Sign Up</h3> }
                {isBrand ? <h3>Become A Brand Partner Today</h3> 
                :
                <p>Get in touch and become a Boundless Giving nonprofit today.</p>}
                <input type='text' placeholder={isBrand?'Company Name':`Nonprofit Name`} name='org_name' onChange={handleChange}/>
                <input type='text' placeholder={isBrand?'Contact Person':'First and Last Name'} name='contact_person' onChange={handleChange}/>
                <input type='email' placeholder='Email' name='email' onChange={handleChange}/>
                {error && <p>{error}</p>}
                </>
                : 
                <>
                  <p className={`${style.thanku_text}`}>Thank you! Someone from our team will be in touch soon</p>
                </>
                }
            </div>
        </DialogMui>
    )
}

export default NonprofitSignup