import React, { useState } from 'react'
import NewButton from '../../../../atoms/NewButton/NewButton'
import classes from './BeTheFirst.module.css'
import { useNavigate } from 'react-router-dom'
import { Desktop, Mobile } from '../../../../responsive/Responsive'
import SignupEmailCampign from '../../../../molecules/SignupEmailCampign/SignupEmailCampign'

const BeTheFirst = ({mobileStyle={backgroundColor:[], color:[]}}) => {
    const [openModal,setOpenModal]=useState(false)
    const backgroundColor = mobileStyle.backgroundColor[0]
    const color = mobileStyle.color[2]
    const handleClick = () => {
      setOpenModal(true)
    }
  return (
  <>
    <section style={{backgroundColor:backgroundColor}}>
        <div className={`${classes.content_wrapper} margin_class`}>
            <p className={classes.content_text} style={{color:color}}>
              <Mobile>
                Be the first to know about the latest Boundless Giving events, auctions, and news
              </Mobile>
              <Desktop>
                Be the first to know about <br /> the latest Boundless Giving <br /> events, auctions, and news
              </Desktop>
            </p>
            <NewButton text={'SIGN UP'} style={{fontSize:'14px'}} onClick={handleClick} backgroundColor={backgroundColor} color={color} />
        </div>
    </section>
    <SignupEmailCampign isOpen={openModal} onClose={()=>setOpenModal(false)} onSubmitForm={()=>setOpenModal(false)} />
  </>
  )
}

export default BeTheFirst