import { ArrowBack, ArrowForward } from "@mui/icons-material";
import classes from './PaginationButton.module.css'

const PaginationButtons = ({onPrevClick=()=> null, onNextClick=()=>null, disabledNext=false, disabledPrev=false}) => {
    return (
        <div className={`${classes.paginationButtonsContainer}`}>
            <button disabled={disabledPrev} onClick={onPrevClick} className={classes.paginationButton} id='swiper-back' ><ArrowBack className={classes.navigateArrow} /></button>
            <button disabled={disabledNext} onClick={onNextClick} className={classes.paginationButton} id='swiper-forward'><ArrowForward className={classes.navigateArrow} /></button>
        </div>
    )
}

export default PaginationButtons;