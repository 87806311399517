import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import GlobalHeader from "../molecules/GlobalHeader/GlobalHeader";
import { Mobile, Desktop } from "../responsive/Responsive"
import Navbar from "../../desktop/components/Navbar/Navbar"
import SubNavbar from "../../desktop/components/SubNavbar/SubNavbar"
import Header from "../../desktop/components/Header/Header"
import Footer from "../../desktop/components/Footer/Footer"
import { Helmet } from "react-helmet-async"
import BackContainer from "../../desktop/components/BackContainer/BackContainer";
import classes from './Preferences.module.css'
import userServices from '../../../services/user-services';
import AuthContext from '../../../store/auth-context';
import authService from "../../../services/auth-services";
import ConfirmAlertLogin from "../ConfirmAlertLogin";
import AccountNavbar from '../../desktop/components/AccountNavbar/AccountNavbar';
import { Alert } from "@mui/material";
import { CircularProgress } from '@mui/material';
import HeaderV2 from '../molecules/Header/HeaderV2';
import SubHeader from '../molecules/SubHeader/SubHeader';
import NeedHelp from '../atoms/NeedHelp/NeedHelp';
import { mobileStyle } from '../Home';
import MobileSubFooter from '../atoms/MobileSubFooter/MobileSubFooter';
import NewFooter from '../molecules/NewFooter/NewFooter';
import HeaderV3 from '../molecules/Header/HeaderV3/HeaderV3';

const
  Preferences = () => {
    const ctx = useContext(AuthContext);
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState({ mailLoading: false })
    const { updateSubscriptionStatus } = ctx;
    useEffect(() => {
      if (ctx?.subscriptionStatus == 1) {
        setChecked(true)
      }
      else {
        setChecked(false)
      }
    }, [ctx?.subscriptionStatus])

    const handleMailPreferenceChane = async (e) => {
      try {
        setLoading({ ...loading, mailLoading: true })
        const data = await userServices.changeMailPreferences(e.target.checked)
        if (data?.status === 200) {
          setChecked(!checked)
          updateSubscriptionStatus(data?.data?.value)
        }
        setLoading({ ...loading, mailLoading: false })
      } catch (error) {
        setLoading({ ...loading, mailLoading: false })
      }
    }


    return (
      <div className={classes.container} >
        <Helmet>
          <title>Preferences | Boundless Giving</title>
        </Helmet>
        <div>

          {/* <HeaderV2 isSubHeader={true} subHeaderTitle='Preferences' containerStyle={{position:'fixed'}} logoType="white2" primaryBackgroundColor="#30313A" primaryColor="#F0E8E3" /> */}
          <HeaderV3 isSubHeader subHeaderTitle='Preferences' primaryBackgroundColor="#30313A" primaryColor="#F0E8E3" position='absolute' />

          {/* <SubHeader title="Preferences" backgroundColor="#30313A" color="#F0E8E3" /> */}
          <div style={{ marginTop: '100px' }} />


            <>
              <div className={`${classes.main} margin_class`} >

                <div className={classes.section_start}>
                  {loading?.mailLoading ?
                    <CircularProgress sx={{ width: '22px !important', height: '22px !important', marginBlock: "auto", color: mobileStyle.color[2] }} />
                    :
                    <input
                      style={{
                        accentColor: mobileStyle.color[1],
                        outline: checked && `1px solid ${mobileStyle.color[2]}`,
                        width: checked && '30px',
                        height: checked && '22px',
                      }}
                      checked={checked} onChange={handleMailPreferenceChane} className={classes.preference_check} type="checkbox" />
                  }
                  <span className={classes.preference_desc}>Grant permission to receive emails from Boundless.</span>
                </div>
                <br />

                <NeedHelp primaryColor="#F0E8E3" style={{ marginTop: '48vh' }} />
             
              </div>
            </>
          

        </div>
        {/* <div className='footer-extra-container-class-30313A' style={{marginTop:'140px'}}>
            <MobileSubFooter needHelp style={{padding:'5vw 5vw'}} primaryBackgroundColor='#30313A' primaryColor='#F0E8E3'/>
         </div> */}
                    <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

      </div>
    )
  }

export default Preferences
