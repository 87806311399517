const TimeLeft = ({ timeLeft, addClasses='' }) => {
  return (
    <span className={`${addClasses}`} style={{fontSize:"13px"}}>
      {timeLeft && timeLeft.minutes
        ? `${timeLeft.days}D ${timeLeft.hours}hrs ${timeLeft.minutes}minutes`
        : timeLeft && !timeLeft.minutes
        ? "Auction Closed"
        : "--:--:--"}
    </span>
  );
};

export default TimeLeft;
