import React from 'react'
import { mobileStyle } from '../../Home' 

const NeedHelp = ({primaryColor=mobileStyle.color[1], style={}}) => {
    return (
        <div style={{ marginTop: "42px", marginBottom: '17px', ...style }} className='track-order-contact'>
            <div style={{color: primaryColor}} className="need_help">
                Need Help?
            </div>
            <a
                href='mailto:help@boundlessgiving.com'
                className="contact_us"
                style={{ color: primaryColor, borderBottom: `1px solid ${primaryColor}`, padding: '2px 1px', width: 'fit-content', textDecoration: "none" }}
            >
                Contact Us
            </a>
            {/* <div className='track-order-numbers'>
                <a style={{ color: 'black' }} href='mailto:help@boundlessgiving.com'>
                    <img src={MailSVG} alt='' /> help@boundlessgiving.com
                </a>
            </div> */}
        </div>
    )
}

export default NeedHelp