import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

import clock from "../../images/clock.svg";
import auctionServices from "../../services/auction-services";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment-timezone";
import { calculateTimeLeft } from "../../utils/util-functions";
import GlobalHeader from "./molecules/GlobalHeader";

import "./Auction.css";
import LotsCard from "./atoms/LotsCard/LotsCard";
import logger from "../../utils/logger";

function Auction() {
  const { auctionId } = useParams();
  const navigate = useNavigate();
  const [auctionDetails, setAuctionDetails] = useState();
  const [auctionItems, setAuctionItems] = useState();
  const [isLoading, setIsLoading] = useState({
    auctionDetails: true,
    auctionItemsList: true,
  });
  const [timeLeft, setTimeLeft] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getAuctionDetails = async (auction_id) => {
      try {
        await auctionServices.getAuctionById(auction_id).then(
          (response) => {
            setAuctionDetails(response?.data);
            setTimeLeft(calculateTimeLeft(response?.data?.end_date));
            setIsLoading({ ...isLoading, auctionDetails: false });
          },
          (error) => {
            logger('./pages/components/Auction.jsx','getAuctionDetails()1: catch',error);
            setIsLoading({ ...isLoading, auctionDetails: false });
            navigate("/not-found");
          }
        );
      } catch (err) {
        logger('./pages/components/Auction.jsx','getAuctionDetails()2: catch',err);
        setIsLoading({ ...isLoading, auctionDetails: false });
        navigate("/not-found");
      }
    };
    const getAuctionItemsList = async (auction_id) => {
      try {
        await auctionServices.getAuctionItemsById(auction_id).then(
          (response) => {
            setAuctionItems(response?.data);
            setIsLoading({ ...isLoading, auctionItemsList: false });
          },
          (error) => {
            logger('./pages/components/Auction.jsx','getAuctionItemsList()1: catch',error);
            setIsLoading({ ...isLoading, auctionItemsList: false });
            navigate("/not-found");
          }
        );
      } catch (err) {
        logger('./pages/components/Auction.jsx','getAuctionItemsList()2: catch',err);
        setIsLoading({ ...isLoading, auctionItemsList: false });
        navigate("/not-found");
      }
    };
    getAuctionDetails(auctionId);
    getAuctionItemsList(auctionId);
  }, [auctionId]);

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(auctionDetails?.end_date));
    }, 10000);

    return () => {
      clearTimeout(id);
    };
  });

  const clickHandler = (i) =>
    navigate(
      `/lot/${i?.id}?auction_id=${i?.auction_id}&cause_id=${auctionDetails?.cause_id}`
    );

  return (
    <>
      {isLoading.auctionDetails && isLoading.auctionItemsList ? (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      ) : (
        <>
          <div className="page_outer">
            <div className="container">
              <GlobalHeader title="Auction" />
              {auctionDetails && (
                <>
                  <div className="auction_banner auction-page-banner">
                    <div className="time_left">
                      <img  src={clock} loading='lazy' alt="" />
                      {new Date(auctionDetails?.start_date) > new Date() ? (
                        <h6>Auction not started</h6>
                      ) : (
                        <h6>
                          {timeLeft && timeLeft.minutes
                            ? `${timeLeft.days}D ${timeLeft.hours}hrs ${timeLeft.minutes}minutes`
                            : timeLeft && !timeLeft.minutes
                            ? "Auction Closed"
                            : "--:--:--"}
                        </h6>
                      )}
                    </div>
                    {auctionDetails?.image1 && !auctionDetails?.image2 ? (
                      <Carousel
                        infiniteLoop={true}
                        showArrows={false}
                        showIndicators={false}
                        showThumbs={false}
                      >
                        {auctionDetails?.image1 && (
                          <div>
                            <img src={auctionDetails?.image1} loading='lazy' alt="img1" />
                          </div>
                        )}
                      </Carousel>
                    ) : (
                      <Carousel
                        infiniteLoop={true}
                        showArrows={false}
                        showIndicators={false}
                        showThumbs={false}
                      >
                        {auctionDetails?.image1 && (
                          <div>
                            <img src={auctionDetails?.image1} loading='lazy' alt="img1" />
                          </div>
                        )}
                        {auctionDetails?.image2 && (
                          <div>
                            <img src={auctionDetails?.image2} loading='lazy' alt="img2" />
                          </div>
                        )}
                        {/* <div>
                  <img src={auctionDetails.image3} alt="" />
                </div> */}
                      </Carousel>
                    )}
                  </div>
                  <main>
                    <div className="auction_society">
                      <div className="auction-society__heading">
                        <h3> {auctionDetails?.title} </h3>
                        <Link
                          className="stylized-underlined-link"
                          onClick={(e) => {e.preventDefault();setIsOpen(true)}}
                         
                        >
                          T&amp;C
                        </Link>
                      </div>
                      <p>
                        {`${auctionDetails.causeDetails?.city}, ${auctionDetails.causeDetails?.state}`}{" "}
                      </p>
                      {new Date(auctionDetails?.start_date) > new Date() ? (
                        <p>
                          {" "}
                          Starts from{" "}
                          {moment(auctionDetails?.start_date).format(
                            "Do MMMM YYYY"
                          )}
                          {", "}
                          {moment(auctionDetails?.start_date)
                            .tz("America/Los_Angeles")
                            .format("ha z")}
                          .{" "}
                        </p>
                      ) : (
                        <p>
                          {" "}
                          Ends on{" "}
                          {moment(auctionDetails?.end_date).format(
                            "Do MMMM YYYY"
                          )}
                          {", "}
                          {moment(auctionDetails?.end_date)
                            .tz("America/Los_Angeles")
                            .format("ha z")}
                          .{" "}
                        </p>
                      )}
                      {/* <Link to="#">
                  <button type="button"> SAVE </button>
                </Link> */}
                      {/* <Link className="info">i</Link> */}
                    </div>

                    <div className="auction_links">
                      <p>
                        <Link
                          to={`/event-overview/${auctionDetails?.id}`}
                        >
                          Event Overview
                        </Link>
                      </p>
                     {auctionDetails?.cause_id ? 
                     <p>
                        <Link
                          to={`/about-cause/${auctionDetails?.cause_id}?auction_id=${auctionDetails?.id}`}
                        >
                          {" "}
                          About This Cause{" "}
                        </Link>
                      </p> 
                      : null}
                    </div>

                    <hr
                      style={{
                        width: "min(100vw, 450px)",
                        position: "relative",
                        left: -15,
                        marginTop: 20,
                      }}
                    />

                    <div className="ourlots_sec">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h4 style={{ fontSize: 13, margin: 0 }}>
                          {" "}
                          {`${auctionItems?.length} Item${
                            auctionItems?.length > 1 ? "s" : ""
                          }`}
                        </h4>
                        {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={filter}
                      alt=""
                      style={{ marginRight: 5, height: 20 }}
                    />
                    <h4 style={{ fontSize: 15, margin: 0 }}> Filters </h4>
                  </div> */}
                      </div>
                      {auctionItems &&
                        auctionItems?.map((i) => (
                          <LotsCard
                            clickHandler={clickHandler}
                            key={i.id}
                            i={i}
                            asset={i.assets[0]}
                          />
                        ))}
                    </div>
                  </main>
                </>
              )}
            </div>
          </div>
          <Modal isOpen={isOpen}>
            <section className="modal-body auction_society">
              <h3 className="heading-3">Terms and Conditions</h3>
              <p>{auctionDetails?.terms_info ? auctionDetails?.terms_info : "Data not available"}</p>
              <button className="modal-close" onClick={() => setIsOpen(false)}>
                Close
              </button>
            </section>
          </Modal>
        </>
      )}
    </>
  );
}

export default Auction;
