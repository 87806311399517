import react from 'react';
import { Link } from 'react-router-dom';
import { Desktop, Mobile } from '../responsive/Responsive';
const  SupportPart = () => {
    return (
        <>
                <div className="part">
                    <p>Find Answers To Frequently Asked Questions <Link to='/faq' style={{borderBottom:'1px solid'}}>here.</Link></p>
                    <p>Still have questions? <a href={process.env.REACT_APP_MAILTO} style={{borderBottom:'1px solid'}}>Send Us An Email.</a></p>
                    <Mobile>
                      <p>Please see our <Link to='/tos' style={{borderBottom:'1px solid'}}>Terms of Service</Link> for detailed Boundless Giving policies.</p>
                      <p>To review the Boundless Giving Privacy Policy please click <Link to="/privacy" style={{borderBottom:'1px solid'}}>here.</Link></p>
                    </Mobile>
                </div>
                <Desktop>
                <div className="part">
                    <p>Please see our <Link to='/tos'>Terms of Service</Link> for detailed Boundless Giving policies.</p>
                    <p>To review the Boundless Giving Privacy Policy please click here: <Link to="/privacy">Privacy Policy</Link></p>
                </div>
                </Desktop>
        </>
    )
}

export default SupportPart