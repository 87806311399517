import React from 'react'
import { mobileStyle } from '../../pages/components/Home'

const Tiktok = ({color=mobileStyle.color[1]}) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6495 0H11.9418V14.9855C11.9418 16.771 10.5158 18.2377 8.74125 18.2377C6.96665 18.2377 5.54064 16.771 5.54064 14.9855C5.54064 13.2319 6.93496 11.7971 8.6462 11.7333V7.97103C4.87517 8.03477 1.83301 11.1275 1.83301 14.9855C1.83301 18.8754 4.93854 22 8.77295 22C12.6073 22 15.7128 18.8435 15.7128 14.9855V7.30143C17.1072 8.32174 18.8184 8.92754 20.6247 8.95944V5.1971C17.8361 5.10145 15.6495 2.80579 15.6495 0Z" fill={color} />
        </svg>
    )
}

export default Tiktok