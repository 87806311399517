import HomePageCarouselAction from "../HomePageCarouselAction/HomePageCarouselAction"
import SwiperComponent from "../SwiperComponent/SwiperComponent"
import classes from './CarouselSectionContainer.module.css'

const CarouselSectionContainer = ({ slidesPerView, children, title = '', headerPara, actionButtonText = '', actionButtonHref = '', breakPoints={},  titleStyle={}, grid={} }) => {
    return (
        <div className={classes.featuredContainer} >
            <div className={classes.featuredHeader}>
                <h2 className={`newTitleClass ${classes.featuredHeading}`} style={{...titleStyle}} >{title}</h2>
                {headerPara && <p className={classes.featuredPara}>{headerPara}</p>}
            </div>
            <div className={classes.carouselContainer} >
                <SwiperComponent grid={grid} slidesPerView={slidesPerView} breakpoints={breakPoints}>
                    {children}
                    <HomePageCarouselAction centerButtonText={actionButtonText} centerButtonHref={actionButtonHref} />
                </SwiperComponent>
            </div>
        </div>
    )
}

export default CarouselSectionContainer;