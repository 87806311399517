import logger from "../utils/logger";

const common = {
    // Add common config values here
    PORT: 8081,
    EVENTS_PAGE_BIG_SCREEN_URL: 'https://events.boundlessgiving.com',
    ASSETS_BASE_URL: "https://bg-assets-bucket-dev.s3.us-east-1.amazonaws.com",
    IMAGES_BASE_URL: "https://images.boundlessgiving.com/images/"
};

const dev = {
    REACT_APP_MODE: 'dev',
    REACT_APP_BASE_URL: "http://localhost:8080",
    AWS_BUCKET_NAME: "bg-assets-bucket-dev",
    REACT_APP_URL: 'http://localhost:8081',
    ...common
};

const qa = {
    REACT_APP_MODE: 'qa',
    REACT_APP_BASE_URL: "https://stage.api.boundlessgiving.com",
    AWS_BUCKET_NAME: "bg-assets-bucket-stage",
    REACT_APP_URL: 'https://stage.boundlessgiving.com',
    ...common
};


const prod = {
    REACT_APP_MODE: 'prod',
    REACT_APP_BASE_URL: "https://api.boundlessgiving.com",
    AWS_BUCKET_NAME: "bg-assets-bucket",
    REACT_APP_URL: 'https://boundlessgiving.com',
    ...common
};
  
export const config = process.env.REACT_APP_MODE === 'prod' ? 
    prod : (process.env.REACT_APP_MODE === "qa" ? qa : dev);
logger('./config/config.js','', config);

export default { config } 