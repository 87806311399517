import { FormControl, InputAdornment, OutlinedInput } from "@mui/material"
import { mobileStyle } from "../../Home";
import { CloseRounded, Search } from '@mui/icons-material'
import { useMediaQuery } from "react-responsive";
import classes from './SearchInput.module.css'

const SearchInput = ({ onChange = () => null, onClick = () => null, value = '', onIconClick = () => null, fullWidth = true, outlinedInputStyle = {}, clearSearchData = () => null, needToClear=false }) => {
    const forMobile = useMediaQuery({
        maxWidth: 768
    })
    const primaryBackgroundColor = mobileStyle.backgroundColor[2];
    const primaryColor = mobileStyle.color[1];
    return (
        <FormControl sx={{ height: forMobile && '47px' }} variant="outlined" fullWidth={fullWidth}>
            <OutlinedInput
                placeholder="Search like you mean it"
                sx={{
                    padding: '2px 10px',
                    minHeight: '40px',
                    borderRadius: "5px",
                    border: `1px solid ${primaryColor}`,
                    color: primaryColor,
                    fontSize: '14px',
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: '18px',
                    fontFamily: 'Instrument Sans',
                    flexDirection: 'row',
                    ".MuiOutlinedInput-input": {
                        ...outlinedInputStyle
                    }
                }}
                id="search-text-search-page"
                fullWidth
                value={value}
                onKeyDown={onClick}
                onChange={onChange}
                endAdornment={
                    <InputAdornment sx={{gap:'9px'}} position="end" >
                        <span onClick={onIconClick}>
                            <Search className={classes.searchIcon}  />
                        </span>
                        {
                            needToClear &&
                            <span onClick={clearSearchData}>
                                <CloseRounded className={classes.searchClearIcon} />
                            </span>
                        }
                        {/* onClick={handleClick} */}
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

export default SearchInput;