import React, { useContext, useEffect, useState } from 'react';
import { mobileStyle } from '../../Home';
import { useNavigate } from 'react-router-dom';
import DesktopCardLayout from '../../atoms/DesktopCardLayout/DesktopCardLayout';
import classes from '../BrowseCauses/BrowseCause.module.css'
import auctionServices from '../../../../services/auction-services';
import BrowsePagesLayout from '../../molecules/BrowsePagesLayout/BrowsePagesLayout';
import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import { changePageNumber, resetItems } from '../../../../redux/itemsReducer';
import { useDispatch } from 'react-redux';
import { FilterContext } from '../../../../store/filter-context';
import { Helmet } from 'react-helmet-async';
import { config } from '../../../../config/config';
const {REACT_APP_URL} = config

const BrowseStores = () => {
    const {reset} = useContext(FilterContext)
    const [storeData, setStoreData] = useState([])
    const [loading, setLoading] = useState(false)
    const params = {
        from: 'browse-store'
    }
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        fetchStores()
        reset()
        dispatch(changePageNumber(1)) /// for changing page number of browseItems page
        dispatch(resetItems())
    }, [])
    const fetchStores = async () => {
        try {
            setLoading(true)
            const data = await auctionServices.getAllStores();
            setStoreData(data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const handleCardClick = (auction) => {
        navigate(`/store/${auction?.custom_url ? auction?.custom_url : auction.id}`
        )
    }
    return (
        <>
            <Helmet>
                    <title>Explore Unique Donor Stores - Boundless Giving</title>
                    <meta 
                      name="description" 
                      content="Explore a variety of thoughtful gifts and unique products at stores like The GOA Gift Shop, Life Rolls on Shop, Lynne Cohen Foundation Marketplace, and more."
                    />
                    <link rel="canonical" href={`${REACT_APP_URL}/browse-stores`} />
            </Helmet>
            <BrowsePagesLayout params={params} loading={loading} helmetTag={'Stores'}>
                {storeData && storeData?.length ? <div className="dbrowse-items-list" style={{ marginBottom: "10px" }}>
                    <div className={classes.causes_grid_container}>
                        {
                            storeData?.map((store, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <DesktopCardLayout imageContainerClass={'browsePageCardImage'}  alt={store?.auction_banner_alt} onCardClick={() => handleCardClick(store)} image={store.image1 + '_small'} mainContainerStyle={{ gap: "10px", margin: 'auto', width: '100%' }}>
                                            <div className="cart-card-content">
                                                <h3 style={{ textAlign: "center",fontWeight:"800" }} >{store?.title}</h3>
                                            </div>
                                        </DesktopCardLayout>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
                    : <div className="no-data" style={{ paddingTop: "4vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
                        Coming Soon!
                    </div>
                }
            </BrowsePagesLayout>
        </>
    )
}

export default BrowseStores;