import React, { useState, useEffect ,useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import auctionServices from "../../services/auction-services";
import { calculateTimeLeft } from "../../utils/util-functions";
import BiddingFaq from "./BiddingFaq";
import ConfirmAlert from "./ConfirmAlert";
import logger from "../../utils/logger";
import {Helmet} from "react-helmet-async"
import { Desktop, Mobile } from "./responsive/Responsive";
import LinkIcon from '@mui/icons-material/Link';
import DialogMui from "./molecules/Dialog/Dialog";
import "../desktop/styles/BidConfirmation.css"
import {useDispatch } from 'react-redux'
import userServices from "../../services/user-services";
import AuthContext from "../../store/auth-context";
import constants from "../../constants/en";
import { mobileStyle } from "./Home";
import HeaderV2 from "./molecules/Header/HeaderV2";
import { useMediaQuery } from "react-responsive";

import styles from './BidConfirmation.module.css'
import classes from './congrats.module.css'
import NewCardLayout from "./atoms/NewCardLayout/NewCardLayout";
import NewButton from "./atoms/NewButton/NewButton";
import MobileSubFooter from "./atoms/MobileSubFooter/MobileSubFooter";
import NewFooter from "./molecules/NewFooter/NewFooter";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";

const primaryBackgroundColor = "var(--pure-white)"
const primaryColor = mobileStyle.color[1]
const redColor = mobileStyle.backgroundColor[0]

function BidConfirmation() {
  const {state} = useLocation();
  const { itemId } = useParams();
  const {search} = useLocation();
  const auctionId = new URLSearchParams(search).get("auction_id");
  const causeId = new URLSearchParams(search).get("cause_id");
  const navigate = useNavigate();
  const [bidDetails, setBidDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [maxBid, setMaxBid] = useState(null);
  const [openDialog, setOpenDialog] = useState(false)
  const [timeLeft, setTimeLeft] = useState(null);
  const [bidMessage, setBidMessage] = useState(null)
  const [userBid, setUserBid] = useState(null);
  const [errorMsg, setErrorMsg] = useState({show:false, message: null})
  const authCtx = useContext(AuthContext);

  const isMobile = useMediaQuery({
    maxWidth: 768
   })

  const dispatch = useDispatch()

  const handleOpenDialog = () => {
    if(bidDetails?.data?.buy_now_price && +maxBid >= +bidDetails?.data?.buy_now_price) {
      setOpenDialog(true)
    } else {
      handleConfirmBid()
    }
  }

  
  const fetchBidDetails = async (auction_id, item_id) => {
    try {
      await auctionServices.fetchBidDetailsOfItemById(auction_id, item_id).then(
        (response) => {
          setBidDetails(response);
          setIsLoading(false);
        },
        (error) => {
          logger('./pages/components/BidConfirmation.jsx','getBidDetails(): catch1',error);
          setIsLoading(false);
        }
      );
    } catch (err) {
      logger('./pages/components/BidConfirmation.jsx','getBidDetails(): catch2',err);
      setIsLoading(false);
    }
  };

  const getUserBid = async (uid, item_id) => {
    try {
      await userServices.checkUserBidItem(uid, item_id).then(
        (response) => {
          setUserBid(response?.data[0]);
        },
        (error) => {
          logger('./pages/components/organisms/ItemDetails.jsx', 'checkUserBid(): catch1', error);
        }
      );
    } catch (err) {
      logger('./pages/components/organisms/ItemDetails.jsx', 'checkUserBid(): catch2', err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchBidDetails(auctionId, itemId)
    getUserBid(authCtx.userId,itemId)
    return () => {
      setBidDetails(null);
    };
  }, [itemId, auctionId,authCtx.userId]);

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(bidDetails?.data?.end_date));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const [drawerPostion, setDrawerPostion] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerPostion(open);
  };

  useEffect(()=>{
    // bidDetails && setMaxBid(getNextBidAmount(bidDetails))
    if(bidDetails) {
      const nextBid = getNextBidAmount(bidDetails)
      if(+nextBid === +bidDetails?.data?.max_bid_users_willing_to_give) {
        setErrorMsg({show:true, message: constants.APP_COPY_SAME_MAX_BID_VALUE_ALERT})
      }
      setMaxBid(nextBid)
    }
  },[bidDetails,userBid])
  
  // console.log(state)
  const handleMaxBid = (e) => {
    if(+e.target.value === +bidDetails?.data?.max_bid_users_willing_to_give) {
      setErrorMsg({show:true, message: constants.APP_COPY_SAME_MAX_BID_VALUE_ALERT})
    }
    else {
      setErrorMsg({show:false, message: null})
    }
    setMaxBid(e.target.value); 
  };

  const handleConfirmBid = async (e=null) => {
    if(e) {
      e.preventDefault();
    }
    setOpenDialog(false)
    const minAllowedAmount = getNextBidAmount(bidDetails)
    if (
      +maxBid >= minAllowedAmount
    ) {
      setIsLoading(true);
      try {
        let payload = {
          bid_amount:
            +bidDetails?.data?.bid_amount + bidDetails?.data?.bid_increments,
          max_bid: +maxBid,
          cause_id:state?.cause_id
        };
        await auctionServices.placeNewBid(auctionId, itemId, payload).then(
          (response) => {
            setBidDetails(response);
            setIsLoading(false);
            let featureImage=bidDetails?.data?.assets?.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait') )?.name
            navigate("/congrats", {
              state: {
                name: bidDetails?.data?.name,
                description: bidDetails?.data?.description,
                feature_img: featureImage,
                msg: response?.msg,
                auctionId: auctionId,
                itemId:itemId,
                causeId:causeId,
                bidAmount:bidDetails?.data?.bid_amount
              },
            });
            
          },
          (error) => {
            logger('./pages/components/BidConfirmation.jsx','handleConfirmBid(): catch1',error);
            // alert(error.response.data.message,"----------");
            setBidMessage(error.response.data.message)
            // error.response.status && navigate(-1,{replace:true})
            fetchBidDetails(auctionId, itemId);
            setErrorMsg({show:false, message: null})
            setIsLoading(false);
          }
        );
      } catch (err) {
        logger('./pages/components/BidConfirmation.jsx','handleConfirmBid(): catch1',err);
        setErrorMsg({show:false, message: null})
        setIsLoading(false);
      }
    } else {
      fetchBidDetails(auctionId, itemId);
      // alert(`Please enter $${minAllowedAmount} or more`)
      setBidMessage(`Please enter $${minAllowedAmount} or more`)
    }
  };

  const handleRedirectToBuyNow = () => {
    setOpenDialog(false);
    navigate(`/lot/${itemId}?auction_id=${auctionId}&cause_id=${causeId}&highlight=Y`);
  }

  const handleRejection = () => {
    setOpenDialog(false);
    // alert("Sorry! You cannot place bid more than Buy Now price.");
    setBidMessage("Sorry! You cannot place bid more than Buy Now price.")
  }

  const getNextBidAmount = (bidDetails) => {
    if(+bidDetails?.data?.bidCount == 0){
      return Number((+bidDetails?.data?.max_bid_users_willing_to_give 
        || +bidDetails?.data?.bid_amount)).toFixed(2);
    }
    else{
      if(bidDetails?.data?.allow_max_bid ==='2'){
        if(+bidDetails?.data?.bid_amount< +userBid?.max_bid){
          return Number(+userBid?.max_bid + bidDetails?.data?.bid_increments).toFixed(2);
        }else{
          return Number(+bidDetails?.data?.bid_amount + bidDetails?.data?.bid_increments).toFixed(2);
        }
      }else{
        return Number(+bidDetails?.data?.max_bid_users_willing_to_give + bidDetails?.data?.bid_increments).toFixed(2);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Bid Confirmation | Boundless Giving</title>
      </Helmet>
      <DialogMui message={bidMessage} isOpen={!!bidMessage} handleClick={() => setBidMessage(null)} ButtonText="Okay" />
      <ConfirmAlert
      alerMsg= {constants.APP_COPY_MAX_BID_PRICE}
      openConfirmAlert={openDialog}
      handleCloseConfirmAlert={handleRejection}
      handleConfirmTask={handleRedirectToBuyNow}
      />
      <div style={{ color:primaryColor}} className="page_outer bid_conf_page dpage_outer_bid">
        <div className="container">
          {/* <Mobile> */}
            {/* <HeaderV2 isSubHeader={true} subHeaderTitle="Enter Bid" logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:'fixed'}} /> */}
            <HeaderV3 isSubHeader subHeaderTitle="Enter Bid"  primaryBackgroundColor={primaryBackgroundColor} position="absolute" />
          {/* </Mobile> */}
          {/* <Desktop>
            <Navbar /> 
            <SubNavbar />
            <Header title="Place Bid" />
          </Desktop> */}
          {isLoading ? (
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "calc(100vh - 103px)",
                  color: "#01709b",
                  backgroundColor: "transparent",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              </Box>
            </div>
          ) : (
            <>
            <main className="margin_class"  style={{paddingTop:isMobile ? '140px' : '170px', width:'auto'}}>
              {/* <Desktop>
              {timeLeft && 
              <div className="num_days">
                {timeLeft?.minutes ? (
                  <p>
                    {`${timeLeft?.days}D ${timeLeft?.hours}H ${timeLeft?.minutes}M`}{" "}
                    LEFT TO BID
                  </p>
                ) : timeLeft && !timeLeft?.seconds ? (
                  <p>Bidding Closed!</p>
                ) : (
                  <p>
                    {`${timeLeft?.minutes}M ${timeLeft?.seconds}S`}{" "}
                    LEFT TO BID
                  </p>
                )}
              </div>
              }

              <div className="dbidpage_container">
                <div className="bid_img">
                  <img
                    src={bidDetails?.data?.assets.filter(img=>img.context_type==='portrait')[0].name}
                    alt="feature_img"
                    style={{width: "100%"}}
                  />
                </div>
                <div className="dlotdetails_container">
                  <div className="lot_details" style={{ marginBottom: 30 }}>
                    <h2 style={{fontWeight: 600}}>
                      {bidDetails?.data?.name}
                    </h2>
                    <Desktop><hr/></Desktop>
                    <div className="lot_stuff">
                      <div className="lot_stuff_row">
                        <p style={{ fontSize: 15, fontWeight: 600 }}>{+bidDetails?.data?.bidCount === 0 ? 'STARTING': 'CURRENT'} BID</p>
                        <p
                          style={{
                            fontSize: 18,
                            fontWeight: 800,
                            color: "var(--primary-blue-clr)",
                          }}
                        >
                          ${bidDetails?.data?.bid_amount ? Number(bidDetails?.data?.bid_amount).toFixed(2) : "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="max_bid_inp">
                    <p>{`Your Bid ${(bidDetails?.data?.allow_max_bid !=='0') ? `(Enter $${getNextBidAmount(bidDetails)} or your maximum bid amount)`:''}`}</p>
                    <div className="max_bid_input_field" style={{fontSize: "17px"}}>
                      <p style={{marginRight: "5px"}}>$</p>
                      <input
                        style={{marginBottom: 0, fontSize: "17px"}}
                        type="number"
                        id="maxBid"
                        name="maxBid"
                        onChange={handleMaxBid}
                        onWheel={(e) => e.target.blur()}
                        value={maxBid}
                        disabled={bidDetails?.data?.allow_max_bid ==='0'?true:false}
                      />
                    </div>
                    
                  </div>
                    {!openDialog && (!isLoading && timeLeft && (timeLeft?.seconds || timeLeft?.minutes || timeLeft?.hours)? (
                    <div
                      className="main-btn-container-one"
                      onClick={handleOpenDialog}
                      style={{margin: "0"}}
                    >
                      <div className="main-btn-button">Continue To Confirm Bid </div>
                    </div>
                  ) : isLoading && timeLeft && timeLeft?.seconds ? (
                    <div className="main-btn-container-one" style={{margin: "0"}}>
                      <div className="main-btn-button" style={{ backgroundColor: "#787878c4" }}>
                        Confirming Your Bid{" "}
                        <span className="loader__dot" style={{ fontSize: "13px" }}>
                          .
                        </span>
                        <span className="loader__dot" style={{ fontSize: "13px" }}>
                          .
                        </span>
                        <span className="loader__dot" style={{ fontSize: "13px" }}>
                          .
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="main-btn-container-one">
                      <div className="main-btn-button" style={{ backgroundColor: "#787878c4" }}>
                        Continue To Confirm Bid
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr style={{marginTop: "40px"}}/>
              </Desktop> */}
              
            {/* <Mobile> */}
              <div className={styles.new_main} >
                <NewCardLayout
                  imageContainerStyle={{padding: "5px", width:"120px", height:"120px"}} neededLine={false}
                  image={bidDetails?.data?.assets.filter(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))[0]?.name} >
                   <div className="cart-card-content">
                     <h1>{bidDetails?.data?.name}</h1>
                     <div style={{gap:'5px'}} className={classes.amount} >
                      <p  style={{fontWeight:700, fontSize:isMobile?"17px":'18px'}} className={classes.price} >${bidDetails?.data?.bid_amount ? Number(bidDetails?.data?.bid_amount).toFixed(2) : "--"}</p>
                      <p style={{margin:'0',fontSize:isMobile?'16px':'18px'}} className={classes.price_label}>{+bidDetails?.data?.bidCount === 0 ? 'Starting': 'Current'} Bid</p>
                     </div>
                     <Desktop>
                      {timeLeft && 
                       <div style={{color:redColor, marginTop:'10px'}} >
                        {timeLeft?.minutes ? (
                         <p className={styles.time_left} >
                            {`${timeLeft?.days ? `${timeLeft.days}${` `} days`: ``} ${timeLeft?.hours ? `${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`: ``}`}{" "}
                            left to bid
                          </p>
                         ) : timeLeft && !timeLeft?.seconds ? (
                           <p className={styles.time_left}>Bidding Closed!</p>
                         ) : (
                           <p className={styles.time_left}>
                             {`${timeLeft?.days ? `${timeLeft.days}${` `} days`:``}  ${timeLeft?.hours ?`${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`:``} ${timeLeft?.seconds ? `${timeLeft.seconds}${` `} sec`:``}`}{" "}
                             left to bid
                           </p>
                         )}
                       </div>
                       }
                       </Desktop>
                   </div>
                </NewCardLayout>
                <div>
                  <div className={styles.input_main}>
                  <div className={styles.label_container} >
                      <span className={styles.label} >Your Bid</span>
                      <span className={styles.sub_label} >
                        {(bidDetails?.data?.allow_max_bid !=='0') ? `(Enter $${getNextBidAmount(bidDetails)} or your maximum bid amount)`:''}
                      </span>
                  </div>
                  <div>
                  <div style={{border:`1px solid ${primaryColor}`}}  className={styles.input_box}>
                   <p className={styles.max_input}>$</p>
                   <input
                        className={styles.max_input}
                        style={{marginBottom: 0,fontSize:!isMobile && '18px'}}
                        type="number"
                        id="maxBid"
                        name="maxBid"
                        onChange={handleMaxBid}
                        onWheel={(e) => e.target.blur()}
                        value={maxBid}
                        disabled={bidDetails?.data?.allow_max_bid ==='0'?true:false}
                      />
                  </div>
                  {
                    errorMsg?.show && <small className={styles.errorMsg}>{errorMsg?.message?.replace('[]', bidDetails?.data?.max_bid_users_willing_to_give)}</small>
                  }
                  </div>
                  </div>
             <Mobile>
                  {timeLeft && 
              <div style={{color:redColor, marginTop:'10px'}} >
                {timeLeft?.minutes ? (
                  <p className={styles.time_left} >
                    {`${timeLeft?.days ? `${timeLeft.days}${` `} days`: ``} ${timeLeft?.hours ? `${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`: ``}`}{" "}
                    left to bid
                  </p>
                ) : timeLeft && !timeLeft?.seconds ? (
                  <p className={styles.time_left}>Bidding Closed!</p>
                ) : (
                  <p className={styles.time_left}>
                    {`${timeLeft?.days ? `${timeLeft.days}${` `} days`:``}  ${timeLeft?.hours ?`${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`:``} ${timeLeft?.seconds ? `${timeLeft.seconds}${` `} sec`:``}`}{" "}
                    left to bid
                  </p>
                )}
              </div>
              }
              </Mobile>
              </div>
              {
                !openDialog && (!isLoading && timeLeft && (timeLeft?.seconds || timeLeft?.minutes || timeLeft?.hours)?
              (
              <>
              <NewButton 
                  onClick={handleOpenDialog}
                  text={'CONTINUE TO CONFIRM BID'}
                  color={redColor} 
                  backgroundColor={primaryBackgroundColor}
                  style={{width: isMobile ? '100%' : '70%', fontSize:"16px", lineHeight:'19px', paddingBlock:'10px', marginTop:!isMobile && '30px'}}
              />
              </>
                ) : isLoading && timeLeft && timeLeft?.seconds ? (
              <>
                <NewButton 
                  text={'Confirming your Bid...'}
                  color={primaryBackgroundColor} 
                  backgroundColor={"#787878c4"}
                  style={{width: isMobile ? '100%' : '70%', fontSize:"16px", lineHeight:'19px', paddingBlock:'10px', border:'0', marginTop:!isMobile && '30px'}}
              />
              </>
                ) : (
              <>
                <NewButton 
                  text={'CONTINUE TO CONFIRM BID'}
                  color={primaryBackgroundColor} 
                  backgroundColor={'#787878c4'}
                  style={{width:isMobile ? '100%' : '70%', fontSize:"16px", lineHeight:'19px', paddingBlock:'10px', border:'0', marginTop:!isMobile && '30px'}}
              />
              </>
                ))
              }
              </div>
            {/* </Mobile> */}

              <div style={{paddingInline:'15px', gap:'7px'}} className="links dlinks">
              <div 
               style={{borderBottom:isMobile && `1px solid ${primaryColor}`, width:isMobile && 'fit-content', fontWeight:600, fontSize:'16px'}}
                onClick={() =>
                  navigate(
                    `/lot/auction/${auctionId}/item/${itemId}/history`
                    , {state:{itemName:bidDetails?.data?.name}}
                  )
                }> <Desktop><LinkIcon/></Desktop> Bid History</div>
              <div style={{borderBottom:isMobile && `1px solid ${primaryColor}`, width:isMobile && 'fit-content', fontWeight:600, fontSize:'16px'}}
               onClick={toggleDrawer(true)}> 
               <Desktop><LinkIcon/></Desktop> How bidding works</div>
              <SwipeableDrawer
                  anchor="bottom"
                  open={drawerPostion}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                >
                  <BiddingFaq />
              </SwipeableDrawer>
              {causeId > 0 ? 
                <div
                 style={{borderBottom:isMobile && `1px solid ${primaryColor}`, width:isMobile && 'fit-content', fontWeight:600, fontSize:'16px'}}
                  onClick={() =>
                    navigate(
                      `/about-cause/${causeId}?auction_id=${auctionId}`,
                      {
                        state: {auctionId:auctionId},
                        replace: true,
                      }
                    )
                  }> <Desktop><LinkIcon/></Desktop> About the cause you're supporting</div>
                : ''}
            </div>
            </main>
            </>
          )}
        </div>
      {/* <Desktop>
        <Footer />
      </Desktop> */}
            {/* <MobileSubFooter style={{padding:'2vw 5vw 7vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}

      </div>
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </>
  );
}

export default BidConfirmation;
