import React from 'react';
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
// import PrimaryBtn from '../../ButtonPrimary';
// import { Desktop, Mobile } from '../../responsive/Responsive';
import NewButton from '../../atoms/NewButton/NewButton';
import { mobileStyle } from '../../Home';


function DialogMui({isOpen,handleClick,onClose=()=>null,message,ButtonText='Click here',children,buttonBgColor='#FCFCFC',buttonColor=mobileStyle.color[0],loading = false,success=false, textStyle={},style={}, contentRootStyle={padding:'14px 20px'}, buttonStyle={}}) {
    return (
        <>

        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{
                ".MuiDialogContent-root": contentRootStyle,
                ".MuiPaper-root": {
                borderRadius: '25px',
                minWidth: '189px',
                paddingTop: '20px',
                justifyContent: 'space-between',
                paddingBottom: '32px',
                paddingInline: '28px',
                gap: '20px',
                backgroundColor:"#FCFCFC",
                zIndex:"1500",
                ...style
              }
            }}
        >
            <DialogContent>
                <DialogContentText textAlign="left">
                    <dd style={{ margin: "10px 10px", fontSize: "18px", textAlign: "center", color:mobileStyle.color[1], ...textStyle }} className='alert-login-signup'>
                       {message?message:children}
                        <NewButton text={ButtonText} backgroundColor={buttonBgColor} color={buttonColor} loading={loading} success={success}
                        
                          style={{
                            marginTop: "12px",
                            fontSize:'16px',
                            
                            paddingBlock:'8px',
                            width:'100%',
                            textTransform:'uppercase',
                            ...buttonStyle
                          }}
                          onClick={handleClick}
                        />
                    </dd>
                     
                </DialogContentText>
            </DialogContent>
        </Dialog>


       {/* <Desktop>
       <Dialog
            open={isOpen}
            sx={{".MuiDialogContent-root": {padding: "14px 20px"}}}
        >
            <DialogContent sx={{ fontFamily: "Cabin" }}>
                <DialogContentText textAlign="left">
                    <dd style={{ margin: "10px 10px", fontSize: "18px", textAlign: "center" }} className='alert-login-signup'>
                       {message?message:children}
                    </dd>
                    <PrimaryBtn
                        style={{margin: 0, height: "38px", fontSize: "14px"}}
                        text={ButtonText}
                        handleClick={handleClick}
                    />
                </DialogContentText>
            </DialogContent>
        </Dialog>
       </Desktop> */}

        </>
    )
}

export default DialogMui