import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'
import logger from '../utils/logger'

export const fetchPreviewAuction = createAsyncThunk(
    'redux/fetchPreviewAuction',
    async ({type, cause_category_id, page, limit}) => {
        const response = await auctionServices.getAllPreviewAuctionsList(page, limit)
        return response
    }
)

const initialState = {
    previewAuction: {
        data: [],
        limit: 20,
        page: 1,
        total_count:0,
        total_page: 0,
    },
    previewLoading: false,
    previewLoadingMore:false,
    error: '',
    from:'',  
}


const previewAuctionSlice = createSlice({
    name: 'previewAuction',
    initialState,
    reducers: {
        resetPreviewAuction:(state,action)=>{
            // state=initialState
            state.previewAuction = {
                data: [],
                limit: 20,
                page: 1,
                total_count:0,
                total_page: 0,               
            }
            state.previewLoading=false
            state.previewLoadingMore=false
            state.error= ''
            state.from=''
        },
        setCompletedFrom:(state, action) => {
            state.from = action.payload.from
        }

    },
    extraReducers: (builder) => {
    
        builder.addCase(fetchPreviewAuction.pending, (state, action) => {
           state.previewAuction.page>1?state.previewLoadingMore=true: state.previewLoading = true;
            state.error = ''
        })

        builder.addCase(fetchPreviewAuction.fulfilled, (state, action) => {
            state.previewAuction = {
                ...state.previewAuction,
                data: [...state.previewAuction?.data, ...action.payload?.data],
                limit: action.payload?.limit,
                page: action.payload?.page,
                total_count: action.payload?.total_count,
                total_page: action.payload?.total_page
            }
            state.previewLoading = false;
            state.previewLoadingMore = false;
            state.error = ''
        })
        builder.addCase(fetchPreviewAuction.rejected, (state, action) => {
            state.previewLoading = false;
            state.previewLoadingMore = false;
            state.error = action.error.message;
            logger('./redux/eventReducer', 'getCategory(): catch1', action.error);
        })
    },
})

export default previewAuctionSlice.reducer;
export const {resetPreviewAuction, setCompletedFrom} = previewAuctionSlice.actions