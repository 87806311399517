import React, { useEffect, useState } from 'react';
import style from './Section2.module.css';
import { Link, useLocation } from 'react-router-dom';

function Section2({fundraise,containerStyle,section2Style={},section2ContainerStyle={}, cardStyle={}, buttonText="GET STARTED", linkTo="/get-started", title=null}) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const location = useLocation()
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
},[])
    return (
        <section style={section2Style} className={style.section2}>
            <div style={{...containerStyle, ...((screenWidth <= 426 && location.pathname === '/') && section2ContainerStyle)}} className={style.cards_container}>
                <h2><strong>{title ? title : "Hosting an event is easier than ever"}</strong></h2>
                <p>Your next fundraiser could be up and running in as little
                     as ten minutes!. We can even do it for you. <span className={style.click_here}><Link to={"/get-started"}>Click here</Link></span></p>
                <div className={style.cards_inner_container}>
                    <div style={cardStyle}>
                        <h3><strong>Auctions</strong></h3>
                        <img src='https://images.leadconnectorhq.com/img/f_webp/q_90/r_900/u_https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6417834c19849c119497c945.png' alt='card' />
                        <p>Online or in-person</p>
                    </div>
                    <div style={cardStyle}>
                        <h3><strong>Pop-Up Shop</strong></h3>
                        <img src='https://images.leadconnectorhq.com/img/f_webp/q_90/r_1200/u_https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6417834c514cd47f33e5eee8.png' alt='card' />
                        <p>E-commerce made easy</p>
                    </div>
                    <div style={cardStyle}>
                        <h3><strong>Combination</strong></h3>
                        <img src='https://images.leadconnectorhq.com/img/f_webp/q_90/r_1200/u_https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6417834c19849ca4c297c944.png' alt='card' />
                        <p>Choose both at once</p>
                    </div>
                </div>
                <div>
                    <Link to={linkTo} >
                        <button>{buttonText}</button>
                    </Link>
                </div>
 
            </div>
        </section>
    )
}

export default Section2