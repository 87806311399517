
import { useNavigate } from 'react-router-dom';
import './BrowseCard.css'
import { useRef, useState, useContext } from 'react';
import classes from "../../../desktop/styles/Home.module.css"
import { Desktop, Mobile } from '../../responsive/Responsive';
import NewCardLayout from '../../atoms/NewCardLayout/NewCardLayout';
import DesktopCardLayout from '../../atoms/DesktopCardLayout/DesktopCardLayout';
import { Tooltip } from '@mui/material';
import AuthContext from '../../../../store/auth-context';

const BottomAction = ({ price, currentBid, action, auction_buyNow, eventPrice, soldOutflag=false, anyBids=false, qty=null, status=null, eventClosed, market_place, showStockAvailability }) => {
  if (action === "buy") {
    // return eventPrice ? (
    //   <div className="action-footer__bid">
    //   <div className="details">
    //   <span className="bid-text">Retail Price</span>
    //   <span className="price gray-text line-through" >${Number(eventPrice).toFixed(0)}</span>
    //   </div>
    //   <div className="details">
    //   <span className="bid-text">Boundless Price</span>
    //     <span className="price" style={{fontWeight: 700}}>${Number(price).toFixed(0)}</span>

    //   </div>
    //   {(qty && qty < 5 && qty > 0) ? 
    //     <div className="details" style={{color: "red"}}> 
    //       {/* <span>&nbsp;</span> */}
    //       <span className='red_alert'>only {qty} left</span>
    //     </div> : ""}
    //   {(soldOutflag) ? 
    //     <div className="details" style={{color: "red"}}> 
    //       {/* <span>&nbsp;</span> */}
    //       <span className='red_alert'>Sold out</span>
    //     </div>:""}     
    // </div>    
    // ) : (
    //   <div className="action-footer__bid">
      
    //   <div className="details">
    //   <span className="bid-text">{ "Boundless Price"}</span>
    //   <span className="price" style={{fontWeight: 700}}>${Number(price).toFixed(0)}</span>

    //   </div>
    //   {(soldOutflag) ? 
    //     <div className="details" style={{color: "red"}}> 
    //       {/* <span>&nbsp;</span> */}
    //       <span className='red_alert'>Sold out</span>
    //     </div> : ""}
    //      {
    //     (eventClosed) ?
    //     <div className="details" style={{color: "red"}}>
    //       {/* <span>&nbsp;</span> */}
    //       <span className='red_alert'>Event Closed</span>
    //     </div>: ""}
    // </div>
    // );


return (
    <div className="action-footer__bid">
     {eventPrice && ( 
    <div className="details">
    <span className="price gray-text line-through store_item_price" >${Number(eventPrice).toFixed(0)}</span>
    <span className="bid-text">Retail Price</span>
    </div>
    )}
    <div className="details min_height_class">
      <span className="price store_item_price" style={{fontWeight: 900}}>${Number(price).toFixed(0)}</span>
    <span className="bid-text">Boundless Price</span>
    </div>
    {(qty && qty < 5 && qty > 0 && showStockAvailability) ? 
      <div className="details red_details" style={{color: "red"}}> 
        {/* <span>&nbsp;</span> */}
        <span className='red_alert'>only {qty} left</span>
      </div> : 
    (soldOutflag && showStockAvailability) ? 
      <div className="details red_details" style={{color: "red"}}> 
        {/* <span>&nbsp;</span> */}
        <span className='red_alert'>Sold out</span>
      </div>:
       <div className="details red_details" style={{color: "red", opacity:0}}> 
        <span className='red_alert'>Div for alignment 1</span>
        </div>
      }
       {
      (eventClosed) ?
      <div className="details red_details" style={{color: "red"}}>
        {/* <span>&nbsp;</span> */}
        <span className='red_alert'>Event Closed</span>
      </div>:""
      }
  </div>

  )

  }
  // return anyBids ? (
  //   <div className="action-footer__bid">
  //     <div className="details">
  //     <span className="bid-text">Current Bid</span>
  //       <span style={{fontWeight: 700}} className="price">${Number(currentBid).toFixed(2) ?? "0.00"}</span>
       
  //     </div>
  //     {auction_buyNow && <div className="details">
  //     <span className="bid-text">Buy Now</span>
  //       <span style={{fontWeight: 700}} className="price">${Number(auction_buyNow).toFixed(2)}</span>
       
  //     </div>}
  //     {(status === 'sold' && soldOutflag) ? 
  //       <div className="details" style={{color: "red"}}> 
  //         {/* <span>&nbsp;</span> */}
  //         <span className='red_alert'>Sold</span>
  //       </div>:""}
      
  //   </div>
  // ) : (
  //   <div className="action-footer__bid">
  //     <div className="details">
  //     <span className="bid-text">Opening Bid</span>
  //       <span style={{fontWeight: 700}} className="price">${Number(currentBid).toFixed(2) ?? "0.00"}</span>
      
  //     </div>
  //     {auction_buyNow ? <div className="details">
  //       <span className="bid-text">Buy Now</span>
  //       <span style={{fontWeight: 700}} className="price">${Number(auction_buyNow).toFixed(2)}</span>
       
  //     </div> : 
  //     <div className="details">
  //       <span className="bid-text"></span>
  //       <span className="price"></span>
  //     </div>}
  //     {(status === 'sold' && soldOutflag) ? 
  //       <div className="details" style={{color: "red"}}> 
  //         {/* <span>&nbsp;</span> */}
  //         <span className='red_alert'>Sold</span>
  //       </div>:""}
  //   </div>


  return (
  <div className="action-footer__bid">
      { anyBids ? <div className="details">
        <span style={{fontWeight: 900}} className="price">${Number(currentBid).toFixed(2) ?? "0.00"}</span>
      <span className="bid-text">Current Bid</span>
      </div> : 
      <div className="details">
        <span style={{fontWeight: 900}} className="price">${Number(currentBid).toFixed(2) ?? "0.00"}</span>
      <span className="bid-text">Opening Bid</span>
      </div>
      }
      {auction_buyNow ? <div className="details">
        <span style={{fontWeight: 900}} className="price">${Number(auction_buyNow).toFixed(2)}</span>
      <span className="bid-text">Buy Now</span>
      </div> :""
      }
      {(status === 'sold' && soldOutflag && showStockAvailability) ? 
        <div className="details red_details" style={{color: "red"}}> 
          {/* <span>&nbsp;</span> */}
          <span className='red_alert'>Sold</span>
        </div>: 
        <div className="details red_details" style={{color: "red", opacity:0}}> 
        <span className='red_alert'>Div for alignment 5</span>
        </div>
        }

     {
      (eventClosed) ?
      <div className="details red_details" style={{color: "red"}}>
        {/* <span>&nbsp;</span> */}
        <span className='red_alert'>Event Closed</span>
      </div>: ""
      }
    </div>
  );
};

const BrowseCard = ({ banner, status, description, price, action, inStyle={}, params, featuredItem=false, imageContainerStyle={}, needXref=true, handleFilterApply=()=>null, updateFavourite=false, ...props }) => {
  const elemRef = useRef()
  const finalAction = props?.store_item_id ? 'buy' : 'bid'
  let finalPrice = price
  let eventPrice = null;
  let soldOutflag = true;
  let qty = null;
  let anyBids = false;
  let eventClosed = false;
  let eventIsLive = props.auction.status === 'live';
  if(props?.auction_auction_item_xrefs?.length) {
    if( props?.auction_auction_item_xrefs[0]?.auction?.status === "live") {
      eventIsLive = true;
    }
    else {
      eventIsLive = false;
    }
  }
  const navigate = useNavigate()
  const {isLoggedIn, userId} = useContext(AuthContext)
  if(finalAction === 'buy') {
    finalPrice = props.store_item.price
    eventPrice = props.store_item.list_price
    soldOutflag = (props.store_item.quantity_in_stock === 0) ? true : false;
    qty = props.store_item.quantity_in_stock;
    // if(props?.store_item && !props.store_item?.store_item_actual_inventories?.length ) {
    //   qty = props.store_item.quantity_in_stock;
    // }
    // else {
    //   for(let variant of props.store_item?.store_item_actual_inventories) {
    //     if(variant?.quantity_in_stock > 0) {
    //        qty = variant?.quantity_in_stock;
    //        break;
    //     }

      // }
    // }
  }
  if(props.highestBid && props.highestBid.length >0 ){
    anyBids=true;
  }
  let currentBid = props?.highestBid && props?.highestBid[0]?.bid_amount ? props?.highestBid[0]?.bid_amount : price
  const { id, auction_id, auction: { cause_id }} = props
  const handleClick = () => {
    // if(soldOutflag) return;
    if(elemRef.current) {
      localStorage.setItem("browseScroll", elemRef.current.offsetTop-elemRef.current.scrollTop)
    }
    let xrefAuctionId;
    if(props?.auction_auction_item_xrefs?.length && needXref) {
      xrefAuctionId = props?.auction_auction_item_xrefs[0]?.auction_id;
      const xrefCauseId = props?.auction_auction_item_xrefs[0]?.auction?.cause?.id || cause_id;
      navigate(`/lot/${id}?auction_id=${auction_id}&cause_id=${xrefCauseId}&xref_auction_id=${xrefAuctionId}`)
    }else {
      navigate(`/lot/${id}?auction_id=${auction_id}&cause_id=${cause_id}`)
    }
  }
  if(props?.store_item && props?.auction?.status === "complete"){
      eventClosed= true
  }
  if(props?.store_item && props?.auction_auction_item_xrefs?.length && props?.auction_auction_item_xrefs[0]?.auction?.status !== "complete") {
    eventClosed= false
  }
  // Use finalAction, if you want to use description as title too.
  return (
    <>
    <Desktop>
    {/* <div onClick={handleClick} ref={elemRef} style={inStyle} className="browse-card">
      <div className="banner">
        <img style={{display:imageLoading ? "none" : 'block'}} onLoad={() => {setImageLoading(false)}} src={banner} alt="" />
        <div className={classes.skeleton} style={{ width: "100%", aspectRatio: '1.15', display: imageLoading ? 'block' : 'none'}}></div>
      </div>
      <div className='browse-card-details'>
        <header className={`heading ${action}`}>{props?.name}</header>
        <BottomAction market_place={props?.auction?.market_place} auction_buyNow={props?.buy_now_price} price={finalPrice} currentBid={currentBid} action={finalAction} eventPrice={eventPrice} soldOutflag={soldOutflag} anyBids={anyBids} qty={qty} status={status} eventClosed={eventClosed}/>        
      </div>
    </div> */}
    <div style={{cursor:"pointer"}} onClick={handleClick} ref={elemRef}>
    <DesktopCardLayout favourites={isLoggedIn && eventIsLive} alt={props?.auction_item_alt} imageContainerStyle={imageContainerStyle} image={banner} contentContainerStyle={{gap:'5px'}} auctionItem={props} handleFetchData={handleFilterApply} updateFavourite={updateFavourite}>
      <h4 className='desk_browse_card_header'  >
        { props?.auction?.market_place == '1' && <span className='desk_browse_card_heading' > {props?.auction?.title} </span>}
        {  props?.name?.length > 35 ?
           <Tooltip 
              title={
                <span style={{ fontSize:"12px", lineHeight:"19px", fontWeight:600}} className='desk_browse_card_heading'>
                    {props?.name}
                </span>} 
               placement='top' >
             <span style={{fontWeight:props?.auction?.market_place == '1' && 400, cursor:"pointer"}} className='desk_browse_card_heading'>
              {/* { props?.name?.length > 35 ?`${props?.name?.slice(0,35)}...` : props?.name} */}
              {props?.name}
             </span>
           </Tooltip>
          :<span style={{fontWeight:props?.auction?.market_place == '1' && 400}} className='desk_browse_card_heading'>
             {props?.name}
          </span>
        }
      </h4>
      <BottomAction  market_place={props?.auction?.market_place} auction_buyNow={props?.buy_now_price} price={finalPrice} currentBid={currentBid} action={finalAction} eventPrice={eventPrice} soldOutflag={soldOutflag} anyBids={anyBids} qty={qty} status={status} eventClosed={eventClosed} showStockAvailability={props.show_stock_availability}/>        
    </DesktopCardLayout>
    </div>
    </Desktop>
    <Mobile>
      <div style={{cursor:"pointer"}} onClick={handleClick} ref={elemRef}>
    { featuredItem ? 
          <DesktopCardLayout alt={props?.auction_item_alt} imageContainerStyle={imageContainerStyle} image={banner} contentContainerStyle={{gap:'5px'}}>
          <h4 className='desk_browse_card_header'  >
            { props?.auction?.market_place == '1' && <span className='desk_browse_card_heading' > {props?.auction?.title} </span>}
            {  props?.name?.length > 35 ?
               <Tooltip 
                  title={
                    <span style={{ fontSize:"12px", lineHeight:"19px", fontWeight:600}} className='desk_browse_card_heading'>
                        {props?.name}
                    </span>} 
                   placement='top' >
                 <span style={{fontWeight:props?.auction?.market_place == '1' && 400, cursor:"pointer"}} className='desk_browse_card_heading'>
                  {/* { props?.name?.length > 35 ?`${props?.name?.slice(0,35)}...` : props?.name} */}
                  {props?.name}
                 </span>
               </Tooltip>
              :<span style={{fontWeight:props?.auction?.market_place == '1' && 400}} className='desk_browse_card_heading'>
                 {props?.name}
              </span>
            }
          </h4>
          <BottomAction  market_place={props?.auction?.market_place} auction_buyNow={props?.buy_now_price} price={finalPrice} currentBid={currentBid} action={finalAction} eventPrice={eventPrice} soldOutflag={soldOutflag} anyBids={anyBids} qty={qty} status={status} eventClosed={eventClosed} showStockAvailability={props.show_stock_availability}/>        
        </DesktopCardLayout>
      :
      <NewCardLayout favourites={isLoggedIn && eventIsLive} alt={props?.auction_item_alt} image={banner} imageContainerStyle={{width:'147px', height:"147px"}} auctionItem={props} handleFetchData={handleFilterApply} updateFavourite={updateFavourite}>
      <div className='cart-card-content'>
      { props?.auction?.market_place == '1' && <h1 style={{fontSize:"14px",fontWeight:"800", marginBottom:'0px', fontFamily:'Instrument Sans Bold'}} > {props?.auction?.title} </h1>}
        <h1 className={`heading ${action}`} style={{fontSize:"14px",fontWeight:"400", paddingTop:"0px",   fontFamily: 'Instrument Sans'}}>{props?.name}</h1>
        <BottomAction  market_place={props?.auction?.market_place} auction_buyNow={props?.buy_now_price} price={finalPrice} currentBid={currentBid} action={finalAction} eventPrice={eventPrice} soldOutflag={soldOutflag} anyBids={anyBids} qty={qty} status={status} eventClosed={eventClosed} showStockAvailability={props.show_stock_availability}/>        
      </div>
      </NewCardLayout>}
      </div>
    </Mobile>
    </>
  );
};

export default BrowseCard;
