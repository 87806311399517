import React from 'react'

const BGLogoComponent = ({ color = "#F0E8E3", type = 'large', width='100%', height='56' }) => {
  return (
    <>
      {
        type !== "large" ?
        /// svg for small logo
          <svg width="50" height="40" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_637_654)">
              <path d="M0 25H5.45195V24.2483H0.754223V0.751718H5.45195V0H0V25Z" fill={color} />
              <path d="M31.5479 0V0.751718H36.2456V24.2483H31.5479V25H36.9998V0H31.5479Z" fill={color} />
              <path d="M14.4596 12.2638C16.2913 11.9631 17.6273 10.4382 17.6273 8.16156C17.6273 5.26207 16.1189 3.26465 12.3693 3.26465H6.63721V21.714H12.3908C16.2482 21.714 17.9721 19.8454 17.9721 16.6238C17.9721 14.1968 16.6576 12.7148 14.4596 12.2638ZM10.8393 6.70107H11.809C13.2744 6.70107 13.6407 7.60314 13.6407 8.78441C13.6407 9.92272 13.2959 10.7818 11.809 10.7818H10.8393V6.70107ZM11.9168 18.2775H10.8393V14.025H11.9168C13.4252 14.025 13.8346 14.8841 13.8346 16.1727C13.8346 17.5258 13.4252 18.2775 11.9168 18.2775Z" fill={color} />
              <path d="M24.6308 3.0498C20.4071 3.0498 18.6616 5.94929 18.6616 11.2543V13.7672C18.6616 19.0721 20.2132 21.9716 23.8981 21.9716C25.3634 21.9716 26.6995 21.3488 27.152 20.1031L27.4753 21.7354H30.3628V12.2208H24.9324V15.4424H26.29V15.7001C26.29 17.3324 25.9668 18.4063 24.6954 18.4063C23.4671 18.4063 23.1008 17.3539 23.1008 15.7001V9.21389C23.1008 7.58159 23.3594 6.52919 24.6308 6.52919C25.9022 6.52919 26.1607 7.58159 26.1607 9.21389V10.4381H30.3628V9.96561C30.3628 5.67008 28.8329 3.0498 24.6308 3.0498Z" fill={color} />
            </g>
            <defs>
              <clipPath id="clip0_637_654">
                <rect width="50" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          :
          /// svg for large logo
          <svg width={width} height={height} viewBox="0 0 215 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_173_964)">
              <path d="M104.178 40.1084H103.013L100.747 46.2462H102.1L102.573 44.8789H104.965L105.437 46.2462H106.853L104.587 40.1084H104.178ZM102.887 43.8762L103.737 41.3846L104.587 43.8762H102.887Z" fill={color} />
              <path d="M111.795 44.6966L109.403 40.1084H107.672V46.2462H108.994V41.658L111.354 46.2462H113.117V40.1084H111.795V44.6966Z" fill={color} />
              <path d="M121.048 40.4429C120.576 40.1694 120.009 40.0479 119.348 40.0479C118.687 40.0479 118.152 40.1694 117.68 40.4429C117.177 40.7163 116.799 41.0809 116.516 41.5367C116.232 41.9925 116.106 42.5394 116.106 43.1775C116.106 43.6333 116.201 44.0891 116.358 44.4537C116.516 44.8487 116.736 45.183 117.051 45.4564C117.334 45.7299 117.68 45.9426 118.089 46.0945C118.498 46.2464 118.908 46.3376 119.38 46.3376C119.852 46.3376 120.292 46.2464 120.67 46.0945C121.079 45.9426 121.426 45.7299 121.709 45.4564C121.992 45.183 122.212 44.8487 122.401 44.4537C122.559 44.0587 122.653 43.6333 122.653 43.1775C122.653 42.5394 122.527 41.9925 122.244 41.5367C121.929 41.0506 121.551 40.6859 121.048 40.4429ZM121.016 44.2714C120.859 44.6056 120.639 44.8487 120.355 45.031C120.072 45.2133 119.757 45.3045 119.38 45.3045C119.002 45.3045 118.687 45.2133 118.404 45.031C118.121 44.8487 117.9 44.6056 117.743 44.2714C117.586 43.9372 117.491 43.5725 117.491 43.1471C117.491 42.7218 117.554 42.3571 117.712 42.0533C117.869 41.7494 118.089 41.5063 118.373 41.324C118.656 41.1417 118.971 41.0809 119.348 41.0809C119.726 41.0809 120.041 41.1721 120.324 41.324C120.607 41.5063 120.828 41.7494 120.985 42.0533C121.142 42.3571 121.237 42.7218 121.237 43.1471C121.237 43.5725 121.174 43.9372 121.016 44.2714Z" fill={color} />
              <path d="M127.689 43.7546C127.689 44.2712 127.563 44.6662 127.343 44.9093C127.091 45.1523 126.776 45.2739 126.336 45.2739C125.926 45.2739 125.58 45.1523 125.328 44.9093C125.077 44.6662 124.982 44.2712 124.982 43.7242V40.1084H123.629V43.5115C123.629 44.4231 123.849 45.122 124.29 45.6081C124.73 46.0943 125.423 46.3374 126.336 46.3374C127.248 46.3374 127.941 46.0943 128.381 45.6081C128.822 45.122 129.074 44.4231 129.074 43.5419V40.1084H127.72V43.7546H127.689Z" fill={color} />
              <path d="M129.86 41.1111H131.938V46.2462H133.291V41.1111H135.368V40.1084H129.86V41.1111Z" fill={color} />
              <path d="M137.572 40.1084H136.219V46.2462H136.848H137.572H140.625V45.2435H137.572V40.1084Z" fill={color} />
              <path d="M142.89 43.6027H145.975V42.6304H142.89V41.1111H146.164V40.1084H142.89H142.167H141.537V46.2462H142.167H142.89H146.258V45.2435H142.89V43.6027Z" fill={color} />
              <path d="M147.014 41.1111H149.091V46.2462H150.444V41.1111H152.522V40.1084H147.014V41.1111Z" fill={color} />
              <path d="M156.11 40.1084H155.48V46.2462H156.834V43.8762H159.761V42.8735H156.834V41.1111H159.95V40.1084H156.834H156.11Z" fill={color} />
              <path d="M165.583 40.4429C165.111 40.1694 164.544 40.0479 163.883 40.0479C163.222 40.0479 162.687 40.1694 162.215 40.4429C161.712 40.7163 161.334 41.0809 161.051 41.5367C160.768 41.9925 160.642 42.5394 160.642 43.1775C160.642 43.6333 160.736 44.0891 160.893 44.4537C161.051 44.8487 161.271 45.183 161.586 45.4564C161.869 45.7299 162.215 45.9426 162.624 46.0945C163.034 46.2464 163.443 46.3376 163.915 46.3376C164.387 46.3376 164.828 46.2464 165.205 46.0945C165.614 45.9426 165.961 45.7299 166.244 45.4564C166.527 45.183 166.748 44.8487 166.936 44.4537C167.094 44.0587 167.188 43.6333 167.188 43.1775C167.188 42.5394 167.062 41.9925 166.779 41.5367C166.464 41.0506 166.087 40.6859 165.583 40.4429ZM165.52 44.2714C165.363 44.6056 165.142 44.8487 164.859 45.031C164.576 45.2133 164.261 45.3045 163.883 45.3045C163.506 45.3045 163.191 45.2133 162.908 45.031C162.624 44.8487 162.404 44.6056 162.247 44.2714C162.089 43.9372 161.995 43.5725 161.995 43.1471C161.995 42.7218 162.058 42.3571 162.215 42.0533C162.373 41.7494 162.593 41.5063 162.876 41.324C163.16 41.1417 163.474 41.0809 163.852 41.0809C164.23 41.0809 164.544 41.1721 164.828 41.324C165.111 41.5063 165.331 41.7494 165.489 42.0533C165.646 42.3571 165.74 42.7218 165.74 43.1471C165.772 43.5725 165.709 43.9372 165.52 44.2714Z" fill={color} />
              <path d="M172.224 43.5114C172.57 43.3595 172.822 43.1468 173.011 42.8733C173.2 42.5999 173.294 42.296 173.294 41.9314C173.294 41.5668 173.2 41.2629 173.011 40.9895C172.822 40.716 172.57 40.5033 172.224 40.3514C171.878 40.1994 171.469 40.1387 171.028 40.1387H168.195V46.2765H169.549V43.7545H169.769L171.783 46.2765H173.609L171.28 43.7545C171.626 43.6937 171.941 43.633 172.224 43.5114ZM170.996 41.111C171.311 41.111 171.563 41.1718 171.752 41.3237C171.909 41.4756 172.004 41.6579 172.004 41.9314C172.004 42.2049 171.909 42.3872 171.752 42.5391C171.563 42.691 171.343 42.7518 170.996 42.7518H169.549V41.111H170.996Z" fill={color} />
              <path d="M179.589 43.9069H181.006V44.9096C180.88 45.0311 180.722 45.1223 180.502 45.1831C180.25 45.2742 179.967 45.3046 179.589 45.3046C179.149 45.3046 178.803 45.2135 178.488 45.0615C178.205 44.8792 177.953 44.6361 177.827 44.3323C177.669 43.998 177.607 43.6334 177.607 43.1776C177.607 42.7523 177.669 42.3572 177.827 42.0534C177.984 41.7495 178.205 41.5065 178.456 41.3241C178.74 41.1722 179.054 41.0811 179.432 41.0811C179.841 41.0811 180.156 41.1722 180.439 41.3545C180.691 41.5368 180.88 41.8103 180.943 42.1445H182.296C182.265 41.7192 182.107 41.3545 181.855 41.0507C181.604 40.7468 181.257 40.4734 180.848 40.291C180.439 40.1087 179.967 40.0176 179.432 40.0176C178.803 40.0176 178.236 40.1391 177.764 40.4126C177.292 40.6861 176.914 41.0507 176.631 41.5368C176.348 42.023 176.222 42.5699 176.222 43.1776C176.222 43.8157 176.348 44.3627 176.631 44.8184C176.883 45.2742 177.292 45.6692 177.764 45.9123C178.267 46.1858 178.834 46.3073 179.526 46.3073C180.156 46.3073 180.691 46.2162 181.163 46.0339C181.604 45.8515 182.013 45.6085 182.359 45.2742V42.8434H179.589V43.9069Z" fill={color} />
              <path d="M188.213 40.4429C187.741 40.1694 187.174 40.0479 186.513 40.0479C185.852 40.0479 185.317 40.1694 184.845 40.4429C184.342 40.7163 183.964 41.0809 183.681 41.5367C183.397 41.9925 183.271 42.5394 183.271 43.1775C183.271 43.6333 183.366 44.0891 183.523 44.4537C183.681 44.8487 183.901 45.183 184.216 45.4564C184.499 45.7299 184.845 45.9426 185.254 46.0945C185.663 46.2464 186.073 46.3376 186.545 46.3376C187.017 46.3376 187.457 46.2464 187.835 46.0945C188.244 45.9426 188.591 45.7299 188.874 45.4564C189.157 45.183 189.377 44.8487 189.566 44.4537C189.724 44.0587 189.818 43.6333 189.818 43.1775C189.818 42.5394 189.692 41.9925 189.409 41.5367C189.094 41.0506 188.716 40.6859 188.213 40.4429ZM188.15 44.2714C187.993 44.6056 187.772 44.8487 187.489 45.031C187.206 45.2133 186.891 45.3045 186.513 45.3045C186.136 45.3045 185.821 45.2133 185.538 45.031C185.254 44.8487 185.034 44.6056 184.877 44.2714C184.719 43.9372 184.625 43.5725 184.625 43.1471C184.625 42.7218 184.688 42.3571 184.845 42.0533C185.003 41.7494 185.223 41.5063 185.506 41.324C185.789 41.1417 186.104 41.0809 186.482 41.0809C186.859 41.0809 187.174 41.1721 187.457 41.324C187.741 41.5063 187.961 41.7494 188.118 42.0533C188.276 42.3571 188.37 42.7218 188.37 43.1471C188.402 43.5725 188.339 43.9372 188.15 44.2714Z" fill={color} />
              <path d="M195.515 40.4429C195.043 40.1694 194.476 40.0479 193.815 40.0479C193.154 40.0479 192.619 40.1694 192.147 40.4429C191.643 40.7163 191.266 41.0809 190.982 41.5367C190.699 41.9925 190.573 42.5394 190.573 43.1775C190.573 43.6333 190.668 44.0891 190.825 44.4537C190.982 44.8487 191.203 45.183 191.517 45.4564C191.801 45.7299 192.147 45.9426 192.556 46.0945C192.965 46.2464 193.374 46.3376 193.847 46.3376C194.319 46.3376 194.759 46.2464 195.137 46.0945C195.546 45.9426 195.892 45.7299 196.176 45.4564C196.459 45.183 196.679 44.8487 196.868 44.4537C197.025 44.0587 197.12 43.6333 197.12 43.1775C197.12 42.5394 196.994 41.9925 196.711 41.5367C196.396 41.0506 195.987 40.6859 195.515 40.4429ZM195.452 44.2714C195.294 44.6056 195.074 44.8487 194.791 45.031C194.507 45.2133 194.193 45.3045 193.815 45.3045C193.437 45.3045 193.123 45.2133 192.839 45.031C192.556 44.8487 192.336 44.6056 192.178 44.2714C192.021 43.9372 191.927 43.5725 191.927 43.1471C191.927 42.7218 191.99 42.3571 192.147 42.0533C192.304 41.7494 192.525 41.5063 192.808 41.324C193.091 41.1417 193.406 41.0809 193.784 41.0809C194.161 41.0809 194.476 41.1721 194.759 41.324C195.043 41.5063 195.263 41.7494 195.42 42.0533C195.578 42.3571 195.672 42.7218 195.672 43.1471C195.703 43.5725 195.641 43.9372 195.452 44.2714Z" fill={color} />
              <path d="M202.44 40.5033C201.905 40.2602 201.307 40.1387 200.583 40.1387H198.159V46.2765H200.583C201.275 46.2765 201.905 46.1549 202.44 45.9119C202.975 45.6688 203.384 45.3042 203.667 44.8484C203.95 44.3926 204.108 43.8457 204.108 43.2076C204.108 42.5695 203.95 42.0529 203.667 41.5972C203.384 41.0806 202.975 40.7464 202.44 40.5033ZM202.471 44.2103C202.314 44.5141 202.062 44.7572 201.747 44.9395C201.432 45.1218 201.023 45.213 200.583 45.213H199.513V41.1414H200.583C201.055 41.1414 201.432 41.2325 201.747 41.3845C202.062 41.5668 202.314 41.7795 202.471 42.0833C202.628 42.3872 202.723 42.7214 202.723 43.1164C202.723 43.5418 202.628 43.9064 202.471 44.2103Z" fill={color} />
              <path d="M94.2012 55.4528H99.9923V54.3893H95.3028V31.9346H99.9923V30.8711H94.2012V55.4528Z" fill={color} />
              <path d="M205.209 30.8711V31.9346H209.899V54.3893H205.209V55.4528H211V30.8711H205.209Z" fill={color} />
              <path d="M12.5578 13.0654C15.2331 12.64 17.1844 10.4827 17.1844 7.26182C17.1844 3.15981 14.9813 0.333984 9.50483 0.333984H1.13281V26.4349H9.5363C15.1701 26.4349 17.688 23.7914 17.688 19.2336C17.688 15.8001 15.7681 13.6731 12.5578 13.0654ZM7.27019 5.19562H8.68651C10.8267 5.19562 11.3618 6.4718 11.3618 8.14299C11.3618 9.81418 10.8582 10.9688 8.68651 10.9688H7.27019V5.19562ZM8.84388 21.5429H7.27019V15.557H8.84388C11.047 15.557 11.645 16.7724 11.645 18.5955C11.645 20.4186 11.047 21.5429 8.84388 21.5429Z" fill={color} />
              <path d="M27.445 26.7694C33.7712 26.7694 36.1947 22.4851 36.1947 15.0103V11.7591C36.1947 4.28432 33.7712 0 27.445 0C21.1188 0 18.6953 4.28432 18.6953 11.7591V14.9799C18.6953 22.5155 21.1503 26.7694 27.445 26.7694ZM25.2104 8.84211C25.2104 6.53283 25.5881 4.95279 27.445 4.95279C29.302 4.95279 29.6797 6.53283 29.6797 8.84211V17.9577C29.6797 20.267 29.302 21.847 27.445 21.847C25.5881 21.847 25.2104 20.267 25.2104 17.9577V8.84211Z" fill={color} />
              <path d="M54.9214 0.333984H48.4378V17.6232C48.4378 20.2667 48.186 21.8164 46.2346 21.8164C44.2832 21.8164 44.0314 20.2971 44.0314 17.6232V0.333984H37.5479V17.1066C37.5479 23.0014 39.5622 26.7995 46.2346 26.7995C52.907 26.7995 54.9214 23.0014 54.9214 17.1066V0.333984Z" fill={color} />
              <path d="M62.5699 12.9742H62.7273L68.9591 26.4349H73.7431V0.333984H67.889V12.3665H67.7316L62.4755 0.333984H56.7158V26.4349H62.5699V12.9742Z" fill={color} />
              <path d="M83.878 0.333984H75.6318V26.4349H83.878C89.9209 26.4349 92.6906 22.4241 92.6906 15.4354V11.3031C92.6591 4.34484 89.9209 0.333984 83.878 0.333984ZM86.2385 17.5624C86.2385 20.1452 85.3257 21.634 83.0911 21.634H81.9266V5.13485H83.0911C85.3257 5.13485 86.2385 6.62373 86.2385 9.20647V17.5624Z" fill={color} />
              <path d="M107.734 21.4213H100.432V0.333984H94.1377V26.4349H107.734V21.4213Z" fill={color} />
              <path d="M109.183 26.4349H122.905V21.4213H115.383V15.6785H120.954V10.6954H115.383V5.34755H122.905V0.333984H109.183V26.4349Z" fill={color} />
              <path d="M132.001 26.7694C136.817 26.7694 140.027 24.1563 140.027 19.1123C140.027 15.0407 137.887 13.2176 134.33 11.3641L131.812 10.0271C130.522 9.35865 129.767 8.53825 129.767 7.2013C129.767 5.86435 130.459 4.89202 131.781 4.89202C133.323 4.89202 134.016 5.92512 134.016 8.05209V9.11557H139.555V8.08247C139.555 2.73467 136.722 0 131.781 0C127.154 0 124.007 2.97775 124.007 7.6267C124.007 12.2756 126.493 13.9772 129.389 15.4965L131.655 16.7119C133.292 17.5931 134.267 18.1704 134.267 19.6592C134.267 21.1481 133.481 21.9078 132.033 21.9078C130.365 21.9078 129.515 20.6316 129.515 18.6869V17.6234H123.976V18.6262C123.913 24.217 127.186 26.7694 132.001 26.7694Z" fill={color} />
              <path d="M151.2 11.3641L148.682 10.0271C147.391 9.35865 146.636 8.53825 146.636 7.2013C146.636 5.86435 147.328 4.89202 148.65 4.89202C150.192 4.89202 150.885 5.92512 150.885 8.05209V9.11557H156.424V8.08247C156.424 2.73467 153.592 0 148.65 0C144.024 0 140.876 2.97775 140.876 7.6267C140.876 12.2756 143.363 13.9772 146.258 15.4965L148.524 16.7119C150.161 17.5931 151.137 18.1704 151.137 19.6592C151.137 21.1481 150.35 21.9078 148.902 21.9078C147.234 21.9078 146.384 20.6316 146.384 18.6869V17.6234H140.845V18.6262C140.845 24.217 144.118 26.7998 148.902 26.7998C153.686 26.7998 156.928 24.1867 156.928 19.1427C156.896 15.0407 154.756 13.2176 151.2 11.3641Z" fill={color} />
              <path d="M8.71823 29.2305C2.5179 29.2305 0 33.3325 0 40.8376V44.3927C0 51.8979 2.26611 55.9999 7.64812 55.9999C9.78834 55.9999 11.7397 55.1187 12.4007 53.3564L12.8728 55.6656H17.0902V42.205H9.15886V46.7628H11.1417V47.1274C11.1417 49.4367 10.6696 50.9559 8.81265 50.9559C6.9557 50.9559 6.48359 49.467 6.48359 47.1274V37.9814C6.48359 35.6721 6.86128 34.1833 8.71823 34.1833C10.5752 34.1833 10.9529 35.6721 10.9529 37.9814V39.7134H17.0902V39.0449C17.0902 32.9679 14.8556 29.2305 8.71823 29.2305Z" fill={color} />
              <path d="M25.21 29.5645H18.8838V55.6654H25.21V29.5645Z" fill={color} />
              <path d="M35.8482 44.6659H35.6908L32.6379 29.5645H26.1543L32.1028 55.6958H38.7753L44.7238 29.5645H38.9012L35.8482 44.6659Z" fill={color} />
              <path d="M51.9942 29.5645H45.668V55.6654H51.9942V29.5645Z" fill={color} />
              <path d="M65.056 41.597H64.8672L59.6425 29.5645H53.8828V55.6958H59.7369V42.2047H59.8943L66.1261 55.6958H70.8786V29.5645H65.056V41.597Z" fill={color} />
              <path d="M81.0454 29.2305C74.8765 29.2305 72.3271 33.3325 72.3271 40.8376V44.3927C72.3271 51.8979 74.5933 55.9999 79.9753 55.9999C82.1155 55.9999 84.0669 55.1187 84.7278 53.3564L85.1999 55.6656H89.4174V42.205H81.486V46.7628H83.4689V47.1274C83.4689 49.4367 82.9967 50.9559 81.1398 50.9559C79.2828 50.9559 78.8107 49.467 78.8107 47.1274V37.9814C78.8107 35.6721 79.1884 34.1833 81.0454 34.1833C82.9023 34.1833 83.28 35.6721 83.28 37.9814V39.7134H89.4174V39.0449C89.4174 32.9679 87.2142 29.2305 81.0454 29.2305Z" fill={color} />
            </g>
            <defs>
              <clipPath id="clip0_173_964">
                <rect width={width} height={height} fill="white" />
              </clipPath>
            </defs>
          </svg>

      }
    </>
  )
}

export default BGLogoComponent