import axios from "axios";
import { headerKey } from "./api-header";
import { config } from '../config/config.js';
import { getGuestUserId } from "../utils/util-functions.js";
const API_BASE_URL = config.REACT_APP_BASE_URL;

const signup = async (firstName, lastName, email, password, subscribeEmail) => {
  const response = await axios.post(API_BASE_URL + "/api/v1/users", {
    username: email,
    first_name: firstName?.trim(),
    last_name: lastName?.trim(),
    // age: getAge(dob),
    email: email,
    password: password,
    subscription_status:subscribeEmail ? 1 : 0 ,
    role: "basic",
    guest_user_id: getGuestUserId()
  });
  if (response?.data && response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data.data.username));
    localStorage.removeItem("guest_user_id")
  }
  return response.data;
};
async function verifyEmail(token,email,state,setState,toGo) {
  try {
    const {data} = await axios.put(API_BASE_URL + `/api/v1/users/email/${email}/verify-email`,{verifyToken: token});
    if (data?.data?.role !== "Admin" && data.token && data.success) {
      localStorage.setItem("user", JSON.stringify(data.data.username));
      localStorage.setItem("token", data.token);
      localStorage.setItem("username", email);
      return true
    }
    return false
  } catch(err) {
    setState({...state,msg:err.response.data.message||err.message});
    setTimeout(()=>{
      toGo('/register');
    },5000);
  }
}

const login = async (email, password) => {
  const response = await axios.post(API_BASE_URL + "/api/v1/users/login", {
    email,
    password,
    role: "basic",
    guest_user_id: getGuestUserId()
  });
  if (response?.data && response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data.data.username));
    localStorage.removeItem('guest_user_id')
  }
  return response.data;
};

const verifyUser = async () => {
  const response = await axios.post(API_BASE_URL + "/api/v1/users/verify",
    {},
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
}

const logout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    // window.location.reload();
}

async function forgotPasswordApi(step,reqBody,setStep,setAlert, redirectLink) {
  try {
    const reqParams = {0: '/email/request-pwd-reset',1: '/password/reset-pwd'};
    if(step === 0 && !reqBody.email) return setAlert({open:true,msg:`Can't leave it blank 😕.`,type:'warning',loading:false});
    if(step === 1 && (!reqBody.newPassword)) return setAlert({open:true,msg:`Can't leave it blank 😕.`,type:'warning',loading:false});
    if(step === 1 && reqBody.newPassword !== reqBody.confirmPassword) return setAlert({open:true,msg:`Passwords doesn't match.`,type:'warning',loading:false});
    if(step === 0) {
      reqBody['role'] = 'basic'
    }
    const resRequest = await axios.put(API_BASE_URL + `/api/v1/users${reqParams[step]}`,{...reqBody, redirectLink:redirectLink});
    const {success,message} = resRequest.data;
    if(!success) return setAlert({open:true,msg:message,type:'error',loading:false});
    // From here means -> Success
    if(step === 0) {
      setAlert({open:true,msg:message,type:'success',loading:false});
      return '';
    } else {
      setStep(2);
      setAlert({...reqBody,loading:false});
      return '';
    }
  } catch(err) {
    setAlert({open:true,msg:err.response.data.message||err.message,type:'error',loading:false});
    return '';
  }
}

const retryPayment= async (paymentToken)=>{
  try{
    const response = await axios.post(API_BASE_URL + "/api/v1/users/retry-payment", {
      paymentToken:paymentToken
    });
    return response
  }catch(error){
    return error
  }
}
const authService = {
  signup,
  login,
  logout,
  verifyUser,
  forgotPasswordApi,
  verifyEmail,
  retryPayment
};

export default authService;
