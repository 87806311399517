import axios from "axios";
import { headerKey, headerKey_ } from "./api-header";
import { config } from '../config/config.js';
const API_BASE_URL = config.REACT_APP_BASE_URL;

const causeInfo = async (causeId) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/cause/${causeId}`,
  {
      headers: { ...headerKey }
  });
  return response.data;
};

const getCauseCategoryList = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/cause/category`, {
    headers: { ...headerKey },
  });
  return response.data;
};

const getItemRelatedCauses = async (item_id) =>{
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/get-item-related-causes/${item_id}`)
  return data
}

const getAllCausesHaveEvents = async (page, limit, from) =>{
 try{
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/get-all/causes-have-events?page=${page}&limit=${limit}&from=${from}`)
  return data
 }catch (error) {
  throw error
 }
}

const fetchCausesByCategory = async (causeCategoryId, limit, page) => {
  try {
     const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/get-causes-by-category/${causeCategoryId}?page=${page}&limit=${limit}`)
     return data
  } catch (error) {
     throw error
  }
}

const imagePreProcess = async (formData,alpha=1) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/auction/pre-process-image?alpha=${alpha}`, formData)
    return data
  }catch(error) {
    throw error
  }
}

const createCause = async (formData) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/cause`, formData,
    {
      headers: { ...headerKey_ },
    }
  )
    return data
  }catch(error) {
    throw error
  }
}

const fetchFeaturedCauses = async (page, limit) => {
  try {
     const {data} = await axios.get(`${API_BASE_URL}/api/v1/cause/get-cause/featured-cause`, {
      params:{
        page,
        limit
      }
     })
     return data
  } catch (error) {
     throw error
  }
}

const causeServices = {
    causeInfo,
    getCauseCategoryList,
    getItemRelatedCauses,
    getAllCausesHaveEvents,
    fetchCausesByCategory,
    imagePreProcess,
    createCause,
    fetchFeaturedCauses
};

export default causeServices;
