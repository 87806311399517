// import MailSVG from "../../images/mail.svg"
// import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';

import './History.css';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import userServices from '../../services/user-services';
import downArrow from '../../images/downArrow.png';
import noOrder from '../../images/no_order.svg';

import logger from "../../utils/logger";
import {Helmet} from "react-helmet-async"
// import { Desktop, Mobile } from "./responsive/Responsive";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, TablePagination } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Footer from "../desktop/components/Footer/Footer";
import Header from "../desktop/components/Header/Header";
import AccountNavbar from "../desktop/components/AccountNavbar/AccountNavbar";
import HeaderV2 from "./molecules/Header/HeaderV2";
import SubHeader from "./molecules/SubHeader/SubHeader";
import { mobileStyle } from "./Home";
import { useMediaQuery } from "react-responsive";
import NeedHelp from "./atoms/NeedHelp/NeedHelp";
import NewCardLayout from "./atoms/NewCardLayout/NewCardLayout";
import LoadingScreen from "./LoadingScreen/Loading";
import ImageSkelton from "./molecules/ImageSkelton/ImageSkelton";
import MobileSubFooter from './atoms/MobileSubFooter/MobileSubFooter';
import NewFooter from './molecules/NewFooter/NewFooter';
import HeaderV3 from './molecules/Header/HeaderV3/HeaderV3';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const primaryColor = mobileStyle.color[1];
const primaryBackgroundColor = mobileStyle.backgroundColor[2];
const redColor = mobileStyle.color[0];


function OrderCard({ img, title, help, pastOrder, totalItems,navigateToTrack, orderAmount }) {
  const [imageLoading, setImageLoading] = useState(true)
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  return (
    <>
    {/* <div className='current_order_card' onClick={navigateToTrack}>
      <div className='main'>
        <div className='image'>
          <img src={img} alt='CurrentOrder' />
        </div>
        <div className='content'>
          <h3>{title}</h3>{totalItems>1?<span>& {totalItems-1} more... </span>:<span></span>}
          <div className='info'>
          </div>
        </div>
      </div>
      <div className='help'>
        <p >{pastOrder ? 'Delivered 10-Jun-22' : 'Track your package?'}</p>
        <p onClick={help} style={{ color: '#6D6D6D' }}>
          Need Help
        </p>
      </div>
    </div> */}
          {/* <Mobile> */}
          <NewCardLayout imageContainerStyle={{width:!isMobile && '150px', height:!isMobile && '150px'}} neededLine={isMobile} onCardClick={navigateToTrack} image={img}>
                  <div className="cart-card-content" style={{gap:'5px', color:primaryColor}}>
                    <h1>{title}</h1>{totalItems>1?<span>& {totalItems-1} more... </span>:<span></span>}
                    <div className="bid_card_data">
                      <div className="bid_card_amount" style={{color:primaryColor}}>
                        <span style={{color:primaryColor}} className="order_amount_text" >Order amount </span>
                        <span className="order_amount_text" style={{color:primaryColor}} >$ {orderAmount}</span>
                      </div>
                    </div>
                  </div>
          </NewCardLayout>
          {/* </Mobile> */}
         
          {/* <Desktop>
          <div  className="des_order_card" onClick={navigateToTrack}>
                    <div
                        className="des_order_card_banner"   
                    >
                        <img
                            src={img}
                            alt=""
                            style={{display:imageLoading ? "none" : 'block'}}
                            onLoad={() => setImageLoading(false)}
                        />
                        <ImageSkelton width='130px' loadingState={imageLoading} style={{height:"130px", borderRadius:"25px"}}/>
                    </div>
                    <div className="des_order_card_details">
                     
                        <p style={{fontSize:"16px"}}>{title}{totalItems>1?<span>& {totalItems-1} more... </span>:<span></span>}</p>
                        <p > ${orderAmount}</p>
                    
                    </div>
                </div>

          </Desktop> */}
    </>
  );
}
function OrdersNeedHelp({order_id, navigate}) {
  return (
    <div className='need_help'>
      <div className='section_1'>
        <div className='content'>
          <p>Item not Arrived yet?</p>
          <p>Problem with your delivery</p>
        </div>
        <div className='content'>
          <p onClick={() => order_id && order_id !== "" ? navigate(order_id) : null} className='link_content'>Track Order</p>
          <p className='link_content'>Contact us for Help</p>
        </div>
      </div>
      <div className='section_2'>
        <p>
          Please see our <span className='link_content'>Terms of service</span>{' '}
          for the full Boundless return policy.
        </p>
        <p style={{ color: '#535353' }}>
          For “Buy Now” items, we offer a full money back guarantee for thirty
          days after delivery for up to two purchased items.You dont have to
          send the item back, btu please consider donating it to charity of you
          are unsatisfied for any reason.
        </p>
      </div>
    </div>
  );
}

function History() {
  const [bool, setBool] = useState('11'); //"11"==='Current Orders' "12"==='Past Orders'
  const [help, setHelp] = useState({
    open: false,
    id: '', //If any ( For Tracking Purpose )
  });
  const authCtx = useContext(AuthContext);
  const [historyData, setHistoryData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const navigate = useNavigate()
  const [totalOrders, setTotalOrders] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [mobilePage,setMobilePage] = useState(1)
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  
  const isMobile = useMediaQuery({
    maxWidth: 768
  })

  const navigateToTrack = (id) => {
    navigate(`/order/track/${id}`)
    return
  }

  function toggleBool(e) {
    setPage(1)
    setBool(e.target.id);
  }
  function toggleHelp(id = '') {
    setHelp({ id: id, open: !help.open });
  }

  useEffect(() => {
    setIsLoading(true);
    const getPurchaseHistory = async (id, status) => {
      try {
        await userServices.getUserPurchaseHistory(id, status, page, limit).then(
          (response) => {
            logger('./pages/components/History.jsx','getPurchaseHistory(): res',response);
            setHistoryData(response?.data);
            setTotalOrders(response?.total_count)
            setTotalPages(response?.total_page)
            setIsLoading(false);
          },
          (error) => {
            logger('./pages/components/History.jsx','getPurchaseHistory(): catch1',error);
            setIsLoading(false);
          }
        );
      } catch (err) {
        logger('./pages/components/History.jsx','getPurchaseHistory(): catch2',err);
        setIsLoading(false);
      }
    };
    if (bool === '11') {
      // setPage(1);
      setMobilePage(1)
      getPurchaseHistory(authCtx?.userId, 'current');
    } else if (bool === '12') {
      // setPage(1);
      setMobilePage(1)
      getPurchaseHistory(authCtx?.userId, 'past');
    }
    return () => {
      setHistoryData(null);
    };
  }, [authCtx?.userId, bool,limit,page]);

  const loadMoreAuction = async () => {
    setIsLoadingMore(true);
    if (bool === '11') {
      try {
        await userServices
          .getUserPurchaseHistory(authCtx?.userId, 'current', mobilePage + 1, limit)
          .then(
            (response) => {
              // let newAuctionsArr = [...historyData, ...response?.data];
              setHistoryData([...historyData, ...response?.data]);
              setIsLoadingMore(false);
              setMobilePage(mobilePage + 1);
            },
            (error) => {
              logger('./pages/components/History.jsx','loadMoreAuction(): catch1',error);
              setIsLoadingMore(false);
            }
          );
      } catch (err) {
        logger('./pages/components/History.jsx','loadMoreAuction(): catch2',err);
        setIsLoadingMore(false);
      }
    } else if (bool === '12') {
      try {
        await userServices
          .getUserPurchaseHistory(authCtx?.userId, 'past', mobilePage + 1, limit)
          .then(
            (response) => {
              // let newAuctionsArr = [...auctions?.data, ...response?.data];
              setHistoryData([...historyData, ...response?.data]);
              setIsLoadingMore(false);
              setMobilePage(mobilePage + 1);
            },
            (error) => {
              logger('./pages/components/History.jsx','loadMoreAuction(): catch3',error);
              setIsLoadingMore(false);
            }
          );
      } catch (err) {
        logger('./pages/components/History.jsx','loadMoreAuction(): catch4',err);
        setIsLoadingMore(false);
      }
    }
  };

  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false);
  };

  const handleTabPrefernceInfoChange = () => {
    navigate('/account', {state:{from: "info"}})
  }

  const handleTabPreferncePaymentChange = () => {
    navigate('/account', {state:{from: "payment"}})
  }

  return (
    <div style={{backgroundColor: primaryBackgroundColor}} className='history_wrapper'>
      <Helmet prioritizeSeoTags>
        <title>History | Boundless Giving</title>
      </Helmet>

              {/* <HeaderV2
                logoType="black2"
                primaryBackgroundColor={primaryBackgroundColor}
                primaryColor={primaryColor}
                containerStyle={{position:"fixed"}}
                isSubHeader={true}
                subHeaderTitle="Order Details"
              /> */}
              <HeaderV3
                primaryBackgroundColor={primaryBackgroundColor}
                isSubHeader
                subHeaderTitle="Order Details"
                position='absolute'
              />

      <div>

        {/* <Desktop>
          <Navbar/>
          <SubNavbar/>
          <Header title="History" />
          <BackContainer className="history-back-button"/>

        
          <AccountNavbar
          setOpenConfirmAlert={setOpenConfirmAlert}
          handleTabPrefernceInfoChange={handleTabPrefernceInfoChange}
          handleTabPreferncePaymentChange={handleTabPreferncePaymentChange}
          style={{minHeight:'0vh',boxShadow:0}}
          tabBodyStyle={{padding:"0px"}}
          from='history'
          ></AccountNavbar>
       
        </Desktop> */}
        {!help.open && (
          <>
          {/* <Desktop>
          <div className={`history_nav ${historyData?.length?'history_nav_shadow':''}`}>
            <p
              className={bool === '11' ? 'selected' : ''}
              id='11'
              onClick={toggleBool}
            >
              Current Orders
            </p>
            <p
              className={bool === '12' ? 'selected' : ''}
              id='12'
              onClick={toggleBool}
            >
              Past Orders
            </p>
          </div>
          </Desktop> */}
          <div className={(isMobile)?"":"margin_class"}>
            {/* tab for Mobile */}
           <p style={{paddingTop:isMobile ? '170px' : "200px",paddingInline:isMobile ? "30px": "10px", color: primaryColor}} className="mobile_main_heading" >Orders</p>
            <div style={{borderBottom:`1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor, marginInline:isMobile?"30px":"auto", display:isMobile ? "":"flex",justifyContent: isMobile? "" : "space-around",marginBottom:isMobile?"":"30px" }} className='bid_nav'>
              <p
                style={{
                  borderBottom:bool === '11' ? `2px solid ${redColor}` : 0,
                  color:bool === '11' ? redColor : primaryColor
                }}
                className={bool === '11' ? 'selected' : ''}
                id='11'
                onClick={toggleBool}
              >
                  Current Orders
              </p>
              <p
                style={{
                  borderBottom:bool === '12' ? `2px solid ${redColor}` : 0,
                  color:bool === '12' ? redColor : primaryColor
                }}
                className={bool === '12' ? 'selected' : ''}
                id='12'
                onClick={toggleBool}
              >
                Past Orders
              </p>
            </div>
          </div>
          </>
        )}
        
      </div>
      <>
        
        <div className='history_body margin_class'>
          {help.open ? (
            <OrdersNeedHelp navigate={navigateToTrack} order_id={help.id} />
          ) : (
            <>
            {/* <Desktop>
              {(historyData && historyData.length) ?
              <div className="paper_div">
                <TableContainer>
                    <Table className="dorder_table" sx={{width:"100%"}}>
                    <TableHead sx={{width:"100%"}}>
                        <TableRow>
                            <StyledTableCell>Order ID</StyledTableCell>
                            <StyledTableCell>Item</StyledTableCell>
                            <StyledTableCell>Price</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyData?.map((row, i) => (
                        <StyledTableRow key={row.id}>
                              <StyledTableCell>{`BG_${row?.id}`}</StyledTableCell>
                                <StyledTableCell>
                                  {row.user_order_auction_item_xrefs[0].auction_item?.name}{' '}
                                  {row.user_order_auction_item_xrefs?.length>1&&`& ${row.user_order_auction_item_xrefs?.length-1} more...`}
                                </StyledTableCell>
                                <StyledTableCell>{
                        row?.auction_item?.store_item_id 
                        ? row?.auction_item?.store_item?.price 
                        : row?.auction_item?.buy_now_price 
                        ? row?.auction_item?.buy_now_price 
                        : row?.auction_item?.price}</StyledTableCell>
                                <StyledTableCell >
                                    <div className='table-btn' onClick={() => navigateToTrack(row.id)}><RemoveRedEyeOutlinedIcon style={{color:'#26B090'}}/> <span style={{color:'#189D7E'}}>View</span></div>
                                </StyledTableCell>
                        </StyledTableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination 
                    className="pagination_container"
                    rowsPerPageOptions={[5,10,15, 20]}
                    component="div"
                    count={totalOrders}
                    rowsPerPage={limit}
                    page={page - 1}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {setLimit(+e.target.value);}}
                />
              </div> 
              :""}
              {isLoading ? <div className="history-table-loader"><CircularProgress style={{'color': 'gray'}} /></div> : (
                (historyData && historyData.length > 0)?
                ""
                :
                <div
                style={{
                  marginTop:"20px",
                  textAlign: 'center',
                  fontSize: '16px',
                }}
                
              >
                <img src={noOrder} width='270px' alt='no-result' />
                <br />
                <br />
                No order found!
              </div>
              )}
            </Desktop> */}
            <div className='section'>

            {isLoading ? (
            <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "calc(100vh - 203px)",
              color: primaryColor,
              backgroundColor: primaryBackgroundColor,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress style={{color:'red'}} />
            </div>
          </Box>
          ) : <>
              {historyData &&
                historyData?.map((i) => (
                  <OrderCard
                    navigateToTrack={() => navigateToTrack(i.id)}
                    key={i.id}
                    help={() => toggleHelp(i?.id)}
                    img={i.preview_image.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name}
                    title={i.user_order_auction_item_xrefs[0].auction_item?.name}
                    totalItems={i.user_order_auction_item_xrefs?.length}
                    // price={
                    //   i?.auction_item?.store_item_id 
                    //   ? i?.auction_item?.store_item?.price 
                    //   : i?.auction_item?.buy_now_price 
                    //   ? i?.auction_item?.buy_now_price 
                    //   : i?.auction_item?.price}
                    pastOrder={!bool}
                    orderAmount={i.order_amount}
                  />
                ))}
              {isLoadingMore ? (
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  Loading <span className='loader__dot'>.</span>
                  <span className='loader__dot'>.</span>
                  <span className='loader__dot'>.</span>
                </div>
              ) : historyData && historyData.length > 0 && (mobilePage < totalPages) ? (
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                  onClick={loadMoreAuction}
                >
                  Load more &nbsp;
                  <img src={downArrow} alt='' />
                </div>
              ) : historyData && historyData.length !== 0 ? <div style={{textAlign: "center", fontWeight: 600}}>You've reached the end!</div> : (
                (
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                    }}
                    onClick={loadMoreAuction}
                  >
                    <img src={noOrder} width='270px' alt='no-result' />
                    <br />
                    <br />
                    No order found!
                  </div>
                )
              )}</>}
            </div>
            </>
          )}
          <div className='track-order-contact'>
              {/* <div style={{fontSize: "20px", fontWeight: "600"}}>Need Help?</div>
              <div style={{fontSize: "12px", color: "#173B83", fontWeight: "600"}}>Contact Us</div>
              <div className='track-order-numbers'><a style={{color: 'black'}} href='mailto:help@boundlessgiving.com'> <img style={{width: "14px", height: "14px"}} src={MailSVG} alt="" /> help@boundlessgiving.com</a></div> */}
              <NeedHelp primaryColor={primaryColor} style={{marginTop:'32px'}} />
          </div>
          <br />
        </div>
      </>
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </div>
  );
}

export default History;
