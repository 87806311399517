import React,{useState,memo, useEffect} from "react";

// This component only help in showing old image without variants for event,marketplace and charity store
const Image=({src,alt,style,className='',variant='_small',onLoad=()=>null})=>{
    const [source,setSource] = useState(src);
    const handleImageLoadingError = ()=>{
      const lastIndex = src.lastIndexOf(variant);
      setSource(lastIndex !== -1 ? src.substring(0, lastIndex) : src)
    }
    useEffect(()=>{
      setSource(src)
    },[src])
    return (
      <img className={className}  src={source} alt={alt} style={style} onLoad={onLoad} onError={handleImageLoadingError} />
    )
  }

export default memo(Image)

