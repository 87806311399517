import React, { useEffect } from 'react'
import GlobalHeader from './molecules/GlobalHeader'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MailSVG from "../../images/mail.svg"
import LocationMarker from "../../images/locationMarker.svg"
import "./TrackOrder.css"
import userServices from '../../services/user-services';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Alert } from '@mui/material';
import { Helmet } from "react-helmet-async"
import { Mobile, Desktop } from './responsive/Responsive';
import Navbar from "../desktop/components/Navbar/Navbar";
import SubNavbar from "../desktop/components/SubNavbar/SubNavbar";
import BackContainer from '../desktop/components/BackContainer/BackContainer';
import Footer from '../desktop/components/Footer/Footer';
import { mobileStyle } from './Home';
import { useMediaQuery } from 'react-responsive';
import HeaderV2 from './molecules/Header/HeaderV2';
import SubHeader from './molecules/SubHeader/SubHeader';
import NeedHelp from './atoms/NeedHelp/NeedHelp';
import NewCardLayout from './atoms/NewCardLayout/NewCardLayout';
import MobileSubFooter from './atoms/MobileSubFooter/MobileSubFooter';
import NewFooter from './molecules/NewFooter/NewFooter';
import HeaderV3 from './molecules/Header/HeaderV3/HeaderV3';

const primaryBackgroundColor = mobileStyle.backgroundColor[2]
const primaryColor = mobileStyle.color[1]
const redColor = mobileStyle.backgroundColor[0]


// -- 1 <---> Created 0
// -- 9 <---> Packed 1
// -- 6 <---> Shipped 2
// -- 12 <---> In Transit 3
// -- 7 <---> Out for Delivery 4
// -- 8 <---> Delivered 5
// -- 14 <--->  Returned 5
// -- 15 <---> Address Check Failed 100

const calculateStatus = (status) => {
    switch (status) {
        case "1":
            return "0"
        case "9":
            return "1"
        case "6":
            return "2"
        case "12":
            return "3"
        case "7":
            return "4"
        case "8":
            return "5"
        case "14":
            return "5"
        case "15":
            return "100"
        default:
            return "0"
    }
}


function OrderCard({ img, title, description, quantity, price, help, pastOrder }) {
    const isMobile = useMediaQuery({
        maxWidth: 768
    })

    return (
        <>
        {/* <div className='current_order_card'>
            <div className='main'>
                <div className='image'>
                    <img src={img} alt='CurrentOrder' />
                </div>
                <div className='content'>
                    <h3>{title}</h3>
                    <div className='info'>
                        <span>Qty: {quantity}</span>
                        <span>${price}</span>
                    </div>
                </div>
            </div>
            <div className='help'>
                <p onClick={help} style={{ color: '#6D6D6D' }}>
                    Need Help
                </p>
            </div>
        </div> */}

            <NewCardLayout imageContainerStyle={{width:!isMobile && '150px', height:!isMobile && '150px'}} image={img}>
                  <div className="cart-card-content" style={{gap:'5px'}}>
                    <h1>{title}</h1>
                    <div className="cart-card-qty_price">
                      <div className="cart-card-remove_price">
                        <h1 style={{color:primaryColor}} >${price}</h1>
                      </div>
                    </div>
                  </div>
                  <div style={{marginTop:'5px'}} className="mobile_quantity_wrapper">
                    <span className="quantity_text">Qty</span>
                    <span className="quantity_text">{quantity}</span>
                  </div>
            </NewCardLayout>
                {/* <div className='help'>
                   <p onClick={help} style={{ color: '#6D6D6D' }}>
                   Need Help
                   </p>
                </div> */}
        </>
    );
}


function OrdersNeedHelp({ order_id, toggleHelp }) {
    return (
        <div className='need_help'>
            <div className='section_1'>
                <div className='content'>
                    <p>Item not Arrived yet?</p>
                    <p>Problem with your delivery</p>
                </div>
                <div className='content'>
                    <p onClick={() => order_id && order_id !== "" ? toggleHelp(order_id) : null} className='link_content'>Track Order</p>
                    <p className='link_content'>Contact us for Help</p>
                </div>
            </div>
            <div className='section_2'>
                <p>
                    Please see our <span className='link_content'>Terms of service</span>{' '}
                    for the full Boundless return policy.
                </p>
                <p style={{ color: '#535353' }}>
                    For “Buy Now” items, we offer a full money back guarantee for thirty
                    days after delivery for up to two purchased items.You dont have to
                    send the item back, btu please consider donating it to charity of you
                    are unsatisfied for any reason.
                </p>
            </div>
        </div>
    );
}


const TrackOrder = () => {
    const [order, setOrder] = useState(null)
    const { orderId } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [orderStaus, setOrderStatus] = useState()
    const [help, setHelp] = useState({
        open: false,
        id: '', //If any ( For Tracking Purpose )
    });
    const isMobile = useMediaQuery({
        maxWidth: 768
    })

    const params = new URLSearchParams(location.search);
    const redirect = params.get('redirect')

    useEffect(() => {
        (async () => {
            const data = await userServices.getUserOrderStatus(orderId)
            setOrder(data)
            setOrderStatus(calculateStatus(data?.status))
        })()
    }, [orderId])


    function toggleHelp(id = '') {
        setHelp({ id: id, open: !help.open });
    }

    return (
        <>
            <Helmet>
                <title>Track Order | Boundless Giving</title>
            </Helmet>
            {order ? (
                <div style={{backgroundColor: primaryBackgroundColor}} >
                    {/* <Desktop>
                        <Navbar />
                        <SubNavbar />
                        <div className="dheader">
                            <h3>History</h3>
                        </div>
                        <BackContainer className="track-order-back-button"/>
                    </Desktop> */}
                    {/* <Mobile> */}
                    
                            {/* <HeaderV2 isSubHeader={true} subHeaderTitle='Track Order' logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:"fixed"}} /> */}
                            <HeaderV3 isSubHeader subHeaderTitle='Track Order' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} position='absolute' />
                    {/* </Mobile> */}
                    <div style={{ paddingTop:isMobile ? '200px' : '210px', backgroundColor: primaryBackgroundColor }} className='track-order-container margin_class'>
                        <div className='item-container'>

                            {help.open ? (
                                <OrdersNeedHelp toggleHelp={toggleHelp} order_id={help.id} />
                            ) : <div className='order-item-contianer'>
                                {order?.user_order_auction_item_xrefs?.map((i) => (
                                    <OrderCard
                                        key={i.auction_item_id}
                                        help={() => toggleHelp(i?.auction_item_id)}
                                        img={i.preview_image.find(img=>(img.context_type==='thumbnail'||img.context_type==='portrait'))?.name}
                                        title={i.auction_item?.name}
                                        quantity={i.quantity_bought}
                                        price={
                                            i?.auction_item?.store_item_id
                                                ? i?.auction_item?.store_item?.price
                                                : i?.auction_item?.buy_now_price
                                                    ? i?.auction_item?.buy_now_price
                                                    : i?.auction_item?.price}

                                    />
                                ))}
                            </div>
                            }
                        </div>
                        <div>


                            {
                                !help.open && (orderStaus === "100" ?
                                    <Alert style={{ margin: "20px 0" }} severity="error" >Address Check Failed</Alert>
                                    : (
                                        <>
                                            <div className='track-order-progess'>
                                                <div style={{ zIndex: 0 }} className={`track-order-line track-order-${orderStaus}`}>
                                                    <div className='track-order-dot'></div>
                                                    <div className='track-order-dot'></div>
                                                    <div className='track-order-dot'></div>
                                                    <div className='track-order-dot'></div>
                                                    <div className='track-order-dot'></div>
                                                    <div className='track-order-dot'></div>
                                                </div>
                                                <div className={`track-order-status track-order-${orderStaus}`}>
                                                    <div className='track-order-header'>Order Received</div>
                                                    <div className='track-order-header'>Preparing your order</div>
                                                    <div className='track-order-header'>Order Shipped</div>
                                                    <div className='track-order-header'>On its way</div>
                                                    <div className='track-order-header'>Out for delivery</div>
                                                    <div className='track-order-header'>
                                                        {order.status === "14" ? "Order Returned" : "Delivered"}<br />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                            }
                            {!help.open && <div className='track-order-del'>
                                <div style={{ fontSize: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>Delivery Address <img style={{ marginLeft: "7px" }} src={LocationMarker} alt="" /> </div>
                                <div style={{ fontSize: "14px", marginTop: "10px" }}>
                                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.name}</p>
                                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.address1}</p>
                                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.city}</p>
                                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.state}, {order.shipping_detail.zip}</p>
                                </div>
                            </div>}
                        </div>
                            {/* <Mobile> */}
                            {/* <div className='track-order-contact'>
                                <div style={{ fontSize: "20px", fontWeight: "600" }}>Need Help?</div>
                                <div style={{ fontSize: "12px", color: "#173B83", fontWeight: "600" }}>Contact Us</div>
                                <div className='track-order-numbers'><a style={{ color: 'black' }} href='mailto:help@boundlessgiving.com'> <img src={MailSVG} alt="" /> help@boundlessgiving.com</a></div>
                            </div> */}
                            <NeedHelp primaryColor={primaryColor} style={{marginTop:'52px'}} />
                            {/* </Mobile> */}

                    </div>
                </div>
            ) : <></>}
            {/* <Desktop><Footer /></Desktop> */}
            <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />


        </>
    )
}

export default TrackOrder