import React from 'react'
import classes from './BrandSection.module.css'
import styles from "../../../../../desktop/styles/Home.module.css"
import { Desktop, Mobile } from '../../../../responsive/Responsive'
import BrandImages from '../../../../../../images/newHomeImages/brandsNewMobile.png'
import DcVansElectric from '../../../../../../images/newHomeImages/brandImages/dcVansElectric.svg'
import LaGear from '../../../../../../images/newHomeImages/brandImages/laGear.svg'
import BillaBong from '../../../../../../images/newHomeImages/brandImages/billaBong.svg'
import OutDoor from '../../../../../../images/newHomeImages/brandImages/outDoor.svg'
import Roxy from '../../../../../../images/newHomeImages/brandImages/roxy.svg'
import BigBrand from '../../../../../../images/newHomeImages/brandImages/bigBrand.svg'
import QuikSilver from '../../../../../../images/newHomeImages/brandImages/quikSilver.svg'
import EsteeLauder from '../../../../../../images/newHomeImages/brandImages/estee-lauder-logo.png'
import SugarPaper from '../../../../../../images/newHomeImages/brandImages/sugar-paper-logo.png'
import AmberInteriors from '../../../../../../images/newHomeImages/brandImages/Amber+Interiors+Logo.png'
import SammyNat from '../../../../../../images/newHomeImages/brandImages/SAMMYNAT.png'
import TequilaMandala from '../../../../../../images/newHomeImages/brandImages/tequilamandala.png'
import LaMer from '../../../../../../images/newHomeImages/brandImages/lamer-Logo-1.png'
import GenLove from '../../../../../../images/newHomeImages/brandImages/genlovenyc.png'

const BrandSection = ({mobileStyle}) => {
    const backgroundColor = mobileStyle?.backgroundColor[1]
    const color = mobileStyle?.color[2]
  return (
    <>
    <Desktop>
      {/* <Section7/> */}
      <section style={{backgroundColor:backgroundColor, color:color }}>
        <div className={ `${classes.desktop_brands_container} margin_class`} >
          <h4 className={classes.desktop_heading} >Boundless Brand Partners include: </h4>
          <div className={classes.desktop_logo_section} >
            <div>
            <img className={classes.image1} loading='lazy' src={DcVansElectric} alt="Boundless Brand Partners" />
            <img src={LaGear} loading='lazy' alt="Boundless Brand Partners" />
            <img src={BillaBong} loading='lazy' alt="Boundless Brand Partners" />
            <img src={OutDoor} loading='lazy' alt="Boundless Brand Partners" />
            </div>
            <div className={classes.imgSubSection} style={{gap:'40px'}}>
            <img src={Roxy} loading='lazy' alt="Boundless Brand Partners" />
            <img src={BigBrand} loading='lazy' alt="Boundless Brand Partners" />
            <img src={QuikSilver} loading='lazy' alt="Boundless Brand Partners" />
            <img className={classes.common_style} style={{height:'35px'}} src={EsteeLauder} loading='lazy' alt="Boundless Brand Partners" />
            <img className={classes.common_style} style={{width:"250px", height:'29px'}} src={GenLove} loading='lazy' alt="Boundless Brand Partners" />
            <img className={classes.common_style} style={{height:'59px'}} src={SugarPaper} loading='lazy' alt="Boundless Brand Partners" />
            <img className={classes.common_style} style={{height:'21px'}} src={SammyNat} loading='lazy' alt="Boundless Brand Partners" />
            <img className={classes.common_style} src={AmberInteriors} loading='lazy' alt="Boundless Brand Partners" />
            <img className={classes.common_style} style={{height:'65px'}} src={TequilaMandala} loading='lazy' alt="Boundless Brand Partners" />
            <img className={classes.common_style} src={LaMer} loading='lazy' alt="Boundless Brand Partners" />
            </div>
          </div>
        </div>
      </section>
    </Desktop>
    
    <Mobile>    
    <section className={styles.dhero} style={{backgroundColor:backgroundColor, color:color, paddingBlock:"35px" }}>
        <div className={classes.section_wrapper} >
          <div className={classes.text_section} >
            <h5 style={{fontSize:"22px",fontFamily:"Instrument Sans Bold"}}>Boundless Brand Partners include:</h5>
          </div>
          <div className={classes.logo_section} >
            <img src={BrandImages} loading='lazy' alt="Boundless Brand Partners" />
            {/* <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" />
            <img src={addidasBlack} alt="" /> */}
          </div>
          {/* <div className={classes.btn_wrapper}  >
            <button style={{border:`2px solid ${color}`, color:color}}>SIGN UP</button>
          </div> */}
        </div>
    </section> 
    </Mobile>
    </>
  )
}

export default BrandSection