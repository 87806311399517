import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import auctionServices from "../../services/auction-services";
import backWhite from "../../images/backWhite.png";
import uploadWhite from "../../images/uploadWhite.png";
import downArrow from "../../images/downArrow.png";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logger from "../../utils/logger";

const AuctionItemList = () => {
  const search = useLocation().search;
  const auction_item_category_id = new URLSearchParams(search).get(
    "auction_item_category_id"
  );
  const category_name = new URLSearchParams(search).get("category_name");
  const buy_now = new URLSearchParams(search).get("buy_now");
  const [auctionItems, setAuctionItems] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getAuctionItemsList = async (category_id) => {
      try {
        await auctionServices
          .getAuctionItemsByCategoryId(page, limit, category_id)
          .then(
            (response) => {
              setAuctionItems(response);
              setIsLoading(false);
            },
            (error) => {
              logger('./pages/components/AuctionItemList.jsx','getAuctionItemsList()1: catch',error);
              setIsLoading(false);
              navigate("/not-found");
            }
          );
      } catch (err) {
        logger('./pages/components/AuctionItemList.jsx','getAuctionItemsList()2: catch',err);
        setIsLoading(false);
        navigate("/not-found");
      }
    };
    const getAllBuyNowAuctionItems = async () => {
      try {
        await auctionServices.getAllBuyNowItems(page, limit).then(
          (response) => {
            setAuctionItems(response);
            setIsLoading(false);
          },
          (error) => {
            logger('./pages/components/AuctionItemList.jsx','getAllBuyNowAuctionItems()2: catch',error);
            setIsLoading(false);
          }
        );
      } catch (err) {
        logger('./pages/components/AuctionItemList.jsx','getAllBuyNowAuctionItems()2: catch',err);
        setIsLoading(false);
      }
    };
    if (buy_now == 1) {
      getAllBuyNowAuctionItems();
    } else {
      getAuctionItemsList(auction_item_category_id);
    }
  }, [auction_item_category_id, buy_now]);

  const loadMoreItem = async () => {
    setIsLoadingMore(true);
    try {
      await auctionServices
        .getAuctionItemsByCategoryId(page + 1, limit, auction_item_category_id)
        .then(
          (response) => {
            let newItemArr = [...auctionItems?.data, ...response?.data];
            setAuctionItems({
              ...auctionItems,
              data: newItemArr,
              limit: response.limit,
              page: response?.page,
              total_count: response?.total_count,
              total_page: response?.total_page,
            });
            setIsLoadingMore(false);
            setPage(page + 1);
          },
          (error) => {
            logger('./pages/components/AuctionItemList.jsx','loadMoreItem()1: catch',error);
            setIsLoadingMore(false);
          }
        );
    } catch (err) {
      logger('./pages/components/AuctionItemList.jsx','loadMoreItem()1: catch',err);
      setIsLoadingMore(false);
    }
  };

  return (
    <div>
      {isLoading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      )}
      <div className="container">
        <div className="auction_header header_sec">
          <span onClick={() => navigate(-1)}>
            <img src={backWhite} alt="" />
          </span>
          <span>
            <h3> Auction Items </h3>
          </span>
          <span>{/* <img src={uploadWhite} alt="" /> */}</span>
        </div>
        <div style={{ padding: "15px" }}>
          {!isLoading && category_name ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 style={{ fontSize: 13, margin: 0 }}>
                {" "}
                {auctionItems?.data?.length} Items found for {category_name}{" "}
                category
              </h4>
            </div>
          ) : !isLoading && buy_now == 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 style={{ fontSize: 13, margin: 0 }}>
                {" "}
                {auctionItems?.data?.length} Items found you can buy now
              </h4>
            </div>
          ) : null}
          {auctionItems &&
            auctionItems?.data &&
            auctionItems?.data?.map((i) => (
              <div
                className="lots_card"
                key={i.id}
                onClick={() =>
                  navigate(
                    `/lot/${i?.id}?auction_id=${i?.auction_id}&cause_id=${i?.auction?.cause_id}`
                  )
                }
              >
                <span>
                  <img src={i.auction_item_assets[0].name} loading='lazy' alt="feature" />
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    width: "70%",
                  }}
                >
                  <h5>
                    {i?.name} | {i?.description}{" "}
                  </h5>
                  <div style={{ display: "flex" }}>
                    <p style={{ width: "80%" }}>{i?.condition}</p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p style={{ width: "80%", fontWeight: 600 }}>Price</p>
                    <p
                      style={{
                        fontWeight: 600,
                        color: "var(--primary-blue-clr)",
                      }}
                    >
                      ${i?.price}
                    </p>
                  </div>
                </span>
              </div>
            ))}
          {isLoadingMore ? (
            <div
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: "30px",
              }}
            >
              Loading <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
            </div>
          ) : page < auctionItems?.total_page ? (
            <div
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: "30px",
              }}
              onClick={loadMoreItem}
            >
              Load more auctions &nbsp;
              <img src={downArrow} alt="" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AuctionItemList;
