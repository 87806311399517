import axios from "axios";
import { headerKey } from "./api-header";
import { config } from '../config/config.js';
import logger from "../utils/logger";
const API_BASE_URL = config.REACT_APP_BASE_URL;

const getDonorItemsById = async (donor_id, page, limit) => {
    try {
        const {data} = await axios.get(`${API_BASE_URL}/api/v1/donor/items/${donor_id}?page=${page}&limit=${limit}`)
        return data
    } catch (error) {
        throw error
    }
}

const getdonorsList = async () => {
    try {
      const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor`);
      return data.data
    } catch (err) {
      logger('./services/donor-services.js','getdonorsList(): catch',err);
    }
  }

const randomDonor = async () => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor/randomDonor`);
    return data
  } catch (err) {
    logger('./services/donor-services.js','randomDonor(): catch',err);
  }
}

const featuredDonors = async () =>{
  try {
    const  data  = await axios.get(`${API_BASE_URL}/api/v1/donor/getFeaturedDonors`);
    return data
  } catch (err) {
    logger('./services/donor-services.js','featuredDonors(): catch',err);
  }
}

const getdonorById = async (id) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor`,{
      params:{donor_id:id}
    });
    return data.data
  } catch (err) {
    logger('./services/donor-services.js','getdonorById(): catch',err);
  }
}

const getEventDonors = async (auctionId) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/api/v1/donor/get-donors/${auctionId}`);
    return data
  } catch (err) {
    logger('./services/donor-services.js','getEventDonors(): catch',err);
    throw err
  }
}
const donorServices = {
    getDonorItemsById,
    getdonorsList,
    randomDonor,
    featuredDonors,
    getdonorById,
    getEventDonors
}

export default donorServices