import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import auctionServices from '../../services/auction-services';
// import Lottie from 'lottie-react';
// import animationJson from '../../images/animation.json';
import './BuyNowCheckout.css';
// import {Mobile, Desktop} from "./responsive/Responsive"
// import Navbar from '../desktop/components/Navbar/Navbar';
// import SubNavbar from '../desktop/components/SubNavbar/SubNavbar';
// import Footer from '../desktop/components/Footer/Footer';
import {Helmet} from "react-helmet-async"
import congrats from "../../images/congrats.svg"
import NewButton from './atoms/NewButton/NewButton';
import { mobileStyle } from './Home';
import BGLogoComponent from '../../images/BGLogoComponent/BGLogoComponent';
import { useMediaQuery } from 'react-responsive';


const BuyNowCheckout = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({minWidth: 768})
  // useEffect(() => {
  //   if (!state) {
  //     navigate('/');
  //     return;
  //   }
  //   (async () => {
  //     const { data } = await auctionServices.getAuctionItemDetailsById(
  //       state.auctionId,
  //       state.itemId
  //     );
  //     setItem({
  //       image: data.assets && data.assets.length > 0 ? data.assets[0].name : '',
  //       name: data.name,
  //     });
  //   })();
  
  // }, []);

  useEffect(() => {
    if(!state) {
      navigate("/shop-now")
    }
  },[])

  const handleGoToCurrentAuction = async () => {
    try {
      if(state.noOfEvent === 1){
        const { data } = await auctionServices.getAuctionById(state.auctionId);
        let url = 'event';
        if(data?.is_store == '1') {
          url = 'store'
        }
        else if(data?.market_place == '1') {
          url = 'marketplace'
        }
        navigate(`/${url}/${data.custom_url ? data.custom_url : data.id}`, {
          // state: { auction: data, image: data?.image2, from: 'event' },
          replace: true, 
        });
      }else{
        navigate("/shop-now")
      }

    } catch (error) {
      navigate('/not-found', { replace: true });
    }
  };

  return (
    <>
      <Helmet>
        <title>Checkout | Boundless Giving</title>
      </Helmet>
      {/* <Mobile> */}
      <div className='bnc_main' style={{backgroundColor:mobileStyle.backgroundColor[0]}}>
      <div className='bnc_container'>
        <div className='new-checkout-screen'>
            <div style={{width:"100%",maxHeight:"150px",marginBottom: isDesktop ? '75px' : "35px"}} >
              <BGLogoComponent type="large" color={mobileStyle.color[2]} height="100%" />
           </div>
          <div style={{textAlign:"left",marginBottom:"20px"}}>
          <img src={congrats} loading='lazy' alt="" />
        </div>
        <p style={{marginTop:"15px",marginBottom:"15px",fontSize:"32px",fontWeight:"400",lineHeight:"35px"}}>Your order has <br style={{display: isDesktop? "none" : 'block'}} /> been placed!</p>
        {state?.charityAmount ? <p style={{marginTop:"15px",marginBottom:"15px",fontSize:"20px",fontWeight:"400"}}>You just donated ${state?.charityAmount} to charity*. <br style={{display: !isDesktop? "none" : 'block'}} /> Congrats on doing some good!</p> : null}
        <p style={{fontSize:"20px",fontWeight:"400",lineHeight:"24px"}}>Check your email for updates</p>
        <NewButton text={'Return to event'} onClick={handleGoToCurrentAuction} color={mobileStyle.color[2]} backgroundColor={mobileStyle.backgroundColor[0]} style={{fontSize:"16px", textTransform:'uppercase', marginTop:"30px", width:isDesktop ? '55%' : "100%"}} />
      
        </div>
        <p className='bnc-tax-benefits'>
        *You may be eligible for a tax deduction for any amount paid above the estimated value of your purchase. Ask your tax preparation expert for details.
        </p>
        </div>
      </div>
      {/* </Mobile> */}
      
      {/* <Desktop>
      <div className='buy-checkout-container'>
        <div style={{minHeight: "100vh", display: 'flex', flexDirection: "column", justifyContent: "space-between"}}>
          <div><Navbar />
          <SubNavbar /></div>
          <h1 style={{marginTop: "50px", fontSize: "1.9rem", fontWeight: "600"}}>Order Placed!</h1>
          <div className='ani-placeholder'>
            <Lottie style={{width: "400px", height: "200px"}}  animationData={animationJson} loop={true} />
          </div>
          <div className='dconfirm-info'>
            <p style={{fontWeight: "600"}}>Check your email for updates.</p>
            <button onClick={handleGoToCurrentAuction} className='buy-checkout-btn'>
              Return to event
            </button>
          </div>
          <Footer />
        </div>
        </div>
      </Desktop> */}
     
    </>
  );
};

export default BuyNowCheckout;
