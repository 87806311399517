import { Backdrop, CircularProgress } from "@mui/material";
import { Desktop, Mobile } from "../responsive/Responsive";
import "./Loading.css"

import React from 'react';

const LoadingScreen = ({bgColor="rgba(0, 0, 0, 0.5)",spinnerColor="red",loadingText=""}) => {
  return (
    <>
    
    <div className="loading-screen" style={{backgroundColor:bgColor}}>
      <div className="loader" style={{border:"2px solid #f3f3f3", borderTop:`2px solid ${spinnerColor}`}}></div>
      <p style={{color:"white"}}>{loadingText}</p>
    </div>
    
    {/* <Desktop>
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{color:"white"}}>{loadingText}</p>
        </div>
      </Backdrop>
    </div>
    </Desktop> */}
    </>
    
  );
};

export default LoadingScreen;
