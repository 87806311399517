import HeaderV2 from "../../molecules/Header/HeaderV2";
import { Desktop, Mobile } from "../../responsive/Responsive";
import "./Impact.css";
// import image1 from "../../../../images/lm-1.svg";
// import image2 from "../../../../images/lm-2.svg"
import MakeAnImpactTop from "../../molecules/MakeAnImpactTop/MakeAnImpactTop";
import NewButton from "../../atoms/NewButton/NewButton";
import { useState } from "react";
import NonprofitSignup from "../../molecules/NonprofitSignup/NonprofitSignup";
import { useNavigate } from "react-router-dom";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import { mobileStyle } from "../../Home";
import { Helmet } from "react-helmet-async";

const LearnMore = () => {
  const [openModal,setOpenModal]=useState(false)
  const [brand, setBrand] = useState(false);
  const navigate = useNavigate()
  const handleModalClick = (brandValue=false) => {
    setOpenModal(true)
    setBrand(brandValue)
  }
  const handleshopNowClick = () => {
    navigate('/shop-now')
  }
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Boundless Giving - Shop, Run Events & Make Impact</title>
        <meta 
          name="description" 
          content="Learn how Boundless Giving turns your purchases into fundraising dollars. Shop guilt-free, run successful events, and create impact effortlessly right away!" 
        />
      </Helmet>
      <Mobile>
        <HeaderV2
          primaryBackgroundColor="#30313A"
          primaryColor="#F0E8E3"
          logoType="white"
        />
        <MakeAnImpactTop>
        <div className="mb-lm-btm">
            Surf the platform. Explore the different ways you can make a
            difference. Or if you’re a brand who wants to donate or a nonprofit
            thinking about running an event of your own, get in touch.
          </div>
        </MakeAnImpactTop>
      </Mobile>
      <Desktop>
        <HeaderV2
          logoType="black"
          primaryBackgroundColor="#F0E8E3"
          primaryColor="#30313A"
          containerStyle={{ position: "fixed" }}
        />
        <MakeAnImpactTop>
          <div className="des-lm-opp-container">
              <h2>Opportunities:</h2>
              <div className="des-lm-card-container">
                <div className="opp-card">
                  <h5>[For Brands + Retailers] </h5>
                  <div className="list_button_wrapper">
                  <ul className="opp-card-list">
                    
                    <li>Transform surplus into a sustainable revenue stream</li>
                    <li>Enhance brand reputation</li>
                    <li>Benefit from tax incentives</li>
                    <li>
                      Increase access to coveted Gen Z + Millennial shoppers
                    </li>
                  </ul>
                  <NewButton text={"Contact Us"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 8px",border:"1px solid #FC3300",fontSize:"12px"}} onClick={() => {handleModalClick(true)}}/>
                  </div>
                </div>
                <div className="opp-card">
                  <h5>[For Nonprofits] </h5>
                  <div className="list_button_wrapper">
                  <ul className="opp-card-list">
                    <li>Raise money in an entirely new way</li>
                    <li>Create ongoing revenue streams</li>
                    <li>Supported by name brands</li>
                    <li>
                    Tap into smarter technology
                    </li>
                    <li>Broaden donor bases</li>
                  </ul>
                  <NewButton text={"Contact Us To Learn More"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 8px",border:"1px solid #FC3300",fontSize:"12px"}} onClick={() => handleModalClick(false)}/>
                </div>
                </div>
                <div className="opp-card">
                  <h5>[For Consumers] </h5>
                  <div className="list_button_wrapper">
                  <ul className="opp-card-list">
                    <li>Experience shopping as a charitable act</li>
                    <li>Feel-good shopping without paying a premium</li>
                    <li>Adopt and advocate for brands with shared values</li>
                  </ul>
                  <NewButton text={"SHOP NOW"} color="#FC3300" style={{textTransform:"uppercase",padding:"5px 8px",border:"1px solid #FC3300",fontSize:"12px"}} onClick={handleshopNowClick}/>
                </div>
                </div>
              </div>
            </div>
            {/* <div className="des_lm_btm">
                <h2>Marketplace Distribution:</h2>

                <p className="des_lm_btm_brands">[For Brands]</p>
                <div className="des_lm_btm_flex">
                    <div className="des_lm_btm_card">
                        <div style={{height:"200px"}}>
                        <h5>Model A: Dropshipping</h5>
                        <p>Allows Boundless to avoid the cost-prohibitive task of taking on inventory and warehouse costs.</p>
                        </div>
                        
                        <div className="des_lm_btm_card_banner">
                            <img src={image2} alt="" />
                        </div>
                    </div>
                    <div className="des_lm_btm_card">
                        <div style={{height:"200px"}}>
                        <h5>Model B: 3PL Partnerships</h5>
                        <p>By integrating directly with 3PLs, vendors can list and ship goods through an integrated API that doesn’t require moving inventory to new locations or finding outside liquidation partners. </p>   
                        </div>
                      
                        <div className="des_lm_btm_card_banner">
                            <img src={image1} alt="" />
                        </div>
                    </div> 
                </div>
            </div> */}
        </MakeAnImpactTop>
      </Desktop>
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]}/>
      <NonprofitSignup isBrand={brand} isOpen={openModal} onClose={()=>setOpenModal(false)} onSubmitForm={()=>setOpenModal(false)} />
    </>
  );
};

export default LearnMore;
