import React from 'react';
import style from './Section3.module.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';

function Section3({fundraise}) {
    return (
        <section className={style.section3}>
            <img src='https://assets.cdn.filesafe.space/gDomeI4d9kjTwwlhShAw/media/64223018f81e19fd65e78e6d.jpeg' alt='bg' />
            <div className={style.card_container}>
                <div className={style.card}>
                    <h2><strong>Fundraising, but way better</strong></h2>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b> Transparent pricing </b></p></div>
                        <p>You keep up to 80% of all event sales.</p>
                    </div>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b> No hidden fees </b></p></div>
                        <p>We don't charge nonprofits extra for payment processing or platform features.</p>
                    </div>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b>Fulfillment services</b></p></div>
                        <p>We can store items in our warehouse and do the picking, packaging, and shipping for you.</p>
                    </div>
                    <div>
                        <div><CheckCircleIcon sx={{ color: '#F43AB0', marginTop: "4px", fontSize: "25px" }} /> <p><b> White glove services</b></p></div>
                        <p>Too busy to create your event? We can do it for you, too.</p>
                    </div>
                    <div className={style.button_container}>
                        <Link to={"/get-started"}>
                            <button>START FUNDRAISING</button>
                        </Link>
                    </div>

                </div>

            </div>
            <div className={style.text_box_container}>
                {/* <div className={style.text_box}>
                    <p>1) Our fees are 10% of all event sales. Optional add-on services increase but never exceed 20%.</p>
                    <p>2) Fulfillment is an optional service if you want us to handle all of the merchandise storage, picking, packing, and shipping.</p>
                    <p>3) White glove is an optional service if you want a dedicated account manager to create your event, list your items, and keep you updated on your fundraiser.</p>
                </div> */}
            </div>
        </section>
    )
}

export default Section3