import { useState } from "react";
import { useDispatch } from 'react-redux';
import DropDownButton from "../DropDownButton/DropDownButton";
import BrowseByDropDownMenuItem from "./BrowseByDropDownMenuItem/BrowseByDropDownMenuItem";
import { getFilterDetails, checkChecked, handleFilterChange } from "../../../../utils/shopnow.utils";

const BrowseByDropDown = ({ filterArray = [], filterType = "", dropDownText = "", selectedFilters={selectedFilters}, pageType="shop-now" }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    // const { selectedFilters } = useSelector(state => state.shopNow);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };

    return (
        <DropDownButton open={open} anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} dropDownText={dropDownText}>
            {
                filterArray?.map((item) => {
                    return (
                        <BrowseByDropDownMenuItem
                            key={getFilterDetails(item, filterType).id}
                            value={getFilterDetails(item, filterType).id}
                            checked={checkChecked(getFilterDetails(item, filterType), filterType, selectedFilters)}
                            label={getFilterDetails(item, filterType).name}
                            onChange={() => handleFilterChange(getFilterDetails(item, filterType), filterType, dispatch, pageType)}
                        />
                    )
                })
            }
        </DropDownButton>
    )
}

export default BrowseByDropDown;