import React, { useState } from "react";
import { validEmail } from "../../utils/reg-ex";
import style from './NoData.module.css';

const NoData = ({ customStyle = {}, color = '#F0E8E3' }) => {
  const [email, setEmail] = useState("")
  const [valid, setValid] = useState(false)
  const handleChange = (e) => {
    setValid(e.target.value.match(validEmail))
    setEmail(e.target.value)
  }

  return (
    <form style={{backgroundColor: "#FC3300"}} action="https://boundlessgiving.us21.list-manage.com/subscribe/post?u=e523e179961f63c6ced52602f&amp;id=19066d7026&amp;f_id=0080c5e1f0%22" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
      <div
        style={{
          height: "306px",
          color: color,
          textAlign: "-webkit-center",
          overflow: "hidden",
          borderRadius: '15px',
          ...customStyle
        }}
        className={`${style.subscribe} margin_class`}
      >
        {/* <div id="mc_embed_signup" style={{height:"100%"}}>
          <form style={{margin: '10px'}} action="https://boundlessgiving.us21.list-manage.com/subscribe/post?u=e523e179961f63c6ced52602f&amp;id=19066d7026&amp;f_id=0080c5e1f0%22" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll" style={{padding: '5px'}}>
                <h2>Don't Miss New Giving Events</h2>
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">
                    Email Address <span className="asterisk">*</span>
                  </label>
                  <input style={{borderRadius:'5px',borderColor:color,backgroundColor:'#fc4314'}} value={email} onChange={handleChange} type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
                  <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                  <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                </div>
                  <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_e523e179961f63c6ced52602f_19066d7026" tabIndex="-1" /></div>
                  <div className="clear"><input disabled={!valid} type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" style={{backgroundColor: valid ? "#1A385C" : "gray"}}/></div>
              </div>
          </form>
      </div> */}
        <p>Don’t miss new giving <br className={style.break}/> events. </p>
        <input style={{ color: color, border: `1px solid ${color}` }} placeholder="Email address" />
        <button type="submit" style={{ color: color, border: `1px solid ${color}` }} >Sign Up</button>

      </div>
    </form>
  );
};

export default NoData;
