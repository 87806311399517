import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  OutlinedInput,
  FormControl,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from 'react-redux'
import "../../atoms/NoSearch/NoSearch.css";
import "./Search.css";
import {Helmet} from "react-helmet-async"
import GlobalHeader from "../../molecules/GlobalHeader/GlobalHeader";
import { auctionSearch, resetAuction } from "../../../../redux/searchReducer";
import { Desktop, Mobile } from "../../responsive/Responsive";
import Navbar from "../../../desktop/components/Navbar/Navbar";
import SubNavbar from "../../../desktop/components/SubNavbar/SubNavbar";
import Header from "../../../desktop/components/Header/Header";
import Footer from "../../../desktop/components/Footer/Footer";
import HeaderV2 from "../../molecules/Header/HeaderV2";
import SubHeader from "../../molecules/SubHeader/SubHeader";
import { mobileStyle } from "../../Home";
import { useMediaQuery } from "react-responsive";

const Search = () => {
  const [isSearched, setIsSearched] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [searchText,setSearchText] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const primaryBackgroundColor = mobileStyle.backgroundColor[2]
  const primaryColor = mobileStyle.color[1]
  const redColor = mobileStyle.backgroundColor[0]
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const initiateSearch = async (searchTerm) => {
    dispatch(auctionSearch({ searchTerm, variable: 'itemsList' }))
    dispatch(auctionSearch({ searchTerm, variable: 'auctionList' }))
    setIsSearched(true);
    setNoResult(!noResult);
  };
  const { itemsList, auctionList } = useSelector(state => state.search)

  useEffect(() => {
    dispatch(resetAuction())
  }, [])

  useEffect(() => {
    if (auctionList.length < 1 && itemsList.length < 1)
      setNoResult(true);
    else {
      setNoResult(false);
      navigate("/search-result", {state: {search: searchText}});
    }
  }, [itemsList, auctionList])

  const handleChange=(e)=>{
    setSearchText(e.target.value)
  }

  const handleClick = (e) => {
    if (e.key === "Enter" && e.target.value.length >= 2) initiateSearch(e.target.value);
    if (!e.key && searchText.length >= 2) initiateSearch(searchText); 
  };

  if (!isSearched || noResult) {
    return (
      <div style={{minHeight:'100vh', backgroundColor: primaryBackgroundColor}} className="container">
        <Helmet prioritizeSeoTags>
          <title>Boundless Giving Search - Find What You Need</title>
          <meta 
            name="description" 
            content="Get opportunities to give back with Boundless Giving's search feature, where you can explore various organizations to find the best match for your generosity."
          />
        </Helmet>
              <HeaderV2 logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:'fixed'}} isSubHeader={true} subHeaderTitle="Search" />
        <main style={{backgroundColor:isMobile && primaryBackgroundColor, color:isMobile && primaryColor}} className="margin_class default-search-page">
          <div className="search_content_container">
          {/* <Mobile> */}
            <p className="default_search_heading">Search for your favorite items.</p>
          {/* </Mobile> */}
          <section style={{marginTop:isMobile && '2px'}} className="search-header">
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                placeholder="Search"
                sx={{ 
                  padding: '2px 10px',
                  minHeight:'40px',
                  borderRadius:"5px",
                  border: `1px solid ${primaryColor}`,
                  color:primaryColor,
                  fontSize: '12px',
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: '18px',
                  fontFamily:'Instrument Sans'

                }}
                id="search-text-search-page"
                fullWidth
                onKeyDown={handleClick}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="start" >
                    <SearchIcon sx={{color:"#30313a7a", fontSize:"16px", fontWeight:400}} onClick={handleClick} />
                  </InputAdornment>
                }
              />
            </FormControl>
            {/* <p className="cancel-search">Cancel</p> */}
          </section>
          </div>
          {/* <Desktop>
          <div className="search-body">
            <SearchIcon fontSize="large" />
            <p className="search-body__text">
              {isSearched && noResult
                ? "No Results"
                : "Search for you favorite items"}
            </p>
          </div>
          </Desktop> */}
          {/* <Mobile> */}
          <div className='no_result_container'>
          {isSearched && noResult
                ? <p style={{color:primaryColor}} className="no_results_text" >No results found</p>
                : ""}
          </div>
          {/* </Mobile> */}
        </main>
        {/* <Desktop><Footer /></Desktop> */}
      </div>
    );
  }
};

export default Search;
