import React from 'react'
import { mobileStyle } from '../../Home'
import classes from './TagHeading.module.css';

const TagHeading = ({ heading="", style = {}, customClass='' }) => {
    return (
            <p style={{
                color:mobileStyle.color[0],
                fontFamily: " DM Mono",
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                height:'15px',
                textAlign:'start',
                ...style,
            }} 
            className={classes[customClass]}
            >
               {`[${heading}]`}
            </p>
    )
}

export default TagHeading