import React, { useEffect, useState } from 'react'
import NewButton from '../../../../atoms/NewButton/NewButton'
import RoundedContainer from '../../../../atoms/RoundedContainer/RoundedContainer'
import classes from './FeaturedEvents.module.css'
import TagHeading from '../../../../atoms/TagHeading/TagHeading'
import auctionServices from '../../../../../../services/auction-services'
import logger from '../../../../../../utils/logger'
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import Image from '../../../../atoms/Image/Image'
import {config} from '../../../../../../config/config'
import { imageUrls } from '../../../../../../constants/url'
const ASSETS_BASE_URL = config.ASSETS_BASE_URL

const FeaturedEvents = ({ mobileStyle = { backgroundColor: [], color: [] }, title='', page='event' }) => {
  const backgroundColor = mobileStyle?.backgroundColor[2]
  const color = mobileStyle?.color[0]
  const [featuredEvent, setFeaturedEvent] = useState([])
  const [loading, setLoading] = useState(false)
  const backgroundImage = imageUrls.featured_event_background_desktop;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  useEffect(() => {
    getFeaturedAuctions()
  }, [])

  const getFeaturedAuctions = async () => {
    try {
      setLoading(true)
      await auctionServices.getFeaturedAuctionsList(page)
        .then((response) => {
          setFeaturedEvent(response.data)
          setLoading(false)
        })
    } catch (error) {
      setLoading(false)
      logger('./pages/components/Home.jsx', 'getFeaturedAuctions(): catch2', error);
    }
  }


  const handleClick = (event) => {
    navigate(`/${page}/${event?.custom_url ? event?.custom_url : event?.id}`)
  }

  const getButtonLabel = () => {
    switch(page) {
      case 'store': case 'marketplace':
        return "SHOP NOW"
      case 'event':
        return "VIEW EVENT"
        default:
          return "SHOP NOW"
    }
  }

  return (
    <>
      {featuredEvent?.length ?
        <section className={classes.section} style={{ backgroundColor: backgroundColor }}>
          {/* <div style={{ width: '100%',display:'flex',justifyContent:'center' }}>
        <RoundedContainer style={{ backgroundColor: backgroundColor, border: `2px solid ${color}`,width:isMobile?'100%':'65%' }}>
          <div style={{ color: color, display: 'flex', flexDirection: 'column', gap: '10px' }} >
            <TagHeading heading={'Featured Event'} />
            <h3 className={classes.heading} >{featuredEvent?.title}</h3>
            <p className={classes.date}>Ends On {moment(featuredEvent?.end_date).format("MMMM DD, YYYY")}</p>
            <NewButton text={"VIEW EVENT"} onClick={handleClick} style={{ fontSize: '14px' }} color={color} backgroundColor={backgroundColor} />
          </div>
        </RoundedContainer>
      </div> */}

          <div style={{ backgroundColor: color}} className={`${classes.main_container} margin_class`} >
            <div className={`${classes.new_non_profit_container}`}>
              <h1 style={{ color: backgroundColor }} className={classes.section_heading}>{title}</h1>
              <div className={classes.carosel_container_wrapper}>
                <div className={classes.carosel_container} >
                {
                  featuredEvent?.length ? featuredEvent.map((event, index) => {
                    return (
                        <RoundedContainer key={index} customClassName={'custom_container'} style={{ backgroundColor: backgroundColor }}>
                          <div className={classes.card_main} onClick={() => handleClick(event)} >
                            <div className={classes.featured_image_container}>
                              {event?.featured_image ?
                                <img src={event?.featured_image} className={classes.featured_image} loading='lazy' alt={event?.auction_banner_alt} />
                                :
                                <Image src={`${event?.image2}_small`} style={{ maxWidth: '100%', width: '100%', height: '100%', objectFit: 'cover', borderTopLeftRadius:'15px', borderTopRightRadius:'15px' }} alt={event?.auction_banner_alt} />
                              }
                            </div>
                            <div className={classes.card_bottom_section}>
                            <h3 className={classes.featured_title} >{event?.title}</h3>
                            <div className={classes.date_view_section} >
                              {
                                page === 'event' &&
                                 <p className={classes.featured_ends_on} >{moment(event?.start_date)<=new Date()?`Ends On ${ moment(event?.end_date).format("MMMM DD, YYYY")}`: `Launches on ${ moment(event?.start_date).format("MMMM DD, YYYY")}`}</p>
                              }
                            <NewButton text={getButtonLabel()} style={{ fontSize: '14px', border: 'none', marginTop:'5px' }} color={backgroundColor} backgroundColor="#30313A" />

                            </div>
                            </div>
                          </div>
                        </RoundedContainer>
                    )
                  })
               :null }
                </div>
              </div>
            </div>
          </div>



        </section>
      :null}
    </>
  )
}

export default FeaturedEvents