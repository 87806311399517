import React from 'react'
import { useNavigate } from 'react-router-dom'
import classes from "./BrowseByCards.module.css"

const BrowseByCards = ({id, image, name, imageFolder="", onClick, useFolder=true, textColor, backgroundColor, alt=""}) => {
  return (
    <>
    {/* <div onClick={onClick} className={classes.dcategories_card}>
        <div className={classes.dcategories_card_img}>
          {
            useFolder ? <img src={require(`../../../../images/${imageFolder}/300x200/${image}`)} alt="" /> :
            <img src={image} alt="" />
          }
        </div>
        <div className={classes.dcategories_card_head}>
          {name}
        </div>
    </div> */}

    <div onClick={onClick} className={classes.desktop_card_container} >
      <div className={classes.desktop_image_container}>
          {
            useFolder ? <img style={{backgroundColor:backgroundColor}} src={require(`../../../../images/${imageFolder}/300x200/${image}`)} alt={alt} /> :
            <img style={{backgroundColor:backgroundColor}} loading='lazy' src={image} alt={alt} />
          }
      </div>
      <p style={{color:textColor}} className={classes.desktop_image_title} >{name}</p>
    </div>

    </>
  )
}

export default BrowseByCards