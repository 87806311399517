import { SwipeableDrawer } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const SwipableDrawerComponent = ({ open, setOpen = () => null, children }) => {
    const isMobile = useMediaQuery({
        maxWidth: 768
    })
    const toggleFilterScreen = () => {
        setOpen(!open)
    }
    return (
        <SwipeableDrawer
            anchor="left"
            open={open}
            onClose={toggleFilterScreen}
            onOpen={toggleFilterScreen}
            sx={{
                ".MuiDrawer-paper.MuiDrawer-paperAnchorLeft": {
                    paddingLeft: 0,
                    width: isMobile ? '100%' : "60%"
                },
                paddingInline: '30px',
            }}
        >
            {children}
        </SwipeableDrawer>
    )
}

export default SwipableDrawerComponent;