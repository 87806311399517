import React from 'react'
import classes from './MarketPlaceDesktopTop.module.css';
import NewButton from '../../../../atoms/NewButton/NewButton';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import constants from '../../../../../../constants/en';
import { imageUrls } from '../../../../../../constants/url';

// const {HOME_PAGE_SHOP_NOW_IMAGE, SHOP_NOW_MOBILE_IMAGE} = constants;
const HOME_PAGE_SHOP_NOW_IMAGE = imageUrls.home_page_desktop_market_place_banner;
const SHOP_NOW_MOBILE_IMAGE = imageUrls.home_page_mobile_market_place_banner
const MarketPlaceDesktopTop = ({title="Boundless Shop"}) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return (
    <section className={classes.main_section} style={{backgroundImage:`url(${isDesktop ? HOME_PAGE_SHOP_NOW_IMAGE:SHOP_NOW_MOBILE_IMAGE})`}} >
        <div className={`margin_class ${classes.container} `} >
            <h3 >Boundless <br className={classes.title_break} /> Shop</h3>
            <NewButton 
               text={'SHOP NOW'}
               backgroundColor='transparent'  
               style={{fontSize:'20px', lineHeight:'16px', letterSpacing:'1.4px', padding:'15px 25px',color:"black",border:"2px solid black"}}
               onClick={() => {
                // dispatch(clearPageNumber());
                // navigate("/browse-items", {
                //   state: { buyNow: true, title: "Buy now", from: 'buyNow' },
                // })
                navigate('/shop-now')
               }
              }
            />
        </div>
    </section>
  )
}

export default MarketPlaceDesktopTop