import React, { useEffect, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import "./BrowseItemUpperHalf.css"
import auctionServices from '../../../../services/auction-services';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Desktop, Mobile } from "../../responsive/Responsive";
import Alert from '@mui/material/Alert';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DialogMui from "../Dialog/Dialog";
import { config } from '../../../../config/config';
import { useMediaQuery } from "react-responsive";
import TagHeading from "../../atoms/TagHeading/TagHeading";
import { mobileStyle } from "../../Home";
import BrowseItemsBanner from "../../atoms/BrowseItemsBanner/BrowseItemsBanner";
import { imageUrls } from "../../../../constants/url";
const REACT_APP_URL = config.REACT_APP_URL;
const primaryBackgroundColor = mobileStyle.backgroundColor[2];
const primaryColor = mobileStyle.color[1];

const BrowseItemUpperHalf = ({ params }) => {
    const [textCopied,setTextCopied]=useState(false)
    const [openModal,setOpenModal]=useState(false)
    const [causeName, setCauseName] = useState("");
    const [goalDetails,setGoalDetails]=useState({goal:'',achieved:'',percentage:'',progress:''});
    const [oldEventImage,setOldEventImage] = useState(false);
    const isDesktop = useMediaQuery({minWidth: 768})
    const myInput=useRef()
    let from = params?.from
    const auctionFallBackImage='https://bg-assets-bucket.s3.amazonaws.com/Event_9/auction_banner.png';
    const buyNowFallBackImage='https://bg-assets-bucket.s3.amazonaws.com/Event_9/buynow_banner.png';

    let browseBrands = imageUrls.shop_now_brands_you_love_desktop;
    let browseCause = imageUrls.shop_now_cause_you_care_desktop;
    let browseMerch = imageUrls.shop_now_our_merch_desktop;
    if(!isDesktop) {
        browseBrands = imageUrls.browse_brands_page_banner_mobile;
        browseCause = imageUrls.browse_cause_page_banner_mobile;
        browseMerch = imageUrls.browse_stores_page_banner_mobile;
    }

    useEffect(() => {
        if (from === ('event' || 'event-auction'|| 'event-byNow' || "event-customCategory")) {
            (async () => {
                const { data } = await auctionServices.getAuctionById(params.auction?.id,params?.showIncomplete)
                setCauseName(data.causeDetails.legal_name)
            })()
            fetchGoal()
        }
    }, [from])

    useEffect(() => {
        // To support old images whcih does not have variant(small,medium,large).
        const img = new Image();
        img.src =isDesktop? params?.auction?.image2+"_small":params?.auction?.image1+"_small";
        img.onerror = () => {
          setOldEventImage(true);
        };
        return ()=>{img.onerror=null}
    },[])


    const getImageVariant=(base_url=isDesktop ? params?.auction?.image2 : params?.auction?.image1)=>{
        
        if(window.innerWidth>=1440){
            return base_url+'_large'
         }else if(window.innerWidth<1440 && window.innerWidth>=1024 ){
            return base_url+'_medium'
         }else if(window.innerWidth<1024 && window.innerWidth>=768 ){
            return base_url+'_small' 
         }else if(window.innerWidth<768 && window.innerWidth>=425 ){
            return base_url+'_large'
         }else if(window.innerWidth<425 && window.innerWidth>=375 ){
            return base_url+'_medium'
         }else if( window.innerWidth<375 ){
            return base_url+'_small'
         }
    }

    const getImageWithoutVariant =(base_url)=>{
        if(base_url) return base_url
        return isDesktop ? params?.auction?.image2 : params?.auction?.image1
    }

    const copyUrl=(e)=>{
      const  copyText=myInput.current
         copyText.select();
         copyText.setSelectionRange(0, 99999);
         navigator.clipboard.writeText(copyText.value);
         setTextCopied(true)
    }

    const handleModalButton=()=>{
        setOpenModal(false);
        setTextCopied(false)
    }
      
       const fetchGoal=async()=>{
        let  {data}  = await auctionServices.findAuctionGoal(params.auction?.id);
       let percentage=((+data?.achieved/+data?.goal)*100).toFixed(1)
       let progress=percentage<100?percentage:100;
        setGoalDetails({goal:data?.goal,achieved:data?.achieved,percentage,progress})
       }

    const navigate = useNavigate()

    
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: primaryBackgroundColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:primaryColor,
    },
  }));
  const handleEventOverview = () => {
    let url=''
    switch (params.type) {
        case 'Marketplace':
            url='marketplace-overview'
            break;
        case 'Store':
            url='store-overview'
            break;
        default:
            url='event-overview'
            break;
    }
    navigate(`/${url}/${params?.auction?.id}`, { state: { auctionDetails: params,showIncomplete:params?.showIncomplete } })

  }
  const handleCauseOverview = () => {
    if(params.type === 'Marketplace') return navigate(`/about-donor/${params?.auction?.donor_id}`)
    navigate(`/about-cause/${params.auction?.cause_id}?auction_id=${params?.auction?.id}`, { state: { auctionDetails: params } })
  }

  let dbBoxColor = ''
  let dbTextColor = mobileStyle.color[1];
  if (params?.auction?.need_box === "1" && params?.auction?.box_color) {
      dbBoxColor = params?.auction?.box_color
  }
  if(params?.auction?.text_color) {
     dbTextColor = params?.auction?.text_color
  }

// console.log(params)
    switch (from) {
        // case 'auction':
        //     return <h1 style={{ margin: "10px 0 20px 0" }}>Items Up For Auction</h1>
        case 'event':
            return (
                <>
                    <Mobile>
                        {
                        // (params.type==='Event' ) ?
                         <div className="mp-banner-container " style={{backgroundImage:`url(${oldEventImage?getImageWithoutVariant():getImageVariant()})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",color:"#30313A",backgroundPosition:'center'}}>
                          <div style={{height:'100%', width:'100%'}} className="overlay_white_gradient mp-event-banner-contianer">
                          {(params?.type === 'Store')&& <h2 style={{color:dbTextColor,fontSize:'30px',fontWeight:'400',borderBottom:`1px solid ${dbTextColor}`}}>{params?.auction?.causeDetails?.legal_name} Shop</h2>}
                            <div
                               style={{
                                padding: params?.auction?.need_box === "1" ? '30px 20px' : '',
                                backgroundColor: dbBoxColor,
                                borderRadius: params?.auction?.need_box === "1" ? '25px' : '',
                                minHeight:params?.type === 'Store' ? '200px':'150px',
                                justifyContent:params?.type === 'Store' ? 'flex-end':'space-between',
                               }} 
                               className="mobile_info_container">
                               
                                {(params?.type === 'Event') && <TagHeading heading={causeName} style={{color:dbTextColor}} />}
                                <h1 style={{color:dbTextColor}} className="mobile_auction_title" >{params.auction?.title}</h1>
                                {(params?.type === 'Event') ? new Date(params.auction?.start_date) > new Date() ? (
                                <p className="mobile_auction_ends" style={{color:dbTextColor}}>
                                    {" "}
                                    Opens {" "}
                                    {moment(params.auction?.start_date).format("MMMM Do, YYYY")}
                                    {", "}
                                    {moment(params.auction?.start_date)
                                        .tz("America/Los_Angeles")
                                        .format("ha z")}
                                    .{" "}
                                </p>
                            ) : (
                                <p className="mobile_auction_ends" style={{color:dbTextColor}}>
                                    {" "}
                                    Ends On{" - "}
                                    {moment(params.auction?.end_date).format("MMMM Do, YYYY")}
                                    {", "}
                                    {moment(params.auction?.end_date)
                                        .tz("America/Los_Angeles")
                                        .format("ha z")}
                                    .{" "}
                                </p>
                            ): ""}

                            <div className="info_buttons" style={{color:dbTextColor}}>
                                <button onClick={handleEventOverview} style={{borderBottom:`1px solid ${dbTextColor}`,color:dbTextColor}} className="info_btn" >{params?.type} Overview</button>
                                <button onClick={handleCauseOverview} style={{borderBottom:`1px solid ${dbTextColor}`,color:dbTextColor}} className="info_btn">{params?.type ==='Marketplace'?'About This Donor':'About This Cause'}</button>
                            </div>
                            </div>
                            </div>
                        </div>
                        // :  
                        //// else it is market place or store
                    //     <div className="mp-banner-container" style={{backgroundImage:`url(${oldEventImage?getImageWithoutVariant():getImageVariant()})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:'center'}}> 
                    //     <div className="mp-banner-text">
                    //         <div>
                    //         <div className="heading">{(params?.auction?.market_place === "1") ? `${params?.auction?.title} Store`: `${params?.auction?.title} Shop`} </div>
                    //         </div>
                    //         <div>
                    //         <div className="sub-heading"> {params?.auction?.title} </div>
                    //         <p className="sub-text">{params?.auction?.about}</p>
                    //         </div>
                    //     </div>
                    // </div>
                        }
                        
                    </Mobile>

                    
                    {/* new event desktop design start  */}
                    <Desktop>
                         <>
                          <BrowseItemsBanner
                            image={oldEventImage?getImageWithoutVariant():getImageVariant()}
                            primaryColor={primaryColor}
                            childrenContainerStyle={{justifyContent:(params?.type === 'Store') ? '':"flex-end"}}
                            gradientClass="overlay_white_gradient"
                            desktopImageStyle={{height:!params.market_place && "85vh"}}
                            // needBox={params?.auction?.need_box === "1"}
                           >
                             {(params?.type === 'Store') && <h2 style={{fontSize:'65px',paddingBottom:'15px',color:dbTextColor,borderBottom:`1px solid ${dbTextColor}`}}>{params?.auction?.causeDetails?.legal_name} Shop</h2>}

                              <div style={{
                                    backgroundColor: dbBoxColor
                                 }} 
                                 className={params?.auction?.need_box === "1" && 'event_custom_box'}>
                              <div className="mobile_info_container " style={{marginBlock:"0", width:'100%', gap:params?.auction?.need_box === "1"  && '5px'}}>


                                {(params?.type === 'Event') && <TagHeading heading={causeName} style={{color:dbTextColor,fontSize:'24px'}} />}
                                <h1 style={{color:dbTextColor, position:"relative"}} className="mobile_auction_title" >
                                    {params.auction?.title}
                                    {params.auction?.status === "complete" && 
                                    <span
                                       style={{
                                        marginTop:"10px",
                                        marginLeft:'10px', 
                                        color:dbTextColor, 
                                        fontSize:"14px", 
                                        fontWeight:400, 
                                        position:"absolute",
                                        width:'100%',
                                        lineHeight:"16px",
                                        bottom:"10px"
                                    }}
                                    >
                                          This event is now closed.
                                    </span>}
                                </h1>

                                {(params?.type === 'Event') ? new Date(params.auction?.start_date) > new Date() ? (
                                <p className="mobile_auction_ends" style={{color:dbTextColor}}>
                                    {" "}
                                    Opens {" "}
                                    {moment(params.auction?.start_date).format("MMMM Do, YYYY")}
                                    {", "}
                                    {moment(params.auction?.start_date)
                                        .tz("America/Los_Angeles")
                                        .format("ha z")}
                                    .{" "}
                                </p>
                            ) : (
                                <p className="mobile_auction_ends" style={{color:dbTextColor}}>
                                    {" "}
                                    Ends On{" - "}
                                    {moment(params.auction?.end_date).format("MMMM Do, YYYY")}
                                    {", "}
                                    {moment(params.auction?.end_date)
                                        .tz("America/Los_Angeles")
                                        .format("ha z")}
                                    .{" "}
                                </p>
                            ): ""}

                            <div className="info_buttons" style={{color:dbTextColor}}>
                                <button onClick={handleEventOverview} 
                                  style={{
                                    borderBottom:`1px solid ${dbTextColor}`,
                                    color:dbTextColor,
                                  }} 
                                  className="info_btn" >{params?.type} Overview
                                  </button>
                                <button onClick={handleCauseOverview} 
                                  style={{
                                    borderBottom:`1px solid ${dbTextColor}`,
                                    color:dbTextColor,
                                  }}
                                  className="info_btn">{params?.type ==='Marketplace'?'About This Donor':'About This Cause'}
                                </button>
                            </div>
                            </div>
                            </div>
                           </BrowseItemsBanner>
                          </>
                        </Desktop>

                    {/* new event desktop design end */}

                    {/* <Desktop>
                        <div className="browse-upper-banner">
                            <img
                                src={params?.image}
                                alt=""
                                className="browse-upper-banner-img"
                            /> */}
                            {/* <div className="browse-upper-banner-content">
                                <div className="browse-upper-banner-content-overview">
                                    <div>{params.auction?.title}</div>
                                    <div>
                                        {`
                                            ${moment(params.auction?.start_date).format("D")} - ${
                                            moment(params.auction?.end_date).format("DD MMMM YYYY")
                                            }
                                        `}
                                    </div>
                                </div>
                                <hr style={{margin: "5px 0"}} />
                                <div className="browse-upper-banner-content-btns">
                                    <button onClick={handleEventOverview}>Event Overview</button>
                                    <button onClick={handleCauseOverview}>About This Cause</button>
                                </div>
                            </div> */}
                        {/* </div>
                    </Desktop> */}
                    {
                        params.auction?.status === "complete" && 
                        <>
                        {/* <Desktop>
                        <div style={{margin:"10px 0"}}>
                        <Alert
                        icon={false}
                        variant='outlined'
                        style={{ backgroundColor: '#ffebe9',marginTop:"10px" }}
                        severity='error'
                        >
                        This event is now closed.
                      </Alert>
                        </div>
                      </Desktop> */}
                      <Mobile>
                        <div>
                            <p 
                              style={{marginTop:"10px", textAlign:'center', color:mobileStyle.color[0], fontSize:"14px", fontWeight:600}}
                            >
                              This event is now closed.
                            </p>
                        </div>
                      </Mobile>
                        </>

                    }
                   
                    {/* <Desktop>
                       { (isDesktop || (!isDesktop && !params.market_place)) && <div className="auction_society_container">
                         <div className="about_event_container">
                         <div className="auction_society">
                            <div className="auction-society__heading">
                                <h3> {params.auction?.title} </h3>
                            </div>
                            {!params.market_place && <h3 style={{ fontSize: "0.9rem" }}>Benefitting: {causeName}</h3>}
                           {!params.market_place ? new Date(params.auction?.start_date) > new Date() ? (
                                <p>
                                    {" "}
                                    Starts from{" "}
                                    {moment(params.auction?.start_date).format("Do MMMM YYYY")}
                                    {", "}
                                    {moment(params.auction?.start_date)
                                        .tz("America/Los_Angeles")
                                        .format("ha z")}
                                    .{" "}
                                </p>
                            ) : (
                                <p>
                                    {" "}
                                    Ends On{" - "}
                                    {moment(params.auction?.end_date).format("MMMM DD YYYY")}
                                </p>
                            ): ""}
                            </div> 
                            <div className="share_button_container">
                            <button onClick={()=>setOpenModal(true)} style={{border:'none'}}>
                                    <ShareOutlinedIcon/>
                            </button>
                            </div>
                         </div>
                        <div className="auction_links">
                        {!params.market_place && <div>
                            <div className="auction_links_button"
                                // to={`/event-overview/${params.auction?.id}`}
                                onClick={handleEventOverview}
                            >
                                Event Overview
                            </div>
                        </div>}
                        <div>
                           {params.auction?.cause_id &&
                            <div className="auction_links_button"
                                // to={`/about-cause/${params.auction?.cause_id}?auction_id=${params.auction?.id}`}
                                onClick={handleCauseOverview}
                            >
                                {" "}
                                About This Cause
                            </div>
                            }
                        </div>
                    </div>
                        </div>}
                        </Desktop> */}


                        {/* old event design */}
                        {/* <Mobile>
                            {!params.market_place && 

                            <div className="mobile_info_container">
                                <TagHeading heading={causeName} style={{color:primaryColor}} />
                                <h1 style={{color:primaryColor}} className="mobile_auction_title" >{params.auction?.title}</h1>
                                {!params.market_place ? new Date(params.auction?.start_date) > new Date() ? (
                                <p className="mobile_auction_ends" style={{color:primaryColor}}>
                                    {" "}
                                    Starts from{" "}
                                    {moment(params.auction?.start_date).format("Do MMMM YYYY")}
                                    {", "}
                                    {moment(params.auction?.start_date)
                                        .tz("America/Los_Angeles")
                                        .format("ha z")}
                                    .{" "}
                                </p>
                            ) : (
                                <p className="mobile_auction_ends" style={{color:primaryColor}}>
                                    {" "}
                                    Ends On{" - "}
                                    {moment(params.auction?.end_date).format("MMMM DD YYYY")}
                                </p>
                            ): ""}

                            <div className="info_buttons" style={{color:primaryColor}}>
                                <button onClick={handleEventOverview} style={{borderBottom:`1px solid ${primaryColor}`}} className="info_btn" >Event Overview</button>
                                <button onClick={handleCauseOverview} style={{borderBottom:`1px solid ${primaryColor}`}} className="info_btn">About This Cause</button>
                            </div>
                            </div>
                            
                            }
                        </Mobile> */}
                 {/* 
                   {isDesktop &&
                            <div>
                                <hr
                                    style={{
                                        margin: "16px 6px",
                                    }}
                                />
                            </div>
                                } */}
                                {goalDetails.goal?
                                <>
                                {/* <Desktop>
                                <div className="ticker_container">
                                    <div>
                                        <div>
                                            <span className="achieved">{new Intl.NumberFormat("en-US",{
                                                style: 'currency',
                                                currency: 'USD'
                                            }).format(goalDetails.achieved) }</span>
                                            <span className="goal">raised of {new Intl.NumberFormat("en-US",{
                                                style: 'currency',
                                                currency: 'USD'
                                            }).format(goalDetails.goal).split(".")[0] } goal</span>
                                        </div>
                                        <div>
                                        <span className="percentage">{Math.floor(+goalDetails.percentage)}%</span>
                                            <span className="percentage_text">of goal</span>
                                        </div>
                                    </div>
                                    <BorderLinearProgress sx={{height:'5px'}} variant="determinate" value={goalDetails.progress} />
                                </div>
                                </Desktop>  */}
                                {/* // <Mobile> */}
                                <section style={{borderRadius:"0px", backgroundColor:primaryColor, marginBlock:'0'}}>
                                {((new Date(params?.auction?.start_date)<new Date()) && (goalDetails?.achieved > 0)) ?
                                 <div 
                                  className="ticker_container margin_class"
                                  style={{
                                   marginBottom:!isDesktop && "15px",
                                   minHeight:'152px',
                                   backgroundColor:primaryColor,
                                   color:primaryBackgroundColor, 
                                   display:"flex",
                                   justifyContent:"center",
                                   flexDirection:"column",
                                   padding:'7vw',
                                   gap:"25px",
                                   borderRadius:"0px !important",
                                  }}
                                  >
                                    <div>
                                        <div>
                                            <span
                                             style={{
                                                fontSize: "32px",
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '35px', /* 109.375% */
                                               
                                                color:primaryBackgroundColor
                                             }}
                                             className="achieved">{new Intl.NumberFormat("en-US",{
                                                style: 'currency',
                                                currency: 'USD'
                                            }).format(goalDetails.achieved) }</span>
                                            <span 
                                              style={{
                                                fontSize: "12px",
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '18px',
                                                color:primaryBackgroundColor,
                                                marginTop:"2px",
                                             }}
                                              className="goal">raised of {new Intl.NumberFormat("en-US",{
                                                style: 'currency',
                                                currency: 'USD'
                                            }).format(goalDetails.goal).split(".")[0] } goal</span>
                                        </div>
                                        <div>
                                        <span 
                                          style={{
                                            fontSize: "32px",
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '35px',
                                            
                                            color:primaryBackgroundColor
                                         }}
                                          className="percentage">{Math.floor(+goalDetails.percentage)}%</span>
                                            <span
                                             style={{
                                                fontSize: "12px",
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '18px',
                                                color:primaryBackgroundColor,
                                                marginTop:"2px",
                                             }}
                                             className="percentage_text">of goal</span>
                                        </div>
                                    </div>
                                    <BorderLinearProgress sx={{height:'2px', ".MuiLinearProgress-bar":{backgroundColor:'#30313a8a'}}} variant="determinate" value={goalDetails.progress} />
                                </div>  : 
                                <div className="ticker_container margin_class"
                                style={{
                                 marginBottom:!isDesktop && "15px",
                                 minHeight:'130px',
                                 backgroundColor:primaryColor,
                                 color:primaryBackgroundColor, 
                                 display:"flex",
                                 justifyContent:"center",
                                 flexDirection:"column",
                                 padding:'3vw',
                                 gap:"25px",
                                 borderRadius:"0px !important",
                                 fontSize:"24px",
                                 fontWeight:"400",
                                 textAlign:'center'
                                }}>Event Goal: {new Intl.NumberFormat("en-US",{
                                                style: 'currency',
                                                currency: 'USD'
                                 }).format(goalDetails.goal).split(".")[0] }</div>}
                    
                                </section>
                                {/* // </Mobile> */}
                                </>
                                :''}
                                <DialogMui isOpen={openModal} handleClick={handleModalButton} ButtonText="close" >
                                    <p style={{textAlign:'center'}}>Invite your friends to the event</p>
                                    <div className="copy_url">
                                    <input ref={myInput} value={`${REACT_APP_URL}/event/${ params.auction?.custom_url ? params.auction?.custom_url  : params.auction?.id}`} readOnly/>
                                    <div onClick={copyUrl}>
                                       {textCopied?<CheckCircleOutlineOutlinedIcon sx={{color:'green'}}/>: <ContentCopyOutlinedIcon/>}
                                    </div>
                                    </div>
                                </DialogMui>
                </>
            );
        case "causeCategory":
            return (
                <>
                <BrowseItemsBanner
                  image={params?.image}
                  heading={params?.title}
                  primaryColor={primaryColor}
                  gradientClass="overlay_white_gradient"
                />
                </>
            );
        case "auctionCategory":
            return (
                <>
                <BrowseItemsBanner
                  image={params?.image}
                  heading={params?.title}
                  primaryColor={primaryColor}
                  gradientClass="overlay_white_gradient"
                />
                </>
            );

        case "donor":
            return (
                <>
                <BrowseItemsBanner
                  image={oldEventImage?getImageWithoutVariant():getImageVariant(isDesktop ? params?.image : params?.auction?.image1)}
                  heading={(params?.market_place) ? `${params?.title} Store`: `${params?.title} Shop`}
                  title={params?.title}
                  about={params?.about ? params?.about : params?.auction?.about}
                  primaryColor={dbTextColor}
                  gradientClass="overlay_white_gradient"
                  dbBoxColor={dbBoxColor}
                //   dbTextColor={dbTextColor}
                  needBox={params?.auction?.need_box === "1"}
                  fromEvent={true}
                  params={params}

                />
                </>
            )
        case "event-auction":
            return (
                <>
                <BrowseItemsBanner
                  image={oldEventImage?getImageWithoutVariant():getImageVariant()}
                  heading={(params?.auction?.auction_label == "")? `Auction`:params?.auction?.auction_label}
                  title={params?.auction?.title}
                  primaryColor={mobileStyle.color[2]}
                  about={params?.auction?.about}
                  gradientClass="mp-banner-text-box-gradient"
                  fromEvent={true}
                  params={params}

                />
               </>
            )
        case "event-byNow":
            return (
                <>
                <BrowseItemsBanner
                  image={oldEventImage ? getImageWithoutVariant():getImageVariant()}
                  heading={(params?.auction?.buy_now_label == "")? `Shop`:params?.auction?.buy_now_label}
                  title={params?.auction?.title}
                  primaryColor={mobileStyle.color[2]}
                  about={params?.auction?.about}
                  gradientClass="mp-banner-text-box-gradient"
                  fromEvent={true}
                  params={params}
                />
               </>
            )
        case "buyNow":
            return (
                <>
                <BrowseItemsBanner
                  image={buyNowFallBackImage}
                  heading={`Shop`}
                  title={"Items You Can Buy Now"}
                  primaryColor={mobileStyle.color[2]}
                  about="50-80% of all proceeds from purchases go directly to your charity. "
                  gradientClass="mp-banner-text-box-gradient"
                />
               </>
            );
        case "auction":
                return (
                    <>
                <BrowseItemsBanner
                  image={auctionFallBackImage}
                  heading={`Auction`}
                  title={"Items Up For Auction"}
                  primaryColor={mobileStyle.color[2]}
                  about="50-80% of all proceeds from purchases go directly to your charity. "
                  gradientClass="mp-banner-text-box-gradient"
                />
               </>
                );
        case "event-category":
            return (
              <>
                <BrowseItemsBanner
                  image={params?.image}
                  heading={params?.title}
                  primaryColor={primaryColor}
                  gradientClass="overlay_white_gradient"
                />
                </>
            )
            case "event-customCategory":
                return (
                    <BrowseItemsBanner
                      image={oldEventImage?getImageWithoutVariant():getImageVariant()}
                      heading={params?.title}
                      primaryColor={primaryColor}
                      gradientClass="overlay_white_gradient"
                    />
                );
            case 'shop-now':
                return (
                    <>
                    <BrowseItemsBanner 
                       image={params?.image}
                       heading={params?.heading}
                       about={params?.about}
                       primaryColor={mobileStyle.color[1]}
                       desktopImageStyle={{backgroundPosition:"top right"}}
                       mobileImageStyle={{height:'340px', backgroundPosition:'center right'}}
                    //    gradientClass="shop_now_white_gradient"
                       aboutStyle={{fontSize:"22px",width:"300px"}}
                    />
                    </>
                );
                // case 'shopNow-marketplace':
                // return (
                //  <>
                // <BrowseItemsBanner
                //   image={browseBrands}
                //   heading={`Marketplace Items`}
                // //   title={"Items Up For Auction"}
                //   primaryColor={primaryColor}
                // //   about="50-90% of all proceeds from purchases go directly to your charity. "
                //   gradientClass="overlay_white_gradient"
                // />
                // </>
                // )
                case 'browse-cause':
                return (
                 <>
                <BrowseItemsBanner
                  image={browseCause}
                  heading={`Causes`}
                //   title={"Items Up For Auction"}
                //   about="50-90% of all proceeds from purchases go directly to your charity. "
                primaryColor={primaryColor}
                // gradientClass="overlay_white_gradient"
                mobileHeadingStyle={{marginTop:"40px"}}
                />
                </>
                )
                case 'browse-brands':
                return (
                 <>
                <BrowseItemsBanner
                  image={browseBrands}
                  heading={`Brands`}
                //   title={"Items Up For Auction"}
                //   about="50-90% of all proceeds from purchases go directly to your charity. "
                primaryColor={primaryColor}
                // gradientClass="overlay_white_gradient"
                mobileHeadingStyle={{marginTop:"40px"}}
                />
                </>
                )
                case 'browse-store':
                return (
                 <>
                <BrowseItemsBanner
                  image={browseMerch}
                  heading={`Stores`}
                //   title={"Items Up For Auction"}
                //   about="50-90% of all proceeds from purchases go directly to your charity. "
                primaryColor={primaryColor}
                // gradientClass="overlay_white_gradient"
                mobileHeadingStyle={{marginTop:"40px"}}
                />
                </>
                )
        default:
            return null;

           

    }
};

export default BrowseItemUpperHalf
