import { useNavigate } from "react-router-dom";
import { calculateTimeLeft } from "../../../../utils/util-functions";
import TimeLeft from "../../TimeLeft"; 
import "./AuctionCard.css";
import NewCardLayout from "../NewCardLayout/NewCardLayout";
import DesktopCardLayout from "../DesktopCardLayout/DesktopCardLayout";
import { Desktop, Mobile } from "../../responsive/Responsive";

const AuctionCard = ({auction, primaryBackgroundColor = "white", primaryColor = "black"}) => {
  const currentEvent = new Date().toISOString() >= auction.start_date ? true : false;
  let timeLeft, message;
  if(currentEvent){
    message = "Ends In :"
    timeLeft = calculateTimeLeft(auction.end_date)
  }else{
    message = "Starts In :"
    timeLeft = calculateTimeLeft(auction.start_date)
  }
  const navigate = useNavigate()
  const handleCardClick = () => {
    let urlPart = 'event'
    if(auction?.is_store == '1'){
       urlPart = 'store'
    }
    else if(auction?.market_place == '1') {
      urlPart = 'marketplace'
    }
    else {
      urlPart = 'event'
    }
     navigate(`/${urlPart}/${auction?.custom_url ? auction?.custom_url : auction?.id}`)
  }
  return (
    // <div className="auction-card"  onClick={() => navigate(`/browse-items`, {state: {auction: auction, image: auction.image2, from: 'event'}})} >
    //   <div className="banner">
    //   <img className="img" src={auction.image1} alt="auction" />
    //   </div>
      
    //   <div className="auction-card-body">
    //     <header>{auction.title}</header>
      //   <div className="auction-card-details">
      //     <div className="auction-card-details-col">
      //       <p style={{fontSize:"14px"}}>
      //         {auction.city},{auction.state}
      //       </p>
      //       <p style={{fontWeight: 600}}>
      //         {/* {itemQuantity} item{itemQuantity > 1 ? "s" : ""} */}
      //       </p>
      //     </div>
      //     {/* <div className="auction-card-details-col col-2">{timeLeft}</div> */}
         
      //   </div>
      //   <div className="time_left"><span style={{fontSize:"14px"}}>{message}{" "}</span><TimeLeft timeLeft={timeLeft}/></div>
      // </div>
    // </div>

        <>
        <Mobile>
          <NewCardLayout imageContainerStyle={{width:"147px", height:"147px"}} image={auction.image1+'_small'} onCardClick={handleCardClick}>
              <div className="cart-card-content">
                  <h1 >{auction.title}</h1>
              <div className="cart-card-qty_price">
               <div className="auction-card-details">
                  <div className="auction-card-details-col">
                      <p style={{fontSize:"14px", marginBottom:'3px'}}>
                        {auction.city},{auction.state}
                      </p>
                       {/* <p>
                          {itemQuantity} item{itemQuantity > 1 ? "s" : ""}
                       </p> */}
                       <p >
                             <span style={{fontSize:"14px"}}>{message}{" "}</span><TimeLeft timeLeft={timeLeft}/>     
                       </p>
                    </div>
                     {/* <div className="auction-card-details-col col-2">{timeLeft}</div> */}  
                     </div>
                     </div>
                   </div>
          </NewCardLayout>
          </Mobile>
          <Desktop>
          <DesktopCardLayout onCardClick={handleCardClick} image={auction.image1+'_small'}>
          <div className="cart-card-content">
                  <h1 >{auction.title}</h1>
              <div className="cart-card-qty_price">
               <div className="auction-card-details">
                  <div className="auction-card-details-col">
                      <p style={{fontSize:"14px", marginBottom:'3px'}}>
                        {auction.city},{auction.state}
                      </p>
                       <p >
                             <span style={{fontSize:"14px"}}>{message}{" "}</span><TimeLeft timeLeft={timeLeft}/>     
                       </p>
                    </div>
                     </div>
                     </div>
                   </div>
          </DesktopCardLayout>
          </Desktop>
    </>
  );
};

export default AuctionCard;
