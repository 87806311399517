import React, { useState } from 'react'
import { Helmet } from "react-helmet-async"
import './Faq.css';
import HeaderV2 from '../molecules/Header/HeaderV2';
import upArrow from "../../../images/upArrow.svg";
import downArrow from "../../../images/downArrow.svg"
import { mobileStyle } from '../Home';
import NewFooter from '../molecules/NewFooter/NewFooter';
import { config } from '../../../config/config';
import HeaderV3 from '../molecules/Header/HeaderV3/HeaderV3';
const { REACT_APP_URL } = config

function FaqBlock({ que, ans }) {
    return (
        <div className="faq_block">
            <h4>{que}</h4>
            {ans}
        </div>
    )
}
const Faq = () => {
    const [q1, setq1] = useState(true);
    const [q2, setq2] = useState(false);
    const [q3, setq3] = useState(false);
    const [q4, setq4] = useState(false);
    const [q5, setq5] = useState(false);
    const [q6, setq6] = useState(false);
    const [q7, setq7] = useState(false);
    const [q8, setq8] = useState(false);
    const bgColor = "var(--pure-white)"
    return (
        <div style={{ backgroundColor: bgColor }} className='faq_wraper'>
            <Helmet>
                <title>Frequently Asked Question - Boundless Giving</title>
                <meta
                    name="description"
                    content="Get answers to your questions about bidding, shipping, returns, donations, and more on our FAQ page to support charitable causes effortlessly. Read it now!"
                />
                <link rel="canonical" href={`${REACT_APP_URL}/faq`} />
            </Helmet>
            {/* <Desktop>
            <Navbar />
            <SubNavbar />
            <Header title="FAQ's" />
        <div className="faq_main">
            
                <img src={DFaq} alt="faq" />
 
   
                <AccordionMui 
                titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                title={'How Does Bidding Work?'} 
                description={
                <ol>
                    <li>To bid in this auction, find your desired lot and choose “Place Bid”.</li>
                    <li>Select your bid amount. You may either place a bid at the “Next Bid'' option or choose a Maximum Bid amount that the Boundless Giving system will execute on your behalf when competing bids are placed.</li>
                    <li>You will then be asked to enter your payment information. Your card will not be charged unless you win the item at the end of the auction.</li>
                    <li>You will receive a confirmation of your bid onscreen and via email.</li>
                </ol>
                }
                />
                <AccordionMui 
                titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                title={'Returns and Refunds'} 
                description={
                <>
                    <div className="faq_mini_wraper">
                        <h5 style={{fontWeight: 600}}>Auction Items</h5>
                        <p>All sales on auction items are final and non-refundable. If for any reason you decide you do not want an item you've won, please contact help@boundlessgiving.com within two days upon the close of the auction and we will attempt to give it to the next highest bidder.</p>
                    </div>
                    <div style={{marginTop: "20px"}} className="faq_mini_wraper">
                        <h5 style={{fontWeight: 600}}>Pop-Up Store / Buy Now Items</h5>
                        <p>Given that the vast majority of the money from your purchase goes to a good cause, it’s important that you’re completely satisfied with your purchase. If you are dissatisfied with a purchase that you bought directly from our store for any reason and want a refund, please contact us at help@boundlessgiving.com and we will take care of it. In exchange, we ask that you donate the refunded item to a cause you care about.</p>
                        <p>For "Buy Now" items, we offer a full money back guarantee for thirty days after delivery for up to two purchased items. You don't have to send the item back, but please consider donating it to charity if you're unsatisfied for any reason.</p>
                        <p>Please note that all refund requests must be made within 45 days of the close of the event.</p>
                    </div>
                </>
                }
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='Shipping and Delivery of Your Purchase or Auction Item.'
                    description={<p>Please note that items purchased in one of the Boundless Giving events will not ship until the conclusion of the event itself. You will receive an email letting you know that your item has shipped and you will be able to track its progress to delivery from that point forward. Any shipping or delivery inquiries should be directed to help@boundlessgiving.com.</p>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='How Do I Contact Customer Service?'
                    description={<p>If you need help with any part of our platform, please contact us at help@boundlessgiving.com and someone will be in touch.</p>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='Can I donate to a cause directly?'
                    description={<p>As of now, Boundless Giving is not set up to receive direct donations on behalf of any organizations. However, if you'd like to donate to a cause you support, please visit the cause's web page or contact us at help@boundlessgiving.com and we will point you in the right direction.</p>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='When will I receive my item?'
                    description={<>
                        <p>If your item is a digital item or experience, you will receive it within 48 hours of the conclusion of the event.</p>
                        <p>If your item is a physical good, it will ship at the conclusion of the event. Once your item has shipped, you will receive an email that will allow you to track your item's delivery.</p>
                    </>}
                />
                <AccordionMui
                    titleStyle={{fontWeight: 700, color: "#1a385c", fontSize: "17px"}}
                    title='How much of my purchase goes to charity?'
                    description={<p>A minimum of 80% of your purchase price (minus minimal labor fulfillment costs) goes directly to the causes you've supported. Boundless Giving is determined to make this platform the most effective tool for fundraising it can possibly be, with as much of the sales as possible going to causes you want to support.</p>}
                />
            
        </div>

            <Footer />
        </Desktop> */}
            {/* <Mobile> */}
            {/* <HeaderV2 isSubHeader={true} subHeaderTitle='FAQ' primaryBackgroundColor='#F0E8E3' primaryColor='#30313A' logoType='black' containerStyle={{position:'fixed'}} /> */}
            <HeaderV3 isSubHeader subHeaderTitle='FAQ' primaryBackgroundColor={bgColor} primaryColor='#30313A' position='absolute' />

            <div className='faq-container margin_class'>
                <h1 className='fq-header'>FAQ</h1>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>How does bidding work?</p>
                        <div className='arrow-container'>
                            <img src={(q1) ? upArrow : downArrow} alt="" onClick={() => setq1(!q1)} />
                        </div>
                    </div>
                    {
                        q1 &&
                        <div className='ans-container'>
                            <ol>
                                <li className='faq-common-style para_fontsize'>To bid in this auction, find your desired lot and choose “Place Bid”.</li>
                                <li className='faq-common-style para_fontsize'>Select your bid amount. You may either place a bid at the “Next Bid'' option or choose a Maximum Bid amount that the Boundless Giving system will execute on your behalf when competing bids are placed.</li>
                                <li className='faq-common-style para_fontsize'>You will then be asked to enter your payment information. Your card will not be charged unless you win the item at the end of the auction.</li>
                                <li className='faq-common-style para_fontsize'>You will receive a confirmation of your bid onscreen and via email.</li>
                            </ol>
                        </div>
                    }
                </div>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>Returns and Refunds</p>
                        <div className='arrow-container'>
                            <img src={(q2) ? upArrow : downArrow} alt="" onClick={() => setq2(!q2)} />
                        </div>
                    </div>
                    {
                        q2 &&
                        <div className='faq-common-style para_fontsize faq_mini_wraper'>
                            <p >Given that the vast majority of the money from your purchase goes to a good cause, it's
                                important that you're completely satisfied. If you are dissatisfied with a purchase bought directly
                                from one of our auctions or marketplace for any reason and want a refund, please contact us at
                                help@boundlessgiving.com</p>
                            <p>
                                Except for purchases noted below, Boundless Giving does not accept returns for items listed for
                                less than $300. In most cases, we will offer a full refund, and we ask that you donate the
                                refunded item to a worthy cause. Customers requesting refunds on more than three purchased
                                items within six months will no longer be welcome on the service and will have their account
                                privileges revoked.
                            </p>
                            <p>
                                To request a refund, contact help@boundlessgiving.com within 14 days of the original shipment
                                date.
                            </p>
                            <p>
                                For items purchased for over $300, Boundless Giving will accept returns. Items must be shipped
                                within 14 days of the original purchase date and received no later than 21 days after the return
                                request has been granted. All returns are subject to a $9.95 shipping and restocking fee.
                            </p>
                            <p>
                                <spna className='return_and_refund_font_bold'>The following items are final sale: handbags, swimwear, luggage, beauty products, and
                                    items sold at 50% or more off the original list price.</spna> Returns may be accepted if, after review
                                by a Boundless Giving customer service representative, the item is found to have been
                                incorrectly described.
                            </p>
                            <p>
                                To send a return, follow these steps
                            </p>
                            <ul>
                                <li>Ensure your returned items meet the conditions of our Return Policy.</li>
                                <li>Pack your return in a sturdy box with adequate padding to protect the item(s).</li>
                                <li>Enclose your name, email address, and signature with your return.</li>
                            </ul>
                            <p>
                                If you have any questions or need further assistance, please reach out to us at
                               <a href='mailto:help@boundlessgiving.com' rel="noreferrer" target='_blank'> help@boundlessgiving.com.</a>
                            </p>

                        </div>
                    }
                </div>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>Shipping and Delivery</p>
                        <div className='arrow-container'>
                            <img src={(q3) ? upArrow : downArrow} alt="" onClick={() => setq3(!q3)} />
                        </div>
                    </div>
                    {
                        q3 &&
                        <div className='ans-container'>
                            <p className='faq-common-style para_fontsize'>
                                All physical goods will be shipped via USPS Flat Rate shipping unless otherwise noted.
                                You will receive an email letting you know that your item has shipped and you will be able to track its progress to delivery from that point forward. Any shipping or delivery inquiries should be directed to help@boundlessgiving.com.</p>
                        </div>
                    }
                </div>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>How do I contact customer service?</p>
                        <div className='arrow-container'>
                            <img src={(q4) ? upArrow : downArrow} alt="" onClick={() => setq4(!q4)} />
                        </div>
                    </div>
                    {
                        q4 &&
                        <div className='ans-container'>
                            <p className='faq-common-style para_fontsize'>If you need help with any part of our platform, please contact us at help@boundlessgiving.com and someone will be in touch.</p>
                        </div>
                    }
                </div>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>Can I donate to a cause directly?</p>
                        <div className='arrow-container'>
                            <img src={(q5) ? upArrow : downArrow} alt="" onClick={() => setq5(!q5)} />
                        </div>
                    </div>
                    {
                        q5 &&
                        <div className='ans-container'>
                            <p className='faq-common-style para_fontsize'>As of now, Boundless Giving is not set up to receive direct donations on behalf of any organizations. However, if you'd like to donate to a cause you support, please visit the cause's web page or contact us at help@boundlessgiving.com and we will point you in the right direction.</p>
                        </div>
                    }
                </div>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>When will I receive my item?</p>
                        <div className='arrow-container'>
                            <img src={(q6) ? upArrow : downArrow} alt="" onClick={() => setq6(!q6)} />
                        </div>
                    </div>
                    {
                        q6 &&
                        <div className='ans-container'>
                            <p className='faq-common-style para_fontsize'>If your item is a digital item or experience won or purchased in a Boundless event, you will receive it within 48 hours of the conclusion of the event.</p>
                            <p className='faq-common-style para_fontsize'>If your item is a physical good won or purchased during a Boundless auction or flash sale, it will ship at the conclusion of the event. Once your item has shipped, you will receive an email that will allow you to track your item's delivery.</p>
                            <p className='faq-common-style para_fontsize'>If you purchased an item in the Boundless Marketplace, it will ship via USPS 5-8 Day delivery.</p>
                        </div>
                    }
                </div>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>How much of my purchase goes to charity?</p>
                        <div className='arrow-container'>
                            <img src={(q7) ? upArrow : downArrow} alt="" onClick={() => setq7(!q7)} />
                        </div>
                    </div>
                    {
                        q7 &&
                        <div className='ans-container'>
                            {/* <p className='faq-common-style para_fontsize'>A minimum of 80% of your purchase price (minus minimal labor fulfillment costs) goes directly to the causes you've supported. Boundless Giving is determined to make this platform the most effective tool for fundraising it can possibly be, with as much of the sales as possible going to causes you want to support.</p> */}
                            <p className='faq-common-style para_fontsize'>50-80% of your purchase price goes to charity.
                                Percentage is determined by agreed upon donation from donor brands and varies from brand to brand.
                                All marketplace items display exactly how much of an item's purchase goes to particular cause.
                                However, in no case will the donation amount be less than 50% of any given item.</p>
                        </div>
                    }
                </div>
                <div className='q-s-container' style={{ marginBottom: "25px", paddingBottom: "20px", borderBottom: "1px solid #30313A" }}>
                    <div className='q-container'>
                        <p>My order hasn't arrived. What do I do?</p>
                        <div className='arrow-container'>
                            <img src={(q8) ? upArrow : downArrow} alt="" onClick={() => setq8(!q8)} />
                        </div>
                    </div>
                    {
                        q8 &&
                        <div className='ans-container'>
                            <p className='faq-common-style para_fontsize'>
                                We're sorry to hear that you're having a problem with your order.
                                Please contact us at help@boundlessgiving.com and we'll work to figure out what happend and fix the situation.
                            </p>
                        </div>
                    }
                </div>
            </div>
            {/* </Mobile> */}
            <NewFooter primaryBackgroundColor={bgColor} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />
        </div>
    )
}

export default Faq;