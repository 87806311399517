import React, { useContext } from 'react';
// import { Desktop, Mobile } from '../../../responsive/Responsive';
// import { useNavigate } from 'react-router-dom';
import AuctionContext from '../../../../../store/auction-context';
// import BrowseByCards from '../../../../desktop/components/BrowseByCards/BrowseByCards';

/// for styles of the card section in desktop we use the same style in browse by cause
// import commonClasses from '../BrowsByCause/BrowsByCause.module.css'
import SectionContainer from '../../../molecules/SectionContainer/SectionContainer';
import CarouselSectionContainer from '../../../molecules/CarouselSectionContainer/CarouselSectionContainer';

// import classes from './BrowsByCategory.module.css';
import CategoryCardV2 from '../../../molecules/CategoryCardV2/CategoryCardV2';
import { SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';

function BrowsByCategory({ title, containerStyle, mobileStyle = { backgroundColor: [], color: [] } }) {
    // const navigate = useNavigate();
    const auctionCxt = useContext(AuctionContext);
    // const mobileBackground = mobileStyle.backgroundColor[2]
    // const mobileCardBackground = mobileStyle.backgroundColor[0]
    // const mobileTitleColor = mobileStyle.color[1]
    // const mobileColor = mobileStyle.color[2]
    const isMobile = useMediaQuery({
        maxWidth: 569
    })
    let grid={}
    if(isMobile) {
        grid = {rows: 2, fill: 'row'}
    }

    // return (
    //     <>
    //         <Desktop>
    //             {/* <section style={{ ...containerStyle, padding: "10px 0" }}>
    //                 <div className={`${classes.dsection} ${classes.dcurrent_events} `}>
    //                     <h3>{title}</h3>
    //                     <div className={classes.dbrowse_cards}>
    //                         {auctionCxt.auctionCategoryList?.map((i, idx) => (
    //                             <BrowseByCards onClick={() => {
    //                                 navigate("/browse-items", {
    //                                     state: {
    //                                         auction_category_id: i?.id,
    //                                         title: i?.name,
    //                                         from: 'auctionCategory',
    //                                         image: require(`../../../../../images/categoryIcons/1200x480/${i.image}`)
    //                                     },
    //                                 })
    //                             }} key={i?.id} name={i?.name} image={i?.image} id={i?.id} imageFolder="categoryIcons" />
    //                         ))}
    //                     </div>
    //                 </div>
    //             </section> */}

    //             <section className={commonClasses.desktop_section} style={{backgroundColor:mobileBackground}} >
    //                 <div className={`margin_class ${commonClasses.desktop_container}`} >
    //                     {/* <h3 style={{color:mobileTitleColor}} className={commonClasses.desktop_section_heading} >Browse</h3> */}
    //                     <div className={commonClasses.desktop_content} >
    //                         <h5 className={commonClasses.desktop_heading}>{title}</h5>
    //                         <div className={commonClasses.desktop_card_wrapper} >
    //                         {auctionCxt.auctionCategoryList?.map((i, idx) => (
    //                             <BrowseByCards onClick={() => {
    //                                 // navigate("/browse-items", {
    //                                 //     state: {
    //                                 //         auction_category_id: i?.id,
    //                                 //         title: i?.name,
    //                                 //         from: 'auctionCategory',
    //                                 //         image: require(`../../../../../images/categoryIcons/1200x480/${i.image}`)
    //                                 //     },
    //                                 // })
    //                                 navigate(`/${i?.id}/browse-items?item-type=browse-category`)
    //                             }} key={i?.id} name={i?.name} image={i?.image} id={i?.id} imageFolder="categoryIcons"/>
    //                         ))}
    //                         </div>
    //                     </div>
    //                 </div>
    //             </section>


    //         </Desktop>
    //         <Mobile>
    //             <div style={{paddingTop:"15px", backgroundColor: mobileBackground, borderBlock:`5px solid ${mobileBackground}` }} className="categories_sec">
    //                 <div className="page_heading" style={{ marginBottom: "5px" }}>
    //                     <h5 className={commonClasses.mobile_heading} style={{ color: mobileTitleColor }} >{title}</h5>
    //                 </div>
    //                 <section className="scrollCategory">
    //                     <div className="categories_list">
    //                         <div className="category_container" style={{marginBottom:"15px"}}>
    //                             {auctionCxt.auctionCategoryList?.map((i, idx) => (
    //                                 // <div
    //                                 //     style={{ margin: "0px 13px 0 0", cursor: "pointer" }}
    //                                 //     key={idx}
    //                                 //     onClick={() =>
    //                                 //         navigate("/browse-items", {
    //                                 //             state: {
    //                                 //                 auction_category_id: i?.id,
    //                                 //                 title: i?.name,
    //                                 //                 from: 'auctionCategory',
    //                                 //                 image: require(`../../../../../images/categoryIcons/1200x480/${i.image}`)
    //                                 //             },
    //                                 //         })
    //                                 //     }
    //                                 // >
    //                                 //     <img
    //                                 //         src={require(`../../../../../images/categoryIcons/100x100/${i.image}`)}
    //                                 //         alt=""
    //                                 //         style={{border:`1px solid ${mobileColor}`, borderRadius: "20px" }}
    //                                 //     />
    //                                 //     <p style={{color:mobileColor}} className={commonClasses.card_title}> {i.name} </p>
    //                                 // </div>
    //                                 <div
    //                                     key={idx}
    //                                     onClick={() =>
    //                                         // navigate("/browse-items", {
    //                                         //     state: {
    //                                         //         auction_category_id: i?.id,
    //                                         //         title: i?.name,
    //                                         //         from: 'auctionCategory',
    //                                         //         image: require(`../../../../../images/categoryIcons/1200x480/${i.image}`)
    //                                         //     },
    //                                         // })
    //                                         navigate(`/${i?.id}/browse-items?item-type=browse-category`)
    //                                     }
    //                                     style={{ padding: '0px 32px', borderRadius: "25px", height: 'fit-content', minWidth: 'fit-content', backgroundColor: mobileCardBackground }}>
    //                                     <p style={{ color: mobileColor }} className={commonClasses.card_title}>{i.name}</p>
    //                                 </div>
    //                             ))}
    //                         </div>
    //                     </div>
    //                 </section>
    //             </div>
    //         </Mobile>
    //     </>
    // )

    // New design for categories

    return (
        <SectionContainer sectionBg='pure-white' >
            <CarouselSectionContainer
                titleStyle={{width:'auto'}} 
                actionButtonText=''
                //   actionButtonHref='/browse-brands'
                title={title}
                breakPoints={{
                    280: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    570: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    840: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                        slidesPerGroup:2
                    },
                    1100: {
                        slidesPerView: 5,
                        spaceBetween: 40,
                        slidesPerGroup:3
                    },
                    1400: {
                        slidesPerView: 6,
                        spaceBetween: 40,
                        slidesPerGroup:3
                    }
                }}
                grid={grid}
            >
                {
                    auctionCxt.auctionCategoryList?.map((item) => {
                        return (
                            <SwiperSlide key={item.name}>
                                <CategoryCardV2 category={item} key={item.name} />
                            </SwiperSlide>
                        )
                    })
                }

            </CarouselSectionContainer>
        </SectionContainer>
    )
}

export default BrowsByCategory