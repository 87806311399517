let token = localStorage.getItem("token");
export const headerKey = {
  'Content-Type': 'application/json',
  'authorization': 'Bearer ' + token,
};

// send as form data
export const headerKey_ = {
  'Content-Type': 'multipart/form-data',
  'authorization': 'Bearer ' + token,
}
