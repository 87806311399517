import React, { useState, useEffect } from 'react';
import authService from '../services/auth-services';
import userServices from '../services/user-services';
import logger from '../utils/logger';

const AuthContext = React.createContext({
  isLoggedIn: false,
  isLoading: false,
  userId: "",
  userInfo: "",
  cardId: "",
  cusId: "",
  isSavedShip: false,
  onLogout: () => {},
  onLogin: (email, password) => {},
  setIsSavedShip: () => {}
});

export const AuthContextProvider = (props) => {
  const [userId, setUserId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [cusId, setCusId] = useState(null);
  const [isSavedShip, setIsSavedShip] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null)

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem('username');
    const storedUserToken = localStorage.getItem('token');
    const userVerification = async () => {
        try {
            const response = await authService.verifyUser()
            if(response.data.username === storedUserLoggedInInformation) {
              setUserId(response?.data?.id);
              setSubscriptionStatus(response?.data?.subscription_status)
              const shipping = await userServices.getUserShippingAddress(response?.data?.id)
              if(shipping.data) setIsSavedShip(true)
              else setIsSavedShip(false)
              setUserInfo(response?.data);
              setCardId(response?.cardData?.card_id);
              setCusId(response?.cardData?.cus_id);
              setIsLoggedIn(true);
              setIsLoading(false);
            } else {
              setIsLoggedIn(false);
              localStorage.clear();
              setIsLoading(false);
            }
          }
        catch (err) {
            logger('./store/auth-context.js','userVerification(): catch',err);
            setIsLoggedIn(false);
            localStorage.clear();
            setIsLoading(false);
          }
    }
    if (storedUserLoggedInInformation && storedUserToken) {
      setIsLoading(true);
      userVerification();
    } else {
      setIsLoading(false);
      setIsLoggedIn(false);
      // localStorage.clear();
    }
    // if (storedUserLoggedInInformation === '1') {
    //   setIsLoggedIn(true);
    // }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  const loginHandler = () => {
    localStorage.setItem('isLoggedIn', '1');
    setIsLoggedIn(true);
  };
  const updateSubscriptionStatus = (status) => {
    setSubscriptionStatus(status)
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isLoading: isLoading,
        userId: userId,
        userInfo: userInfo,
        cardId: cardId,
        cusId: cusId,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        setCardId: setCardId,
        setCusId: setCusId,
        isSavedShip: isSavedShip,
        setIsSavedShip: setIsSavedShip,
        subscriptionStatus:subscriptionStatus,
        updateSubscriptionStatus,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;