import React, { useContext, useEffect, useState } from 'react';
import { mobileStyle } from '../../Home';
import { useNavigate } from 'react-router-dom';
import DesktopCardLayout from '../../atoms/DesktopCardLayout/DesktopCardLayout';
import logger from '../../../../utils/logger';
import classes from '../BrowseCauses/BrowseCause.module.css'
import donorServices from '../../../../services/donor-services';
import BrowsePagesLayout from '../../molecules/BrowsePagesLayout/BrowsePagesLayout';
import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import { changePageNumber, resetItems } from '../../../../redux/itemsReducer';
import { useDispatch } from 'react-redux';
import { FilterContext } from '../../../../store/filter-context';
import { Helmet } from 'react-helmet-async';
import { config } from '../../../../config/config';
const {REACT_APP_URL} = config


const BrowseBrands = () => {
    const [featuredDonors, setFeaturedDonors] = useState([])
    const {reset} = useContext(FilterContext)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = {
        from: 'browse-brands'
    }
    const getFeaturedDonors = async () => {
        try {
            setLoading(true);
            const data = await donorServices.featuredDonors();
            setFeaturedDonors(data?.data?.donor)
            setLoading(false);
        } catch (error) {
            logger('./pages/components/organisms/browsebrands.jsx', 'getFeaturedDonors(): catch', error);
            setLoading(false);
        }
    }
    useEffect(() => {
        getFeaturedDonors()
        reset()/// for reseting filters in filtercontext
        dispatch(changePageNumber(1)) /// for changing page number of browseItems page
        dispatch(resetItems())
    }, [])

    const handleCardClick = (donor) => {
        // navigate(`/marketplace/${donor?.auctions[0]?.custom_url ? donor?.auctions[0]?.custom_url : donor?.auctions[0]?.id}`)
        navigate(`/marketplace/${donor?.auctions[0]?.custom_url ? donor?.auctions[0]?.custom_url : donor?.auctions[0]?.id}`)
    }

    return (
        <>
            <Helmet>
                    {/* <title>Browse Event | Boundless Giving</title> */}
                    <title>Browse Brands to Donate Products - Boundless Giving</title>
                    <meta 
                      name="description" 
                      content="Explore a wide range of brands on Boundless Giving, where you can discover quality products and support your favorite brands while making a difference."
                    />
                    <link rel="canonical" href={`${REACT_APP_URL}/browse-brands`} />
            </Helmet>
            <BrowsePagesLayout params={params} loading={loading} helmetTag={'Brands'}>
                {featuredDonors && featuredDonors?.length ? <div className="dbrowse-items-list" style={{ marginBottom: "10px" }}>
                    <div className={classes.causes_grid_container}>
                        {
                            featuredDonors?.map((donor, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <DesktopCardLayout imageContainerClass={'browsePageCardImage'} alt={donor?.donor_alt} image={donor?.featured_icon} onCardClick={() => handleCardClick(donor)} mainContainerStyle={{ gap: "10px", margin: 'auto' }}>
                                            <div className="cart-card-content">
                                                <h3 style={{ textAlign: "center",fontWeight:"800" }} >{donor.org_name}</h3>
                                            </div>
                                        </DesktopCardLayout>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
                    : <div className="no-data" style={{ paddingTop: "4vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
                        Coming Soon!
                    </div>
                }
            </BrowsePagesLayout>
        </>
    )
}

export default BrowseBrands;