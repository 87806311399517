import React from 'react';

import style from './ComingSoon.module.css';
import ComingSoonImage from "../../../../images/coming_soon.png"
import { mobileStyle } from '../../Home';
import HeaderV2 from '../../molecules/Header/HeaderV2';
import { useLocation, useNavigate } from 'react-router-dom';
import Image from '../../atoms/Image/Image';
import moment from "moment-timezone";
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';
import HeaderV3 from '../../molecules/Header/HeaderV3/HeaderV3';
const primaryBackgroundColor = mobileStyle.backgroundColor[2];
const primaryColor = mobileStyle.color[1];

function ComingSoon() {
    const location = useLocation();
    // console.log(location?.state)
    const isDesktop = useMediaQuery({minWidth: 768})
    const navigate = useNavigate()
    const getEventType = () => {
        const state = location.state;
        if(state?.is_store == '1') {
            return 'Store'
        }
        else if(state?.market_place == '1') {
            return 'Marketplace'
        }
        else {
            return 'Event'
        }
    }

    useEffect(() => {
        if( !location.state || !Object.values(location.state)?.length) {
            navigate('/not-found')
            return
        }
    },[])

    return (

        <div className={style.main_container}>
            {/* <HeaderV2 logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} /> */}
            <HeaderV3 primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} position='absolute' />
            <div className={style.sub_container}>
                <div className={style.coming_soon}>
                    <div>
                        {/* <img className={style.bg_logo} src={ComingSoonImage} alt='bg-logo' /> */}
                        <Image src={isDesktop ? `${location?.state?.image2}_small` : `${location?.state?.image1}_small` }/>
                        <h3 >{location?.state?.title}</h3>
                        <h5>{`${getEventType()} ${getEventType()==='Event'? ` starts on ${moment(location?.state?.start_date).format("MMMM DD, YYYY")}`:'launching soon'}`} </h5>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ComingSoon