import React from 'react';
import style from './Section6.module.css';
import { Link } from 'react-router-dom';

function Section6({fundraise}) {
    return (
        <section className={style.section6}>
            <div>
                <h2><strong>Made for nonprofits with more heart than resources</strong></h2>
                <p>
                    We get that fundraising takes a lot of time and energy.
                    That's why we made Boundless Giving fast and easy for
                    fundraisers like you.
                </p>
                <div className={style.card}>
                    <img id='img'
                        src='https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/6419c29becb0dde9a79d194c.png'
                        alt='alt' />
                    <div id='text'>
                        <h2  style={{fontSize:"36px"}} ><strong>Break fundraising records</strong></h2>
                        <p style={{fontSize:"17.5px"}} >
                            We'll spend $300 of our own money to advertise your Boundless Giving event
                            on Meta™ social media platforms. This is a limited-time
                            offer for qualifying charities. See if you qualify by clicking below.
                        </p>
                        <div className={style.button_container}>
                            <Link to="/get-started"  rel='noopener noreferrer'><button>Apply now</button></Link>
                        </div>
                    </div>

                </div>
                <div className={style.cta}>
                    <p>
                        DISCLAIMER: "Meta" is a registered trademark of Meta Platforms,
                        Inc. Boundless Giving, Inc. is not affiliated with, endorsed by,
                        or sponsored by Meta Platforms, Inc. in any way. All other trademarks
                        and logos used on this website are the property of their respective owners.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Section6