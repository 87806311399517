import { useEffect, useState } from "react";
import SectionContainer from "../../../../../molecules/SectionContainer/SectionContainer"
import classes from './FeaturedEventsV2.module.css';
import auctionServices from "../../../../../../../services/auction-services";
import EventCardV2 from "../../../../../molecules/EventCardV2/EventCardV2";
import logger from '../../../../../../../utils/logger'
import { Desktop, Mobile } from "../../../../../responsive/Responsive";
import { Link } from "react-router-dom";
import NewButton from "../../../../../atoms/NewButton/NewButton";

const FeaturedEventsV2 = ({title}) => {
    const [featuredEvent, setFeaturedEvent] = useState([])
    useEffect(() => {
        getFeaturedAuctions()
      }, [])
    
      const getFeaturedAuctions = async () => {
        try {
          await auctionServices.getFeaturedAuctionsList('event', 1, 2)
            .then((response) => {
              setFeaturedEvent(response.data)
            })
        } catch (error) {
          logger('FeaturedEventsV2.jsx', 'getFeaturedAuctions(): catch2', error);
        }
      }

      if(!featuredEvent?.length) {
        return;
      }
    
    return (
        <SectionContainer sectionBg="pure-white" >
            <div className={classes.boundlessEventContainer} >
                <div className={classes.eventsLeft} >
                    <div className={classes.topPart}>
                        <h2 className={`newTitleClass ${classes.eventsTitle}`} >{title}</h2>
                        <p className={classes.para} >Be part of events that change lives. Your involvement counts.</p>
                    </div>
                    <Desktop>
                        <Link to={'/auction'}><NewButton className="carouselCardButton" text={'VIEW ALL EVENTS'} /></Link>
                    </Desktop>
                </div>
                <div className={classes.eventsRight} >
                    {
                        featuredEvent.map((item) => {
                            return (
                                <EventCardV2 key={item?.id} event={item}/>
                            )
                        })
                    }
                </div>
                <Mobile>
                    <Link to={'/auction'}><NewButton className="carouselCardButton" text={'VIEW ALL EVENTS'} /></Link>
                </Mobile>
            </div>
        </SectionContainer>
    )
}

export default FeaturedEventsV2;