import { A11y, Navigation, Pagination, Grid } from "swiper/modules";
import { Swiper } from "swiper/react";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';

const SwiperComponent = ({children, slidesPerView="auto", breakpoints={}, grid={}, spaceBetween=10}) => {
    const modules = [Navigation, Pagination, A11y]
    if(Object.keys(grid)?.length) {
        modules.push(Grid)
    }
    return (
        <Swiper
            slidesPerView={slidesPerView}
            grid={grid}
            spaceBetween={spaceBetween}
            pagination={{
                el: '#pagination-el',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"></span>';
                },
                // dynamicBullets: true,
                // dynamicMainBullets: 5
            }}
            navigation={{ nextEl: "#swiper-forward", prevEl: "#swiper-back" }}
            modules={modules}
            breakpoints={breakpoints}
        >
            {children}
        </Swiper>
    )
}

export default SwiperComponent;