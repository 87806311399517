import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import eye_open from "../../images/eye_open.png";
import Loghead from "../../images/shortBlackLogo.svg";

import { validEmail } from "../../utils/reg-ex.js";
import authService from "../../services/auth-services";
import logger from "../../utils/logger";
import { Helmet } from "react-helmet-async";
import { Desktop, Mobile } from "../components/responsive/Responsive";
import classes from "../desktop/styles/login.module.css";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CircularProgress } from "@mui/material";
import "./login.css";
import newWhiteLogo from "../../images/newHomeImages/whiteLogo.svg"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function Login() {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showPwd, setShowPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation()
  let navigate = useNavigate();

  // handle email
  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (validEmail.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };

  //handle password
  const handlePwd = (e) => {
    setPassword(e.target.value);
    if (!e.target.value) {
      setPasswordErr(true);
    } else {
      setPasswordErr(false);
    }
  };

  // Submit details
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailErr && password) {
      try {
        setIsLoading(true)
        await authService.login(email, password).then(
          (response) => {
            //==code with role checking
            // if (response?.data?.role !== "Admin") {
            //   localStorage.setItem("token", response.token);
            //   localStorage.setItem("username", email);
            //   // navigate("/");
            //   setErrorMsg(null);
            //   if(state && state.redirect) {
            //     window.location.replace(state.redirect)
            //   } else {
            //     window.location.reload();
            //   }
            // } else {
            //   setErrorMsg({
            //     type: "error",
            //     body: "Invalid credentials!",
            //   });
            // }
            // setIsLoading(false)
            

            //==code without role checking
            if (response?.data) {
              localStorage.setItem("token", response.token);
              localStorage.setItem("username", email);
              // navigate("/");
              setErrorMsg(null);
              if (state && state?.redirect) {
                window.location.replace(state.redirect)
              } else {
                window.location.reload();
              }
            } else {
              setErrorMsg({
                type: "error",
                body: "Invalid credentials!",
              });
            }
            setIsLoading(false)
          },
          (error) => {
            logger('./pages/layouts/Login.jsx', 'handleSubmit(): catch', error);
            setErrorMsg({
              type: "error",
              body: error.response.data.message || "Login failed!",
            });
            setIsLoading(false)
          }
        );
      } catch (error) {
        console.error(error);
        setErrorMsg({ type: "error", body: "Login failed!" });
        setIsLoading(false)
      }
    } else {
      setEmailErr(true);
      setPasswordErr(true);
    }
  };

  const handleGuestUser = () => {
    localStorage.setItem("guest_user_visited", true);
    navigate("/");
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Login | Boundless Giving</title>
      </Helmet>
      {/* <Desktop >
        <div className={classes.dlog_in_wrapper} style={{ height: '100%' }}>
          <div className={classes.dlogin_sec}>
            <div className={classes.dlogin_sec_left}>
              <img src={loginpic} alt="" />
            </div>
            <div className={classes.dlogin_sec_right}>
              <div style={{marginBottom:'-15px'}}><img src={Loghead} alt="" width="100px"  /></div>
              <h4 style={{ color: "gray",marginTop:"15px" }}>Welcome Back !</h4>
              <p style={{ textAlign: "center", fontSize: '0.8rem' }}>Log in and start browsing Boundless events.</p>
              {errorMsg && (
                <p
                  className={
                    errorMsg?.type === "error"
                      ? `${classes.err_msg}`
                      : `${classes.success_msg}`
                  }
                >
                  {errorMsg?.body}
                </p>
              )}
              <div className={classes.dform_container}>
                <form>
                  <div className={classes.form_controll}>
                    <label>Email Address</label>
                    <input type="email" value={email} onChange={handleEmail} />
                  </div>
                  <div className={classes.form_controll}>
                    <label>Password</label>
                    <div className={classes.form_controll_input}>
                      <input type={showPwd ? "text" : "password"} onChange={handlePwd} />
                      {
                        showPwd ? <VisibilityOffIcon className={classes.eye} onClick={() => { setShowPwd(!showPwd) }} /> :
                          <RemoveRedEyeIcon className={classes.eye} onClick={() => { setShowPwd(!showPwd) }} />
                      }
                    </div>
                    <p style={{ textAlign: "right", paddingRight: '20%', fontSize: '0.8rem' }}>
                      <Link to="/reset-pwd">Forgot password?</Link>
                    </p>
                  </div>
                  <button
                    className={
                      email !== "" &&
                        email !== null &&
                        password !== "" &&
                        password !== null &&
                        !emailErr &&
                        !passwordErr
                        ? `${isLoading ? classes.login_loading : classes.login}` : `${classes.login_not_allowed}`
                    }
                    type="button"
                    onClick={handleSubmit}
                  >
                    {isLoading ? <CircularProgress sx={{ color: 'white' }} size={29} /> : "Log In"}

                  </button>

                </form>

              </div>

              <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "20px" }}>
                <h6>
                  Don't have an account?
                  <Link to="/register" state={{ redirect: state && state.redirect ? state.redirect : null }}
                    style={{
                      fontWeight: 600
                    }}> Signup</Link>
                </h6>
                <h6>or</h6>
                <h6>
                  <div
                    onClick={handleGuestUser}
                    style={{
                      textDecoration: "underline",
                      fontWeight: "600",
                      color: "#1a385c",
                      fontSize: "16px",
                      cursor: "pointer"
                    }}
                  >
                    Continue as guest
                  </div>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Desktop> */}
      {/* <Mobile> */}
        <div className="login-container">
          <div className="login-logo">
            <Link to='/' >
            <img src={newWhiteLogo} alt="Boundless Giving Logo" />
            </Link>
          </div>
          <div className="login-form-container">
            <h1 className="heading">Welcome back!</h1>
            <p className="sub-heading">
              Log in and start browsing Boundless events.
            </p >
            <form className="logForm">
              <section>
                <div className="form-group">
                  <div className="field_outer">
                    <input
                      type="email"
                      value={email}
                      className={
                        emailErr ? "login-form-control error-field" : "login-form-control"
                      }
                      placeholder={
                        emailErr ? "Enter your email!" : "Email Address"
                      }
                      onChange={handleEmail}
                    />
                    {/* {emailErr && <label className='error-label'>Enter a valid email id!</label>} */}
                  </div>
                </div>
                <div className="form-group">
                  <div className="field_outer">
                    <input
                      type={showPwd ? "text" : "password"}
                      value={password}
                      placeholder={
                        passwordErr ? "Enter your password!" : "Password"
                      }
                      className={
                        passwordErr
                          ? "login-form-control error-field"
                          : "login-form-control"
                      }
                      onChange={handlePwd}
                    />
                    {/* <img
                      src={eye_open}
                      alt=""
                      onClick={() => {
                        setShowPwd(!showPwd);
                      }}
                    /> */}
                     {
                        showPwd ? <span><VisibilityOffOutlinedIcon className={classes.eye} onClick={() => { setShowPwd(!showPwd) }} /></span> :
                        <span><RemoveRedEyeOutlinedIcon className={classes.eye} onClick={() => { setShowPwd(!showPwd) }} /></span>
                      }
                  </div>
                </div>
                <div className="form-group">
                  <h5>
                    <Link to="/reset-pwd" state={{ redirect: state && state?.redirect ? state?.redirect : null }}>Forgot password?</Link>
                  </h5>
                </div>
               

                {errorMsg ? (
                  <p
                    className={
                      errorMsg?.type === "error"
                        ? "error_response_msg"
                        : "success_response_msg"
                    }
                    style={{width:'100%'}}
                  >
                    {errorMsg?.body}
                  </p>
                ):<p></p>}
              </section>
             
            </form>
          </div>
          <button
                  className={
                    email !== "" &&
                      email !== null &&
                      password !== "" &&
                      password !== null &&
                      !emailErr &&
                      !passwordErr
                      ? (isLoading ? "login-blockBtn-loading " : "login-blockBtn")
                      : "login-blockBtn not-allowed-btn"
                  }
                  type="button"
                  onClick={handleSubmit}
                >

                  {isLoading ? <CircularProgress sx={{ color: 'white' }} size={29} /> : "LOG IN"}

                </button>
                <div className="stickDown" style={{color:"#F0E8E3",marginBottom:"0"}}>
                <h6 style={{fontSize:"16px"}}>
                  Don't have an account? <Link to="/register" style={{color:"#F0E8E3",fontSize:"16px"}} state={{ redirect: state && state?.redirect ? state?.redirect : null }}>Signup</Link>
                </h6>
                <h6 style={{fontSize:"16px"}}>Or</h6>
                <h4>
                  <div  
                  >
                   <span style={{
                      textDecoration: "underline",
                      fontWeight: "400",
                      fontSize: "15px",
                    }}
                    onClick={handleGuestUser}
                    > Continue as guest</span>
                  </div>
                </h4>
              </div>
        </div>
      {/* </Mobile> */}
    </>
  );
}

export default Login;
