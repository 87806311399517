import React, { useEffect, useState } from 'react';
import NewButton from '../../atoms/NewButton/NewButton';
import EventCategoryCard from '../EventCategoryCard/EventCategoryCard';
import { useNavigate } from 'react-router-dom';

function CustomCategorySection({ customHeadingCategory, auctionId, browseByButtonStyle, redColor, primaryBackgroundColor, admin_preview }) {
    const navigate = useNavigate();
    const [customCategoryHeadingState, setCustomCategoryHeadingState] = useState([{ auction_custom_categories: [] }])

    useEffect(() => {
        let newData = []
        const set = new Set()
        customHeadingCategory?.forEach(heading => {
            let data = heading.auction_custom_categories.filter(category => {
                if (!set.has(category?.category_name)) {
                    set.add(category?.category_name)
                    return true
                }
                return false
            })
            data.length && newData.push({ ...heading, auction_custom_categories: data })
        })
        setCustomCategoryHeadingState(newData)
    }, [customHeadingCategory])
    
    return (
        <>
            {customCategoryHeadingState?.map((heading) =>
                <div className='custom_category_container' key={heading.id}>
                    <h5 className='custom_category_heading' >
                        {heading?.name}
                    </h5>
                    <div className='image-container'>
                        <div style={{ display: "flex", gap: '30px' }} className='sub-category'>
                            {heading?.auction_custom_categories?.map((category, i) =>
                                !category?.image_key ?
                                    <NewButton
                                        key={category.id}
                                        onClick={() =>
                                            navigate(`/event/${auctionId}/browse-items?item-type=event-custom-category&category=${category?.id}${admin_preview ? `&showIncomplete=${true}` : ''}`)
                                        }
                                        style={{ ...browseByButtonStyle, height: 'fit-content', marginBlock: 'auto' }}
                                        text={category?.category_name} backgroundColor={redColor} color={primaryBackgroundColor}
                                    />
                                    :
                                    <EventCategoryCard
                                        onCategoryClick={() => navigate(`/event/${auctionId}/browse-items?item-type=event-custom-category&category=${category?.id}${admin_preview ? `&showIncomplete=${true}` : ''}`)}
                                        key={category.id} name={category?.category_name} image={category?.image_key}
                                    />
                            )}
                        </div>
                    </div>
                </div>
            )
            }
        </>)
}

export default CustomCategorySection