import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { CauseContextProvider } from './store/cause-context';
import { AuctionContextProvider } from './store/auction-context';
import { AuthContextProvider } from './store/auth-context';
import { FilterContextProvider } from './store/filter-context';
import logger from "./utils/logger";

logger('index.js','',process.env);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  	<BrowserRouter>
		<AuthContextProvider>
			<CauseContextProvider>
				<AuctionContextProvider>
					<FilterContextProvider>
						<App />
					</FilterContextProvider>
				</AuctionContextProvider>
			</CauseContextProvider>
		</AuthContextProvider>
  	</BrowserRouter>
);