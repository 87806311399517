import React, { useState,useRef } from 'react';
import DialogMui from '../Dialog/Dialog';
import style from './SignupEmailCampign.module.css'

function SignupEmailCampign({ isOpen, backgroundColor = "#F0E8E3", onClose, onSubmitForm = () => null }) {
    const [formData, setFormData] = useState({})
    const [error, setError] = useState('')
    const [successAlert, setSuccessAlert] = useState(false);
    const emailCampignSignupForm = useRef(null)
    const submitForm = () => {
        if (!formData.FNAME || !formData.EMAIL) {
            return setError('First name and Email are required')
        }
        if( !formData.EMAIL?.includes('@')) {
            return setError('Enter a valid email')
        }
        try {
            emailCampignSignupForm.current.submit();
            // onSubmitForm()
            setSuccessAlert(true)
        }
        catch(error) {
            setSuccessAlert(false)
        }
    }
    const handleChange = (e) => {
        if (!e.target) return
        setFormData({ ...formData, [e.target.name]: e.target.value })
        setError('')
    }
    const handleClose = () => {
        onClose()
        setSuccessAlert(false);
    }

    return (
        <DialogMui
            contentRootStyle={{padding:'14px 0px'}}
            onClose={handleClose}
            handleClick={successAlert ? handleClose : submitForm}
            style={{ backgroundColor: backgroundColor }}
            ButtonText={successAlert ? 'Close' :'Subscribe Now'}
            buttonBgColor={'#FC3300'}
            buttonColor='white'
            isOpen={isOpen}>
            <form ref={emailCampignSignupForm} action="https://boundlessgiving.us21.list-manage.com/subscribe/post?u=e523e179961f63c6ced52602f&amp;id=19066d7026&amp;f_id=0080c5e1f0%22" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" method='post'>
                <div className={style.email_campign_signup_container}>
                    { !successAlert ? <>
                    <h3>Sign Up</h3>
                    <p>Register for updates about upcoming Boundless events and news</p>
                    <input type='text' placeholder='First Name' name='FNAME' onChange={handleChange} />
                    <input type='text' placeholder='Last Name' name='LNAME' onChange={handleChange} />
                    <input type='email' placeholder='Email Address' name='EMAIL' onChange={handleChange} />
                    <p>{error}</p>
                    </>
                    :
                    <>
                    <p className={`${style.thanku_text}`}>Thank you! Someone from our team will be in touch soon</p>
                    </>
                    }
                </div>
            </form>
        </DialogMui>
    )
}

export default SignupEmailCampign