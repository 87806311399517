import React, { useState } from 'react';
import ImageSkelton from '../ImageSkelton/ImageSkelton';
import { Desktop } from '../../responsive/Responsive';
import classes from './ItemDetailCard.module.css'


const ItemDetailCardLayout = ({imageArray=[], landscapeImage, onThumbNailClick, children, customImageClass="" , alt=''}) => {
    const [imageLoading, setImageLoading] = useState(true)
    // console.log(imageArray)
    return (
        <>
        <div className='itemdetails-content-container'>
            <Desktop>
            <div className='itemdetails-dimages-container'>
                <div className={`itemdetails-dimages-image ${classes[customImageClass]}`}>
                    {/* {(lotDetails?.assets && (landscapeImage.length > 1)) && <div onClick={() => handleImageChange(-1)} className='itemdetails-dimages-left'><ChevronLeftIcon /></div>} */}
                    <img style={{ display: imageLoading ? 'none' : 'block' }} onLoad={() => { setImageLoading(false) }} src={landscapeImage} alt={alt} />
                    <ImageSkelton loadingState={imageLoading} style={{height: "100%", width: "100%"}} />
                    {/* {(lotDetails?.assets && (landscapeImage.length > 1)) && <div onClick={() => handleImageChange(1)} className='itemdetails-dimages-right'><ChevronRightIcon /></div>} */}
                </div>
                <div className='itemdetails-dimages-thumb'>
                    {
                        imageArray.map((i, idx) => (
                            <div className={`${classes[customImageClass]}`} onClick={() => onThumbNailClick(idx)} key={i.name}>
                                <img style={{ display: imageLoading ? 'none' : 'block' }} onLoad={() => { setImageLoading(false) }} src={(i.name)?i.name:i} alt={alt} />
                                <ImageSkelton loadingState={imageLoading} style={{height: "100%", width: "100%"}} />
                            </div>
                        ))}
                </div>
            </div>
            </Desktop>
            {children}
        </div>
        </>
    )
}

export default ItemDetailCardLayout