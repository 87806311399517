import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet-async";
import classes from './ViewBlog.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import userServices from '../../../../services/user-services';
import moment from "moment"
import LoadingScreen from '../../LoadingScreen/Loading';
import HeaderV2 from '../../molecules/Header/HeaderV2';
import { mobileStyle } from '../../Home';
import NewFooter from '../../molecules/NewFooter/NewFooter';
import { config } from "../../../../config/config";
import HeaderV3 from '../../molecules/Header/HeaderV3/HeaderV3';
const {REACT_APP_URL} = config

const ViewBlog = () => {
  const primaryColor = mobileStyle.color[1];
  const primaryBackgroundColor = "var(--pure-white)";
  const params = useParams()
  const [blog, setBlog] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  useEffect(() => {
    fetchBlog()
  }, [])
  const fetchBlog = async () => {
    setIsLoading(true)
    try {
      const { data } = await userServices.getBlogData(params?.id)
      setIsLoading(false)
      setBlog(data.data)
    } catch (error) {
      setIsLoading(false)
      navigate("/not-found")
    }
  }
  return (
    <>
      {isLoading ?
        <LoadingScreen bgColor="#F0E8E3" /> :
        <div className={classes.view_blog_section} >
          <Helmet>
            <title>{blog?.title}</title>
            <meta 
              name="description" 
              content={blog?.subtitle} 
            />
            <link rel="canonical" href={`${REACT_APP_URL}/blog/${params?.id}`} />
          </Helmet>
          {/* <Mobile>
            <GlobalHeader
                title="Blog"
                SecondaryBtnContent={()=><span></span>}
                secondaryBtnBehavior="none"
            />
     </Mobile>
     <Desktop>
         <Navbar />
         <SubNavbar />
         <Header title="Blog" />
     </Desktop> */}
          {/* <HeaderV2 primaryBackgroundColor={primaryBackgroundColor} logoType='black' primaryColor={primaryColor} containerStyle={{ position: 'fixed' }} subHeaderTitle='Blog' /> */}
          <HeaderV3 primaryBackgroundColor={primaryBackgroundColor} position='absolute' subHeaderTitle='Blog' isSubHeader />
          <div className={`${classes.container} margin_class`} >
            <h1>{blog?.title}</h1>
            <div className={classes.image_wrapper}>
              <img src={blog?.image} alt={blog?.blog_alt} />
            </div>
            <div className={classes.content_wrapper}>
              <div className={classes.subtitle}>
                <h3>{blog?.subtitle}</h3>
                <div>{moment(blog?.created_at).format("MMMM Do YYYY, h:mm a")}</div>
              </div>
              <div className={classes.htmlcontent} dangerouslySetInnerHTML={{ __html: blog?.content }} />
              {/* <p >{ blog?.content}</p> */}
              <p>By: <span style={{ fontWeight: 600 }} >{blog?.user?.first_name} {blog?.user?.last_name}</span> </p>
            </div>
          </div>
          <NewFooter primaryBackgroundColor={primaryColor} primaryColor={primaryBackgroundColor} logoColor={mobileStyle.color[0]} />
        </div>}
    </>
  )
}

export default ViewBlog
