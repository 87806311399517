import { Link } from 'react-router-dom';
import SectionContainer from '../../../../molecules/SectionContainer/SectionContainer';
import classes from './ShopBrandsCausesEvents.module.css'
import { ArrowForward } from '@mui/icons-material';
import {config} from '../../../../../../config/config';
import { useState } from 'react';
// import auctionServices from '../../../../../../services/auction-services';
import ImageSkelton from '../../../../molecules/ImageSkelton/ImageSkelton';
const {IMAGES_BASE_URL} = config

const ShopBrandsCausesEvents = ({extraData}) => {

    // const [eventImageUrls,setEventImageUrls] = useState({});
    const [imageLoading, setImageLoading] = useState(true)
    // let img = new Image();
    // Here the extra data is image keys for this section which can be seperated using ','
    const imagesArray = extraData?.split(',')
    const cardDetails = [
        {
            title: "Brands <br/> You Love",
            image: `${IMAGES_BASE_URL}${imagesArray[0]}`,
            linkText: 'Shop By Brand',
            link: '/browse-brands'
        },
        {
            title: "Causes <br/> You Care About",
            image: `${IMAGES_BASE_URL}${imagesArray[1]}`,
            linkText: 'Shop By Cause',
            link: '/browse-stores'
        },
        {
            title: "Exclusive <br/> Events",
            image: `${IMAGES_BASE_URL}${imagesArray[2]}`,
            linkText: 'Shop By Event',
            link: '/auction'
        }
    ]

    // const getExclusiveEventImage = async () => {
    //   try {
    //     const response = await auctionServices.getExclusiveEventImage();
    //     // const img = new Image();
    //     // if (!eventData?.image2) return
    //     img.src = response?.data?.eventImageUrl + "_small";
    //     let oldImage = false
    //     img.onerror = () => {
    //         // setOldEventImage(true);
    //         oldImage = true
    //     };
    //     let images = {
    //         causeImageUrl:response.data.causeImageUrl,
    //         eventImageUrl:''
    //     }
    //     if(oldImage) {
    //         images = {
    //             ...images,
    //             eventImageUrl: response?.data?.eventImageUrl
    //         }
    //     }
    //     else {
    //         images = {
    //             ...images,
    //             eventImageUrl: img.src
    //         }
    //     }
    //     setEventImageUrls(images);
    //   }catch(error) {}
    // }  
    // useEffect(()=>{
    //  getExclusiveEventImage();
    //  return () => {
    //     img.onerror = null
    // }
    // },[])

    return (
        <SectionContainer sectionBg='bg-white'>
            <div className={classes.cardWrapper} >
                {
                    cardDetails.map((item) => {
                        return (
                            <div key={item.title} className={classes.cardContainer} >
                                {/* <h2 className={classes.cardTitle} >{item.title}</h2> */}
                                <h2 className={`newTitleClass ${classes.cardTitle}`} dangerouslySetInnerHTML={{ __html: item.title }} />
                                <Link className={classes.link} to={item.link}>
                                <div className={classes.imageContainer} >
                                    <img src={item.image} alt={item.title} onLoad={() => setImageLoading(false)} style={{display:imageLoading ? "none" : 'block'}} />
                                    <ImageSkelton loadingState={imageLoading} style={{height:'100%'}} />
                                </div>
                                </Link>
                                <div className={classes.linkBox}>
                                    <Link className={classes.link} to={item.link}>
                                        <span className={classes.linkText} >{item.linkText}</span>
                                        <ArrowForward className={classes.arrow} />
                                    </Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </SectionContainer>
    )
}

export default ShopBrandsCausesEvents;