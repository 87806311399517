import { MenuItem } from "@mui/material";

const BrowseByDropDownMenuItem = ({value='', checked=false, label, onChange=()=>null}) => {
    return (
        <MenuItem
            onClick={(e) => onChange(e)}
            sx={{ paddingTop: '15px', paddingBottom: '15px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}
        >
            <input value={value} checked={checked} type="checkbox" style={{cursor:'pointer'}} />
            <span>{label}</span>
        </MenuItem>
    )
}

export default BrowseByDropDownMenuItem;