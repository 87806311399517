import React from 'react'
import { useNavigate, Link } from "react-router-dom";
import { Desktop, Mobile } from '../../../responsive/Responsive';
import SectionContainer from '../../../molecules/SectionContainer/SectionContainer';
import classes from './AboutUs.module.css'
import constants from '../../../../../constants/en'
import { imageUrls } from '../../../../../constants/url'
import NewButton from '../../../atoms/NewButton/NewButton';
const { APP_HOME_PAGE_COPIES: { aboutSection } } = constants


const AboutUs = ({ title = "About Us" }) => {
    const navigate = useNavigate()
    const aboutUs = () => {
        navigate('/about');
    };
    // return (
    //     <Mobile>
    //     <div className="current_banner" style={{paddingTop:'14px'}}>
    //         <div className="page_heading">
    //             <h3>{title}</h3>
    //             <br />
    //             <p
    //                 style={{
    //                     textAlign: "left",
    //                     fontSize: 16,
    //                     color: "#000",

    //                     fontWeight: "normal"
    //                 }}
    //             >
    //                 Boundless Giving was founded with a mission to help nonprofits increase fundraising impact and expand donor bases. <span onClick={aboutUs} style={{ color: "blue" }}>Learn more</span>
    //                 <br />
    //                 <br />
    //                 If you're a nonprofit interested in hosting a pop-up or auction of your own, email <a style={{ color: "blue" }} href="mailto:info@boundlessgiving.com">info@boundlessgiving.com</a>
    //                 <br />
    //                 <br />
    //                 <br />
    //             </p>
    //         </div>
    //     </div>
    //     </Mobile>
    // )

    // New about us design

    return (
        <SectionContainer sectionBg='pure-white'>
            <div className={classes.aboutUsContainer} >
                <div className={classes.aboutUsLeft} >
                    <h2 className={`newTitleClass ${classes.aboutUsTitle}`} >{title}</h2>
                    <div className={classes.leftBottom} >
                        <h4 className={classes.leftBottomHead}>{aboutSection[0]}</h4>
                        <p className={classes.leftBottomPara}>{aboutSection[1]}</p>
                        <p className={classes.leftBottomPara}>{aboutSection[2]}</p>
                        <Desktop>
                            <div className={classes.leftAction} >
                                <Link to={'/browse-brands'} ><NewButton text={'VIEW BRANDS'} className="carouselCardButton" style={{width:'max-content', fontSize: '12px !important'}} /> </Link>
                                <Link to={'/browse-stores'} ><NewButton text={'SHOP NONPROFITS'} className="carouselCardButton" style={{width:'max-content', fontSize: '12px !important'}} /> </Link>
                                {/* <Link to={'/non-profit'} className={classes.nonProfitsLinks} >VIEW NONPROFITS</Link> */}
                            </div>
                        </Desktop>
                    </div>
                </div>
                <div className={classes.aboutUsRight} >
                    <img className={classes.mainImage} src={imageUrls.home_about_us_section_image} alt={title} />
                </div>
                <Mobile>
                    <div className={classes.leftAction} >
                        <Link to={'/brand'} ><NewButton text={'VIEW BRANDS'} className="carouselCardButton" style={{width:'max-content', fontSize: '12px !important'}} /> </Link>
                        <Link to={'/non-profit'} className={classes.nonProfitsLinks} >VIEW NONPROFITS</Link>
                    </div>
                </Mobile>
            </div>
        </SectionContainer>
    )
}

export default AboutUs
