import React from 'react'
import Footer from '../../desktop/components/Footer/Footer'
import Header from '../../desktop/components/Header/Header'
import Navbar from '../../desktop/components/Navbar/Navbar'
import SubNavbar from '../../desktop/components/SubNavbar/SubNavbar'
import { Mobile, Desktop } from '../responsive/Responsive'
import preval from 'preval.macro'
import "./DeploymentInfo.css"
import GlobalHeader from '../molecules/GlobalHeader'
import moment from "moment"

const DeployedInfo = () => {

  const dateTimeStamp = preval`module.exports = new Date();`

  return (
    <div className='site-status-container'>
        <div>
          <Desktop>
              <Navbar />
              <SubNavbar />
              <Header title="Site Status" />
          </Desktop>
          <Mobile>
            <GlobalHeader title="Site Status" />
          </Mobile>
          <div className='site-status-content'>
            Last Deployed: {moment(dateTimeStamp).tz('America/Los_Angeles').format('MMMM Do YYYY, h:mm:ss a z')}
          </div>
        </div>
        <Desktop>
        <div>
          <Footer />
        </div>
        </Desktop>
    </div>
  )
}

export default DeployedInfo