import React from 'react'
import {Helmet} from "react-helmet-async"
import './About.css';
// import HeaderV2 from '../molecules/Header/HeaderV2';
import HeaderV3 from '../molecules/Header/HeaderV3/HeaderV3';
import { mobileStyle } from '../Home';
import NewFooter from '../molecules/NewFooter/NewFooter';
import { imageUrls } from '../../../constants/url';
import { config } from '../../../config/config';
const {REACT_APP_URL} = config

const rayan = imageUrls.bg_rayan;
const nick = imageUrls.bg_nick;
const brandon = imageUrls.bg_brandon;
const neil = imageUrls.bg_neil;
const julie = imageUrls.bg_julie
const primaryColor = mobileStyle.color[0];
const primaryBackgroundColor = "var(--pure-white)";

const About = () => {
    const redColor = mobileStyle.backgroundColor[0];
  return (
    <>
        <Helmet>
            {/* <title>About | Boundless Giving</title> */}
            <title>Nonprofit Charity Fundraising Software & Solutions</title>
            <meta 
              name="description" 
              content="Boundless Giving provide you with online nonprofit school fundraising software for innovative charity events & schools fundraising solutions. Partner with us" 
            />
            <meta 
              name="keywords"
              content="School Fundraising Software"
            />
            <link rel="canonical" href={`${REACT_APP_URL}/about`} />
        </Helmet>
        <div style={{backgroundColor:primaryBackgroundColor}}>
        {/* <Desktop>
            <Navbar />
            <SubNavbar />
            <Header title="About Us" />
            <BackContainer style={{marginLeft:"150px"}}/>
        <div className="support_main">

                <div className='dabout_image_container'>
                    <img src={DAboutUs} alt="aboutus" />
                </div>
           
            <div className="part dabout_part">
                <p>Boundless Giving was founded with a mission to help nonprofits increase fundraising impact and expand donor bases. We have partnered with an array of brands who are looking to improve their sustainability and philanthropic efforts by supplying donated surplus and promotional goods into Boundless Giving charitable pop-ups and auctions. </p>
            </div>
            <div className="part">
                <p>A big part of our purpose is making sure that as much of the money spent on goods from consumers like you goes directly to the charities we represent. We are guaranteeing that a minimum of 80% of proceeds will be donated directly to the causes we promote on our platform. </p>
                <p>If you’d like to get in touch to donate, host an event of your own, or offer some feedback, you can email us at <a href="mailto:info@boundlessgiving.com">info@boundlessgiving.com</a>.</p>
                <p>Our mailing address is:</p>
                <p style={{fontWeight: 600}}> 
                    Boundless Giving, Inc.
                    <br/>22287 Mulholland Hwy #570
                    <br/>Calabasas, CA 91302
                    <br/>Tel: 323-790-6585
                </p>
            </div>
        </div>
            <Footer style={{marginTop: "100px"}} />
        </Desktop> */}
        {/* <Mobile> */}
            {/* <HeaderV2 primaryBackgroundColor={primaryBackgroundColor} logoType='orange' primaryColor={primaryColor} containerStyle={{position:'fixed'}} /> */}
            <HeaderV3 position='absolute' />
            <div style={{color:primaryColor}} className='about-container margin_class'>
                <h1 className='ab-heading'>About Us</h1>
                <h3 className='ab-sub-heading'>Boundless Giving was founded with a mission to help nonprofits increase fundraising impact and expand donor bases.</h3>
                <p className='ab-common-style para_fontsize'>We have partnered with an array of brands who are looking to improve their sustainability and philanthropic efforts by supplying donated unsold goods to nonprofits participating in the Boundless Giving platform.</p>
                <p className='ab-common-style para_fontsize'>A big part of our purpose is making sure that as much of the money spent on goods from consumers like you goes directly to the charities we represent. We are guaranteeing that 50-80% of a net marketplace and event proceeds will be donated directly to the causes we promote on our platform.</p>

                <p className='ab-common-style para_fontsize'>
                   "We have the audacity to believe we can reimagine fundraising in a way that transforms the experience for nonprofits and consumers alike. We're putting every ounce of energy we have into turning that vision into reality." - Ryan Jaffe
                </p>

                <h2 className='ab-heading' style={{marginBottom:"30px", marginTop:"40px", fontSize:'42px'}}>The Team</h2>
                <div className='team-container' style={{paddingBottom:"30px",borderBottom:"1px solid red",marginBottom:"35px"}}>
                    <div className='profile'>
                        <img src={rayan} loading='lazy' alt="Ryan Jaffe" />
                        <div className='description'>
                        <p className='name'>Ryan Jaffe</p>
                        <p className='designation'>Co-founder / CEO</p> 
                        </div>
                    </div>
                    <div className='profile'>
                        <img src={nick} loading='lazy' style={{backgroundColor:'#30313a',borderRadius:'20px'}} alt="Nick Danzis" />
                        <div className='description'>
                        <p className='name'>Nick Danzis</p>
                        <p className='designation'>Co-Founder / Advisor</p> 
                        </div>
                    </div>
                    <div className='profile'>
                        <img src={brandon} loading='lazy' alt="Brandon Watson" />
                        <div className='description'>
                        <p className='name'>Brandon Watson </p>
                        <p className='designation'>Co-Founder / Advisor</p> 
                        </div>
                    </div>
                    <div className='profile'>
                        <img src={julie} loading='lazy' alt="Julie Berkus" />
                        <div className='description'>
                        <p className='name'>Julie Berkus </p>
                        <p className='designation'>Head of Partnerships</p> 
                        </div>
                    </div>
                    <div className='profile'>
                        <img src={neil} loading='lazy' alt="Neil Collier" />
                        <div className='description'>
                        <p className='name'>Neil Collier </p>
                        <p className='designation'>Co-Founder / CTO</p> 
                        </div>
                    </div>
                </div>
                <div className='ab-address-container' style={{marginBottom:"80px"}}>
                <p className='ab-common-style para_fontsize'>If you’d like to get in touch to donate, host an event of your own, or offer some feedback, you can email us at <a href="mailto:info@boundlessgiving.com" style={{borderBottom:`1px solid ${primaryColor}`}}>info@boundlessgiving.com</a>.</p>
                <p className='ab-common-style para_fontsize'>Our mailing address is:</p> 
                <p style={{margin:'0'}} className='ab-common-style para_fontsize'>Boundless Giving, Inc.</p> 
                <p style={{margin:'0'}} className='ab-common-style para_fontsize'>22287 Mulholland Hwy</p> 
                <p style={{margin:'0'}} className='ab-common-style para_fontsize'>#570</p> 
                <p style={{margin:'0'}} className='ab-common-style para_fontsize'>Calabasas, CA 91302</p>
                <p className='ab-common-style para_fontsize'><a style={{textDecoration:"none"}} href="tel:3237906585">Tel: 323-790-6585</a></p>
                </div>
            </div>
        {/* </Mobile> */}
        </div>
        <NewFooter primaryBackgroundColor={redColor} primaryColor={primaryBackgroundColor} logoColor={mobileStyle.color[1]}/>
    </>
  )
}

export default About;