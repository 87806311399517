import React from 'react'
import classes from "./Footer.module.css"
import Tiktok from "../../../../images/footer_tiktok.svg"
import Facebook from "../../../../images/footer_facebook.svg"
import Insta from "../../../../images/footer_insta.svg"
import Twitter from "../../../../images/footer_twitter.svg"
import {Link} from "react-router-dom"

const Footer = ({style={}}) => {
  return (
    <footer style={{...style}} className={classes.dfooter}>
    <div className={classes.dfooter_wrapper}>
        <div>
            <div><Link to="/about">About Us</Link></div>
            <div><a href='mailto:info@boundlessgiving.com'>Contact</a></div>
            <div><Link to="/support">Get Help</Link></div>
            <div><Link to="/faq">FAQ</Link></div>
            <div><Link to="/tos">Terms Of Service</Link></div>
        </div>
        <div className={classes.dfooter_icons}>
          <a href="https://www.facebook.com/boundlessgiving.io/"><img src={Facebook} alt="facebook" /></a>
          <a href="https://www.instagram.com/boundlessgiving/"><img src={Insta} alt="insta" /></a>
          <a href="https://twitter.com/BoundlessGiving"><img src={Twitter} alt="twitter" /></a>
          <a href="https://www.tiktok.com/@officialboundlessgiving"><img src={Tiktok} alt="tiktok" /></a>
        </div>
    </div>
  </footer>
  )
}

export default Footer