import React from 'react';
import PaginationButtons from '../../atoms/PaginationButtons/PaginationButtons';
import { Pagination } from '@mui/material';
import clasess from './PaginationV2.module.css';
import { useMediaQuery } from 'react-responsive';

function PaginationV2({totalPage,page,onLoadMore, customContainerClass=''}) {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    if(totalPage === 0 ) return null
    return (
        <div className={`${clasess.paginationContainer} ${customContainerClass}`}>
            <Pagination
                count={totalPage}
                onChange={onLoadMore}
                page={page}
                // siblingCount={0}
                hideNextButton={true}
                hidePrevButton={true}
                sx={{
                    'button.MuiPaginationItem-root': {
                        height: isMobile? "10px" : "15px",
                        width: isMobile? "10px" : "15px",
                        minWidth: isMobile? "10px" : '15px',
                        paddingInline: '0',
                        borderRadius: '0',
                        border: '1px solid var(--red-color)',
                        color: 'transparent'
                    },
                    "button.MuiPaginationItem-root.Mui-selected": {
                        backgroundColor: 'var(--red-color)'
                    },
                    "div.MuiPaginationItem-root": {
                        display: 'none'
                    },
                   
                }}
            />
            <PaginationButtons disabledNext={page === totalPage} disabledPrev={page === 1} onNextClick={(e) => onLoadMore(e, page + 1)} onPrevClick={(e) => onLoadMore(e, page - 1)} />
        </div>
    )
}

export default PaginationV2