import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useEffect } from 'react'
import "./VariantsSelector.css";

const checkQTY_V = (variants,selected,setAuctionActive,activeByDate) => {
    const var_types_selected = variants.filter(v=>{
        const show = Object.keys(selected).map(s=> v[s]===selected[s]);
        return show.filter(s=>s === true).length === show.length;
    });
    if(var_types_selected.length) {
        const {quantity_in_stock} = var_types_selected[0];
        if(!quantity_in_stock) {
            setAuctionActive(false);
        } else {
            setAuctionActive(true && activeByDate);
        }
    }
}

const VariantsSelector = ({status_event,start_date_event,end_date_event,setAuctionActive,selected, selectedVar, setSelected, options, setSelectedVar, variants,auctionComplete, setHasQuantity}) => {
    const handleSelect = (e) => {
        let sel_here = selected;
        sel_here = {...selected, [e.target.name]: e.target.value};
        checkQTY_V(variants,sel_here,setAuctionActive,!auctionComplete
        );
        setSelected({...selected, [e.target.name]: e.target.value});
    }
    useEffect(() => {
        if(!selected) {
            const obj = {}
            Object.keys(options).forEach(key => {
                if(options[key] && options[key].length > 0) {
                    obj[key] = options[key][0]
                }
            })
            setSelected(obj)
            return
        }
        setSelectedVar(null)
        if(Object.keys(selected).length === Object.keys(options).length) {
            variants.forEach(variant => {
                let flag = true
                Object.keys(selected).forEach(key => {
                    if(variant[key] !== selected[key]) {
                        flag = false
                    }
                })
                if(flag) {
                    setSelectedVar(variant)
                    if(variant?.quantity_in_stock <= 0) {
                        setAuctionActive(false);
                        setHasQuantity(false)
                    }
                    else {
                        setHasQuantity(true)
                    }
                    return
                }
            })
        }
    }, [selected])

    const sortOptions = () => {
          const sorted = { ...options }; // Create a copy of options
      
          for (const key in options) {
            sorted[key].sort((a, b) => {
              const aQuantity = variants.find(variant => variant[key] === a)?.quantity_in_stock || 0;
              const bQuantity = variants.find(variant => variant[key] === b)?.quantity_in_stock || 0;
      
              if (aQuantity === 0 && bQuantity !== 0) {
                return 1;
              } else if (bQuantity === 0 && aQuantity !== 0) {
                return -1;
              }
              return 0;
            });
          }
          return sorted;
        
      }

  return (
    <>
            <hr/>
            <div>Choose options:</div>
            {/* <h1>T-shirt</h1>
            <div style={{marginTop: "10px"}}><span style={{fontWeight: 'bold'}}>Price: </span>{!selectedVar ? "Out of stock":selectedVar.price}</div> */}
            <div>
                {Object.keys(sortOptions()).map(optionName => (
                    <div key={optionName} style={{marginTop: "10px"}}>
                        <div className='variant-selector-container'>
                            <div className='variant-optionName'>{optionName} :</div>
                            {
                                selected &&
                                <FormControl key={optionName} sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Select
                                        displayEmpty
                                        defaultValue={selected[optionName]}
                                        name={optionName}
                                        value={selected[optionName]}
                                        onChange={handleSelect}
                                        sx={{ fontSize: "0.8rem"}}
                                    >
                                        {
                                            sortOptions()[optionName].map(value => (
                                                <MenuItem key={value} sx={{ fontSize: "0.8rem"}} value={value}>{value}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            }
                        </div>
                    </div>
                ))}
            </div>
        {/* </div> */}
    </>
  )
}

export default VariantsSelector