import { Helmet } from "react-helmet-async";
import { config } from "../../../../config/config";
import classes from './CreateNonProfit.module.css'
import HeaderV2 from "../../molecules/Header/HeaderV2";
import { mobileStyle } from "../../Home";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import Input from "../../atoms/Input/Input";
import { useContext, useEffect, useState } from "react";
import CauseContext from "../../../../store/cause-context";
import SelectDropDown from "../../atoms/SelectDropDown/SelectDropDown";
import TextArea from "../../atoms/TextArea/TextArea";
import ImageInput from "../../atoms/ImageInput/ImageInput";
import constants from "../../../../constants/en";
import causeServices from "../../../../services/cause-services";
import DialogMui from "../../molecules/Dialog/Dialog";
import { checkImageBeforeUpload } from "../../../../utils/util-functions";
import NewButton from "../../atoms/NewButton/NewButton";
import LoadingScreen from "../../LoadingScreen/Loading";
import NeedHelp from "../../atoms/NeedHelp/NeedHelp";
import AuthContext from "../../../../store/auth-context";
import { useNavigate } from 'react-router-dom';
import { State } from "country-state-city";
import { USZipCodeRegex } from "../../../../utils/reg-ex";
import HeaderV3 from "../../molecules/Header/HeaderV3/HeaderV3";

const { REACT_APP_URL } = config;

const CreateNonProfit = () => {
    const { backgroundColor } = mobileStyle
    const whiteBG = "var(--pure-white)"
    const redBG = backgroundColor[0]
    const blackBG = backgroundColor[1]
    const initialData = {
        cause_category_id: "",
        contact_firstname: "",
        contact_lastname: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        image: [],
        video: [],
        about: "",
        org_name: "BG",
        legal_name: "",// cause name
        tax_id: "123",
        cause_logo: null,
        phone_number: '',
        cause_ein: ''
    }
    const initialDataCopy = { ...initialData }
    const [form, setForm] = useState(initialData);
    const [causeCategories, setCauseCategories] = useState([])
    const [loaders, setLoaders] = useState({ overallLoading: false, landscape: false, logo: false, video: false })
    const [landscapePreview, setLandscapePreview] = useState([])
    const [logoPreview, setLogoPreview] = useState([])
    const [videoPreview, setVideoPreview] = useState([])
    const [formError, setFormError] = useState({ isOpen: false, message: '' })
    const { causeCategoryList } = useContext(CauseContext);
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate()
    const country = 'US'

    useEffect(() => {
        if(!isLoggedIn) {
            navigate("/login", {state: {redirect: '/non-profit/create'}})
          }
    },[])

    useEffect(() => {
        if (causeCategoryList?.length) {
            const newCats = causeCategoryList.map((item) => {
                return {
                    key: item?.name,
                    value: item?.id
                }
            })
            setCauseCategories(newCats)
        }
    }, [causeCategoryList])

    const handleFormChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    const handleCloseAlert = () => {
        setFormError({ isOpen: false, message: '' })
    }

    const handleLandscapeImageChange = async (e) => {
        if (e.target.files.length === 0) return
        setLoaders({ ...loaders, landscape: true })
        const filesUrl = []
        const selectedFiles = []
        const targetFilesObj = [...e.target.files];
        const preProcessedFiles = []
        if (targetFilesObj.length > 3 || landscapePreview.length === 3 || (landscapePreview.length + targetFilesObj.length) > 3) {
            setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_LANDSCAPE_IMAGE_NUMBER });
            setLoaders({ ...loaders, landscape: false })
            return
        }
        if (targetFilesObj.every((currentValue) => currentValue.size > 5000000)) {
            setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE });
            setLoaders({ ...loaders, landscape: false })
            return
        }
        targetFilesObj.map((file) => {
            return filesUrl.push({ name: URL.createObjectURL(file), type: 'img', local: true });
        });
        let invalidImage = false;
        for (const url of filesUrl) {
            const { width, height } = await checkImageBeforeUpload(url.name)
            if (width < 636 || height < 318) {
                invalidImage = true
            }
        }
        if (invalidImage) {
            setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_LAND_SCAPE_IMG_SIZE })
            setLoaders({ ...loaders, landscape: false })
            return
        }
        try {
            for (const image of targetFilesObj) {
                const formData = new FormData()
                formData.append("image", image)
                formData.append("width", 636)
                formData.append("height", 318)

                const preProcessedImage = await causeServices.imagePreProcess(formData)

                selectedFiles.push({ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true });
                const uint8 = new Uint8Array(preProcessedImage.buffer.data)
                const newBlob = new Blob([uint8], { type: "image/jpeg" })
                preProcessedFiles.push(newBlob)
            }
        } catch (err) { setLoaders({ ...loaders, landscape: false }) }
        setLandscapePreview([...landscapePreview, ...selectedFiles])
        setForm({
            ...form,
            image: [...form.image, ...preProcessedFiles]
        })
        setLoaders({ ...loaders, landscape: false })
    }

    const handleMultipleVideos = (event) => {
        const selectedFIles = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles];
        const twentyMb = 20_971_520;//20mb in bytes
        if (targetFilesObject.length === 1) {
            if(targetFiles[0]?.size > twentyMb) {
                setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_VIDEO_SIZE });
                return;
            }
            targetFilesObject.map((file) => {
                return selectedFIles.push({ name: URL.createObjectURL(file) });
            });
            setVideoPreview(selectedFIles);
            setForm({
                ...form,
                video: event.target.files[0],
            });
        } else {
            setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_VIDEO_LIMIT });
        }
    };

    const handleLogoChange = async (e) => {
        try {
            if (!e.target.files[0]) return;
            setLoaders({ ...loaders, logo: true })
            const imageFile = e.target.files[0];
            if (imageFile.size > 5000000) {
                setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE })
                setLoaders({ ...loaders, logo: false })
                return;
            }
            const imageUrl = URL.createObjectURL(imageFile);
            const { width, height } = await checkImageBeforeUpload(imageUrl)
            if (width < 80 || height < 80) {
                setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_LOGO_SIZE })
                setLoaders({ ...loaders, logo: false })
                return
            }
            setLogoPreview([{ name: imageUrl, type: 'img' }])
            setForm({
                ...form,
                cause_logo: imageFile
            })
            setLoaders({ ...loaders, logo: false })
        } catch (error) {
            setLoaders({ ...loaders, logo: false })
        }
    }

    const deleteImage = (imageType = '', url = null, imageIdx = null) => {
        if (imageType === 'landscape') {
            setForm({
                ...form,
                image: form.image.filter((i, idx) => idx !== imageIdx)
            })
            setLandscapePreview(landscapePreview.filter((o, idx) => url !== o.name))
        }
        else if (imageType === 'video') {
            setForm({
                ...form,
                video: null
            })
            setVideoPreview([])
        }
        else {
            setLogoPreview([]);
            setForm({
                ...form,
                cause_logo: null
            })
        }
    }

    const updatedState = () => {
        return State.getStatesOfCountry(country).map((s) => {
          return {
            key: s.name,
            value: s.isoCode,
          };
        });
      };

    const handleValidation = () => {
        if(!form.state) {
          setFormError({isOpen: true, message: constants.APP_COPY_CAUSE_STATE})
          return true
        }
        if (form.image?.length === 0) {
            setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_COVER_IMG })
            return true
        }
        if (!form.cause_category_id) {
            setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_CATEGORY })
            return true
        }
        if(form.contact_firstname.indexOf(' ') >= 0){
          setFormError({isOpen: true, message: constants.APP_COPY_CAUSE_FIRST_NAME})
          return true      
        }
        // if(form.contact_lastname.indexOf(' ') >= 0){
        //   setFormError({isOpen: true, message: constants.APP_COPY_CAUSE_LAST_NAME})
        //   return true      
        // }
        
        if(!form.zip.match(USZipCodeRegex)){
          setFormError({isOpen: true, message: constants.APP_COPY_CAUSE_ZIP_CODE})
          return true
        }
        if (!form.cause_logo) {
            setFormError({ isOpen: true, message: constants.APP_COPY_CAUSE_LOGO_REQURIED })
            return true
        }
        return false
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (handleValidation()) return
        let formData = new FormData();
        for (let key of Object.keys(form)) {
            form[key] && formData.append(key, form[key])
        }
        formData.delete('image')
        formData.delete('org_name')
        formData.append("org_name", "BG");
        let j = 0
        let temp = {}
        const imageLimit = 3
        for (let i = 0; i < landscapePreview.length; i++) {
            if (landscapePreview[i].imageType) {
                temp[landscapePreview[i].imageType] = landscapePreview[i].imageType;
                continue;
            }
            let imageName = ''
            for (let k = 1; k <= imageLimit; k++) {
                if (!temp[`image${k}`]) {
                    imageName = `image${k}`
                    temp[imageName] = imageName
                    break;
                }
            }
            formData.append(imageName, form.image[j++])
        }
        try {
            setLoaders({ ...loaders, overallLoading: true })
            await causeServices.createCause(formData)
            clearStates()
            setLoaders({ ...loaders, overallLoading: false })
            setFormError({ isOpen: true, message: constants.APP_COPY_CREATE_CAUSE_SUCCESS })
        } catch (error) {
            setLoaders({ ...loaders, overallLoading: false })
            setFormError({ isOpen: true, message: error?.response?.data?.data?.length ? error?.response?.data?.data[0]?.msg : constants.APP_COPY_SOMETHING_WENT_WRONG })
        }
    }

    function clearStates() {
        setForm(initialDataCopy)
        setLandscapePreview([])
        setLogoPreview([])
        setVideoPreview([])
    }

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Create Your Non Profit</title>
                <link rel="canonical" href={`${REACT_APP_URL}/non-profit/create`} />
            </Helmet>
            {
                loaders.overallLoading &&
                <LoadingScreen loadingText='Saving Non Profit...' />
            }
            <div className={classes.createNonProfiteContainer}>
                {/* <HeaderV2 primaryBackgroundColor={whiteBG} logoType="black" primaryColor={blackBG} containerStyle={{ position: "fixed" }} subHeaderTitle='Create Nonprofit' /> */}
                <HeaderV3 primaryBackgroundColor={whiteBG} subHeaderTitle='Create Nonprofit' isSubHeader position="absolute" />
                <div className={`${classes.pageMain} margin_class`}>
                    <h1 className={classes.pageTitle}>Create Your Non Profit</h1>
                    <form onSubmit={handleSubmit} className={classes.createForm}>
                        <div className={classes.formInputsSection}>
                            <Input label="Cause Name" required name="legal_name" value={form.legal_name} onChange={handleFormChange} />
                            <Input label="Cause Address 1" required name="address1" value={form.address1} onChange={handleFormChange} />
                        </div>
                        <div className={classes.formInputsSection}>
                            <Input label="Cause Address 2" name="address2" value={form.address2} onChange={handleFormChange} />
                            <Input label="City" required name="city" value={form.city} onChange={handleFormChange} />
                        </div>
                        <div className={classes.formInputsSection}>
                            <Input label="Zip" required name="zip" value={form.zip} onChange={handleFormChange} />
                            <SelectDropDown required options={updatedState()} label={'Select State'} name="state" value={form.state} onChange={handleFormChange} />
                        </div>
                        <div className={classes.formInputsSection}>
                            <Input label="Cause EIN" name="cause_ein" value={form.cause_ein} onChange={handleFormChange} />
                            <SelectDropDown required options={causeCategories} label={'Select Category'} name="cause_category_id" value={form.cause_category_id} onChange={handleFormChange} />
                        </div>
                        <div className={classes.formInputsSection}>
                            <TextArea
                                required
                                label={'Describe your cause in a few sentences. For example, who does it serve? How long has it been around? What is its mission etc.'}
                                name="about" value={form.about} onChange={handleFormChange}
                            />
                        </div>
                        <div className={classes.formInputsSection}>
                            <Input label="Contact First Name" required name="contact_firstname" value={form.contact_firstname} onChange={handleFormChange} />
                            <Input label="Contact Last Name" name="contact_lastname" value={form.contact_lastname} onChange={handleFormChange} />
                        </div>
                        <div className={classes.formInputsSection}>
                            <Input label="Contact Email" required name="email" value={form.email} onChange={handleFormChange} />
                            <Input label="Contact Phone Number" type='number' name="phone_number" value={form.phone_number} onChange={handleFormChange} />
                        </div>
                        <div className={classes.formInputsSection}>
                            <ImageInput
                                edit
                                required
                                label={constants.APP_COPY_CAUSE_LANDSCAPE_IMAGE_LABEL}
                                loading={loaders.landscape}
                                onDelete={(url, idx) => deleteImage('landscape', url, idx)}
                                multiple
                                images={landscapePreview}
                                max={3}
                                handleChange={handleLandscapeImageChange}
                            />
                        </div>
                        <div className={classes.formInputsSection}>
                            <ImageInput
                                edit
                                onDelete={(url, idx) => deleteImage('logo', url, idx)}
                                label={'Add a cause logo (80x80px).'}
                                max={1}
                                loading={loaders.logo}
                                images={logoPreview}
                                required
                                handleChange={handleLogoChange}
                            />
                            <ImageInput
                                max={1}
                                edit
                                onDelete={(url, idx) => deleteImage('video', url, idx)}
                                label='Add a Cause Video (Max 20Mb)'
                                accept="video/*"
                                handleChange={handleMultipleVideos}
                                images={videoPreview}
                            />
                        </div>
                        <NewButton type="submit" text={'SAVE'} className="formSubmit" />
                        <NeedHelp primaryColor={blackBG} style={{ marginTop: '50px', marginBottom: "49px" }} />
                    </form>
                </div>
                <NewFooter primaryBackgroundColor={blackBG} primaryColor={whiteBG} logoColor={redBG} />
            </div>
            <DialogMui 
               isOpen={formError.isOpen}
               message={formError.message} 
               ButtonText={'Okay'}
               style={{paddingInline: '10px'}}
               buttonStyle={{maxWidth:'60%', alignSelf:'center'}}
               textStyle={{display:'flex', flexDirection:'column', gap:'10px'}} 
               handleClick={handleCloseAlert} 
            />
        </>
    )
}

export default CreateNonProfit;