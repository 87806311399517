import React, { useState } from 'react'
import styles from './EventBrowseItemsContent.module.css'
import NewButton from '../../atoms/NewButton/NewButton'
import TagHeading from '../../atoms/TagHeading/TagHeading'
import RoundedContainer from '../../atoms/RoundedContainer/RoundedContainer'
import { mobileStyle } from '../../Home'
import { useMediaQuery } from 'react-responsive'

const EventBrowseItemsContent = ({ tagText='Browse', buttonText='SHOP NOW', headingText='OUR SHOP', onClick, roundedContainerStyle={}, color=mobileStyle.color[0], buttonColor=mobileStyle.color[2], buttonBgColor=mobileStyle.color[0], buttonStyle={},fontSizeMainText="32px", lowQualityImage=null, image='' }) => {

    const [imageLoaded, setImageLoaded] = useState(false);
    const handleHighQualityImageLoad = () => {
        if(!lowQualityImage) return
        setImageLoaded(true)
    }
      // Preload the high-quality image to check if it's loaded
     const highQualityImage = new Image();
     highQualityImage.src = image;
     highQualityImage.onload = handleHighQualityImageLoad;
    

    const xsDevices = useMediaQuery({
        maxWidth: 310
      })
    return (
        <>
            <RoundedContainer
                style={{
                    width: '100%',
                    backgroundColor: mobileStyle.backgroundColor[2],
                    border: 0,
                    paddingBlock: "30px",
                    borderRadius:'100px',
                    position:'relative',
                    ...roundedContainerStyle
                }}
            >
                {
                    (!imageLoaded && lowQualityImage) &&
                    <img 
                      src={lowQualityImage} 
                      alt=""
                      className={styles.low_quality_section_image}
                    />
                }
                <div className={styles.desktop_browse_card_content}>
                    <TagHeading heading={tagText} style={{ color: color, marginBottom:xsDevices ? "0" : "0.5rem" }} />
                    <h3 className={styles.desktop_browse_card_heading} style={{ color: color,fontSize:fontSizeMainText }}>{headingText}</h3>
                    <NewButton onClick={onClick} style={{ fontSize: "14px", paddingInline:'22px',textTransform:"uppercase",...buttonStyle }} text={buttonText} color={buttonColor} backgroundColor={buttonBgColor} />
                </div>
            </RoundedContainer >
        </>
    )
}

export default EventBrowseItemsContent