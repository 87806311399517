import { Menu, CancelOutlined } from "@mui/icons-material";
import constants from "../../../../../constants/en";
import {imageUrls} from "../../../../../constants/url";
import BGLogoComponent from "../../../../../images/BGLogoComponent/BGLogoComponent";
import { mobileStyle } from "../../../Home";
import classes from './HeaderV3.module.css';
import { Link } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";
import FavouriteIcon from "../../FavouriteIcon/FavouriteIcon";
import CartIcon from "../../CartIcon/CartIcon";
import NewButton from "../../../atoms/NewButton/NewButton";
import { memo, useState } from "react";
// import { Desktop, Mobile } from "../../../responsive/Responsive";
import { AnimatePresence, motion } from "framer-motion";
import SubHeader from "../../SubHeader/SubHeader";
const { APP_HEADER_COPIES: { pageLinks } } = constants
const {header_account_img, header_search_img} = imageUrls


const HeaderV3 = ({ isSubHeader,subHeaderDesktop=true ,subHeaderTitle='', primaryBackgroundColor = '#FC3300',position='relative',subHeaderOnclick,primaryColor}) => {
    const { color } = mobileStyle
    const [mobileMenu, setMobileMenu] = useState(false)
    const badgeStyle = {
        width: "19px",
        height: '19px',
        marginLeft: '13px',
        top: '28px',
        backgroundColor: mobileStyle.color[1],
        color: "#fff",
    }
    const handleMobileMenu = () => {
        setMobileMenu(!mobileMenu)
    }

    const isDesktop = useMediaQuery({
        minWidth: 768
    })
    const isMobile = useMediaQuery({
        maxWidth: 843
    })
    return (
        <div style={{position:position}} className={classes.sectionContainer}>
       
        <section className={classes.headerSection}>
            <div className={classes.headerContainer} >
                <Link to={'/'} className={classes.logoLink} ><BGLogoComponent type={'large'} color={color[0]} /></Link>
                {!isMobile &&
                    <div className={classes.linksContainer} >
                        {
                            pageLinks.map((item) =>
                                <Link key={item.link} to={item.link} className={classes.textLinks} >{item.label}</Link>
                            )
                        }
                    </div>
                }
                <div className={classes.iconLinks} >
                    <Link to={'/search'} > <img src={header_search_img} className={classes.icon} alt="header search" /> </Link>
                    <FavouriteIcon badgeStyle={badgeStyle} fontSize={'1.5rem'} color={color[1]} />
                    <CartIcon badgeStyle={badgeStyle} fontSize={'1.5rem'} iconClassName={classes.icon} color={color[1]} />
                    <Link to={'/account'}><img src={header_account_img} className={classes.icon} alt="header account" /></Link>
                    {
                        !isMobile && <Link to={'/shop-now'} ><NewButton text={'SHOP NOW'} className="headerShopNow" /></Link>
                    }
                    {
                        isMobile && <Menu onClick={handleMobileMenu} className={`${classes.icon} ${classes.menuIcon}`} />
                    }
                </div>
            </div>
            <AnimatePresence>
                {
                    mobileMenu ?
                        <motion.div transition={{ duration: 0.3 }} initial={{ height: 0 }} animate={{ height: "100vh" }} exit={{ height: 0 }} className={`${classes.mobileNavLinksAnimater}`}>
                            <div className={classes.openHeaderTop} ><CancelOutlined onClick={handleMobileMenu} className={classes.icon} /></div>
                            <div className={classes.mobileLinksContainer}>
                                {
                                    pageLinks.map((item) =>
                                        <Link key={item.link} to={item.link} className={classes.mobileLinks} style={{opacity: mobileMenu ? 1 : 0}} >{item.label}</Link>
                                    )
                                }
                                <Link to={'/shop-now'} className={classes.mobileLinks} >Shop Now</Link>
                            </div>
                        </motion.div>
                        : null
                }
            </AnimatePresence>

        </section>
        <section className={classes.subHeaderSection}>

        {( ((isDesktop && subHeaderDesktop) || !isDesktop) && (isSubHeader || subHeaderTitle !== '')) && <SubHeader title={subHeaderTitle} onClick={subHeaderOnclick} backgroundColor={primaryBackgroundColor} color={primaryColor}  />}
        </section>
        </div>
    )
}

export default memo(HeaderV3);