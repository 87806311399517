import { useState } from "react";
import { mobileStyle } from "../Home";
import HeaderV2 from "../molecules/Header/HeaderV2";
import NewFooter from "../molecules/NewFooter/NewFooter";
import NonprofitSignup from "../molecules/NonprofitSignup/NonprofitSignup";
import { Mobile } from "../responsive/Responsive";
import "./Brand.css";
import { Helmet } from "react-helmet-async";
import { config } from '../../../config/config';
import HeaderV3 from "../molecules/Header/HeaderV3/HeaderV3";
const {REACT_APP_URL} = config

const Brand = () => {
  const [openModal,setOpenModal]=useState(false)
  return (
    <>
    <Helmet prioritizeSeoTags>
        <title>Transform Excess Inventory into Impact with Boundless Giving</title>
        <meta 
          name="description" 
          content="Explore Boundless Giving, the innovative online marketplace reshaping the way brands connect with consumers and support nonprofits. Learn more right now!" 
        />
        <link rel="canonical" href={`${REACT_APP_URL}/brand`} />
   </Helmet>
    <div style={{backgroundColor:'#EFE9E4'}}>
      {/* <HeaderV2
        primaryBackgroundColor="#EFE9E4"
        primaryColor="black"
        logoType="black"
        containerStyle={{position:'fixed'}}
      /> */}
      <HeaderV3
        primaryBackgroundColor="#EFE9E4"
        position="absolute"
      />
      
      <div className="br-container margin_class">
        <h1 className="br-heading">For Brands</h1>
        <p className="br-sub-heading">
          $114,000,000,000 worth of inventory is wasted each year. <br />
          That’s a lot of zeroes.
        </p>
        <p className="br-common-style para_fontsize">
          The idea for Boundless Giving came to us when we learned about the
          impact of product waste on our planet. We knew there had to be an
          alternative to destroying goods, sending them to landfills, or just
          letting them sit endlessly in warehouses.
        </p>
        <p className="br-common-style para_fontsize mb-35 mt-35" >
          As it turns out, that was just the beginning of what Boundless Giving
          has been built to do.
        </p>
        <h5 className="br-not-into-resale">
          Not into resale websites? Perfect. This isn’t that.
        </h5>
        <p className="br-common-style para_fontsize">
          Boundless Giving isn’t a resale website, a discounter, an overstock
          retailer or a liquidator – <span style={{borderBottom:"1px solid black"}} className="br-not-into-resale">it’s a revolution in giving. </span>
        </p>
        <p className="br-common-style para_fontsize mt-35" >
          It’s a whole new kind of online marketplace built by a team of
          creative thinkers, entrepreneurs, business strategists, cutting-edge
          technologists, and genuine do-gooders with the aim of bringing
          together brands, shoppers, and nonprofits in a way that benefits them
          all.
        </p>
        <p className="br-common-style para_fontsize mb-35 mt-35" >
          {" "}
          And because a full 50-80% of proceeds go directly to nonprofits, the
          incredible impact we can make together is baked right in.
        </p>
        <h5 style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}>There are so many ways we can help brands do good – and look good.</h5>
        <p className="br-common-style para_fontsize" >More than ever, people want to shop their values. And wow, are they savvy; they know what brands stand for, what causes they support, how robust their sustainability efforts are, and whether they authentically live into the social mission statements on their websites. 
        </p>  
        <p className="br-common-style para_fontsize mt-35" >  Your relationship with Boundless Giving is a great look on you. But that’s not the only benefit:</p>
        <div className="br-list-container">
            <ul>
                <li className="br-common-style para_fontsize">
                <b>Attract</b>  new values-based customers who can become your biggest evangelists
                </li>
                <li className="br-common-style para_fontsize"><b>Bolster</b>  your brand image by generating meaningful fundraising support for nonprofits of all kinds</li>
                <li className="br-common-style para_fontsize">
  <b>Save on warehouse costs </b> 
    and storage and shipping cost by getting your surplus items or last-season goods into the hands of consumers who will love them.</li>
   <li className="br-common-style para_fontsize"><b>Benefit</b>  from charitable tax deductions. You can opt to keep 30% of the net proceeds, donate the full 100% to a nonprofit, or anything in between.  </li> 
    <li className="br-common-style para_fontsize mb-35"><b>Help</b>  the planet by eliminating waste. (We have families too, it’s important to us.)</li>
            </ul>
        </div>
        <p className="br-sub-heading">
        “This team has found a way to help us donate product where pretty much all we have to do is align with a cause we care about and feel good about it.”
        </p>
        <p style={{paddingBottom:"30px",borderBottom:" 1px solid #30313A"}}> — Andrew Altshule, CEO Outdoor Products</p>
        {/* <p style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}>So how does it work? Easy.</p>
        <div>
            <ol>
                <li className="br-common-style para_fontsize">You provide us with wonderful things that are sitting in warehouses, in retail locations, or direct from your factories.  </li>
                <li className="br-common-style para_fontsize">We catalog them and add them to our beautiful marketplace either as a limited time sale, part of a themed pop-up shop, or an online auction for a specific nonprofit partner.</li>
                <li className="br-common-style para_fontsize">We promote the event.</li>
                <li className="br-common-style para_fontsize">Our nonprofit partners promote the event.</li>
                <li className="br-common-style para_fontsize">You promote the event. (Well you don’t have to, but it helps.) </li>
                <li className="br-common-style para_fontsize">We manage the entire sales process.</li>
                <li className="br-common-style para_fontsize mb-35">Dropship the items directly, or we can support fulfillment with our carefully vetted 3PL providers.</li>
            </ol>
        </div> */}
        <h5 style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}>One of our own core values: Transparency in giving.</h5>
        <p className="br-common-style para_fontsize">Boundless Giving earns an incredibly reasonable 20% commission on all sales, and we guarantee a minimum of 50% of proceeds to nonprofits. </p>
        <p className="br-common-style para_fontsize mb-35 mt-35" >That means you earn up to 30% of the net sales of your items, or you can choose to donate the full 100%. Whatever works best for you.</p>
        <p className="br-common-style para_fontsize"> <span style={{ fontSize: "20px", fontWeight: "700", cursor:'pointer', borderBottom:'1px solid black', lineHeight:'27px'}} onClick={()=>setOpenModal(true)}>Reach out today.</span> There’s so much more we can tell you.  We’re excited to get to know more about you, your brand, your mission, your inventory challenges, and how we can help. 
        {/* <p style={{ fontSize: "20px", fontWeight: "700", margin: "20px 0" }}> <span  onClick={()=>setOpenModal(true)} style={{cursor:'pointer', borderBottom:'1px solid black'}}>Let’s talk.</span> There’s so much more we can tell you.</p> */}
        </p>
        <p className="br-common-style para_fontsize" style={{ marginTop: "35px",paddingBottom:"60px" }}>We can even put together custom programs for you and create solutions based on your own unique needs. Guess you’d say that’s the “Boundless” part of Boundless Giving.</p>
      <div className="email_signup">
            <p>Become a brand partner today.</p>
            <button onClick={()=>setOpenModal(true)}>CONTACT US</button>
      </div>
      </div>
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />
      <NonprofitSignup isBrand={true} isOpen={openModal} onClose={()=>setOpenModal(false)} onSubmitForm={()=>setOpenModal(false)} />
    </div>
    </>
  );
};

export default Brand;
