import { useState } from "react";
import classes from './EventCategoryCard.module.css';
import ImageSkelton from "../ImageSkelton/ImageSkelton";

const EventCategoryCard = ({ image = "", name = "", onCategoryClick = () => null }) => {
    const [imageLoading, setImageLoading] = useState(true)
    return (
        <div onClick={onCategoryClick} className={classes.categoryCardContainer} >
            <div className={classes.categoryImageContainer} >
                <ImageSkelton width='100%' loadingState={imageLoading} style={{ height: "100%", borderRadius: "25px" }} />
                {image && image !== "" && <img src={image} alt={name} className={classes.categoryImage} onLoad={() => setImageLoading(false)} />}
            </div>
            <div className={classes.categoryCardBottom}>
                <p>{name}</p>
            </div>
        </div>
    )
}

export default EventCategoryCard;