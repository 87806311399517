import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services'
import logger from '../utils/logger';


export const getAuction = createAsyncThunk(
    'redux/getAuction',
    async ({type, cause_category_id, page, limit}) => {

        let todaysDate = new Date();
        if (type === "upcoming") {
            const response = await auctionServices.getUpcomingAuctionsList(todaysDate, page, limit);
            return response
            //intstead of getUpcomingAuctionsList()
        } else if (type === "current") {
            const response = await auctionServices.getCurrentAuctions(todaysDate, page, limit);
            return response
            //intstead of getCurrentAuctions();
        } else if (cause_category_id) {
            const response = await auctionServices.getAllAuctionsList(page, limit, cause_category_id);
            return response
            //intstead of getAuctionsList(cause_category_id);
        } else {
            const response = await auctionServices.getAllAuctionsList(page, limit);
            return response
            //intstead of getAuctionsList();
        }
    }
)

const initialState = {
    auctions: {
        data: [],
        limit: 20,
        page: 1,
        total_count:0,
        total_page: 0,
    },
    isLoading: false,
    isLoadingMore:false,
    error: '',
    from:'',  
}


const auctionSlice = createSlice({
    name: 'auction',
    initialState,
    reducers: {
        resetAuction:(state,action)=>{
            // state=initialState
            state.auctions = {
                data: [],
                limit: 20,
                page: 1,
                total_count:0,
                total_page: 0,               
            }
            state.isLoading=false
            state.isLoadingMore=false
            state.error= ''
            state.from=''
        },
        setFrom:(state, action) => {
            state.from = action.payload.from
        }
    },
    extraReducers: (builder) => {
    
        builder.addCase(getAuction.pending, (state, action) => {
           state.auctions.page>1?state.isLoadingMore=true: state.isLoading = true;
            state.error = ''
        })

        builder.addCase(getAuction.fulfilled, (state, action) => {

            if(action.payload?.page === 1){
                state.auctions = {
                    ...state.auctions,
                    data: action.payload?.data,
                    limit: action.payload?.limit,
                    page: action.payload?.page,
                    total_count: action.payload?.total_count,
                    total_page: action.payload?.total_page
                }
            }
            else if(action.payload?.page > 1){
              state.auctions = {
                ...state.auctions,
                data: [...state.auctions?.data, ...action.payload?.data],
                limit: action.payload?.limit,
                page: action.payload?.page,
                total_count: action.payload?.total_count,
                total_page: action.payload?.total_page
            }
           }
            state.isLoading = false;
            state.isLoadingMore = false;
            state.error = ''
        })
        builder.addCase(getAuction.rejected, (state, action) => {
            state.isLoading = false;
            state.isLoadingMore = false;
            state.error = action.error.message;
            logger('./redux/eventReducer', 'getCategory(): catch1', action.error);
        })
    },
})

export default auctionSlice.reducer;
export const {resetAuction, setFrom} = auctionSlice.actions



