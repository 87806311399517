import React, { useState } from "react";
import classes from './DesktopCardLayout.module.css'
import ImageSkelton from '../../molecules/ImageSkelton/ImageSkelton';
import Image from "../Image/Image";
import BrowseItemsFavouriteButton from "../../molecules/BrowseItemsFavouriteButton/BrowseItemsFavouriteButton";


const DesktopCardLayout = ({children, image='', imageContainerStyle, onCardClick=()=>null, onCardImageClick=()=>null, contentContainerStyle={}, mainContainerStyle={}, imageStyle={}, alt='', favourites=false, auctionItem=null, handleFetchData=()=>null, updateFavourite=false, imageContainerClass=''}) => {
    const [imageLoading, setImageLoading] = useState(true)
    return (
        <>
            <div onClick={onCardClick} className={classes.main_container} style={{...mainContainerStyle}} >
                {/* {
                    favourites && 
                    <BrowseItemsFavouriteButton auctionItem={auctionItem} updateFavourite={updateFavourite} handleFetchData={handleFetchData}/>
                } */}
                <div onClick={onCardImageClick} className={`${classes.img_container} ${classes[imageContainerClass]}`} style={{...imageContainerStyle}} >
                    <Image src={image} alt={alt}  style={{display:imageLoading ? "none" : 'block', ...imageStyle}} onLoad={() => setImageLoading(false)} />
                    <ImageSkelton width='100%' loadingState={imageLoading} style={{height:"100%", borderRadius:"25px"}}/>
                </div>
                 <div className={classes.content_container} style={{...contentContainerStyle}} >
                    {children}
                 </div>
            </div>
        </>
    )
}

export default DesktopCardLayout