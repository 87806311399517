import React from "react";
import { Box } from "@mui/system";
import { Link } from "@mui/material";

import DesktopImg from "../../images/desktop.png";
import MobileImg from "../../images/mobile.png";

function Hero() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: { md: "block", xs: "none", position: "relative" },
          }}
        >
          <img
            src={DesktopImg}
            alt="bg"
            style={{ width: "100%", objectFit: "cover" }}
          />
          <Link
            href="mailto:help@boundlessgiving.com?subject=I'd like to learn more about Boundless Giving&body=Hi I want Boundless Giving news and updates. Please add me to your list."
            sx={{
              textDecoration: "none",
              width: { lg: "200px", md: "160px" },
              height: { lg: "56px", md: "46px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: { lg: "28%", md: "30%" },
              bottom: { lg: "15%", xs: "10%" },
              background: "#FB008D",
              color: "white",
              border: "2px solid white",
              borderRadius: "10px",
              fontWeight: 600,
              fontFamily: "AvenirNext",
            }}
          >
            Learn More
          </Link>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: { md: "none", xs: "block" },
            position: "relative",
          }}
        >
          <img
            src={MobileImg}
            alt="bg"
            style={{ width: "100%", objectFit: "cover" }}
          />
          <Link
            href="mailto:help@boundlessgiving.com?subject=I'd like to learn more about Boundless Giving&body=Hi I want Boundless Giving news and updates. Please add me to your list."
            sx={{
              textDecoration: "none",
              width: { sm: "160px", xs: "100px" },
              height: { sm: "46px", xs: "36px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: { sm: "28%", xs: "26%" },
              top: "50%",
              background: "#FB008D",
              color: "white",
              border: "1px solid white",
              borderRadius: "6px",
              fontWeight: 600,
              fontFamily: "AvenirNext",
              fontSize: { sm: "16px", xs: "12px" },
            }}
          >
            Learn More
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default Hero;
