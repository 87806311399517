import React,{memo} from 'react';
import { Carousel } from 'react-responsive-carousel';
import Image from '../Image/Image';
import constants from '../../../../constants/en';

const CarouselCard = ({ lotDetails }) => {
  return (
    <div className='auction_banner'>
      <Carousel
        showIndicators={lotDetails.assets?.filter(img=>img.context_type==='portrait')?.length > 1}
        showThumbs={lotDetails.assets?.filter(img=>img.context_type==='portrait')?.length > 1}
      >
        {lotDetails?.assets &&
          lotDetails.assets.filter(img=>img.context_type==='portrait').map((i) => (
            <div key={i?.name}>
              <Image src={`${i?.name}_${constants.ITEM_MOBILE_VARIANT}`} variant={`_${constants.ITEM_MOBILE_VARIANT}`} alt={lotDetails?.auction_item_alt} />
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default memo(CarouselCard);
