import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { fetchSavedCard, deletePaymentDetails } from '../../../services/payment-services';
import AuthContext from '../../../store/auth-context';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmAlert from '../ConfirmAlert';
import HeaderV2 from '../molecules/Header/HeaderV2';
import NewButton from '../atoms/NewButton/NewButton';
import { mobileStyle } from '../Home';
import style from './ExistingPayment.module.css';
import MobileSubFooter from '../atoms/MobileSubFooter/MobileSubFooter';
import { useMediaQuery } from "react-responsive";
import NewFooter from '../molecules/NewFooter/NewFooter';
import HeaderV3 from '../molecules/Header/HeaderV3/HeaderV3';
const primaryBackgroundColor = "var(--pure-white)";
const redColor = mobileStyle.backgroundColor[0];

const ExistingPayment = () => {
    const { state } = useLocation()
    const [cardDetails, setCardDetails] = useState(null)
    const { cusId, cardId, setCardId, setCusId } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const isDesktop = useMediaQuery({minWidth: 768})

    const navigate = useNavigate()
    useEffect(() => {
        setLoading(true)
        if (cusId && cardId) {
            const cardDetails = async () => {
                const card = await fetchSavedCard(cusId, cardId)
                setCardDetails({
                    name: card.name,
                    last4: card.last4,
                    brand: card.brand,
                    address1: card.address_line1,
                    city: card.address_city,
                    state: card.address_state,
                    zip: card.address_zip
                })
            }
            cardDetails()
        }
        setLoading(false)
    }, [])
    const handleClick = () => {
        navigate("/payment", {
            state: {
                ...state,
            }
        })
    }

    const handleDeletePayment = () => {
        setIsOpen(true)
    }
    const confirmDeletePayment = async () => {
        await deletePaymentDetails()
        setIsOpen(false)
        setCardId(null)
        setCusId(null)
        navigate('/account', { replace: true })
    }
    return (
        <div className={style.mh_100} style={{ backgroundColor: primaryBackgroundColor }}>
            {/* <HeaderV2 isSubHeader={true} subHeaderTitle='Payment Methods' logoType='black2' color="#30313A" primaryBackgroundColor='#EFE7E2' containerStyle={{ position: 'fixed' }} /> */}
            <HeaderV3 isSubHeader subHeaderTitle='Payment Methods' primaryBackgroundColor={primaryBackgroundColor} position='absolute' />
           
            {
                !loading && (
                    <>
                        {cardDetails ? (
                            <>
                            <div className={`margin_class ${style.ep_container}`}>
                                <h5>Payment Methods</h5>
                                <div className={style.bag_shipping_sec} >
                                    <div className={style.bag_shipping_content} >
                                        <div>{`${cardDetails.name}`}</div>
                                        <div>{`**** **** **** ${cardDetails.last4}`}</div>
                                        <div>{cardDetails.brand}</div>
                                    </div>
                                    <div>
                                        <button onClick={handleDeletePayment} type="button" >
                                            <DeleteOutlineOutlinedIcon />
                                        </button>
                                    </div>
                                </div>
                                <div className={style.change_payment_btn}>
                                    <NewButton
                                        onClick={handleClick}
                                        text={'Change Payment Method'} backgroundColor={primaryBackgroundColor} color={redColor} style={{ width: "100%", margin: "auto", textTransform: 'uppercase', fontSize: '16px', fontWeight: "600" }}
                                    />
                                </div>
                                <ConfirmAlert
                                    alerMsg="Confirm to delete payment details"
                                    openConfirmAlert={isOpen}
                                    handleCloseConfirmAlert={() => setIsOpen(false)}
                                    handleConfirmTask={confirmDeletePayment}
                                />
                            </div>
                            {/* <MobileSubFooter needHelp style={{padding:isDesktop?"":'5vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
                            <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

                            </>
                            
                        ) :
                            <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#EFE7E2" }}>
                                <CircularProgress color="inherit" />
                            </div>
                        }
                    </>
                )
            }
        </div>
    )
}

export default ExistingPayment