
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

// import Image4 from "../../images/bg_loading_05.png"

import "./Auth.css"

function Auth() {
    const navigate = useNavigate();

    const handleGuestUser = () => {
        localStorage.setItem("guest_user_visited", true);
        navigate("/");
    }
    return (
        <>
            <div className="authentic_sec">
                <div className='auth_s_container'>
                    <div className='auth_s_content'>
                        <div className="auth_heading">
                            <h1><span>let's get boundless</span></h1>
                        </div>
                        <div>
                            <div><span><Link to="/login"> LOGIN </Link> OR <Link to="/register"><span>JOIN NOW</span></Link></span></div>
                            {/* <div style={{fontSize: "0.9rem", margin: "10px 0"}}><span>OR</span></div> */}
                            <div></div>
                        </div>
                        {/* <div className="browse_as_guest" onClick={handleGuestUser}><span>BROWSE AS A GUEST</span></div> */}
                    </div>
                </div>
                {/* <div className="container">
                    <div className="auth_inside">
                        <div className="authen_pics">
                        </div>
                        <div className="authen_content" style={{ textAlign: "center" }}>
                            <h5> <Link to="/login"> Login </Link> </h5>
                            <div className="orSec">
                                <span>Or</span>
                            </div>
                            <h5> <div onClick={handleGuestUser}> Start Browsing </div> </h5>
                            <Link to="/register"><button className="blockBtn" style={{ width: "90%" }} type="button"> JOIN </button></Link>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Auth

