import React from "react";
import style from "./Section7.module.css";
import { useNavigate } from "react-router-dom";
import Vans from "../../../../../images/symbol-Vans.jpeg"
import Roxy from "../../../../../images/roxy-logo.png"
import DC from "../../../../../images/DC-Logo.png"
import Quicksilver from "../../../../../images/quiksilver-logo-1.png"
import Billabong from "../../../../../images/billabong-logo.png"
import Outdoor from "../../../../../images/outdoor.png"
import { info_email_mailto } from "../../../../../utils/constants";

const Section7 = ({section7Style, title="And for brands that care, too", subtext="We partner with brands and companies to collect their surplus inventory, promotional products, and merchandise donations to give nonprofits another means of fundraising. Are you a brand looking for a better way to give? Click here to get in touch with us."}) => {

  const navigate = useNavigate();
  return (
    <>
      <section style={{margin:"auto", maxWidth:"1250px"}} className={style.section7}>
        <div>
          <strong>
            <h2 style={section7Style} className={style.headline}>{title}</h2>
          </strong>
          <p style={section7Style} className={style.subheadline}>
            { subtext }{" "}
            <span className={style.section7_clickhere} onClick={()=>window.open(info_email_mailto)}>Click here</span> to get
            in touch with us.
          </p>
          <p className={style.gray_line}>
          Partner brands that have supported Boundless causes include:
          </p>
          <div className={style.img_container}>
            <div>
              <img
                src="https://assets.cdn.filesafe.space/gDomeI4d9kjTwwlhShAw/media/639bbd66b23e0a3227f0c066.png"
                alt=""
                width="200px"
                // style={{height:"100px"}}
                className={style.image1}
              />
            </div>
            <div>
              <img
                src="https://assets.cdn.filesafe.space/gDomeI4d9kjTwwlhShAw/media/63961942b5d8bdced46e6bc7.png"
                alt=""
                width="200px"
                // height="150px"
                // style={{height:"100px"}}
                className={style.image1}
              />
            </div>
            <div>
              <img
                src={Vans}
                alt=""
                width="150px"
              />
            </div>
            <div>
              <img
                src="https://assets.cdn.filesafe.space/gDomeI4d9kjTwwlhShAw/media/63961876e1530af9dca867bf.png"
                alt=""
                width="200px"
                // height="150px"
                // style={{height:"100px"}}
                className={style.image1}
              />
            </div>
            <div>
              <img
                src={DC}
                alt=""
                width="100px"
              />
            </div>
            <div>
              <img
                src={Roxy}
                alt=""
                width="100px"
              />
            </div>
            <div>
              <img
                src={Quicksilver}
                alt=""
                width="100px"
              />
            </div>
            <div>
              <img
                src={Billabong}
                alt=""
                width="100px"
              />
            </div>
            <div>
              <img
                src={Outdoor}
                alt=""
                width="100px"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section7;
