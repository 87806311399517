import { useState } from 'react';
import SectionContainer from '../../../molecules/SectionContainer/SectionContainer';
import classes from './BrowseItemsBannerV2.module.css';
import ImageSkelton from '../../../molecules/ImageSkelton/ImageSkelton';

const BrowseItemsBannerV2 = ({lowQualityImage=null, image='', alt='', children, customContainerClass=''}) => {
    const [imageLoading, setImageLoading] = useState(true)
    const handleImageLoading = () => {
        setImageLoading(false)
    }
    return (
        <div className={`${classes.bannerContainer} ${classes[customContainerClass]}`} >
            <div className={classes.bannerImageContainer} >
                <img onLoad={handleImageLoading} src={image} alt={alt} style={{display: imageLoading ? 'none' : 'block'}} />
                {lowQualityImage && imageLoading && <img src={image} alt={alt} />}
                {
                    !lowQualityImage && 
                    <ImageSkelton loadingState={imageLoading} style={{height:'40vh'}} />
                }
            </div>
            <SectionContainer childClass='browseBannerContent'>
                {children}
            </SectionContainer>
        </div>
    )
}

export default BrowseItemsBannerV2;