import React, { useState, useEffect, useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import userServices from "../../services/user-services";
import AuthContext from "../../store/auth-context";
import GlobalHeader from "./molecules/GlobalHeader";
import auctionServices from "../../services/auction-services";
import "./MyBids.css";
import logger from "../../utils/logger";
import { Helmet } from "react-helmet-async"
import { Desktop, Mobile } from "./responsive/Responsive";
import Navbar from "../desktop/components/Navbar/Navbar";
import Header from "../desktop/components/Header/Header";
import Footer from "../desktop/components/Footer/Footer";
import SubNavbar from "../desktop/components/SubNavbar/SubNavbar";
import BackContainer from "../desktop/components/BackContainer/BackContainer";
import AccountNavbar from "../desktop/components/AccountNavbar/AccountNavbar";
import HeaderV2 from "./molecules/Header/HeaderV2";
import SubHeader from "./molecules/SubHeader/SubHeader";
import { mobileStyle } from "./Home";
import { useMediaQuery } from "react-responsive";
import NeedHelp from "./atoms/NeedHelp/NeedHelp";
import NewCardLayout from "./atoms/NewCardLayout/NewCardLayout";
import ImageSkelton from "./molecules/ImageSkelton/ImageSkelton";
import MobileSubFooter from "./atoms/MobileSubFooter/MobileSubFooter";
import NewFooter from "./molecules/NewFooter/NewFooter";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";

function MyBids() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [blClass, setBlClass] = useState("");
  const [tabName, setTabName] = useState("active");
  const [activeBidData, setActiveBidData] = useState(null);

  const [completedBidData, setCompletedBidData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const primaryBackgroundColor = mobileStyle.backgroundColor[1];
  const primaryColor = mobileStyle.color[2];
  const redColor = mobileStyle.color[0]
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const [imageLoading, setImageLoading] = useState(true)
  useEffect(() => {
    setIsLoading(true);
    const getBidDetails = async (user_id) => {
      try {
        await userServices.getUserBids(user_id, tabName).then(
          (response) => {
            tabName === "active" && setActiveBidData(response.data);
            tabName === "completed" && setCompletedBidData(response.data);
            setIsLoading(false);
          },
          (error) => {
            logger('./pages/components/MyBids.jsx', 'getBidDetails(): catch1', error);
            tabName === "active" && setActiveBidData(null);
            tabName === "completed" && setCompletedBidData(null);
            setIsLoading(false);
          }
        );
      } catch (err) {
        logger('./pages/components/MyBids.jsx', 'getBidDetails(): catch2', err);
        tabName === "active" && setActiveBidData(null);
        tabName === "completed" && setCompletedBidData(null);
        setIsLoading(false);
      }
    };
    getBidDetails(authCtx?.userId);
  }, [authCtx, page, tabName]);

  const handleTabChange = (e) => {
    setTabName(e.target.id);
    if (e.target.id === "active") setBlClass("moveLeft");
    else if (e.target.id === "completed") setBlClass("moveRight");
  };

  const getBidDetails = async (auction_id, item_id) => {
    try {
      const response = await auctionServices.getBidDetailsOfItemById(auction_id, item_id)
      return response
    } catch (err) {
      logger('./pages/components/MyBids.jsx', 'getBidDetails(): catch3', err);
    }
  };

  const checkUserBid = async (uid, item_id) => {
    try {
      const response = await userServices.checkUserBidItem(uid, item_id)
      return response.data
    } catch (err) {
      logger('./pages/components/MyBids.jsx', 'checkUserBid(): catch', err);
    }
  };

  const handleNavigate = async (bid) => {
    const bidDetails = await getBidDetails(bid?.auction_item?.auction_id, bid?.auction_item?.id)
    const userBid = authCtx.isLoggedIn && await checkUserBid(authCtx.userId, bid?.auction_item?.id);
    navigate(
      `/lot/${bid?.auction_item?.id}/place-bid?auction_id=${bid?.auction_item?.auction_id}&item_id=${bid?.auction_item?.id}&cause_id=0`,
      {
        state: {
          userId: authCtx?.userId,
          bidDetails: bidDetails.data,
          user_bid: userBid && userBid.length > 0 ? userBid[0] : null
        },
      }
    )
  }

  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false);
  };

  const handleTabPrefernceInfoChange = () => {
    navigate('/account', {state:{from: "info"}})
  }

  const handleTabPreferncePaymentChange = () => {
    navigate('/account', {state:{from: "payment"}})
  }

  return (
    <div style={{backgroundColor: primaryBackgroundColor}}>
      <Helmet prioritizeSeoTags>
        <title>My Bids | Boundless Giving</title>
      </Helmet>
      {/* <HeaderV2
                logoType="white2"
                primaryBackgroundColor={primaryBackgroundColor}
                primaryColor={primaryColor}
                containerStyle={{position:"fixed"}}
                isSubHeader={true}
                subHeaderTitle="My Bids"
              /> */}
      <HeaderV3
       primaryBackgroundColor={primaryBackgroundColor}
       isSubHeader
       subHeaderTitle="My Bids"
       position="absolute"
       primaryColor={primaryColor}
              />
              
    
              
      <div className={`${isMobile?"":"margin_class"}`} style={{backgroundColor:primaryBackgroundColor,}}>

        <div style={{backgroundColor:primaryBackgroundColor}} className="container">
            <div  style={{paddingTop:isMobile?"170px":"200px" }}>

                <p style={{color: primaryColor,marginInline:isMobile?"30px":"",}} className="mobile_main_heading" >Auction Items</p>

              {/* tab for mobile */}
              <div style={{borderBottom:`1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor, marginInline:isMobile?"30px":"", display:isMobile ? "":"flex",justifyContent: isMobile? "" : "space-around" }} className='bid_nav'>
              <p
                style={{
                  borderBottom:tabName === 'active' ? `2px solid ${redColor}` : 0,
                  color:tabName === 'active' ? redColor : primaryColor
                }}
                className={tabName === "active" ? 'mobileSelected' : ''}
                id="active"
                onClick={handleTabChange}
              >
                  Active
              </p>
              <p
                style={{
                  borderBottom:tabName === 'completed' ? `2px solid ${redColor}` : 0,
                  color:tabName === 'completed' ? redColor : primaryColor
                }}
                className={tabName === "completed" ? 'mobileSelected' : ''}
                id="completed"
                onClick={handleTabChange}
              >
                Completed
              </p>
            </div>
            
          {isLoading ? (
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "calc(100vh - 203px)",
                  color: primaryColor,
                  backgroundColor: primaryBackgroundColor,
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              </Box>
            </div>
          ) : (
            <main style={{padding:!isMobile && '0'}}  className="mt-4">
              <div style={{
                width:"100%",
                display: 'flex',
                flexDirection:'column'
              }} className="bid-history-bid_cards">
                {(
                  tabName === "active"
                    ? activeBidData
                    : tabName === "completed"
                      ? completedBidData
                      : activeBidData
                ) ? (
                  (tabName === "active"
                    ? activeBidData
                    : tabName === "completed"
                      ? completedBidData
                      : activeBidData
                  ).map((bid, idx) => (
                    <React.Fragment key={idx}>
                {/* <Mobile> */}
                  <NewCardLayout neededLine={false} imageContainerStyle={{height:!isMobile && '150px', width:!isMobile && '150px'}} onCardClick={() => handleNavigate(bid)} image={bid?.assets.find(img => img.context_type === 'portrait')?.name}>
                  <div className="cart-card-content" style={{gap:'5px', color:primaryColor}}>
                    <h1>{bid?.auction_item?.name}</h1>
                    <div className="bid_card_data">
                      <div className="bid_card_amount" style={{color:primaryColor}}>
                        <span >
                        {tabName === "active"
                              ? "Current Bid"
                              : "Winning Bid"}
                        </span>
                        <span style={{color:primaryColor}} >
                        {/* ${" "}{tabName === "active"
                              ? bid.current_bid
                              : bid.max_bid} */}
                              ${Math.round(bid.current_bid)}.00
                        </span>
                      </div>

                      <div className="bid_card_amount">
                      <span>Your Last Bid</span>
                        <span style={{color:primaryColor}} >$ {Math.round(bid.bid_amount)}.00</span>
                      </div>
                    </div>
                  </div>
                  </NewCardLayout>
                {/* </Mobile> */}
                {/* <Desktop>
                   <div  className="des_bid_card" onClick={() => handleNavigate(bid)}>
                    <div
                        className="des_bid_card_banner"   
                    >
                        <img
                            src={bid?.assets.find(img => img.context_type === 'portrait')?.name}
                            alt=""
                            style={{display:imageLoading ? "none" : 'block'}}
                            onLoad={() => setImageLoading(false)}
                            
                        />
                        <ImageSkelton width='130px' loadingState={imageLoading} style={{height:"130px", borderRadius:"25px"}}/>
                    </div>
                    <div className="des_bid_card_details" style={{width:"80%",color:"whitesmoke"}}>
                     
                        <p style={{fontSize:"16px"}}>{bid?.auction_item?.name}</p>
                    <div className="bid_card_data">
                      <div className="bid_card_amount" style={{color:primaryColor,display:"flex",justifyContent:"flex-start",alignItems:"center",columnGap:"50px"}}>
                        <p style={{fontSize:"20px",fontWeight:"400"}}>
                        {tabName === "active"
                              ? "Current Bid"
                              : "Winning Bid"}
                        </p>
                        <p style={{color:primaryColor,fontSize:"20px",fontWeight:"400"}} >
                        ${" "}{tabName === "active"
                              ? bid.current_bid
                              : bid.max_bid}
                        </p>
                      </div>

                      <div className="bid_card_amount" style={{color:primaryColor,display:"flex",justifyContent:"flex-start",alignItems:"center",columnGap:"45px"}}>
                      <p style={{fontSize:"20px",fontWeight:"400"}}>Your Last Bid</p>
                        <p style={{color:primaryColor,fontSize:"20px",fontWeight:"400"}} >$ {bid.bid_amount}</p>
                      </div>
                    </div>
                    
                    </div>
                </div>

          </Desktop> */}
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ display:'flex', justifyContent:'center', marginTop:'50px', fontWeight:600,color:'white' }}>
                    {!isLoading && "No Bid Found!"} 
                  </div>
                )}
              </div>
            </main>
          )}
               
                <NeedHelp style={{marginBottom:'0', paddingBottom:'50px'}} primaryColor={primaryColor}/>
          </div>
        </div>
      </div>
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </div>
  );
}

export default MyBids;
