import React from 'react'
import styles from './RoundedContainer.module.css'
const RoundedContainer = ({children, style, customClassName=""}) => {
  return (
    <div className={`${styles.rounded_container} ${styles[customClassName]}`} style={{...style}} >
     {children}
    </div>
  )
}

export default RoundedContainer