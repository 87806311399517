import classes from './Input.module.css';

const Input = ({ onChange = () => null, value = '', type = 'text', name = '', placeholder = '', required = false, label, customClass = '' }) => {
    return (
        <div className={classes.inputContainer}>
            {label && <label className={classes.label}>{label} {required && <span style={{color: "red"}}>*</span> }</label>}
            <input
                // style={{ border: `1px solid ${primaryColor}`, backgroundColor: primaryBackgroundColor }}
                className={`${classes.inputClass} ${classes[customClass]}`}
                onChange={onChange}
                value={value}
                type={type}
                name={name}
                placeholder={placeholder}
                required={required}
            />
        </div>
    )
}

export default Input;