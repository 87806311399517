import React from 'react'
import classes from "../../../desktop/styles/Home.module.css"

const ImageSkelton = ({width="100%", aspectRatio="1.15", loadingState=false, style={}}) => {
  return (
    <div
     className={classes.skeleton}
      style={{
          width: width,
          aspectRatio: aspectRatio,
          overflow:'hidden',
          display: loadingState ? 'block' : 'none', 
          ...style,
        }}>

    </div>
  )
}

export default ImageSkelton