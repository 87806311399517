import "./ButtonPrimary.css";

const PrimaryBtn = ({ text, handleClick, style=null, type="button"}) => {
  return (
    <div style={{textAlign: "right"}}>
      <button type={type} className="primary-btn--default" onClick={handleClick} style={style ? style : {}}>
      {text}
     </button>
    </div>
  );
};

export default PrimaryBtn;
