import React from 'react'
import classes from './MobileSubFooter.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { Desktop, Mobile } from '../../responsive/Responsive'
import { useMediaQuery } from 'react-responsive'

const MobileSubFooter = ({ primaryBackgroundColor, primaryColor,style={},desktopStyle={},needHelp=false}) => {
  
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  let styles= {};
  if(isMobile){
    styles = {...style}
  }else{
    styles = {...desktopStyle}
  }
    const navigate = useNavigate()
    const backButtonClick = (e) => {
        e.preventDefault();
        if (window.history.length < 1) {
          navigate("/", {
            preventScrollReset: true
          })
          return
        }
        navigate(-1, {
          preventScrollReset: true
        });
      };
    return (
      <>

        <div style={{ backgroundColor: primaryBackgroundColor,...styles }} className={`${classes.new_footer_container} ${isMobile ? "" :"margin_class"}`}>
            <button onClick={backButtonClick} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, borderBottom: `1px solid ${primaryColor}` }} className={classes.new_footer_btn} >
                Back
            </button>
            {needHelp ? 
            <div className={classes?.need_help_mf}style={{color: primaryColor}} >
               <p className={classes?.footer_need_help} style={{marginBottom:'0'}}>Need Help?</p> 
                <a href='mailto:help@boundlessgiving.com' style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, borderBottom: `1px solid ${primaryColor}` }} className={classes.new_footer_btn} >
                Contact Us
            </a>
            </div> :
             <a href='mailto:help@boundlessgiving.com' style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, borderBottom: `1px solid ${primaryColor}` }} className={classes.new_footer_btn} >
                Contact Us
            </a>}
            
            <button style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, borderBottom: `1px solid ${primaryColor}` }} className={classes.new_footer_btn} >
                <Link to={'/faq'}>FAQ</Link>
            </button>
        </div>

      </>
 
    )
}

export default MobileSubFooter