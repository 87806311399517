import classes from './SectionContainer.module.css'

const SectionContainer = ({ children, sectionBg='pure-white', childClass='' }) => {
    return (
        <section className={`${classes.sectionContainer} ${classes[sectionBg]}`}>
            <div className={`${classes.sectionChild} ${classes[childClass]}`}>
                {children}
            </div>
        </section>
    )
}

export default SectionContainer;