

export const InitiateCheckout = (contents) => {
    window.fbq('track','InitiateCheckout',contents);
}
export const AddPaymentInfo = () => {
    window.fbq('track','AddPaymentInfo');
}
export const Purchase = (purchaseDetails) => {
    window.fbq('track','Purchase',purchaseDetails);
}
export const AddToCart = (itemData) => {
    window.fbq('track','AddToCart',itemData);
}
export const ViewContent = (content_type,content_name) => {
    window.fbq('track','ViewContent',{content_type,content_name});
}