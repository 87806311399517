import { useNavigate } from 'react-router-dom';
import TagHeading from '../../atoms/TagHeading/TagHeading';
import classes from './EventCardV2.module.css';
import { ArrowForward } from '@mui/icons-material';
import Image from '../../atoms/Image/Image';
import moment from 'moment'

const EventCardV2 = ({ event }) => {
    const navigate = useNavigate()
    return (
        
        <div onClick={() => navigate(`/event/${event?.custom_url ? event?.custom_url : event?.id}`)} className={classes.eventCardContainer} >
            <div className={classes.imageContainer} >
                {/* {event?.featured_image ?
                    <img src={event?.featured_image} className={classes.bannerImage} loading='lazy' alt={event?.auction_banner_alt} />
                    : */}
                    <Image src={event?.featured_image ||( event?.image2+'_medium')} style={{ maxWidth: '100%', width: '100%', height: '100%', objectFit: 'cover'}} alt={event?.auction_banner_alt} />
                {/* } */}
                {/* <img className={classes.eventLogo} src={event?.event_logo} alt={event?.event_logo_alt} /> */}
            </div>
            <div className={classes.cardContent}>
                <TagHeading heading={event?.cause?.legal_name} customClass='featuredItemCard' />
                <h2 className={`newTitleClass ${classes.itemName}`}>{event?.title}</h2>
                <p className={classes.eventDate}> Ends On:{" "}
                {moment(event?.start_date)<=new Date()?`${ moment(event?.end_date).format("MM/DD/YYYY")}`: `${ moment(event?.start_date).format("MM/DD/YYYY")}`}
                </p>
            </div>
            <div className={classes.linkBox}>
                <div className={classes.link} >
                    <span className={classes.linkText} >View Event</span>
                    <ArrowForward className={classes.arrow} />
                </div>
            </div>
        </div>
    )
}

export default EventCardV2;