import { mobileStyle } from "../../Home";
import NewCardLayout from "../NewCardLayout/NewCardLayout";

const LotsCard = ({ i, asset, clickHandler, primaryBackgroundColor = "white", primaryColor = "black" }) => {

  return (
  //   <div className="lots_card" key={i.id} onClick={() => clickHandler(i)}>
  //   <span className="banner" >
  //     <img className="img" src={asset?.name} alt="feature" />
  //   </span>
  //   <span
  //     style={{
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "space-evenly",
  //       width: "70%",
  //     }}
  //   >
  //     <h6>
  //       { i?.name === i?.short_description ? `${i?.name}` : `${i?.name}` }{" "}
  //     </h6>
  //     <div className="price_details">
  //       <span
  //       style={{marginBootom:0}} >
  //         {
  //           (i.store_item_id!==null)? "Boundless Price":"Bid"
  //         }
  //       </span>
  //       <span
  //         style={{
  //           fontWeight: 800,
  //           color: "var(--primary-blue-clr)",
  //           fontSize:"18px",
  //           marginBottom:0
  //         }}
          
  //       >
  //         ${i?.store_item ? i?.store_item?.price : (i?.highestBid && i?.highestBid[0]?.bid_amount ? i?.highestBid[0]?.bid_amount : i?.price)}
  //       </span>
  //     </div>
  //     <div className="price_details" style={{marginTop:0}}>
  //       <span>
  //         {
  //           (i.buy_now_price!=null  && (i.buy_now_price)!== "0.00")? <span style={{cursor:"pointer",textDecoration:"none" }} onClick={() => clickHandler(i)}>Buy Now</span> : null
  //         }
  //       </span>
  //       <span 
  //       style={{
  //         fontWeight: 800,
  //         color: "var(--primary-blue-clr)",
  //         fontSize:"18px"
  //       }}
  //       >
  //         {
  //            (i.buy_now_price!=null  && (i.buy_now_price)!="0.00")? 
          
  //                       `$${i.buy_now_price}` :""
  //         }
  //       </span>
  //     </div>
  //   </span>
  // </div>

             <>
                <NewCardLayout imageContainerStyle={{width:"147px", height:"147px"}} image={asset?.name} onClick={() => clickHandler(i)}>
                   <div className="cart-card-content">
                     <h1 >{i?.name === i?.short_description ? `${i?.name}` : `${i?.name}`}{" "}</h1>
                     {/* <div style={{marginTop: "-10px", fontSize: "12px", display: "flex", gap: "10px", marginBottom: "10px"}}>
                       {item.auction_item.store_item.variant &&  item.auction_item.store_item.option ? (
                         Object.keys(item.auction_item.store_item.option).map((key) => (
                           <span style={{color:primaryColor}}>{key}: {item.auction_item.store_item.option[key]}</span>
                         ))
                       ) : null}
                     </div> */}
                     <div className="action-footer__bid">
                         {i?.store_item && <div className="search_card_price">
                            {/* <h1  className="list_price_text" style={{color:primaryColor}} > 
                              {
                                 (i?.store_item?.list_price != null && (i?.store_item.list_price) != "0.00") ?
                                 `$${i?.store_item.list_price}` : ""
                              }                              
                              </h1> */}
                              <div  className="details" style={{color:primaryColor}} >
                              {
                                 
                                 ( i?.store_item.list_price != null && (i?.store_item.list_price) !== "0.00") ? 
                                 <>
                                 <span className="bid-text">Retail Price</span>
                                 <span className="price gray-text line-through" style={{ cursor: "pointer" }} onClick={() => clickHandler(i)}>
                                 {`$${Number(i?.store_item.list_price).toFixed(0)}`}</span>
                                 </>
                                  : null
                              }
                              </div>
                           </div> }

                           <div className="details">
                            <span className="bid-text" style={{color:primaryColor}} >
                              {
                                (i.store_item_id !== null) ? "Boundless Price" : "Bid"
                              }
                            </span>
                            <span className="price" style={{color:primaryColor, fontWeight:700}} >
                              ${i?.store_item ? Number(i?.store_item?.price).toFixed(0) : (i?.highestBid && i?.highestBid[0]?.bid_amount ? Number(i?.highestBid[0]?.bid_amount).toFixed(0) : Number(i?.price).toFixed(0))}
                            </span>
                           </div>
                           
                          { (i.buy_now_price != null && i.buy_now_price != "0.00") && 
                           <div className="details">
                              <span className="bid-text" style={{color:primaryColor}} >
                              {
                                 (i.buy_now_price != null && (i.buy_now_price) !== "0.00") ? <span style={{ cursor: "pointer", textDecoration: "none" }} onClick={() => clickHandler(i)}>Buy Now</span> : null
                              }
                              </span>
                             <span  className="price" style={{color:primaryColor, fontWeight:700}} > 
                              {
                                 (i.buy_now_price != null && (i.buy_now_price) != "0.00") ?`$${Number(i.buy_now_price).toFixed(0)}` : ""
                              }                              
                              </span>
                           </div>
                           }
                         { (i.store_item && i.store_item.quantity_in_stock < 5 && i.store_item.quantity_in_stock > 0) && <p style={{color:mobileStyle.color[0]}} className="quantity_left_text">{`only ${i?.store_item.quantity_in_stock} left`}</p>}
                     </div>
                   </div>
              </NewCardLayout>
        </>
  );
};

export default LotsCard;
