import { useNavigate } from 'react-router-dom';
import classes from './CategoryCardV2.module.css';

const CategoryCardV2 = ({category}) => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(`/${category?.id}/browse-items?item-type=browse-category`)
    }
    return (
        <div onClick={handleClick} className={classes.categoryCard} >
            <div className={classes.catImageContainer} >
                <img
                    src={require(`../../../../images/categoryIcons/300x200/${category?.image}`)}
                    alt={category?.name}
                />
            </div>
            <h2 className={classes.categoryName} >{category?.name}</h2>
        </div>
    )
}

export default CategoryCardV2;