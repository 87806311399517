import React, { useEffect, useState } from 'react';
import { Desktop, Mobile } from '../../../responsive/Responsive';
import { Link, useNavigate } from "react-router-dom";
import classes from './Events.module.css';
import commonClasses from "../../../../desktop/styles/Home.module.css"
import logger from '../../../../../utils/logger';
import auctionServices from '../../../../../services/auction-services';
import moment from 'moment-timezone';
import styles from '../NewHomePageComponents/SplashCarosel/splashCarosel.module.css'
// import eventFerrriWheel from '../../../../../images/newHomeImages/eventFerrriWheel.jpeg'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ImageSkelton from '../../../molecules/ImageSkelton/ImageSkelton';
import { imageUrls } from '../../../../../constants/url';

const eventFerrriWheel = imageUrls.home_page_event_ferrari_wheel;
function Events({ mobileStyle = {} }) {
    const navigate = useNavigate();
    const [auctionList, setAuctionList] = useState([])
    const [completedAuctionList, setcompletedAuctionList] = useState([])
    const [previewAuctionList, setPreviewAuctionList] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [bannerLoading, setBannerLoading] = useState(true)

    const backgroundColor = mobileStyle?.backgroundColor[0]
    const color = mobileStyle?.color[0]

    const getUpcomingAndActiveAuctions = async () => {
        try {
            await auctionServices.getAllAuctionsList(page, limit).then(
                (response) => {
                    setAuctionList(response);
                    //   setIsLoading(false);
                },
                (error) => {
                    logger('./pages/components/Home.jsx', 'getCurrentAuctions(): catch1', error);
                    //   setIsLoading(false);
                }
            );
        } catch (err) {
            logger('./pages/components/Home.jsx', 'getCurrentAuctions(): catch2', err);
            //   setIsLoading(false);
        }
    };

    const getCompletedAuctions = async () => {
        try {
            await auctionServices.getAllCompletedAuctionsList(page, limit).then(
                (response) => {
                    setcompletedAuctionList(response)
                    //   setIsLoading(false);
                },
                (error) => {
                    logger('./pages/components/Home.jsx', 'getCompletedAuctions(): catch1', error);
                    //   setIsLoading(false);
                }
            );
        } catch (err) {
            logger('./pages/components/Home.jsx', 'getCompletedAuctions(): catch2', err);
            //   setIsLoading(false);
        }
    };

    const getPreviewAuctions = async () => {
        try {
            await auctionServices.getAllPreviewAuctionsList(page, limit).then(
                (response) => {
                    setPreviewAuctionList(response)
                    //   setIsLoading(false);
                },
                (error) => {
                    logger('./pages/components/Home.jsx', 'getPreviewAuctions(): catch1', error);
                    //   setIsLoading(false);
                }
            );
        } catch (err) {
            logger('./pages/components/Home.jsx', 'getPreviewAuctions(): catch2', err);
            //   setIsLoading(false);
        }
    };

    useEffect(() => {
        getUpcomingAndActiveAuctions();
        getCompletedAuctions();
        getPreviewAuctions();
    }, [])
    return (
        <>
            <Desktop>

                {
                    (!auctionList?.data?.length && !previewAuctionList?.data?.length && !completedAuctionList?.data?.length) ? null : (
                        <section className={classes.dcurrent_events_container} style={{ margin: "50px 0" }}>
                            <div className={`${classes.dsection} ${classes.dcurrent_events}`}>
                                <h3>Browse Boundless Events </h3>
                                <div className={classes.dcurrent_cards}>
                                    {auctionList &&
                                        auctionList?.data?.length > 0 &&
                                        auctionList?.data?.map((currAuc) => (
                                            <div key={currAuc.id} className={classes.dcurrent_card} onClick={() =>
                                                navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc?.id}`)
                                            }>
                                                <div className={classes.dcurrent_card_img}>
                                                    <img src={currAuc?.image1} loading='lazy' alt="" style={{ cursor: "pointer" }} />
                                                </div>
                                                <div className={classes.dcurrent_card_content}>
                                                    <div className={classes.dcurrent_card_about}>
                                                        The {currAuc?.title} Benefitting <br />
                                                        {currAuc?.cause?.legal_name}
                                                    </div>
                                                    <div onClick={() =>
                                                        navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc?.id}`)
                                                    } className={classes.dcurrent_card_btn}>Explore</div>
                                                </div>
                                            </div>
                                        ))}
                                    {previewAuctionList &&
                                        previewAuctionList?.data?.length > 0 &&
                                        previewAuctionList?.data?.map((currAuc) => (
                                            <div key={currAuc.id} className={classes.dcurrent_card} onClick={() =>
                                                navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc?.id}`)
                                            }>
                                                <div className={classes.dcurrent_card_img}>
                                                    <img src={currAuc?.image1} loading='lazy' alt="" style={{ cursor: "pointer" }} />
                                                </div>
                                                <div className={classes.dcurrent_card_content}>
                                                    <div className={classes.dcurrent_card_about}>
                                                        The {currAuc?.title} Benefitting <br />
                                                        {currAuc?.cause?.legal_name}
                                                    </div>
                                                    <div onClick={() =>
                                                        navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc?.id}`)
                                                    } className={classes.dcurrent_card_btn}>Preview</div>
                                                </div>
                                            </div>
                                        ))}
                                    {completedAuctionList &&
                                        completedAuctionList?.data?.length > 0 &&
                                        completedAuctionList?.data?.map((currAuc) => (
                                            <div key={currAuc.id} className={classes.dcurrent_card} onClick={() =>
                                                navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc?.id}`)
                                            }>
                                                <div className={classes.dcurrent_card_img}>
                                                    <img src={currAuc?.image1} loading='lazy' alt="" style={{ cursor: "pointer" }} />
                                                </div>
                                                <div className={classes.dcurrent_card_content}>
                                                    <div className={classes.dcurrent_card_about}>
                                                        The {currAuc?.title} Benefitting <br />
                                                        {currAuc?.cause?.legal_name}
                                                    </div>
                                                    <div onClick={() =>
                                                        navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc?.id}`)
                                                    } className={classes.dcurrent_card_btn}>Completed</div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </section>
                    )
                }
            </Desktop>
            <Mobile>

                {/* {(!auctionList?.data?.length && !previewAuctionList?.data?.length && !completedAuctionList?.data?.length) ?
                    <div style={{ marginTop: "20px" }}></div> : (
                        <div style={{ margin: "20px 0px 0px" }} className="current_auction">
                            <div style={{paddingRight:'31px'}} className="page_heading">
                                <h3 style={{marginBottom:"20px"}}>
                                    Browse Boundless Events
                                    <span>
                                        <Link
                                            to="/auction?type=all"
                                            className="viewAllText"
                                        >
                                            View All
                                        </Link>
                                    </span>
                                </h3>
                            </div>
                            <section className="scrollHomedata" style={{ marginTop: "15px !important", paddingRight:'30px' }}>
                                <div
                                    style={{ display: "flex", overflow: "scroll", gap: '20px' }}
                                    className="auctionDiv2"
                                >
                                    {auctionList &&
                                        auctionList?.data?.length > 0 &&
                                        auctionList?.data?.map((currAuc) => (
                                            <div
                                                onClick={() =>
                                                    navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc.id}`, { state: { auction: currAuc, image: currAuc?.image2, from: 'event' } })
                                                }
                                                key={currAuc.id} className={`spec_auc_div ${classes.label_container}`}>
                                                {new Date(moment.tz(currAuc.start_date, "America/Los_Angeles").format()) > new Date() ? (
                                                    <button style={{ right: "10px", fontWeight: "700", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }} className="imgDesc">
                                                        Opens {" "}{moment(currAuc.start_date).tz("America/Los_Angeles").add(0, 'days').fromNow()}

                                                    </button>
                                                ) : (
                                                    <button style={{ right: "10px", fontWeight: "700", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }} className="imgDesc">
                                                        Now Open!
                                                    </button>
                                                )}
                                                <img src={currAuc?.image1} alt="" />
                                            </div>
                                        ))}
                                    {previewAuctionList &&
                                        previewAuctionList?.data?.length > 0 &&
                                        previewAuctionList?.data?.map((currAuc) => (
                                            <div
                                                onClick={() =>
                                                    navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc.id}`, { state: { auction: currAuc, image: currAuc?.image2, from: 'event' } })
                                                }
                                                key={currAuc.id} className={`spec_auc_div ${classes.label_container}`}>
                                                <button style={{ right: "10px", fontWeight: "700", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }} className="imgDesc">
                                                    Preview
                                                </button>
                                                <img src={currAuc?.image1} alt="" />
                                            </div>
                                        ))}
                                    {completedAuctionList &&
                                        completedAuctionList?.data?.length > 0 &&
                                        completedAuctionList?.data?.map((currAuc) => (
                                            <div
                                                onClick={() =>
                                                    navigate(`/event/${currAuc.custom_url ? currAuc.custom_url : currAuc.id}`, { state: { auction: currAuc, image: currAuc?.image2, from: 'event' } })
                                                }
                                                key={currAuc.id} className={`spec_auc_div ${classes.label_container}`}>
                                                <button style={{ right: "10px", fontWeight: "700", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }} className="imgDesc">
                                                    Event Closed
                                                </button>
                                                <img src={currAuc?.image1} alt="" />
                                            </div>
                                        ))}
                                </div>
                            </section>
                        </div>
                    )} */}

                <section style={{ backgroundColor: backgroundColor }} className={commonClasses.dhero}>
                    <div className={styles.carosel_container} style={{ display: 'flex' }}>
                        <img onLoad={() => setBannerLoading(false)} src={eventFerrriWheel} loading='lazy' alt="Events" style={{ display: bannerLoading ? 'none' : 'block' }} />
                        <ImageSkelton width='96%' loadingState={bannerLoading} style={{ position: "relative", margin: 'auto', borderRadius: '15px', minHeight: '530px' }} />
                        <div style={{ color: color, display: bannerLoading ? 'none' : 'block' }}>
                            <h4>Therapy shopping:<br /> Now <br /> upto 80% more therapeutio</h4>
                            <ExpandCircleDownOutlinedIcon sx={{ fontSize: '48px', float: 'left' }} />
                        </div>
                    </div>
                </section>

            </Mobile>
        </>
    )
}

export default Events