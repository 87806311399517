import React from 'react';
import FeaturedEvents from '../FeaturedEvents/FeaturedEvents';


const FeaturedShop = ({mobileStyle={}, title='Featured Shop'}) => {
    return (
        <FeaturedEvents page={'store'} mobileStyle={mobileStyle} title={title} />
    )
}

export default FeaturedShop;