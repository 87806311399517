import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import logger from '../utils/logger'
import auctionServices from '../services/auction-services';

export const getAllCategoriesHaveItem = createAsyncThunk(
    'redux/getAllCategoriesHaveItem',
    async () => {
        const {data} = await auctionServices.fetchAllAvailableCategories()
        return data
    }
)

const initialState = {
    categories: [],
    loading: false,
    error: '',
}

const categorySlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCategoriesHaveItem.pending, (state, action) => {
             state.loading = true;
             state.error = ''
         })

         builder.addCase(getAllCategoriesHaveItem.fulfilled, (state, action) => {
            state.categories = action.payload;         
            state.loading = false;
            state.error = '';
        })

        builder.addCase(getAllCategoriesHaveItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            logger('./redux/categoryReducer.js', 'getAllCategoriesHaveItem(): catch', action.error);
        })
 
    }
})

export default categorySlice.reducer;